import { get, injectTranslations } from "ia-react-core";
import { FC } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { State } from "../../../interfaces/State";
import { Language } from "../../enum/Language";
import ErrorBoundary, { ErrorBoundaryMapStateToPropsProps, ErrorBoundaryProps } from "./ErrorBoundary";
import en from "./translations/en";
import fr from "./translations/fr";

const mapStateToProps = (state: State): ErrorBoundaryMapStateToPropsProps => ({
  error: get(state, "App.error"),
});

export default compose<FC<ErrorBoundaryProps>>(
  injectTranslations("ErrorBoundary", [
    { language: Language.French, resource: fr },
    { language: Language.English, resource: en },
  ]),
  connect(mapStateToProps, null),
)(ErrorBoundary);
