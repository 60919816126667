import { TFunction } from "i18next";
import { Col, Control, Form, Row } from "ia-react-core";
import * as React from "react";

import { SearchCriteria } from "../../../../common/interfaces/SearchCriteria";
import RepCodeSelector from "./components/RepCodeSelector";
import SearchButtonReset from "./components/SearchButtonReset";
import { Label, SearchContainer, SubmitButton, TextControl, Title } from "./Search.style";

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface SearchProps {
  t: TFunction;
  onSearch: (criteria: string, repCodes: string[]) => void;
  onReset: () => void;
  loading: boolean;
  repCodes: string[];
  searchCriteria: SearchCriteria;
  isSupport: boolean;
}

// eslint-disable-next-line no-useless-escape
// const regexSearchFormater = /[-`~!#$%^&*()_|+=?;:±£¢¤¬¦²³¼½¾"<>\{\}\[\]\\\/]/gi;
const formName = "clientSearch";
class Search extends React.PureComponent<SearchProps> {
  private search = (formValues: SearchCriteria): void => {
    const { onSearch, onReset, loading, repCodes } = this.props;

    if (!("text" in formValues) && !("repCodes" in formValues)) {
      onReset();
      return;
    }

    if (!formValues.text && !formValues.repCodes) {
      onSearch("", repCodes);
      return;
    }

    const repCodesUseToSearch = formValues.repCodes.length !== 0 ? formValues.repCodes : repCodes;

    if (!loading) {
      onSearch(formValues.text.trim(), repCodesUseToSearch);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private searchCriteriaFormater = (value: any) => {
    if (value === " ") {
      return "";
    }
    return value;
    // return value.replace(regexSearchFormater, "");
  };

  private renderAdvisorSelector = () => {
    const { repCodes, t, isSupport } = this.props;
    const name = "repCodes";

    if (isSupport || repCodes.length <= 1) {
      const repCode = repCodes.length === 1 ? repCodes[0] : "";
      return (
        <Control name={name} defaultValue={repCode}>
          {() => <input type="hidden" name={name} />}
        </Control>
      );
    }
    return (
      <Col xs={12} md={3} p-b-xs={16}>
        <Label>{t("clientSearch.filter.repCode")}</Label>
        <RepCodeSelector repCodes={repCodes} t={t} name={name} />
      </Col>
    );
  };

  public render() {
    const { t, loading, searchCriteria } = this.props;

    return (
      <SearchContainer>
        <Title>{t("App:clientSearch.title")}</Title>

        <Form<SearchCriteria> defaultCol={1} name={formName} onSubmit={(formValues: SearchCriteria) => this.search(formValues)} initialValues={searchCriteria}>
          <Row>
            <Col xs={12} md={5} p-b-xs={16}>
              <Label>{t("clientSearch.placeholder")}</Label>
              <TextControl
                name="text"
                   valueToStateFormatters={[{ formatter: (value: any) => value, deferred: true }]}
                // valueToStateFormatters={[{ formatter: (value: any) => value.replace(regexSearchFormater, ""), deferred: true }]}
                stateToValueFormatters={[
                  {
                    formatter: this.searchCriteriaFormater,
                  },
                ]}
              />
            </Col>
            {this.renderAdvisorSelector()}
            <Col xs={12} md={4} p-t-md={28}>
              <SubmitButton disabled={loading}>{t("clientSearch.submit")}</SubmitButton>
              <SearchButtonReset name={t("clientSearch.reset")} disabled={loading} formName={formName} />
            </Col>
          </Row>
        </Form>
      </SearchContainer>
    );
  }
}

export default Search;
