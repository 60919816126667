import styled from "styled-components";
import { Button } from "../../../../../common/common.style";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  
  padding-left: 0px;
  padding-right: 20px;
  padding-bottom: 27px;

`;

export const ToggleSendDocumentButton = styled(Button)`
  padding-right: 20px;
  height: 45px;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  
  svg {
    fill: ${(props) => props.theme.colors.white};
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
  }

  @media(max-width: 500px) {
    margin-top: 10px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;

  @media(max-width: 500px) {
    flex-direction: column;
    align-items:flex-start;
  }
`;

export const InformativeMessage = styled.h5`

`;

export const Seperation = styled.hr`
  border: none;
  background-color: ${(props) => props.theme.colors.gray10};
  color: ${(props) => props.theme.colors.gray10};  
  height: 1px;
  margin: 0 0 20px 0;
`;

export const BackToDocumentList = styled.a`
  margin-bottom: 10px;
  margin-right: auto;
  display: block;
  color: ${(props) => props.theme.colors.blue5};
  cursor: pointer;

  :hover {
    color: ${(props) => props.theme.colors.blue3};
  }

  svg {
    vertical-align: text-top;
    margin-left: -6px;
  }
`;

export const LoadMoreContainer = styled.div`
  margin: 20px 0;
  text-align: center;
  width: 100%;
`;

export const TableSection = styled.div`
  margin-top: 20px;
`;
