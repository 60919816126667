import { Link } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";

export const GlobalTooltipStyle = createGlobalStyle`
 .ias-popover .ant-popover-inner {
    background: white;
  }

  .ias-popover > .ant-popover-content > .ant-popover-arrow,
  .ias-popover.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow{
    right: 0.4rem;
    top: 0.05rem;

    /* Border */
    border-left: 1px solid #7f7f7f;
    border-top: 1px solid #7f7f7f;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: white;
  }
 .ias-popover > .ant-popover-content > .ant-popover-inner >  .ant-popover-inner-content{
    display: table-caption;
    min-width: 150px;
    padding: 0;
    border: 1px solid #7f7f7f;
 }
 .ant-popover-placement-bottomRight{
   padding: 0;
 }
`;

export const Styled = styled.div``;

export const ButtonAction = styled.button`
  background: transparent;
  display: block;
  color: ${(props) => props.theme.colors.blue5};
  outline: none;
  cursor: pointer;
  padding: 0 5px;
  width: 100%;
`;

export const LinkRouterAction = styled(Link)`
  background: transparent;
  display: block;
  color: #333333;
  padding: 7px 2px 7px 15px;
  width: 100%;
  position: relative;
  :hover {
    background-color: #e3f2fd;
    color: #2062d4;
  }
`;

export const LinkAction = styled.a`
  background: transparent;
  display: block;
  color: #333333;
  padding: 7px 2px 7px 15px;
  width: 100%;
  position: relative;
  :hover {
    background-color: #e3f2fd;
    color: #2062d4;
  }
`;

export const Separator = styled.hr`
  border: none;
  border-bottom: 1px solid rgba(215, 215, 215, 1);
`;

export const InvisibleSeparator = styled.hr`
  border: none;
  margin: 0;
`;
