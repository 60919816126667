import { ChartData, ChartPoint } from "chart.js";
import { TFunction } from "i18next";
import { Moment } from "moment";

import InvestmentObjectiveLevel from "../enum/InvestmentObjectiveLevel";
import { InvestmentObjective } from "../interfaces/InvestmentObjective";

export class InvestmentObjectiveHelper {
  static getLabel(level: InvestmentObjectiveLevel, t: TFunction): string {
    switch (level) {
      case InvestmentObjectiveLevel.Income:
        return t("App:investmentObjective.income");
      case InvestmentObjectiveLevel.Growth:
        return t("App:investmentObjective.growth");
      case InvestmentObjectiveLevel.Speculative:
        return t("App:investmentObjective.speculative");
      case InvestmentObjectiveLevel.Liquidity:
        return t("App:investmentObjective.liquidity");
      case InvestmentObjectiveLevel.Safety:
        return t("App:investmentObjective.safety");
      default:
        return t("App:investmentObjective.unknown");
    }
  }

  static getBackgroundColor(level: InvestmentObjectiveLevel): string {
    switch (level) {
      case InvestmentObjectiveLevel.Income:
        return "#003DA5";
      case InvestmentObjectiveLevel.Growth:
        return "#407BFF";
      case InvestmentObjectiveLevel.Speculative:
        return "#7BC2D9";
      case InvestmentObjectiveLevel.Liquidity:
        return "#C0CA33";
      case InvestmentObjectiveLevel.Safety:
        return "#B6B8DC";
      default:
        return "";
    }
  }

  static getInvestmentObjectiveDataChart(investmentsObjective: InvestmentObjective[], t: TFunction): ChartData {
    if (!investmentsObjective) {
      return {};
    }

    const values = [...investmentsObjective];
    const labels: Array<string | string[] | number | number[] | Date | Date[] | Moment | Moment[]> = [];
    const data: Array<number | null | undefined | number[]> | ChartPoint[] = [];
    const backgroundColor: string[] = [];
    const hoverBackgroundColor: string[] = [];
    values.sort((a: InvestmentObjective, b: InvestmentObjective) => a.level - b.level);

    values
      .filter((investmentObjective: InvestmentObjective) => investmentObjective.percentage > 0)
      .forEach((investmentObjective: InvestmentObjective) => {
        const percentage = investmentObjective.percentage * 100;
        labels.push(`${InvestmentObjectiveHelper.getLabel(investmentObjective.level, t)}: ${percentage}%`);
        data.push(percentage);
        backgroundColor.push(InvestmentObjectiveHelper.getBackgroundColor(investmentObjective.level));
      });

    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor,
          hoverBackgroundColor,
        },
      ],
    };
  }
}
