export interface EventLog {
    id: number;
    eventType: number;
    payload: string;
    payloadCategory: string;
    modifiedBy: string;
    owner: string;
    message: string;
    timeStamp: Date;
}

export enum EventLogCategory {
    LOGIN = 'LOGIN',
    MOBILE_LOGIN = 'MOBILE LOGIN',
    ACCOUNT_SHARING = 'ACCOUNT-SHARING',
    ACCOUNT_HOUSEHOLD = 'ACCOUNT-HOUSEHOLD',
    DELIVERYPREDERENCES_UPDATED = 'DELIVERY-PREFERENCES',
    DOCUMENT_SHARING= 'DOCUMENT-SHARING',
    MAILNOTIFICATION_UPDATED = 'MAIL-NOTIFICATION',
    SHARED = 'SHARED',
    UNSHARED = 'UNSHARED',
    NONCLIENT = 'NONCLIENT',
    CONSENT = "CONSENT",
    PASSWORD_RESET = "PASSWORD-RESET",
    UNLOCK_ACCOUNT = "UNLOCK-ACCOUNT",
}
