import * as React from "react";

import { HidableText } from "../interfaces/HidableText";
import HideInfo from "./HideInfo";
import { ClickableItem } from "./tabs/profile/profileTab.style";

export interface HidableInfoProps {
    info: string | JSX.Element | undefined;
    text: HidableText;
}

interface LocalState {
    isInfoShown: boolean;
}

export default class HidableInfo extends React.PureComponent<HidableInfoProps, LocalState> {
  constructor(props: HidableInfoProps) {
    super(props);

    this.state = { isInfoShown: false };
  }

    private setIsInfoShown = () => {
      const { isInfoShown } = this.state;
      this.setState({ isInfoShown: !isInfoShown });
    }

    public render() {
      const { isInfoShown } = this.state;
      const { info, text } = this.props;

      if (!isInfoShown) return <ClickableItem onClick={this.setIsInfoShown}>{text.show}</ClickableItem>;

      return <HideInfo info={info} click={this.setIsInfoShown} hide={text.hide} />;
    }
}
