export default {
  title: "Dossiers de signature",
  status: {
    awaiting: "En attente de signature",
    expired: "Expiré",
    cancelled: "Annulé",
    locked: "Signataire verrouillé",
    completed: "Complété",
    refused: "Refusé",
    emailrebound: "Courriel non reçu",
    turnPending: "Tour à venir",
  },
};
