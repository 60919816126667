import styled, { createGlobalStyle } from "styled-components";

export const GlobalTreeViewSelectStyle = createGlobalStyle`
 .ias-treeview-select .ant-select-tree .ant-select-tree-switcher {
  display: none;
}

.ias-treeview-select .ant-select-tree-list-holder-inner {
  margin-left: 20px;
}

.ias-treeview-select .ant-select-tree-checkbox-inner {
  border-width: 1px;
}

.ias-treeview-select .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
  background-color: #2062d4;
}

.ias-treeview-select  .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: #2062d4;
  border-color: #2062d4;
}

`;

export const Container = styled.div`
  width: 100%;
  flex: 0 0 auto;

  .ant-select-multiple .ant-select-selector {
    padding: 8px 9px 10px 9px;
    border-color: ${(props) => props.theme.colors.gray3};
    border-bottom-color: ${(props) => props.theme.colors.gray4};
    height: 40px;
    color: ${(props) => props.theme.colors.black};
  }
  .ant-select-multiple.has-data .ant-select-selector {
    border-bottom-color: ${(props) => props.theme.colors.blue5};
  }

  .ant-select-show-search.ant-select-multiple .ant-select-selector {
    cursor: pointer;
  }

  .ant-select-multiple .ant-select-selection-item {
    background: transparent;
    border: none;
    margin-left: 0;
    padding-left: 0;
    padding-right: 15px;
    margin-top: 0;
    margin-bottom: 4px;
    cursor: pointer;
  }

  .ant-select-arrow {
    color: ${(props) => props.theme.colors.blue5};
  }
`;

export const CheckboxGroup = styled.div`
  display: block;
`;

export const Title = styled.span`
  color: #757575;
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 1.15;
  display: inline-block;
`;
