enum SignaturePackageActionEvent {
  cancel,
  downloadEvidenceSummary,
  downloadSignedDocuments,
  emailBounce,
  unlock,
  selectedSignaturePackage,
}

export default SignaturePackageActionEvent;
