import { ColumnType } from "antd/lib/table";
import { InjectTranslationsProps, Loader } from "ia-react-core";
import React from "react";
import { Text } from "../../../../../../../common/common.style";
import { Attachment } from "../../../../../../../common/messages/interfaces/Attachment";

export const StatusColumn = ({ t }: InjectTranslationsProps): ColumnType<Attachment> => ({
  title: t("documentsTab.statusName"),
  dataIndex: "isUnshared",
  width: "10%",
  key: "status",
  render: (value: string, record: Attachment) => {
    const showStatusText = value ? t("documentsTab.unshared") : t("documentsTab.shared");
    return <> {record.isResharing || record.isUnsharing ? <Loader size={24} style={{ margin: '0 0 0 10px' }} /> : <Text>{showStatusText}</Text> } </>;
  },

});
