import {
  JointAccountHolderActionTypes as types,
  GetJointAccountHoldersByAccounts,
  GetJointAccountHoldersByAccountsError,
  GetJointAccountHoldersByAccountsSuccess,
} from "./jointAccountHolders.actions.types";
import { ClientAccount } from "../../interfaces/Account";
import { Client } from "../../interfaces/Client";

export const getJointAccountHoldersByAccounts = (accounts: ClientAccount[], clientId: string): GetJointAccountHoldersByAccounts => ({ type: types.GET_JOINT_ACCOUNT_HOLDERS_BY_ACCOUNTS, accounts, clientId });

export const getJointAccountHoldersByAccountsSuccess = (response: Client[]): GetJointAccountHoldersByAccountsSuccess => ({ type: types.GET_JOINT_ACCOUNT_HOLDERS_BY_ACCOUNTS_SUCCESS, response });

export const getJointAccountHoldersByAccountsError = (accounts: ClientAccount[], clientId: string): GetJointAccountHoldersByAccountsError => ({ type: types.GET_JOINT_ACCOUNT_HOLDERS_BY_ACCOUNTS_ERROR, accounts, clientId });
