import { InjectTranslationsProps } from "ia-react-core";
import React, { useCallback, useState } from "react";
import { Client } from "../../../../../common/client/interfaces/Client";
import { IParty } from "../../../../../common/client/interfaces/Party";
import SettingsTagsValues from "../../../../../common/enum/SettingsTagsValue";
import { Container } from "../documents/DocumentsTab.style";
import AccessSharing from "./accessSharing/AccessSharing";
import { SettingsHeader } from "./SettingsTab.style";
import SettingsTags from "./SettingsTags";
import { REACT_APP_SHOW_ACCESS_SHARING, REACT_APP_TOGGLE_USERMANAGEMENT } from "../../../../../constants/API";
import UserManagement from "./userManagement/UserManagement";

export interface SettingsTabStateToProps {

}

export interface SettingsTabProps {
  client: Client;
  repCodes: string[];
  selectedClientLocked: boolean;
}

interface SettingsTabComposedProps extends SettingsTabStateToProps, SettingsTabProps, InjectTranslationsProps { }
const SettingsTab: React.FC<SettingsTabComposedProps> = ({ client, repCodes, selectedClientLocked, t, i18n }: SettingsTabComposedProps): JSX.Element => {
  const [selectedTag, setSelectedTag] = useState<string>(SettingsTagsValues.sharedAccess);

  const onFilterSelected = useCallback((selectedValue: SettingsTagsValues): void => {
    setSelectedTag(selectedValue);
  }, []);

  const lang = i18n.language;
  return (
    <Container>
      <SettingsHeader>
        <SettingsTags onFilterChange={onFilterSelected} t={t} />
      </SettingsHeader>
      <Container>
        {/* {selectedTag == SettingsTagsValues.household && t("documentsTab.comingSoon")}
        {selectedTag == SettingsTagsValues.performance && t("documentsTab.comingSoon")} */}
        {REACT_APP_TOGGLE_USERMANAGEMENT && selectedTag === SettingsTagsValues.userManagement && (<UserManagement client={client as IParty} isLocked={selectedClientLocked} t={t} />)}
        {REACT_APP_SHOW_ACCESS_SHARING && selectedTag === SettingsTagsValues.sharedAccess && (<AccessSharing client={client as IParty} t={t} lang={lang} accessToken="" repCodes={repCodes} />)}
      </Container>
    </Container>
  );
};

export default SettingsTab;
