export default {
  title: "Advisor Contact Card",
  iAWealthAdviceCentre: "iA Wealth Advice Centre",
  team: "Team",
  regulatoryRoles: {
    trader: "Trader",
    licensedAssistant: "Licensed Assistant",
    chiefComplianceOfficer: "Chief Compliance Officer",
    supervisor: "Supervisor",
    investmentAdvisor: "Investment Advisor",
    corporateBranchManager: "Corporate Branch Manager",
    seniorInvestmentAdvisor: "Senior Investment Advisor",
    branchAdministrator: "Branch Administrator",
    seniorFinancialAdvisor: "Senior Financial Advisor",
    regionalVicePresident: "Senior Investment Advisor",
    supervisingPortfolioManager: "Portfolio Manager",
    seniorWealthAdvisor: "Senior Wealth Advisor",
    seniorWealthAdvisorFemale: "Senior Wealth Advisor",
    wealthAdvisor: "Wealth Advisor",
    associateInvestmentAdvisor: "Associate Investment Advisor",
    portfolioManager: "Portfolio Manager",
    seniorPortfolioManager: "Senior Portfolio Manager",
    unlicensedAssistant: "",
    seniorBranchManager: "Senior Branch Manager",
    portfolioManagerUnderSupervision: "Portfolio Manager",
    chiefFinancialOfficer: "Chief Financial Officer",
    qcFinancialPlanner: "QC Financial Planner",
    institutionalSupervisor: "Institutional Supervisor",
    executive: "Investment Advisor",
    other: "",
  },
};
