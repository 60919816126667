import * as React from "react";

import { Close } from "../../../../../common/svg";
import { RemoveSelection, SearchFieldContainer, TextInput } from "../AutoComplete.style";

export interface AutoCompleteSearchFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  placeholder: string;
  name: string;
  onValueChange: (value: string) => void;
  hasValueSelected: boolean;
  onRemove: () => void;
}

// eslint-disable-next-line no-useless-escape
const regexSearchFormater = /[-`~!#$%^&*()_|+=?;:±£¢¤¬¦²³¼½¾.,@"<>\{\}\[\]\\\/]/gi;

class AutoCompleteSearchField extends React.PureComponent<AutoCompleteSearchFieldProps> {
  private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onValueChange } = this.props;
    let { value } = event.target;

    if (value === " ") {
      value = "";
    }

    onValueChange(value);
  };

  private onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (regexSearchFormater.test(event.key)) event.preventDefault();
  };

  public render() {
    const { placeholder, name, hasValueSelected, value, onRemove } = this.props;

    return (
      <SearchFieldContainer hasValueSelected={hasValueSelected}>
        <TextInput name={name} onChange={this.onChange} placeholder={placeholder} value={value} onKeyPress={this.onKeyPress} disabled={hasValueSelected} />
        {hasValueSelected && <RemoveSelection onClick={onRemove}>{Close()}</RemoveSelection>}
      </SearchFieldContainer>
    );
  }
}

export default AutoCompleteSearchField;
