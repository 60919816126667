import { ColumnType } from "antd/lib/table";
import { ExpandableConfig } from "antd/lib/table/interface";
import { TFunction } from "i18next";
import { RenderExpandIconProps } from "rc-table/lib/interface";
import * as React from "react";
import { Col, Row } from "react-grid-system";

import { Account } from "../../../../../../common/account/interfaces/Account";
import { expandIcon } from "../../../../../../common/table/ExpandIcon";
import StyledTable from "../../../../../../common/table/StyledTable";
import { AccountGroupedByOwner } from "../../../../interfaces/AccountGroupedByOwner";
import { AccountTypeColumn, IdColumn, ProductTypeColumn } from "../../profile/AccountsTable/Columns";
import { OwnerLevelItem } from "../investorProfileTab.style";
import InvestmentObjectiveChart from "./InvestmentObjectiveChart";
import RiskToleranceChart from "./RiskToleranceChart";
import TimeHorizonInfo from "./TimeHorizonInfo";

export interface OwnerObjectivesProps {
  t: TFunction;
  dataSource: AccountGroupedByOwner;
  locale: string;
  loadingAccounts: boolean;
}

const tableColumns = (props: OwnerObjectivesProps): ColumnType<Account>[] => {
  const columns: ColumnType<Account>[] = [IdColumn(), AccountTypeColumn(props), ProductTypeColumn()];
  return columns;
};

const expandable = (props: OwnerObjectivesProps): ExpandableConfig<Account> => {
  const { t } = props;
  return {
    rowExpandable: () => true,
    expandedRowRender: (record: Account) =>
      (
        <>
          <TimeHorizonInfo data={record.timeHorizon} t={t} />
          <Row>
            <Col>
              <InvestmentObjectiveChart t={t} data={record.investmentObjectives} />
            </Col>
            <Col>
              <RiskToleranceChart t={t} data={record.riskTolerances} />
            </Col>
          </Row>
        </>),
    expandIconColumnIndex: 4,
    expandIcon: (expandProps: RenderExpandIconProps<Account>) => expandIcon<Account>(expandProps),
  };
};

const OwnerObjectives: React.FC<OwnerObjectivesProps> = (props: OwnerObjectivesProps) => {
  const { loadingAccounts, t, dataSource } = props;
  return (
    <>
      <OwnerLevelItem>{dataSource.owner}</OwnerLevelItem>
      <StyledTable
        className="no-border"
        showHeader={false}
        t={t}
        dataSource={dataSource.accounts}
        loading={loadingAccounts}
        columns={tableColumns(props)}
        rowKey="id"
        pagination={false}
        expandable={expandable(props)}
      />
    </>);
};

export default OwnerObjectives;
