import { Table } from "antd";
import * as React from "react";

import { StyledTableProps } from "./StyledTable";
import { StyledExpendable } from "./StyledTable.style";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ExpandableStyledTableProps<RecordType extends object = any> extends StyledTableProps<RecordType> {}

const ExpandableStyledTable: React.FC<ExpandableStyledTableProps> = (props: ExpandableStyledTableProps) => (
  <StyledExpendable>
    <Table className="expendable-table" {...props} pagination={false} showHeader={false} />
  </StyledExpendable>
);

export default ExpandableStyledTable;
