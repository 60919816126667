export function DateDiffInDays(d1: Date, d2: Date): number {
  // Set to noon - avoid DST errors
  d1.setHours(12, 0, 0);
  d2.setHours(12, 0, 0);

  const t2: number = d2.getTime();
  const t1: number = d1.getTime();
  const diff: number = t2 - t1;
  const minutes: number = 24 * 3600 * 1000;

  return Math.round(diff / minutes);
}
