import "core-js/stable";
import "regenerator-runtime/runtime";

import React from "react";
import ReactDOM from "react-dom";

import ConfiguredApp from "./ConfiguredApp";

// import FontFaceObserver from "fontfaceobserver";
const MOUNT_NODE = document.getElementById('app');

const render = () => {
  ReactDOM.render(
    <ConfiguredApp />,
    MOUNT_NODE,
  );
};

// const montserrat = new FontFaceObserver('Montserrat');
// const openSans = new FontFaceObserver('Open Sans');

// Promise.all([montserrat.load(), openSans.load()]).then(() => {
render();
// });
