import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin: 1.5em auto;
`;

export const Title = styled.h2`
  font-family: ${(props) => props.theme.fontFamily.semiBold};
  font-size: 21px;
  font-weight: 700;
  font-style: normal;
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 15px;
  line-height: normal;
`;

export const ActionMenu = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  width: 30px;
  height: 24px;
  padding: 0;
`;

export const LoadMoreContainer = styled.div`
  margin: 20px 0;
  text-align: center;
  width: 100%;
`;
export const StatusContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex: 0 0 auto;
`;
export const Icon = styled.div`
  flex: 0 0 28px;
`;
export const Information = styled.div`
  span {
    display: block;
    margin-bottom: 3px;
  }
`;
