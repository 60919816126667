import styled from "styled-components";

export const BoxActionContent = styled.div`
  &&& {
    background: #ffffff;
    padding: 20px;
  }
`;

export const BoxContainer = styled.div`
  &&& {
    background: #ffffff;
    box-shadow: 1px 1px 1px #d1d1d1;
    margin: 0 0 20px;
  }
`;

export const Header = styled.div`
  &&& {
    background: ${(props) => props.theme.colors.primary};
    color: #ffffff;
    font-size: 14px;
    line-height: 20px;
    min-height: 20px;
    text-transform: uppercase;
    user-select: none;
    cursor: default;
    padding: 15px;
    font-family: ${(props) => props.theme.fontFamily.bold};
  }
`;
