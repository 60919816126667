import { ColumnType } from "antd/lib/table";
import * as React from "react";

import { SignaturePackage } from "../../../../../../../common/signaturePackage/interfaces/SignaturePackage";
import { ResultTableProps } from "../ResultTable";

export function ResultColumnCreatedBy(props: ResultTableProps): ColumnType<SignaturePackage> {
  const { t } = props;

  return {
    title: t("searchResult.columns.createdBy"),
    dataIndex: "createdBy",
    key: "createdBy",
    render: (value: unknown, record: SignaturePackage) => <>{record.sharer.name}</>,
  };
}
