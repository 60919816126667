import styled from 'styled-components';

export const Container = styled.div`
  margin: 30px;
`;

export const ButtonsContainer = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto auto;
  column-gap: 20px;
  justify-items: center;
  align-items: center;
`;

export const Title = styled.h1<{ size: 'md' | 'lg' }>`
  font-family: "OpenSans-Bold", "Open Sans Bold", "Open Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: ${(props) => (props.size === 'md' ? '20px' : '24px')};
  font-size: 24px;
`;