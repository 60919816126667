import { RequestResourceSuccessByName } from "ia-react-core";
import { all, Effect, put, takeLatest } from "redux-saga/effects";

import { getUser } from "../common/user/redux/user.actions";
import UserSaga from "../common/user/redux/user.saga";
import { axiosInstance } from "../common/utils/HttpClient";

/* eslint-disable @typescript-eslint/no-explicit-any */
function* configureAuth(action: any) {
  axiosInstance.defaults.headers.common = {};
  axiosInstance.defaults.headers.common.Authorization = `${action.request.token_type} ${action.request.access_token}`;
  yield put(getUser());
}

function* watchApigateway() {
  yield takeLatest(`${RequestResourceSuccessByName.type}/apiGateway`, configureAuth);
}

export default function* appSaga(): IterableIterator<Effect> {
  yield all([watchApigateway(), UserSaga()]);
}
