export default {
  title: "Client Profile",
  profileTab: {
    title: "Profile",
    personalInformation: {
      title: "Client Information",
      employer: "Employer",
      occupation: "Occupation",
      annualIncome: "Annual Income",
      netWorth: "Net Worth",
      jointAccountHolder: "Joint Account Holder",
    },
    clientPortal: "Client Portal",
    sendInvitation: "Send invitation",
    contactInformation: {
      title: "Contact Information",
      address: "Address",
      Phone: "Phone",
    },
    advisorsInformation: {
      title: "Representative Information",
      representativeCode: "Representative Code",
    },
    bankAccountsInformation: {
      title: "Bank Accounts",
      institutionName: "Institution",
      institutionNumber: "Institution Number",
      institutionBranch: "Branch Number",
      account: "Account Number",
    },
  },
  investorTab: {
    title: "Investor Profile",
    knowledge: {
      title: "Investment Knowledge",
      level: {
        1: "Sophisticated",
        2: "Good",
        3: "Limited",
        4: "Poor / None",
      },
    },
    objectives: {
      title: "Investment objectives, risk tolerance and time horizon",
      accountTitle: "Account",
      investmentObjectives: "Investment Objectives",
      riskTolerance: "Risk Tolerance",
    },
    timeHorizon: {
      title: "Time horizon",
    },
  },
  documentsTab: {
    title: "Documents",
    file: "File",
    date: "Date",
    noDocument: "No document found",
    documentSent: "The documents were successfully shared!",
    documentShared: "The document was successfully shared!",
    documentUnshared: "The document was successfully unshared!",
    errorMessage: "An error has occured. Please try again later.",
    virusScanDetectedAnAnomaly: "We were not able to send any of the documents. A virus was detected in the file \"{{0}}\"",
    errorMessageDocumentLoadFailed: "An error occured while fetching the document(s). Please try again later.",
    errorMessageDocumentReshareFailed: "An error occured while resharing the document. Please try again later.",
    errorMessageDocumentUnshareFailed: "An error occured while unsharing the document. Please try again later.",
    NoEmailSent: "The documents were successfully shared. However, due to technical reasons, we were not able to notify your client by email.",
    sendADocument: "Send a document",
    linkToDocumentList: "Back",
    send: "Send",
    fromName: "From",
    statusName: "Status",
    actionName: "Action",
    download: "Download",
    shared: "Shared",
    unshared: "Unshared",
    shareAction: "Share",
    unshareAction: "Unshare",
    read: "Read",
    unread: "Unread",
    type: "Type",
    clientNotRegistered: "Your client is not registered to the Client Portal.",
    optionOnlyAvailableForRegisteredClient: "This option is only available for clients who have completed their registration process.",
    optionNotAvailableThirdParty: "Password reset is not yet available for custom users and third parties. Please contact Advisor Support for assistance resetting the password.",
    clientLocked: "Password reset is not available because this account is locked. Please contact Advisor Support for assistance unlocking the account and resetting the password.",
    fileTooLarge: "The file could not be uploaded. The file exceeds the allowed limit of 20MB.",
    unsupportedDocumentType: "The file could not be uploaded. The file type is not authorized.",
    sentbutton: "Sent",
    receivedbutton: "Received",
    maxFileSize: "Max file size: 20MB",
    accountsMessage: "Choose an account that this document is most relevant to. If this document applies to all of this client's accounts, select any account",
    documentsLabel: "Document(s)",
    messageNotAFileStorage: "The Secure Document Exchange function allows you and your clients to securely exchange documents, however it is not a file storage solution. We strongly recommend you save the received documents in your clients' files.",
    sendDocumentConfirmationMessage: "I acknowledge that the above document(s) will be shared to {{0}} and be accessible to the client via iA Private Wealth Client Portal under “Documents”. The client will also receive an email notification. I confirm that I am sharing these documents with the correct client.",
    comingSoon: "Coming soon",
    unshareTitleModal: "Are you sure you want to unshare the document?",
    unshareBodyModal: "The document will disappear from the Client Portal, but it will still be available for download in the Advisor Portal.",
    unhsareCancelModal: "Not now",
    unhsareOkModal: "Unshare",
    fileName: "File name",
    details: "Details",
    choose: "Choose",
    unshareOption1: "Information for wrong client",
    unshareOption2: "Date/period of information is incorrect",
    unshareOption3: "Information is not up to date",
    unshareOption4: "Document has wrong information",
    unshareOption5: "Other",

  },
  closed: "closed",
  logsTab: {
    title: "Logs",
    doneBy: "Done by",
    details: "Details",
    noData: "No data to display",
    current: "Current Details",
    original: "Original Details",
    added: "Added: ",
    removed: "Removed: ",
    accessShare: "Account access granted to",
    true: "Yes",
    false: "No",
    consent: {
      marketing: "Distinctive client experience changed to \"{{0}}\"",
      sollicitation: "Promotions changed to \"{{0}}\"",
    },
    affiliatesChanges: "Sharing of Affiliates: {{0}}",
    thirdPartyChanges: "Third Party providers: {{0}}",
    True: "Yes",
    False: "No",
    consentUpdated: "Changed in {{0}}",
    affiliateConsentVerbiage: "Affiliate consent version: {{0}}",
    thirdPartyConsentVerbiage: "Third party consent version: {{0}}",
    clientportalias: "Client Portal",
    mobileappiapw: "Mobile",
    cob: "Client Onboarding",
    cpm: "CMP Portal",
    isElectronic: "Paperless",
    isPaper: "Paper",
    statements: "Statements and Annual Reports",
    managedStatement: "Quarterly Investment Review",
    taxDocuments: "Tax Slips",
    tradeConfirmation: "Trade Confirmations",
    changedTo: " changed to ",
    clientId: " (Client id: {{0}})",
    mailNotification: "Email notifications",
    mailNotificationYes: "YES",
    mailNotificationNo: "NO",
    changedTo2: " changed to ",
    providedAccess: "has been provided view access for",
    removedAccess: "has been removed from view access for",
    temporaryPasswordGenerated: "Temporary password generated",
    unlockAccountMessage: "Account unlocked",
    emailNotification: " email notifications changed to {{0}} ",
    and: " and",
    documentShared: "Documents shared",
    clientHasSharedDocuments: "The client has shared documents to his/her advisor ({{0}}).",
    advisorHasSharedDocuments: "The user ({{0}}) has shared documents to the client.",
  },
  settingsTab: {
    title: "Settings",
    householdbutton: "Household",
    performancebutton: "Performance",
    sharedAccessbutton: "Access Sharing",
    userManagementbutton: "User Management",
  },
  userManagement: {
    accountActions: "Account Actions",
    generateTemporaryPassword: "Generate Temporary Password",
    newPasswordToTransmit: "New password to transmit",
    iHaveVerifiedIdentity: "I have verified my client identity",
    generate: "Generate",
    cancel: "Cancel",
    aboutToGenerateNewPasswordMessage: "You are about to generate a new password for this person.",
    wantToContinue: "Do you want to continue?",
    unlockAccount: "Unlock account",
    accountLocked: "Account locked",
    clientLockedMessage: "Generating temporary password is not available because this account is locked due to too many password attempts. Please verify your client's identity before unlocking the account.",
    accountUnlockedSuccess: "Account successfully unlocked.",
    accountUnlockTitleFailure: "Unlocking failed",
    messageFailure: "Please try again or contact the support team for assistance.",
    passwordTitleFailure: "Resetting password failed"
  },
  documentDelivery: {
    documents: "Documents",
    clientId: "Client ID",
    name: "Name",
    isPaperless: "Paperless",
    emailNotificationConsent: "Email notifications",
    Statement: "Statements and Annual Reports",
    ManagedStatement: "Quarterly Investment Review",
    TaxDocument: "Tax Slips",
    TradeConfirmation: "Trade Confirmations",
    disabledTaxSlipsMsg: "Please note that the delivery preferences for your tax slips cannot be changed during tax season, which occurs from December 29 to April 30.",
    reset: "Reset changes",
    apply: "Apply changes",
    savePreferencesConfirmation: "Your changes have been successfully submitted.",
    savePreferencesFailure: "There is an error in saving your preferences. Please try again.",
    confirm: "Confirm Changes",
    confirmationMessageTitle: "Confirmation message",
    errorMessageTitle: "Error message",
    error: "Error",
    disabledWarning: "You can only modify delivery preferences for account holders. This setting is disabled for users with shared access roles.",
    paperlessNote: "An email notification will be sent when a new document is accessible in the client portal.",
  },
  accessSharing: {
    recipient: "Recipient's info",
    clientIds: "Client IDs",
    name: "Recipient name",
    username: "Username",
    clientId: "Client ID",
    confirmMsg: "You are giving access to the following accounts:",
    msgEditError: "Client has not been located.",
    accesToMyAccount: "Access to your Client ID",
    deleteMember: "Revoke access",
    deleteMemberConfirmation: "Are you sure you want to delete this access sharing member?",
    select: "SELECT",
    add: "Add",
    clientName: "Name",
    noData: "No Data",
    noOwnerAccountWarning: "The Access Share function is only available to account owner.",
    corpAccountWarning: "Please share the company client ID directly from the company's profile.",
    owner: "Owner",
    thirdParty: "Third Party",
    corporateOwner: "Corporate Owner",
    next: "Next",
    confirm: "CONFIRMATION",
    previous: "Previous",
    search: "Search",
    allAccounts: "All",
    error: "Error",
    subMenuAccessSharing: "Access Sharing",
    thirdPartyClientProvidedCopy: "My client has provided me a signed copy of the Third Party Access Form and a copy has been sent to New Accounts.",
  },
};
