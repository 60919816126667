import { TFunction } from 'i18next';
import * as React from 'react';
import styled from 'styled-components';
import { iaButtonStyle, iaButtonStyleDisabled } from '../../../../../../common/common.style';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import { Button } from '@mui/material';

interface IFooterProps {
  disabled: boolean;
  isLoading: boolean;
  isLocked: boolean;
  onGeneratePassword: () => void;
  onUnlockAccount: () => void;
  t: TFunction;
}

const HorizontalScrollDiv = styled.div`
  padding-top : 24px;
  overflowX: 'auto'
`;

const buttonStyle = {...iaButtonStyle, marginRight: '15px'}
const disabledButtonStyle = {...iaButtonStyleDisabled, marginRight: '15px'}

export default class UserManagementFooter extends React.PureComponent<IFooterProps> {
  public render() {
    const {t, isLocked} = this.props;
    const isDisabled = this.props.isLoading || this.props.disabled;
    const isPasswordDisabled = (this.props.isLoading || this.props.disabled) || isLocked;
    return (
      <HorizontalScrollDiv>
        {isLocked &&
          <Button key="unlockButton"
            style={isDisabled ? disabledButtonStyle : buttonStyle }
            disabled={isDisabled}
            onClick={this.props.onUnlockAccount}
            startIcon={<LockOpenOutlinedIcon />}
            sx={{textTransform: 'capitalize'}}>
            {t("userManagement.unlockAccount")}
          </Button>
        }
        <Button key="passwordButton"
          style={isPasswordDisabled ? disabledButtonStyle : buttonStyle}
          disabled={isPasswordDisabled}
          onClick={this.props.onGeneratePassword}
          sx={{textTransform: 'capitalize'}}>
          {t("userManagement.generateTemporaryPassword")}
        </Button>
      </HorizontalScrollDiv>);
  }
}
