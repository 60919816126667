import { CheckboxItem, Form, InjectTranslationsProps, Loader, SelectControl, UploadZoneControl, useScopedSelector } from "ia-react-core";
import React, { useCallback, useMemo, useState } from "react";
import { Account } from "../../../../../../common/account/interfaces/Account";
import { Client } from "../../../../../../common/client/interfaces/Client";
import ClientHelper from "../../../../../../common/client/utils/ClientHelper";
import { ResponseError } from "../../../../../../common/interfaces/ResponsePayload";
import ResponseErrorStatus from "../../../../../../common/messages/interfaces/ResponseErrorStatus";
import ResponseSuccessStatus from "../../../../../../common/messages/interfaces/ResponseSuccessStatus";
import MessagesService from "../../../../../../common/messages/messages.service";
import { AccountSelector, AccountSelectorSection, Confirmation, ConfirmationSection, DisabledButton, MaxSizeText, SendDocumentContent, SendDocumentSubtitle, SubmitDocumentButton, Subtitle } from "./SendDocument.style";

const acceptedFileTypes = [".csv", ".txt", ".doc", ".docx", ".pdf", ".xls", ".xlsx", ".ppt", ".pptx", ".jpg", ".jpeg", ".png", ".bmp", ".gif", ".tif", ".tiff", ".heif", ".heic"];
const stateKey = "SendDocument";
const service = new MessagesService();

export interface SendDocumentProps {
  onSend: () => void;
  onSuccess: (successStatus: ResponseSuccessStatus) => void;
  onError: (error: ResponseError) => void;
  client: Client;
}

export interface SendDocumentStateToProps {
  accounts: Account[];
  partyId: string;
}

interface SendDocumentComposedProps extends SendDocumentStateToProps, SendDocumentProps, InjectTranslationsProps { }
const SendDocuments: React.FC<SendDocumentComposedProps> = ({ accounts, partyId, onSuccess, onError, onSend, client, t }: SendDocumentComposedProps): JSX.Element => {
  const files: File[] = useScopedSelector(`${stateKey}.upload`);
  const selectedOption: string = useScopedSelector(`${stateKey}.accounts`);
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const accountOptions: Array<{label: string; value: string}> = useMemo(() => {
    const repcodes = accounts.map((a) => a.repCode);
    const uniqueRepcodes = new Set(repcodes);
    if (uniqueRepcodes.size > 1) {
      const distinctRootIds: string[] = [];
      const distinctAccounts = accounts.filter((account: Account) => {
        if (distinctRootIds.findIndex((rootId: string) => rootId === account.rootId) === -1) {
          distinctRootIds.push(account.rootId);
          return true;
        }

        return false;
      });
             
      return distinctAccounts.map((account: Account) => ({ label: `${account.rootId} - ${account.name}`, value: account.repCode }));
    }
    return [];
  }, [accounts]);

  const selectedRepcode: string = useMemo(() => {
    if (accountOptions.length > 0 && selectedOption !== undefined) {
      return selectedOption;
    }
    return accounts[0].repCode;
  }, [accountOptions.length, accounts, selectedOption]);

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true);
      onSend();
      const result = await service.sendMessage(selectedRepcode, partyId, files);
      if (result.error) {
        onError(result.error);
        setLoading(false);
      } else {
        onSuccess(ResponseSuccessStatus.Share);
      }
    } catch (error) {
      onError({
        status: ResponseErrorStatus.Unknown,
        detail: error as string,
      });

      setLoading(false);
    }
  }, [selectedRepcode, partyId, files, onError, onSuccess, onSend]);

  return ( 
    <Form name="SendDocument" valuesSrc={stateKey} onSubmit={onSubmit}>
      { loading && <Loader />}
      { !loading && (
        <SendDocumentContent>
          <SendDocumentSubtitle>{t("documentsTab.sendADocument")}</SendDocumentSubtitle>
          { accountOptions.length > 0 && (
            <AccountSelectorSection>
              <Subtitle>{t("documentsTab.accountsMessage")}</Subtitle>
              <AccountSelector>
                <SelectControl name="accounts" options={accountOptions} required defaultValue={accountOptions[0].value} />
              </AccountSelector>
            </AccountSelectorSection>)}
          <Subtitle>{t("documentsTab.documentsLabel")}</Subtitle>
          <MaxSizeText>{t("documentsTab.maxFileSize")}</MaxSizeText>
          <UploadZoneControl 
            name="upload" 
            accept={acceptedFileTypes}
            multiple 
            maxSize={20971520} 
            noHint 
            fileTooLargeMessage={t("documentsTab.fileTooLarge")} 
            fileInvalidTypeMessage={t("documentsTab.unsupportedDocumentType")}
          />
          <ConfirmationSection>
            <Confirmation> 
              <CheckboxItem 
                name="confirm" 
                label={t("documentsTab.sendDocumentConfirmationMessage", { 0: ClientHelper.getFullName(client) })} 
                checked={confirmation} 
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setConfirmation(event.target.checked)}
              />
            </Confirmation>
            { confirmation && files && files.length > 0
              ? <SubmitDocumentButton type="submit">{t("documentsTab.send")}</SubmitDocumentButton>
              : <DisabledButton disabled>{t("documentsTab.send")}</DisabledButton>}
          </ConfirmationSection>
        </SendDocumentContent>)}
    </Form>
  );
};

export default SendDocuments;
