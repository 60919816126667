import { PaginatedResponse } from "../../interfaces/PaginatedResponse";
import { SearchCriteria } from "../../interfaces/SearchCriteria";
import { SearchKeyValue } from "../interfaces/SearchKeyValue";
import { SignaturePackage } from "../interfaces/SignaturePackage";
import {
  LoadMoreSignaturePackages,
  SearchSignaturePackage,
  SearchSignaturePackageError,
  SearchSignaturePackageSuccess,
  SetNbSearchLaunched,
  SignaturePackageActionTypes as types,
} from "./signaturePackage.actions.types";

export const loadMoreSignaturePackages = (searchCriteria: SearchCriteria, offset: number): LoadMoreSignaturePackages => ({ type: types.LOAD_MORE_SIGNATUREPACKAGES, searchCriteria, offset });

export const searchSignaturePackage = (searchCriteria: SearchCriteria): SearchSignaturePackage => ({ type: types.SEARCH_SIGNATUREPACKAGE, searchCriteria });

export const searchSignaturePackageSuccess = (paginatedResponse: PaginatedResponse<SignaturePackage>, searchKeyValue: SearchKeyValue): SearchSignaturePackageSuccess => ({
  type: types.SEARCH_SIGNATUREPACKAGE_SUCCESS,
  paginatedResponse,
  searchKeyValue,
});

export const searchSignaturePackageError = (searchKeyValue: SearchKeyValue): SearchSignaturePackageError => ({ type: types.SEARCH_SIGNATUREPACKAGE_ERROR, searchKeyValue });

export const setNbSearchLaunched = (nbSearchLaunched: number): SetNbSearchLaunched => ({ type: types.SET_NB_SEARCH_LAUNCHED, nbSearchLaunched });
