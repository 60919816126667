import * as React from "react";

import { EmptyValue } from "../constants";

interface ValueProps {
  children: string | undefined;
}

const Value: React.FC<ValueProps> = ({ children }: ValueProps) => { 
  if (!children || children === undefined || children === "") return <>{EmptyValue}</>;

  return <>{children}</>;
};

export default Value;
