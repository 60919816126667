import { SignaturePackageDetailsUrl } from "../../../constants/Router";
import { EmptyValue } from "../../constants";
import { SignerStatus } from "../enum";
import { SignaturePackage } from "../interfaces/SignaturePackage";
import { Signer } from "../interfaces/Signer";

export default class SignaturePackageHelper {
  static getClientName(signaturePackage: SignaturePackage) {
    if (!signaturePackage) {
      return EmptyValue;
    }

    return `${signaturePackage.client.name}`;
  }

  static detailsUrl(id: string): string {
    let url: string = SignaturePackageDetailsUrl;
    url = url.substr(0, SignaturePackageDetailsUrl.lastIndexOf("/") + 1);
    return `${url}${id}`;
  }

  static getAwaitingSigner(signaturePackage: SignaturePackage): Signer | null {
    const awaitingStatus = [SignerStatus.emailrebound, SignerStatus.locked, SignerStatus.signingpending];
    const signersAwaitingStatus = signaturePackage.signers.filter((signer: Signer) => awaitingStatus.includes(signer.status));
    if (signersAwaitingStatus.length === 0) {
      return null;
    }
    return signersAwaitingStatus[0];
  }
}
