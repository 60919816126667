import { Reducer } from "redux";

import { ClientState, initialClientState } from "../../../interfaces/ClientState";
import { AccountAction, AccountActionTypes as types } from "./account.actions.types";

const AccountReducer: Reducer<ClientState, AccountAction> = (state = initialClientState, action): ClientState => {
  switch (action.type) {
    case types.GET_ACCOUNTS_CLIENT_INFO_BY_CLIENTIDS:
      return { ...state, accounts: [], loadingAccounts: true };
    case types.GET_ACCOUNTS_CLIENT_INFO_BY_CLIENTIDS_SUCCESS:
      return { ...state, accounts: action.accounts, loadingAccounts: false };
    case types.GET_ACCOUNTS_CLIENT_INFO_BY_CLIENTIDS_ERROR:
      return { ...state, accounts: [], loadingAccounts: false };
    default:
      return state;
  }
};

export default AccountReducer;
