import { InjectSagaProps, InjectTranslationsProps, Loader } from "ia-react-core";
import * as React from "react";
import { Helmet } from "react-helmet";

import { Container, LoadingContainer } from "../SignaturePackage.style";

export interface DetailsProps {}

export interface DetailsDispatchToProps {}

export interface DetailsMapStateToProps {
  loading: boolean;
}

export interface DetailsComposedProps extends DetailsProps, DetailsDispatchToProps, DetailsMapStateToProps, InjectTranslationsProps, InjectSagaProps {}

class Details extends React.PureComponent<DetailsComposedProps> {
  public render() {
    const { t, loading } = this.props;

    return (
      <Container>
        <Helmet>
          <title>{`${t("title")} - ${t("SignaturePackage:title")}`}</title>
        </Helmet>

        <h1>{`${t("SignaturePackage:title")} ${t("title")}`}</h1>

        {loading && (
          <LoadingContainer>
            <Loader />
          </LoadingContainer>
        )}
      </Container>
    );
  }
}

export default Details;
