/* eslint-disable no-empty */
import React, { useState } from 'react';
import { TFunction } from 'i18next';
import CheckableTag from 'antd/lib/tag/CheckableTag';
import { Subtitle } from '../../SendDocument/SendDocument.style';
import DocumentsFilterValues from '../../../../../../../common/enum/DocumentsFilterValues';

export interface DocumentsFilterProps {
  type: string;
  t: TFunction;
  onFilterChange: (selectedValues: string[]) => void;
}

/* eslint-disable no-console */
const DocumentsFilter: React.FC<DocumentsFilterProps> = (props: DocumentsFilterProps) => {
  const { t, onFilterChange } = props;
  const [selectedTags, setSelectedTags] = useState<string[]>(['']);
  const tagsData = [
    { label: t("documentsTab.read"), value: DocumentsFilterValues.Read },
    { label: t("documentsTab.unread"), value: DocumentsFilterValues.Unread },
    { label: t("documentsTab.shared"), value: DocumentsFilterValues.Shared },
    { label: t("documentsTab.unshared"), value: DocumentsFilterValues.Unshared },
  ];

  const handleChange = (tag: string, checked: boolean) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((e) => e !== tag);
    setSelectedTags(nextSelectedTags);
    onFilterChange(nextSelectedTags);
  };

  return (
    <>
      <Subtitle>{t("documentsTab.statusName")}</Subtitle>
      {tagsData.map((tag) => (
        <CheckableTag
          key={tag.value}
          checked={selectedTags.includes(tag.value)}
          onChange={(checked) => handleChange(tag.value, checked)}
        >
          {tag.label}
        </CheckableTag>
      ))}
    </>
  );
};

export default DocumentsFilter;
