import { InjectTranslationsProps } from "ia-react-core";
import * as React from "react";
import { Helmet } from "react-helmet";
import { Route, Switch } from "react-router";

import { SignaturePackageCreateUrl, SignaturePackageDetailsUrl, SignaturePackageTrackingUrl } from "../../constants/Router";
import Create from "./Create";
import Details from "./Details";
import { Container } from "./SignaturePackage.style";
import TrackingSearch from "./Tracking";

export interface SignaturePackageProps {}

export interface SignaturePackageComposedProps extends SignaturePackageProps, InjectTranslationsProps {}

class SignaturePackage extends React.PureComponent<SignaturePackageComposedProps> {
  public render() {
    const { t } = this.props;
    return (
      <Container>
        <Helmet title={t("title")} />
        <Switch>
          <Route exact path={SignaturePackageTrackingUrl} component={TrackingSearch} />
          <Route path={SignaturePackageCreateUrl} component={Create} />
          <Route path={SignaturePackageDetailsUrl} component={Details} />
        </Switch>
      </Container>
    );
  }
}

export default SignaturePackage;
