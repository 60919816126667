export function compareBankAccount(a: BankAccount, b: BankAccount): boolean {
  return a.institutionBranch === b.institutionBranch && a.institutionNumber === b.institutionNumber && a.accountNumber === b.accountNumber;
}

export interface BankAccount {
  accountId: string;
  institutionName: string;
  institutionNumber: number;
  institutionBranch: string;
  accountNumber: string;
}
