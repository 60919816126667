/* eslint-disable no-console */
import { InjectTranslationsProps } from "ia-react-core";
import React from "react";

import Section from "../../enum/Section";
import { Information } from "../../svg";
import { Container } from "./NoResult.style";

export interface NoResultProps {
  section: Section;
}

export interface NoResultComposedProps extends NoResultProps, InjectTranslationsProps {}

export const NoResult: React.FC<NoResultComposedProps> = ({ t, section }: NoResultComposedProps) => (
  <Container>
    {Information(44)}
    <div>{t(section)}</div>
  </Container>
);
