import { TFunction } from "i18next";
import * as React from "react";

import { InvestmentObjective } from "../../../../../../common/client/interfaces/InvestmentObjective";
import { InvestmentObjectiveHelper } from "../../../../../../common/client/utils/InvestmentObjectiveHelper";
import StyledDoughnut from "../../../../../../common/components/Chart/StyledDoughnut";
import { ChartContainer, ChartTitle } from "../investorProfileTab.style";

export interface InvestmentObjectiveChartProps {
    t: TFunction;
    data: InvestmentObjective[];
}

const InvestmentObjectiveChart: React.FC<InvestmentObjectiveChartProps> = (props: InvestmentObjectiveChartProps) => {
  const { t, data } = props;
  return ( 
    <ChartContainer>
      <ChartTitle>{t("investorTab.objectives.investmentObjectives")}</ChartTitle>
      <StyledDoughnut data={InvestmentObjectiveHelper.getInvestmentObjectiveDataChart(data, t)} />
    </ChartContainer>);
};

export default InvestmentObjectiveChart;
