import { createSelector } from "reselect";
import { v4 as uuid } from "uuid";

import { Account } from "../../../common/account/interfaces/Account";
import { BankAccount, compareBankAccount } from "../../../common/account/interfaces/BankAccount";
import { selectAccounts } from "../../../common/account/redux/account.selector";
import { AccountGroupedByBankAccount } from "../interfaces/AccountGroupedByBankAccount";

export const selectAccountsGroupByBankAccounts = createSelector(selectAccounts, (accounts: Account[]): AccountGroupedByBankAccount[] => {
  const accountsByBankAccount: AccountGroupedByBankAccount[] = [];
  let bankAccounts: BankAccount[] = [];

  if (!accounts || accounts.length === 0) {
    return accountsByBankAccount;
  }

  accounts.forEach((account: Account) => {
    bankAccounts = bankAccounts.concat(account.bankAccounts);
  });

  bankAccounts.forEach((bankAccount: BankAccount) => {
    const item = accountsByBankAccount.find((agb: AccountGroupedByBankAccount) => compareBankAccount(agb, bankAccount));
    if (!item) {
      const accountByBankAccount: AccountGroupedByBankAccount = {
        id: uuid(),
        institutionName: bankAccount.institutionName,
        institutionNumber: bankAccount.institutionNumber,
        accountNumber: bankAccount.accountNumber,
        accountId: bankAccount.accountId,
        institutionBranch: bankAccount.institutionBranch,
        accounts: accounts.filter((account: Account) => account.bankAccounts.some((ba: BankAccount) => compareBankAccount(ba, bankAccount))),
      };
      accountsByBankAccount.push(accountByBankAccount);
    }
  });

  return accountsByBankAccount;
});
