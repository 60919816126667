import { Account } from "../common/account/interfaces/Account";
import { TeamByRepCode } from "../common/advisor/interfaces/TeamByRepCode";
import { Client } from "../common/client/interfaces/Client";
import { SearchKeyValue } from "../common/client/interfaces/SearchKeyValue";
import { MessagesBySeverity } from "../common/interfaces/ResponsePayload";

export interface ClientState {
  teamByRepCode: TeamByRepCode;
  clients: Client[];
  searchKeyValueErrors: SearchKeyValue[];
  loading: boolean;
  totalResults: number;
  currentOffset: number;
  selectedClient?: Client;
  messages?: MessagesBySeverity;
  searchLoading: boolean;
  jointAccountHolders: Client[];
  accounts: Account[];
  loadingAccounts: boolean;
  nbSearchLaunched: number;
}

export const initialClientState: ClientState = {  
  teamByRepCode: {},
  clients: [],
  searchKeyValueErrors: [],
  loading: false,
  currentOffset: 0,
  totalResults: 0,
  searchLoading: false,
  jointAccountHolders: [],
  accounts: [],
  loadingAccounts: false,
  nbSearchLaunched: 0,
};
