import { PaginatedResponse } from "../../interfaces/PaginatedResponse";
import { ResponsePayload } from "../../interfaces/ResponsePayload";
import { SearchCriteria } from "../../interfaces/SearchCriteria";
import { Client } from "../interfaces/Client";
import { SearchKeyValue } from "../interfaces/SearchKeyValue";

export enum ClientActionTypes {
  LOAD_MORE_CLIENTS = "[Client] Load More Clients",
  SEARCH_CLIENT = "[Client] Search Client",
  SEARCH_CLIENT_SUCCESS = "[Client] Search Client Success",
  SEARCH_CLIENT_ERROR = "[Client] Search Client Error",
  SEARCH_CLIENT_AUTOCOMPLETE = "[Client] Search Client Auto Complete",
  GET_CLIENT = "[Client] Get Client",
  GET_CLIENT_SUCCESS = "[Client] Get Client Success",
  GET_CLIENT_ERROR = "[Client] Get Client Error",
  SET_NB_SEARCH_LAUNCHED = "[Client] Set nb search launched",
  GET_CLIENT_LOCKED = "[Client] Get Client Locked",
  GET_CLIENT_LOCKED_SUCCESS = "[Client] Get Client Locked Success",
  GET_CLIENT_LOCKED_ERROR = "[Client] Get Client Locked Error",
}

export interface LoadMoreClients {
  type: ClientActionTypes.LOAD_MORE_CLIENTS;
  searchCriteria: SearchCriteria;
  offset: number;
}
export interface SearchClient {
  type: ClientActionTypes.SEARCH_CLIENT;
  searchCriteria: SearchCriteria;
}

export interface SearchClientSuccess {
  type: ClientActionTypes.SEARCH_CLIENT_SUCCESS;
  paginatedResponse: PaginatedResponse<Client>;
  searchKeyValue: SearchKeyValue;
}

export interface SearchClientError {
  type: ClientActionTypes.SEARCH_CLIENT_ERROR;
  searchKeyValue: SearchKeyValue;
}

export interface SearchClientAutoComplete {
  type: ClientActionTypes.SEARCH_CLIENT_AUTOCOMPLETE;
  criteria: string;
}

export interface GetClient {
  type: ClientActionTypes.GET_CLIENT;
  id: string;
  fetchAdditionnalInfos: boolean;
}

export interface GetClientSuccess {
  type: ClientActionTypes.GET_CLIENT_SUCCESS;
  response: ResponsePayload<Client>;
}

export interface GetClientError {
  type: ClientActionTypes.GET_CLIENT_ERROR;
  id: string;
}
export interface SetNbSearchLaunched {
  type: ClientActionTypes.SET_NB_SEARCH_LAUNCHED;
  nbSearchLaunched: number;
}

export interface GetClientLocked {
  type: ClientActionTypes.GET_CLIENT_LOCKED;
  id: string;
}

export interface GetClientLockedSuccess {
  type: ClientActionTypes.GET_CLIENT_LOCKED_SUCCESS;
  locked: boolean;
}

export interface GetClientLockedError {
  type: ClientActionTypes.GET_CLIENT_LOCKED_ERROR;
  id: string;
}
export type ClientAction = LoadMoreClients | SearchClient | SearchClientSuccess | SearchClientError | SearchClientAutoComplete | GetClient | GetClientSuccess | GetClientError | SetNbSearchLaunched | GetClientLocked | GetClientLockedError | GetClientLockedSuccess;
