import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin: 3em auto;
`;

export const Title = styled.h2`
  font-family: ${(props) => props.theme.fontFamily.semiBold};
  font-size: 21px;
  font-weight: 700;
  font-style: normal;
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 15px;
  margin-right: auto;
  line-height: normal;
`;

export const Accounts = styled.div`
  font-size: 14px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;

  svg {
    flex: 0 0 auto;
  }
`;

export const ActionMenu = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  width: 30px;
  height: 24px;
  padding: 0;
`;

export const AccountTitle = styled.span`
  flex: 0 0 auto;
`;

export const AccountLayout = styled.div`
  flex: 0 auto;
  display: flex;
  flex-flow: row wrap;
`;

export const Account = styled.div`
  flex: 0 1 auto;

  :first-child span {
    margin: 0;
  }

  span {
    margin: 0 0 0 4px;
  }
`;

export const AccountNumber = styled.span<{ highlight: boolean }>`
  font-weight: ${(props) => (props.highlight ? "bold" : "normal")};
  color: ${(props) => (props.highlight ? "#666" : "#000")};
  border-bottom: ${(props) => (props.highlight ? "1px solid #666" : "none")};
  margin: 0 3px;
`;

export const AddressInformation = styled.div``;

export const PreferedAccessCodeAction = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  text-align: left;
  color: ${(props) => props.theme.colors.blue5};

  :hover {
    text-decoration: underline;
  }
`;

export const PreferedAccessCode = styled.div`
  font-size: 14px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  color: ${(props) => props.theme.colors.black};
  span {
    margin: 0 0 0 4px;
  }
`;

export const PreferedAccessCodeLoading = styled.div`
  > div {
    margin: 0 0 0 4px;
    float: left;
  }
`;

export const LoadMoreContainer = styled.div`
  margin: 20px 0;
  text-align: center;
  width: 100%;
`;

export const TitleSection = styled.div`
  display: flex;
`;
