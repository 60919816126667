import { Modal, Select } from "antd";
import React from "react";
import { QuestionCircleFilled } from '@ant-design/icons';
import { Col, Row } from "react-grid-system";
import { get, InjectTranslationsProps } from "ia-react-core";
import TextArea from "antd/lib/input/TextArea";
import { ButtonActionLookAlike } from "../../../../../../../common/common.style";
import { Attachment } from "../../../../../../../common/messages/interfaces/Attachment";
import StyledPopover from "../../../../../../../common/popover/StyledPopover";
import { InvisibleSeparator } from "../../../../../../../common/popover/StyledPopover.style";
import { Action } from "../../../../../../../common/svg";
import { ActionMenu } from "../../../../../../SignaturePackage/Tracking/components/Result/Result.style";
import { GlobalModalStyle, ModalTitle } from "./DocumentActions.style";
import { Subtitle } from "../../SendDocument/SendDocument.style";
import { ToLocalTimezoneShortDate } from "../../../../../../../common/components/FormatDate";
import UnshareReasons from "../../../../../../../common/enum/UnshareReasons";
import { connect } from "react-redux";
import { State } from "../../../../../../../interfaces/State";

export interface DocumentActionsProps {
  record: Attachment;
  props: InjectTranslationsProps;
  onLinkClicked: (id: string, fileName: string) => Promise<void>;
  onShareToggleClicked: (record: Attachment, isResharing: boolean, reason: string) => Promise<void>;
}

const mapStateToProps = (state: State): DocumentStateToProps => ({
  locale: (get(state, "language.locale") as unknown) as string,
});

export interface DocumentStateToProps {
  locale:string;
}

interface LocalState {
  visible: boolean;
  isModalOpen: boolean;
  date: Date;
  isTextAreaHidden: boolean;
  textAreaCount: number;
  textAreaReason: string;
  buttonDisableStatus: boolean;
  selectedReason: string;
}

export interface DocumentActionsComposedProps extends DocumentActionsProps, DocumentStateToProps {}


class DocumentActions extends React.PureComponent<DocumentActionsComposedProps, LocalState> {
  constructor(props: DocumentActionsComposedProps) {
    super(props);
    this.state = {
      visible: false,
      isModalOpen: false,
      date: new Date(),
      isTextAreaHidden: true,
      textAreaCount: 0,
      textAreaReason: "",
      buttonDisableStatus: true,
      selectedReason: '',
    };
  }

  private handleVisibleChange = (visible: boolean): void => {
    this.setState({ visible });
  };

  private handleAttachementDownloadClick = (visible: boolean, id: string, fileName: string): void => {
    this.setState({ visible });
    const { onLinkClicked } = this.props;
    onLinkClicked(id, fileName);
  };

  private handleAttachementShareToggleClick = (visible: boolean, isResharing: boolean): void => {
    this.setState({ visible });
    if (isResharing === false) {
      this.setState({ isModalOpen: true, selectedReason: "" });
    } else {
      const { onShareToggleClicked, record } = this.props;
      onShareToggleClicked(record, isResharing, "");
    }
  };

  private handleOk = () => {
    const { textAreaReason, selectedReason } = this.state;
    const { onShareToggleClicked, record } = this.props;
    const reason = (selectedReason === UnshareReasons.unshareOption5) ? textAreaReason : selectedReason;
    if (reason !== "") {
      onShareToggleClicked(record, false, reason);
    }
    this.setState({ isModalOpen: false, isTextAreaHidden: true, textAreaCount: 0, textAreaReason: "", buttonDisableStatus: true, selectedReason: "" });
  };

  private handleCancel = () => {
    this.setState({ isModalOpen: false, isTextAreaHidden: true, textAreaCount: 0, textAreaReason: "", buttonDisableStatus: true });
  };

  private handleSelectReasonChange = (value: string) => {
    if (value === UnshareReasons.unshareOption5) {
      this.setState({ isTextAreaHidden: false, buttonDisableStatus: true, selectedReason: UnshareReasons.unshareOption5, textAreaReason: "" });
    } else {
      this.setState({ isTextAreaHidden: true, buttonDisableStatus: false, textAreaCount: 0, textAreaReason: "", selectedReason: value });
    }
  };

  private onOtherReasonChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    this.setState({ textAreaCount: value.length, textAreaReason: value, buttonDisableStatus: e.target.value.length <= 10 });
  };

  private contentAction = () => {
    const { record, props: { t } } = this.props;
    return (
      <>
        <ButtonActionLookAlike disabled={record.isDownloading} onClick={() => this.handleAttachementDownloadClick(false, record.id, record.name)}>{t("documentsTab.download")}</ButtonActionLookAlike>
        <InvisibleSeparator />
        <ButtonActionLookAlike disabled={record.isResharing || !record.isUnshared} onClick={() => this.handleAttachementShareToggleClick(false, true)}>{t("documentsTab.shareAction")}</ButtonActionLookAlike>
        <InvisibleSeparator />
        <ButtonActionLookAlike disabled={record.isUnsharing || record.isUnshared} onClick={() => this.handleAttachementShareToggleClick(false, false)}>{t("documentsTab.unshareAction")}</ButtonActionLookAlike>
      </>
    );
  };

  public render() {
    const { visible, isModalOpen, date, isTextAreaHidden, textAreaCount, buttonDisableStatus, selectedReason, textAreaReason } = this.state;
    const { props: { t, i18n }, record, locale } = this.props;
    
    return (
      <>
        <StyledPopover trigger="click" content={this.contentAction()} visible={visible} onVisibleChange={this.handleVisibleChange} placement="bottomRight">
          <ActionMenu>{Action(30)}</ActionMenu>
        </StyledPopover>
        <GlobalModalStyle />
        <Modal
          className="my-custom-class" 
          visible={isModalOpen} 
          onOk={this.handleCancel}
          okText={t("documentsTab.unhsareCancelModal")}
          onCancel={this.handleOk} 
          cancelText={t("documentsTab.unhsareOkModal")}
          cancelButtonProps={{ disabled: buttonDisableStatus }}
          closable={false}
          title={
            <>
              <QuestionCircleFilled style={{ fontSize: '40px', color: '#2062d4' }} />
              <ModalTitle>
                {t("documentsTab.unshareTitleModal")}
              </ModalTitle>
            </>
          }
        >
          <p>{t("documentsTab.unshareBodyModal")}</p>
          <Row>
            <Col md={4}>
              <Subtitle>{t("documentsTab.date")}</Subtitle>
              <ToLocalTimezoneShortDate locale={locale}>{date}</ToLocalTimezoneShortDate>
            </Col>
            <Col md={8}>
              <Subtitle>{t("documentsTab.fileName")}</Subtitle>
              <p>{record.name}</p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Subtitle>{t("documentsTab.details")}</Subtitle>
              <Select
                style={{ width: '440px' }}
                placeholder={t("documentsTab.choose")}
                onChange={this.handleSelectReasonChange}
                value={selectedReason || undefined}
                options={[
                  {
                    value: UnshareReasons.unshareOption1,
                    label: t("documentsTab.unshareOption1"),
                  },
                  {
                    value: UnshareReasons.unshareOption2,
                    label: t("documentsTab.unshareOption2"),
                  },
                  {
                    value: UnshareReasons.unshareOption3,
                    label: t("documentsTab.unshareOption3"),
                  },
                  {
                    value: UnshareReasons.unshareOption4,
                    label: t("documentsTab.unshareOption4"),
                  },
                  {
                    value: UnshareReasons.unshareOption5,
                    label: t("documentsTab.unshareOption5"),
                  },
                ]}
              />
              <div hidden={isTextAreaHidden} style={{ width: '440px' }}>
                <TextArea
                  showCount
                  maxLength={255} 
                  onChange={this.onOtherReasonChange}
                  placeholder="" 
                  value={textAreaReason}
                />
                <p style={{ textAlign: 'right' }}>{textAreaCount}/255</p>
              </div>
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

export default connect(mapStateToProps)(DocumentActions);
