import styled from "styled-components";

export const Title = styled.h3`
  &&& {
    margin: 0 0 3px 7px;
    font-size: 14px;
    font-family: ${(props) => props.theme.fontFamily.bold};
    font-weight: bold;
  }
`;

export const CheckboxGroup = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;
