import { AppErrorBoundary, ExtranetLayout } from "ia-react-core";
import React, { FC } from "react";
import { Helmet } from "react-helmet";

import { IASAdvisorCenterLayout } from "./AppLayout.style";

/**
 * This AppLayout component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
export interface AppLayoutProps {
  title: string;
  children: React.ReactNode;
}

interface AppLayoutComposedProps extends AppLayoutProps {}

const AppLayout: FC<AppLayoutComposedProps> = ({ children, title }) => (
  <AppErrorBoundary>
    <IASAdvisorCenterLayout>
      <ExtranetLayout themeVersion="1" lobCode="AC">
        <Helmet title={title} titleTemplate={`%s - ${title}`} />
        {children}
      </ExtranetLayout>
    </IASAdvisorCenterLayout>
  </AppErrorBoundary>
);

export default AppLayout;
