import * as React from "react";

import { BoxActionContent, BoxContainer } from "./BoxAction.style";
import BoxActionHeader from "./BoxActionHeader";

interface BoxActionProps {
  children: React.ReactChild;
  title: string;
}

class BoxAction extends React.PureComponent<BoxActionProps> {
  public render() {
    const { children, title } = this.props;
    return (
      <BoxContainer>
        <BoxActionHeader title={title} />
        <BoxActionContent>{children}</BoxActionContent>
      </BoxContainer>
    );
  }
}

export default BoxAction;
