import * as React from 'react';
import {useEffect, useState} from "react";
import { getAccountRoleName, getCorporateAccounts, getShareableAccounts, IAccessSharingViewData, mapPartyToShareListView } from '../../../../../../common/client/interfaces/AccesSharing';
import AccountService from '../../../../../../common/account/account.service';
import { TFunction } from 'i18next';
import { IAccount, IParty, IPartyAccount, PartyTypeCode } from '../../../../../../common/client/interfaces/Party';
import { subsidiaryName } from '../../../../../../common/client/interfaces/Client';
import styled from 'styled-components';
import { Alert, Button, Modal, Table } from "antd";
import { HouseholdType } from '../../../../../../common/enum/HouseholdType';
import { httpService } from '../../../../../../common/service/http.service';
import { DeleteOutlined } from '@ant-design/icons';
import { EditSharing } from './EditSharing';
import StyledTable from '../../../../../../common/table/StyledTable';
import { GlobalAccessSharingTableStyle } from './AccessSharingTable.style';
import { useSelector, connect } from 'react-redux';
import { State } from "../../../../../../interfaces/State";
import { get } from 'ia-react-core';
import { compose } from 'redux';
import { HttpPut } from '../../../../../../common/utils/HttpClient';
import { API_PARTIES } from '../../../../../../constants/API';

const SharingViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const TableContainer = styled.div`
    margin-bottom: 10px;
`;

const FlexContainer = styled.div`
    display: flex;
`;

const NameContainer = styled.div`
    min-width: 200px;
`;

const ActionContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const WarningContainer = styled.div`
    margin-bottom: 10px;
`;

export interface AccessSharingProps {
    client: IParty;
    t: TFunction;
    lang: string;
}

export interface AccessSharingStateToProps {
  accessToken: string;
  repCodes: string[];
}

interface AccessSharingComposedProps extends AccessSharingProps, AccessSharingStateToProps {}

const accountService = new AccountService();

const AccessSharing: React.FC<AccessSharingComposedProps> = ({ client, t, lang, accessToken, repCodes }: AccessSharingComposedProps): JSX.Element => {

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<IAccount[] | null>(null);
  const [sharingAccounts, setSharingAccounts] = useState<IPartyAccount[] | null>(null);
  const [confirmVisible, setConfirmVisible] = useState<boolean>(false);
  const [idShareDelete, setIdShareDelete] = useState<string | null>();

  const getOwnerAccounts = async () => {
    const party: IParty = client;

    console.log("starting owner accounts");
    setIsLoading(true);
    const userAccounts = party.accounts;
    const userOwnerAccounts = getShareableAccounts(userAccounts);
    const ids = `clientIds=${userOwnerAccounts.map((a) => a.id).join(',')}`;
    const responseAccount = await accountService.searchAccountsByClientIds(ids, lang, subsidiaryName);
    const responseAccessSharing = await httpService.get(`/FWMWPNS1/api/Sharing/?id=${party.id}`);
    setSharingAccounts(responseAccessSharing.data.data as IPartyAccount[]);
    setAccounts(responseAccount as unknown as IAccount[]);
    setIsLoading(false);
  }

  const getSharingAccounts = async () => {
    const party: IParty = client;
    setIsLoading(true);
    const responseAccessSharing = await httpService.get(`/FWMWPNS1/api/Sharing/?id=${party.id}`);
    setSharingAccounts(responseAccessSharing.data.data as IPartyAccount[]);
    setIsLoading(false);
  }

  const saveSharingAccounts = async (fromId: string, toId: string, accounts: string[], role: string) => {
    setIsLoading(true);
    await HttpPut(`${API_PARTIES}/AddSharring?fromId=${fromId}&id=${toId}&role=${role}`, accounts, {});
    await httpService.put(`/FWMWPNS1/api/NotifySharing?fromId=${fromId}&id=${toId}&role=${role}`, accounts, accessToken );
    getSharingAccounts();
    setIsLoading(false);
  }

  useEffect(() => {
    if (!accounts && !sharingAccounts) {
      getOwnerAccounts();
    }
  }, [accounts, sharingAccounts]);

  const handleDelete = (e: any) => {
    setConfirmVisible(true);
    setIdShareDelete(e.currentTarget.dataset.id);
  };

  const handleCancel = () => {
    setConfirmVisible(false);
    setIdShareDelete('');
  };

  const handleRemoveShare = () => {
    if (idShareDelete) {
      saveSharingAccounts(client.id, idShareDelete, [], HouseholdType.SHARING);

      if (getCorporateAccounts(client.accounts).length > 0) {
        saveSharingAccounts(client.id, idShareDelete, [], HouseholdType.COMPANY);
      }

      setConfirmVisible(false);
      setIdShareDelete('');
    }
  };

  const renderContent = (value: any, row: IAccessSharingViewData) => {
    const obj = {
      children: (
        <FlexContainer>
          <NameContainer>
            {row.partyName}
          </NameContainer>

          <ActionContainer>
            <EditSharing client={client} isEdit saveSharingAccounts={saveSharingAccounts} sharingAccounts={sharingAccounts} partyId={row.partyId} t={t} lang={lang} repCodes={repCodes} />
            <Button shape="circle" icon={<DeleteOutlined />} type="primary" danger data-id={row.partyId} onClick={handleDelete} />
          </ActionContainer>
        </FlexContainer>
      ),
      props: { rowSpan: row.rowSpan, className: 'groupColumn' },
    };

    if (row.isLastRow) {
      obj.props.className = 'groupColumn borderBottomBlue';
    }

    return obj;
  };

  const renderItem = (value: any, row: any) => {
    const obj = {
      children: value,
      props: { className: row.isLastRow ? 'borderBottomBlue' : '' },
    };

    return obj;
  };

  const renderOwnerItem = (value: any, row: any) => {
    const obj = {
      children: getAccountRoleName(row.role, t),
      props: { className: row.isLastRow ? 'borderBottomBlue' : '' },
    };

    return obj;
  };


  const columns = [
    {
      title: t('accessSharing.name'),
      dataIndex: '',
      key: 'Owner',
      width: '300px',
      render: renderContent,
    },
    {
      title: t('accessSharing.accesToMyAccount'),
      dataIndex: 'accountDisplayName',
      key: 'accountDisplayName',
      render: renderItem,
    },
    {
      title: '',
      dataIndex: 'accountNumber',
      key: 'accountNumber',
      render: renderItem,
    },
    {
      title: '',
      key: '',
      dataIndex: '',
      render: renderOwnerItem,
    },
  ];

  let data = [] as IAccessSharingViewData[];
  if (sharingAccounts && accounts) {
    let accessSharingAccounts = sharingAccounts.filter((x) => x.role === HouseholdType.SHARING);
    if (getCorporateAccounts(client.accounts).length > 0) {
      accessSharingAccounts = sharingAccounts.filter((x) => x.role === HouseholdType.SHARING || x.role === HouseholdType.COMPANY);
    }
    data = mapPartyToShareListView(accessSharingAccounts, accounts);
  }

  const isNonClient = client.partyTypeCode === PartyTypeCode.NonClient;
  const corpAccounts = client.accounts.filter((x) => x.role === HouseholdType.COMPANY);
  const corpAccountList = corpAccounts.map((a) => `${a.id}`).join(', ');
  const shareableAccounts = getShareableAccounts(client.accounts);

  let alertMessage = '';
  if (shareableAccounts.length === 0) {
    alertMessage = t('accessSharing.noOwnerAccountWarning');
  }
  if (corpAccounts.length > 0) {
    alertMessage += ` ${t('accessSharing.corpAccountWarning')} (${corpAccountList})`;
  }


  return (
    <>
      <SharingViewContainer>
        <GlobalAccessSharingTableStyle />
          <TableContainer>
            <StyledTable locale={{ emptyText: t('accessSharing.noData') }} rowKey="key" columns={columns} dataSource={data} pagination={false} className="tableHousehold" loading={isLoading} t={t} />
          </TableContainer>
          {alertMessage !== '' && <WarningContainer><Alert message={alertMessage} type="warning" showIcon /></WarningContainer>}
          {(!isNonClient) && <EditSharing client={client} isEdit={false} saveSharingAccounts={saveSharingAccounts} sharingAccounts={sharingAccounts} t={t} lang={lang} repCodes={repCodes} />}
          <Modal title={t('accessSharing.deleteMember')} visible={confirmVisible} onOk={handleRemoveShare} onCancel={handleCancel}>
            {t('accessSharing.deleteMemberConfirmation')}
          </Modal>
      </SharingViewContainer>
    </>
  );
};

const mapStateToProps = (state: State, { scope }: any): AccessSharingStateToProps => ({
  accessToken: get(state, "App.apiGatewayData.access_token") as unknown as string,
  repCodes: get(state, "App.repCodes") as string[]
});

export default compose<React.FC<AccessSharingComposedProps>>(
  connect(mapStateToProps),
)(AccessSharing);