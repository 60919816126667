import { ColumnType } from "antd/lib/table";
import { InjectTranslationsProps, Loader } from "ia-react-core";
import * as React from "react";
import { ButtonLinkLookAlike } from "../../../../../../../common/common.style";
import { Attachment } from "../../../../../../../common/messages/interfaces/Attachment";
import MessagesHelper from "../../../../../../../common/messages/utils/MessagesHelper";
import { FileContainer, FileIconContainer } from "../DocumentsTable.style";

export const FileColumn = ({ t }: InjectTranslationsProps, onLinkClicked: (id: string, fileName: string) => Promise<void>): ColumnType<Attachment> => ({
  title: t("documentsTab.file"),
  dataIndex: "file",
  width: "50%",
  key: "file",
  render: (value: unknown, record: Attachment) => (
    <FileContainer>
      <FileIconContainer>
        {record.isDownloading ? <Loader size={24} /> : MessagesHelper.getFileIconByExtension(record.name)}
      </FileIconContainer>
      <ButtonLinkLookAlike disabled={record.isDownloading} onClick={() => onLinkClicked(record.id, record.name)}>{record.name}</ButtonLinkLookAlike>
    </FileContainer>
  ),
});
