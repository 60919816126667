import { Phone } from "../../interfaces/Phone";
import { IParty } from "./Party";
import { Email } from "./Email";
import { FinancialProfile } from "./FinancialProfile";
import { Occupation } from "./Occupation";

export interface Client extends IParty{
  id: string;
  firstName: string;
  lastName: string;
  middleName: string;
  genderCode: string;
  phones: Phone[];
  lastModified: Date;
  emails: Email[];
  personalizedAccessCode: string;
  loading: boolean;
  hasError: boolean;
  requestedAccessCode: boolean;
  dateOfBirth: Date;
  dateRegistered: Date;
  sin: string;
  languageCode: string;
  businessName: string;
  investmentKnowledgeLevel: number;
  financialProfile: FinancialProfile;
  occupations: Occupation[];
}

export const subsidiaryName = 'IaSecurities';
