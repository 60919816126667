import { Reducer } from "redux";

import { ClientState, initialClientState } from "../../../interfaces/ClientState";
import SearchType from "../enum/SearchType";
import { Client } from "../interfaces/Client";
import {
  ClientAction,
  ClientActionTypes as types,
  GetClient,
  GetClientError,
  GetClientSuccess,
  SearchClient,
  SearchClientError,
  SearchClientSuccess,
  SetNbSearchLaunched,
  GetClientLocked,
  GetClientLockedSuccess,
  GetClientLockedError,
} from "./client.actions.types";

/* eslint-disable no-restricted-syntax */

const handleSearchClient = (state: ClientState, { searchCriteria }: SearchClient) => ({
  ...state,
  searchCriteria,
  searchKeyValueErrors: [],
  clients: [],
  loading: true,
  searchLoading: true,
  totalResults: 0,
  currentOffset: 0,
});

const handleSearchClientSuccess = (state: ClientState, { paginatedResponse, searchKeyValue }: SearchClientSuccess) => {
  const clients = [...state.clients];
  let totalResults = 0;
  let { currentOffset } = { ...state };

  for (const client of paginatedResponse.data) {
    if (clients.findIndex((element) => element.id === client.id) === -1) {
      clients.push(client);
    }
  }

  totalResults = state.totalResults < paginatedResponse.total ? paginatedResponse.total : state.totalResults;
  if (searchKeyValue.searchType === SearchType.fullName) {
    currentOffset = currentOffset + paginatedResponse.data.length >= paginatedResponse.total ? paginatedResponse.total : paginatedResponse.offset + paginatedResponse.limit;
  }

  const searchLoading: boolean = state.nbSearchLaunched - 1 > 0 && clients.length === 0;

  return { ...state, clients, loading: false, searchLoading, currentOffset, totalResults, nbSearchLaunched: state.nbSearchLaunched - 1 };
};

const handleSearchClientError = (state: ClientState, { searchKeyValue }: SearchClientError) => ({
  ...state, searchKeyValueErrors: [...state.searchKeyValueErrors, searchKeyValue], loading: false,
});

const handleSearchClientAutoComplete = (state: ClientState) => ({
  ...state,
  searchKeyValueErrors: [],
  clients: [],
  loading: true,
  searchLoading: true,
  totalResults: 0,
  currentOffset: 0,
});

const handleLoadMoreClients = (state: ClientState) => (
  { ...state, loading: true }
);

const handleGetClient = (state: ClientState, { id }: GetClient) => ({
  ...state,
  clients: state.clients.map((client: Client) => {
    if (client.id === id) {
      return { ...client, loading: true, requestedAccessCode: true };
    }
    return client;
  }),
  selectedClient: undefined,
});

const handleGetClientSuccess = (state: ClientState, { response }: GetClientSuccess) => ({
  ...state,
  selectedClient: response.data,
  messages: response.messages,
  clients: state.clients.map((client: Client) => {
    if (client.id === response.data.id) {
      return { ...client, loading: false, personalizedAccessCode: response.data.personalizedAccessCode };
    }
    return client;
  }),
});

const handleGetClientError = (state: ClientState, { id }: GetClientError) => ({
  ...state,
  selectedClient: undefined,
  clients: state.clients.map((client: Client) => {
    if (client.id === id) {
      return { ...client, loading: false };
    }
    return client;
  }),
});

const handleSetNbSearchLaunched = (state: ClientState, { nbSearchLaunched }: SetNbSearchLaunched) => ({
  ...state,
  nbSearchLaunched,
});

const handleGetClientLockedSuccess = (state: ClientState, { locked } : GetClientLockedSuccess) => ({
  ...state,
  isLocked: locked,
});

const ClientReducer: Reducer<ClientState, ClientAction> = (state = initialClientState, action): ClientState => {
  switch (action.type) {
    case types.SEARCH_CLIENT:
      return handleSearchClient(state, action);
    case types.SEARCH_CLIENT_AUTOCOMPLETE:
      return handleSearchClientAutoComplete(state);
    case types.SEARCH_CLIENT_SUCCESS:
      return handleSearchClientSuccess(state, action);
    case types.SEARCH_CLIENT_ERROR:
      return handleSearchClientError(state, action);
    case types.LOAD_MORE_CLIENTS:
      return handleLoadMoreClients(state);
    case types.GET_CLIENT:
      return handleGetClient(state, action);
    case types.GET_CLIENT_SUCCESS:
      return handleGetClientSuccess(state, action);
    case types.GET_CLIENT_ERROR:
      return handleGetClientError(state, action);
    case types.SET_NB_SEARCH_LAUNCHED:
      return handleSetNbSearchLaunched(state, action);
    case types.GET_CLIENT_LOCKED_SUCCESS:
      return handleGetClientLockedSuccess(state, action);
    default:
      return state;
  }
};

export default ClientReducer;
