import * as React from "react";
import { TFunction } from "i18next";
import { Client } from "../../../../../../common/client/interfaces/Client";
import ClientHelper from "../../../../../../common/client/utils/ClientHelper";
import { Link } from "../../../../../../common/common.style";

export interface MirrorViewLinkProps {
    client: Client;
    t: TFunction;
}

const MirrorViewLink: React.FC<MirrorViewLinkProps> = (props: MirrorViewLinkProps) => {
  const { t, client } = props;

  return (
    <Link href={ClientHelper.getMirrorViewLink(client.id)} target="_blank">
      {t("App:mirrorView")}
    </Link>
  );
};

export default MirrorViewLink;
