import { ColumnType } from "antd/lib/table";
import * as React from "react";

import PersonalizedAccessCode from "../../../../../../common/client/components/PersonalizedAccessCode";
import { Client } from "../../../../../../common/client/interfaces/Client";
import { ClientSearchResultTableProps } from "../ClientSearchResultTable";

export function ClientSearchResultColumnAccessCode(props: ClientSearchResultTableProps): ColumnType<Client> {
  const { t } = props;

  return {
    title: t("App:client.personalizedAccessCode"),
    dataIndex: "name",
    key: "name",
    render: (value: unknown, record: Client) => (
      <PersonalizedAccessCode t={t}>{record.personalizedAccessCode}</PersonalizedAccessCode>
    ),
  };
}
