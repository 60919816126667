enum TimeHorizon {
    Unknown,
    Years3AndLess,
    Years3To5,
    Years6To10,
    Years11To20,
    Years20AndOver
}

export default TimeHorizon;
