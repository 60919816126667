import { TFunction } from "i18next";
import * as React from "react";
import { Col, Row } from "react-grid-system";

import ErrorBoundary from "../../../../../common/components/ErrorBoundary";
import { KnowledgeLevel } from "../../../../../common/enum/KnowledgeLevel";
import Section from "../../../../../common/enum/Section";
import { ClientProfileComposedProps } from "../../../ClientProfile";
import { Title } from "../../../clientProfile.style";
import InvestmentObjectives from "./InvestmentObjectives/InvestmentObjectives";
import {
  InvestmentKnowledgeContainer,
  InvestmentObjectivesContainer,
  KnowledgeLevelItem,
  KnowledgeLevelSelectedItem,
} from "./investorProfileTab.style";

interface InvestorProfileTabProps extends ClientProfileComposedProps { }

const renderKnowledgeLevels = (knowledgeLevel: number, t: TFunction): JSX.Element[] => Object.values(KnowledgeLevel)
  .filter((v) => typeof v === 'number')
  .map((v) => {
    const text = t(`investorTab.knowledge.level.${v}`);
    return v === knowledgeLevel
      ? (<KnowledgeLevelSelectedItem key={v}>{text}</KnowledgeLevelSelectedItem>)
      : (<KnowledgeLevelItem key={v}>{text}</KnowledgeLevelItem>);
  });

const InvestorProfileTab: React.FC<InvestorProfileTabProps> = (props: InvestorProfileTabProps) => {
  const { t, client, loadingAccounts, accountsGroupedByOwner, i18n, locale } = props;
  
  return (
    <>
      <Row>
        <Col>
          <InvestmentKnowledgeContainer>
            <Title>{t("investorTab.knowledge.title")}</Title>
            {renderKnowledgeLevels(client.investmentKnowledgeLevel, t)}
          </InvestmentKnowledgeContainer>
        </Col>
      </Row>
      <Row>
        <Col>
          <InvestmentObjectivesContainer>
            <Title>{t("investorTab.objectives.title")}</Title>
            <ErrorBoundary sections={[Section.account]}>
              <InvestmentObjectives t={t} dataSource={accountsGroupedByOwner} locale={locale} loadingAccounts={loadingAccounts} />
            </ErrorBoundary>
          </InvestmentObjectivesContainer>
        </Col>
      </Row>
    </>
  );
};

export default InvestorProfileTab;
