import * as React from "react";

import { Item, List } from "../../../../../../common/common.style";
import { Document } from "../../../../../../common/signaturePackage/interfaces/Document";

interface LocalProps {
  documents: Document[];
}

const documentsRender = (documents: Document[]) => documents.map((document: Document) => <Item key={document.id}>{document.name}</Item>);

const ResultDocuments: React.FC<LocalProps> = ({ documents }: LocalProps) => <List>{documentsRender(documents)}</List>;

export default ResultDocuments;
