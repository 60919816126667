import * as React from "react";
import Highlighter from "react-highlight-words";

import { AutoCompleteOption } from "../../../pages/Dashboard/components/AutoComplete/AutoComplete";
import AutoCompleteDataSource from "../../../pages/Dashboard/components/AutoComplete/datasource";
import { ClientAccount } from "../interfaces/Account";
import { Client } from "../interfaces/Client";
import { Accounts, Name } from "./ClientAutoCompleteDataSource.style";

export default class ClientAutoCompleteDataSource implements AutoCompleteDataSource<Client> {
  selectedResult: (option: AutoCompleteOption<Client>) => string;

  formatResult: (option: AutoCompleteOption<Client>, searchCriteria?: string | number | string[] | undefined) => React.ReactNode;

  options: AutoCompleteOption<Client>[];

  constructor(clients: Client[]) {
    this.options = this.getOptions(clients);
    this.formatResult = this.formatResultLabel;
    this.selectedResult = this.selectedResultFormat;
  }

  private getOptions = (clients: Client[]): AutoCompleteOption<Client>[] => clients.map((client: Client) => ({ label: this.formatLabel(client), value: client, id: client.id } as AutoCompleteOption<Client>));

  private formatLabel = (client: Client, searchCriteria?: string | number | string[] | undefined): React.ReactNode => {
    const fullName = `${client.firstName} ${client.lastName}`;
    const clientIds = `${client.accounts.map((account: ClientAccount) => account.id).join(", ")}`;

    const searchWords = !searchCriteria
      ? []
      : searchCriteria
        .toString()
        .trim()
        .split(" ");

    return (
      <>
        <Name>
          <Highlighter textToHighlight={fullName} searchWords={searchWords} highlightTag="strong" />
        </Name>
        <Accounts>
          <Highlighter textToHighlight={clientIds} searchWords={searchWords} highlightTag="strong" />
        </Accounts>
      </>
    );
  };

  private formatResultLabel = (option: AutoCompleteOption<Client>, searchCriteria?: string | number | string[] | undefined): React.ReactNode => this.formatLabel(option.value, searchCriteria);

  private selectedResultFormat = (option: AutoCompleteOption<Client>): string => `${option.value.firstName} ${option.value.lastName}`;
}
