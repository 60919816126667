import { PaginatedResponse } from "../../interfaces/PaginatedResponse";
import { ResponsePayload } from "../../interfaces/ResponsePayload";
import { SearchCriteria } from "../../interfaces/SearchCriteria";
import { Client } from "../interfaces/Client";
import { SearchKeyValue } from "../interfaces/SearchKeyValue";
import {
  ClientActionTypes as types,
  GetClient,
  GetClientError,
  GetClientSuccess,
  LoadMoreClients,
  SearchClient,
  SearchClientAutoComplete,
  SearchClientError,
  SearchClientSuccess,
  SetNbSearchLaunched,
  GetClientLocked,
  GetClientLockedSuccess,
  GetClientLockedError,
} from "./client.actions.types";

export const loadMoreClients = (searchCriteria: SearchCriteria, offset: number): LoadMoreClients => ({ type: types.LOAD_MORE_CLIENTS, searchCriteria, offset });

export const searchClient = (searchCriteria: SearchCriteria): SearchClient => ({ type: types.SEARCH_CLIENT, searchCriteria });

export const searchClientSuccess = (paginatedResponse: PaginatedResponse<Client>, searchKeyValue: SearchKeyValue): SearchClientSuccess => ({ type: types.SEARCH_CLIENT_SUCCESS, paginatedResponse, searchKeyValue });

export const searchClientError = (searchKeyValue: SearchKeyValue): SearchClientError => ({ type: types.SEARCH_CLIENT_ERROR, searchKeyValue });

export const searchClientAutoComplete = (criteria: string): SearchClientAutoComplete => ({ type: types.SEARCH_CLIENT_AUTOCOMPLETE, criteria });

export const getClient = (id: string, fetchAdditionnalInfos: boolean): GetClient => ({ type: types.GET_CLIENT, id, fetchAdditionnalInfos });

export const getClientSuccess = (response: ResponsePayload<Client>): GetClientSuccess => ({ type: types.GET_CLIENT_SUCCESS, response });

export const getClientError = (id: string): GetClientError => ({ type: types.GET_CLIENT_ERROR, id });

export const setNbSearchLaunched = (nbSearchLaunched: number): SetNbSearchLaunched => ({ type: types.SET_NB_SEARCH_LAUNCHED, nbSearchLaunched });

export const getClientLocked = (id: string) : GetClientLocked => ({type: types.GET_CLIENT_LOCKED, id});

export const getClientLockedSuccess = (locked: boolean) : GetClientLockedSuccess => ({type: types.GET_CLIENT_LOCKED_SUCCESS, locked});

export const getClientLockedError = (id: string) : GetClientLockedError => ({type: types.GET_CLIENT_LOCKED_ERROR, id});
