import styled from "styled-components";

export const IASAdvisorCenterLayout = styled.div`
  @media (min-width: 1140px) {
    .we_pageContainer > div:nth-child(2) > div {
      padding: 20px 25px;
      background-color: #f2f2f2;
    }
  }
`;
