import { all, apply, Effect, put, takeLatest } from "@redux-saga/core/effects";

import { User } from "../interfaces/User";
import UserService from "../user.service";
import { getUserError, getUserSuccess } from "./user.actions";
import { UserActionTypes as types } from "./user.actions.types";

/* eslint-disable @typescript-eslint/unbound-method */
function* handleFetchUser() {
  try {
    const service = new UserService();
    const user: User = yield apply(service, service.getUser, []);
    yield put(getUserSuccess(user));
  } catch (error) {
    console.log(error);
    yield put(getUserError());
  }
}

function* watchGetUser() {
  yield takeLatest(types.GET_USER, handleFetchUser);
}

export default function* UserSaga(): IterableIterator<Effect> {
  yield all([watchGetUser()]);
}
