import { EmptyValue } from "../constants";

export default class Formatter {
  static formatSin(sin: string): string {
    if (!sin) return EmptyValue;

    const cleaned = (`${sin}`).replace(/\D/g, '');
    // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/);

    return match && match.length >= 4
      ? `${match[1]} ${match[2]} ${match[3]}`
      : EmptyValue;
  }

  static formatPostalCode(postalCode: string): string | undefined {
    if (!postalCode) return undefined;

    const cleaned = (`${postalCode}`).replace(' ', '');
    // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
    const match = cleaned.match(/^([A-Z]\d[A-Z])(\d[A-Z]\d)$/);

    return match && match.length >= 3
      ? `${match[1]} ${match[2]}`
      : undefined;
  }

  static formatWebSiteUrl = (url: string): string => {
    const URL_SCHEME_REGEXP = /^((?:f|ht)tps?:)?\/\//;
    const DEFAULT_SCHEME = "http";
    let formatedUrl = url;
  
    if (!URL_SCHEME_REGEXP.test(url)) {
      formatedUrl = `${DEFAULT_SCHEME}://${url}`;
    }
  
    return formatedUrl;
  };
}
