import { Control, ControlProps, ControlRenderProp } from "ia-react-core";
import * as React from "react";

import AutoComplete, { AutoCompleteProps } from "../AutoComplete/AutoComplete";

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface AutoCompleteControlProps<Value, StateValue> extends Omit<AutoCompleteProps<Value>, "defaultValue" | "name" | "value">, Omit<ControlProps<Value, StateValue>, "children"> {}

const AutoCompleteControl: React.FC<AutoCompleteControlProps<any, any>> = ({ name, fetchAction, loading, dataSource, texts, onSelected }) => (
  <Control name={name}>{({ onValueChange }: ControlRenderProp<any, Element>) => <AutoComplete dataSource={dataSource} onSelected={(value: any) => { onValueChange(value); onSelected(value); }} name={name} fetchAction={fetchAction} loading={loading} texts={texts} />}</Control>
);

export default AutoCompleteControl;
