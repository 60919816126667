/* eslint-disable @typescript-eslint/unbound-method */
import { all, apply, Effect, put, takeLatest } from "@redux-saga/core/effects";

import { setError } from "../../../redux/app.actions";
import Section from "../../enum/Section";
import AdvisorService from "../advisor.service";
import { TeamByRepCode } from "../interfaces/TeamByRepCode";
import { getAdvisorsByRepCodesSuccess } from "./advisor.actions";
import { AdvisorActionTypes as types, GetAdvisorsByRepCodes } from "./advisor.actions.types";

function* handleFetchAdvisorsByRepCodes({ repCodes }: GetAdvisorsByRepCodes) {
  try {
    const service = new AdvisorService();
    const advisors: TeamByRepCode = yield apply(service, service.getTeamByRepCodes, [repCodes]);
    yield put(getAdvisorsByRepCodesSuccess(advisors));
  } catch (error) {
    yield put(setError({ section: Section.advisor, error }));
  }
}

function* watchGetAdvisorsByRepCodes() {
  yield takeLatest(types.GET_ADVISORS_BY_REPCODES, handleFetchAdvisorsByRepCodes);
}

export default function* AdvisorSaga(): IterableIterator<Effect> {
  yield all([watchGetAdvisorsByRepCodes()]);
}
