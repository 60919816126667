import { ToggleButton } from "ia-react-core";
import React, { useEffect, useState } from "react";
import { Container, LeftButton, RightButton } from "./styles";

export interface ComboButtonProps {
    text: string;
    value: string;
    defaultChecked?: boolean;
}

interface ToggleButtonComboProps {
    firstButton: ComboButtonProps;
    secondButton: ComboButtonProps;
    onValueChange?: (value: string) => void;
}

const ToggleButtonCombo: React.FC<ToggleButtonComboProps> = (props: ToggleButtonComboProps): JSX.Element => {
  const { firstButton, secondButton, onValueChange } = props;
  const [checked, setChecked] = useState<boolean>(true);
  useEffect(() => {
    if (secondButton.defaultChecked !== undefined && secondButton.defaultChecked) {
      setChecked(false);
    }
  }, [secondButton.defaultChecked]);

  const onChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(!checked);
    if (onValueChange) {
      onValueChange(event.target.value);
    }
  };

  return (
    <Container>
      <LeftButton><ToggleButton className="left-button" name="buttonCombo" value={firstButton.value} checked={checked} onChange={onChecked}>{firstButton.text}</ToggleButton></LeftButton>
      <RightButton><ToggleButton className="right-button" name="buttonCombo" value={secondButton.value} checked={!checked} onChange={onChecked}>{secondButton.text}</ToggleButton></RightButton>
    </Container>
  );
};

export default ToggleButtonCombo;
