import styled from "styled-components";
import { Button } from "../../../../../common/common.style";

export const SettingsHeader = styled.div`
  display: block;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;

  @media(max-width: 500px) {
    flex-direction: column;
    align-items:flex-start;
  }

  .ant-tag-checkable {
    display: inline-block;
    background: #ffffff;
    color: #525870;
    padding: 11px 16px;
    font-family: 'Open Sans';
    font-style: normal
    box-sizing: border-box;
    min-height: 40px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    margin-bottom: 16px;
    margin-right: 0px;
    line-height: 24px;
  }
  .ant-tag-checkable-checked {
    color: #003da5;
    padding: 10px 15px;
    border-bottom: 1px solid #003da5 !important;
  }
`;


