import styled, { createGlobalStyle } from "styled-components";

export const ModalTitle = styled.h2` 
  font-size: 24px;
  color: #333333;
  font-family: ${(props) => props.theme.fontFamily.regular};
  font-weight: 700;
  margin: 18px 0 0 0;
  line-height: normal;
`;

export const GlobalModalStyle = createGlobalStyle`
  
  .ant-modal-header {
    border-bottom: none;
    padding-bottom: 0;
    padding-top: 24px;
  }
  .ant-modal-body{
    padding-bottom: 10px;
  }
  .ant-modal-footer{
    border-top: none;
    text-align: left;
    padding-bottom: 24px;
    padding-left: 24px;

    .ant-btn{
      border-width: 0px;
      height: 40px;
      background: inherit;
      background-color: #003DA5;
      color: #FFFFFF;
      border: none;
      border-radius: 4px;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      font-family: 'Open Sans Semibold', 'Open Sans Regular', 'Open Sans', sans-serif;
      font-weight: 600;
      font-style: normal;
      font-size: 14px;
      text-align: left;
      :hover{
        background-color: #2062d4;
      }
    }
    .ant-btn.ant-btn-primary{
      height: 40px;
      background: inherit;
      background-color: #FFFFFF;
      box-sizing: border-box;
      border-width: 1px;
      border-style: solid;
      border-color: #003DA5;
      border-radius: 4px;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      font-family: 'Open Sans Bold', 'Open Sans Regular', 'Open Sans', sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 14px;
      color: #003DA5;
      text-align: left;

      :hover{
        border-color: #2062d4 !important;
        color: #2062d4 !important;
      }
    }
  }

  textarea {  
    width: 440px;
    height: 119px;
    padding: 2px 2px 2px 2px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    letter-spacing: normal;
    color: #000000;
    vertical-align: none;
    text-align: left;
    text-transform: none;
    background-color: transparent;
    border-color: #aaaaaa;
    resize: none;
    margin-top: 15px;

    :focus-visible {
      outline-color: #aaaaaa;
    }
  }

`;
