import * as React from "react";

import { LinkBold } from "../../../../../../common/common.style";
import SignaturePackageActionEvent from "../../../../../../common/signaturePackage/enum/SignaturePackageActionEvent";
import SignaturePackageHelper from "../../../../../../common/signaturePackage/utils/SignaturePackageHelper";

interface LocalProps {
  id: string;
  actionEvent: (id: string, action: SignaturePackageActionEvent, event?: React.MouseEvent<HTMLElement>) => void;
}

const ResultPackage: React.FC<LocalProps> = ({ id, actionEvent }: LocalProps) => (
  <LinkBold onClick={(e) => actionEvent(id, SignaturePackageActionEvent.selectedSignaturePackage, e)} to={SignaturePackageHelper.detailsUrl(id)}>
    {id}
  </LinkBold>
);

export default ResultPackage;
