import axios, { AxiosResponse } from 'axios';
import { authHeader } from '../Helpers/auth-header';
import { authenticationService, LoginStatus } from './authentication.service';

export const httpService = {
  get,
  put,
  patch,
  post,
  handleExportCsv,
};

async function put(url: string, payload: any) {
  try {
    const response = await axios.put(url, payload, authHeader());
    return response;
  } catch (error) {
    handleError(error);
    throw (error);
  }
}

async function post(url: string, payload: any) {
  try {
    const response = await axios.post(url, payload, authHeader());
    return response;
  } catch (error) {
    handleError(error);
    throw (error);
  }
}

async function patch(url: string, data: any) {
  try {
    const response = await axios.patch(url, data, authHeader());
    return response;
  } catch (error) {
    handleError(error);
    throw (error);
  }
}

async function get(url: string) {
  try {
    const response = await axios.get(url, authHeader());
    return response;
  } catch (error) {
    handleError(error);
    throw (error);
  }
}

function handleError(error: any) {
  if (error.response) {
    /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);

    handleAuthorizationFail(error.response);
  } else if (error.request) {
    /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
    console.log(error.request);
  } else {
    // Something happened in setting up the request and triggered an Error
    console.log('Error', error.message);
  }
  console.log(error);
}

function handleAuthorizationFail(response: AxiosResponse) {
  if ([401, 403].includes(response.status)) {
    authenticationService.setStatus(LoginStatus.EXPIRED);
  }
}

function handleExportCsv(url: string, completedCallback: any, completedCallbackError: any, options: any = {}) {
  axios.get(url, options)
    .then((response) => {
      if (completedCallback) {
        completedCallback(response.data);
      }
    }).catch((error) => {
      console.error('Could not Download the Excel report from the backend.', error);
      completedCallbackError(error);
    });
}
