import { ColumnType } from "antd/lib/table";
import * as React from "react";

import { SignaturePackage } from "../../../../../../../common/signaturePackage/interfaces/SignaturePackage";
import ResultDocuments from "../ResultDocuments";
import { ResultTableProps } from "../ResultTable";

export function ResultColumnDocuments(props: ResultTableProps): ColumnType<SignaturePackage> {
  const { t } = props;

  return {
    title: t("searchResult.columns.documents"),
    dataIndex: "documents",
    key: "documents",
    render: (value: unknown, record: SignaturePackage) => <ResultDocuments documents={record.documents} />,
  };
}
