import { Advisor } from "../../../../../../common/advisor/interfaces/Advisor";
import * as React from 'react';

import { getPrimaryEmail, getPrimaryPhone } from './helper';
import { Container, EmailSection, Icon, Informations, Name, PhoneSection } from './styles/advisorCard.style';
import { TFunction } from 'i18next';

interface ILocalProps {
  nationalBranch: Advisor;
  t: TFunction;
}

const NationalBranchCard: React.FC<ILocalProps> = ({ nationalBranch, t }: ILocalProps) => {
  const email: string = getPrimaryEmail(nationalBranch, true);
  const primaryPhone = getPrimaryPhone(nationalBranch.phones);
  return (
    <Container>
      <Icon />
      <Informations>
        <Name>{t('iAWealthAdviceCentre') as string}</Name>
        {primaryPhone && <PhoneSection>{primaryPhone}</PhoneSection>}
        {email !== '' && (
          <EmailSection>
            <a href={`mailto:${email}`}>{email}</a>
          </EmailSection>
        )}
      </Informations>
    </Container>
  );
};

export default NationalBranchCard;
