import en from "faker/locale/en";
import fr from "faker/locale/fr";
import { get, injectTranslations, withScope } from "ia-react-core";
import { FC } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { selectOwnedOpenAccounts } from "../../../../../../common/client/redux/client.selector";
import { Language } from "../../../../../../common/enum/Language";
import { State } from "../../../../../../interfaces/State";
import SendDocuments, { SendDocumentProps, SendDocumentStateToProps } from "./SendDocument";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: State, { scope }: any): SendDocumentStateToProps => ({ 
  partyId: get(state, "App.ClientProfile.selectedClient.id") as unknown as string,
  accounts: selectOwnedOpenAccounts(state, scope),
});

export default compose<FC<SendDocumentProps>>(
  injectTranslations("ClientProfile", [
    { language: Language.French, resource: fr },
    { language: Language.English, resource: en },
  ]),
  withScope,
  connect(mapStateToProps),
)(SendDocuments);
