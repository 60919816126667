import React from 'react';
import { Row, Col } from 'antd';
import { Trans } from 'react-i18next';
import i18n from 'i18next';
import { MainBox, TextFieldLabel, SubTitle, LabelWhite, TextResultFieldLabel } from './Styles/CreateUserStyle';
import { ICandidate } from './Models/Client';
import { HouseholdNoRoleType } from './Models/Household';

export interface IProps {
    nonClient: ICandidate;
}

export class UserSummary extends React.Component<IProps> {
    private renderClientList = (account: any, index: number) => (
      <Row style={{ marginTop: 15 }} gutter={[16, 0]} key={`account${index}`}>
        <Col span={8}>
          <TextResultFieldLabel>{account.id.toUpperCase()}</TextResultFieldLabel>
        </Col>
        <Col span={8}>
          <TextResultFieldLabel>{account.name}</TextResultFieldLabel>
        </Col>
        <Col span={8}>
          <TextResultFieldLabel>{account.noRole === HouseholdNoRoleType.CORPORATE ? i18n.t('createUser.CO') : i18n.t('createUser.ThirdParty')}</TextResultFieldLabel>
        </Col>
      </Row>
    )

    render() {
      return (
        <div>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <MainBox style={{ height: 180 }}>
                <Row gutter={[16, 16]} style={{padding: '8px'}}>
                  <Col span={24}>
                    <SubTitle>
                      <Trans i18nKey="createUser.PersonalInformation" />
                    </SubTitle>
                  </Col>
                </Row>
                <Row style={{padding: '8px'}}>
                  <Col span={12}>
                    <TextFieldLabel> <Trans i18nKey="createUser.firstName" /> </TextFieldLabel>
                    <TextResultFieldLabel>{this.props.nonClient.firstName}</TextResultFieldLabel>
                  </Col>
                  <Col span={12}>
                    <TextFieldLabel><Trans i18nKey="createUser.lastName" /> </TextFieldLabel>
                    <TextResultFieldLabel>{this.props.nonClient.lastName}</TextResultFieldLabel>
                  </Col>
                </Row>
                <Row gutter={[16, 16]} style={{paddingLeft: '8px'}}>
                  <Col span={24}>
                    <TextFieldLabel id="langueLbl"><Trans i18nKey="createUser.language" /></TextFieldLabel>
                    <TextResultFieldLabel>{this.props.nonClient.languageCode.toUpperCase()}</TextResultFieldLabel>
                  </Col>

                </Row>
              </MainBox>
            </Col>
            <Col span={16}>
              <MainBox style={{ height: 180 }}>
                <Row gutter={[16, 16]} style={{padding: '8px'}}>
                  <Col span={24}>
                    <SubTitle><Trans i18nKey="createUser.contact" /></SubTitle>
                  </Col>
                </Row>
                <Row gutter={[16, 16]} style={{padding: '8px'}}>
                  <Col span={8}>
                    <TextFieldLabel shrink><Trans i18nKey="createUser.phone" /></TextFieldLabel>
                    <TextResultFieldLabel>{this.props.nonClient.phones[0].phoneNumber}</TextResultFieldLabel>
                    <div style={{paddingTop: '8px'}}>
                      <TextFieldLabel><Trans i18nKey="createUser.email" /></TextFieldLabel>
                      <TextResultFieldLabel>{this.props.nonClient.emails[0].emailAddress}</TextResultFieldLabel>
                    </div>
                  </Col>
                  <Col span={8}>
                    <TextFieldLabel shrink htmlFor="lastNameId">
                      <Trans i18nKey="createUser.address" />
                    </TextFieldLabel>
                    <div>
                    <TextResultFieldLabel>{this.props.nonClient.addresses[0].streetNumber} {this.props.nonClient.addresses[0].streetName}{this.props.nonClient.addresses[0].unitNumber ? `, ${this.props.nonClient.addresses[0].unitNumber}`: ''}</TextResultFieldLabel>
                    </div>
                    <div>
                      <TextResultFieldLabel>{this.props.nonClient.addresses[0].municipality} {this.props.nonClient.addresses[0].regionCode}</TextResultFieldLabel>
                    </div>
                    <div>
                      <TextResultFieldLabel>{this.props.nonClient.addresses[0].countryCode} {this.props.nonClient.addresses[0].postalCode}</TextResultFieldLabel>
                    </div>
                  </Col>
                </Row>

              </MainBox>
            </Col>

          </Row>
          <MainBox style={{ marginTop: 20, marginBottom: 20 }}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <SubTitle>
                  <Trans i18nKey="createUser.ClientIds" />
                </SubTitle>
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ backgroundColor: 'rgba(0, 61, 165, 1)', paddingTop: 8, paddingBottom: 8 }}>
              <Col span={8}>
                <LabelWhite>ID</LabelWhite>
              </Col>
              <Col span={8}>
                <LabelWhite><Trans i18nKey="name" /></LabelWhite>
              </Col>
              <Col span={8}>
                <LabelWhite><Trans i18nKey="createUser.role" /></LabelWhite>
              </Col>
            </Row>
            {this.props.nonClient.accounts.map((currElement, index) => this.renderClientList(currElement, index))}

          </MainBox>
        </div>
      );
    }
}
