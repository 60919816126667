import { InjectTranslationsProps, OpenIdConfig, OpenIdConnector } from "ia-react-core";
import React, { FC } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AppLoader from "./common/AppLoader";
import ErrorBoundary from "./common/components/ErrorBoundary";
import { DomainUrl } from "./common/enum/DomainUrl";
import Section from "./common/enum/Section";
import { API_GATEWAY_AUTHORITY, API_GATEWAY_CLIENTID_EN, API_GATEWAY_CLIENTID_FR } from "./constants/API";
import {
  ClientProfileUrl,
  ClientsSearchUrl,
  DashboardUrl,
  ProfileContactUrl,
  SignaturePackageTrackingUrl,
  ContentContainerUrl,
} from "./constants/Router";
import AppLayout from "./layouts/AppLayout";
import ClientProfile from "./pages/ClientProfile";
import ClientSearch from "./pages/ClientSearch";
import Dashboard from "./pages/Dashboard";
import Contact from "./pages/Profile/Contact";
import ContentContainer from "./pages/ContentView/ContentContainer";
import SignaturePackage from "./pages/SignaturePackage";
import { setError as setErrorAction } from "./redux/app.actions";
import { authenticationService } from "./common/service/authentication.service";

// Pages
export interface AppProps extends InjectTranslationsProps {
  setError: typeof setErrorAction;
}

const getOpenIdConfig = (clientId: string): OpenIdConfig => ({
  authority: API_GATEWAY_AUTHORITY,
  clientId,
  scope: "openid profile IA_Scopes",
  responseType: "code"
});



const App: FC<AppProps> = ({ t, setError }) => {
  const clientId = window.location.origin.toLowerCase().includes(DomainUrl.IASA.toLowerCase()) ? API_GATEWAY_CLIENTID_EN : API_GATEWAY_CLIENTID_FR;

  if (!clientId) {
    return (
      <AppLayout title={t("title")}>
        <AppLoader>
          <Switch>
            <Route exact path={DashboardUrl} component={Dashboard} />
            <Route exact path={ClientsSearchUrl} component={ContentContainer} />
            <Route exact path={ProfileContactUrl} component={Contact} />
            <Route path={SignaturePackageTrackingUrl} component={SignaturePackage} />
            <Route path={ClientProfileUrl} component={ClientProfile} />
            <Route path={ContentContainerUrl} component={ContentContainer} />
            <Redirect to={ContentContainerUrl} />
          </Switch>
        </AppLoader>
      </AppLayout>
    );
  }

  const config = getOpenIdConfig(clientId);

  return (
    <OpenIdConnector name="apiGateway" loginUrl="/" loginOnUnauthorized={false} loginOnConnectionFailure={false} config={config} onFailure={(error) => setError({ section: Section.application, error })}>
      <AppLayout title={t("title")}>
        <ErrorBoundary sections={[Section.application]}>
          <AppLoader>
            <Switch>
              <Route exact path={DashboardUrl} component={Dashboard} />
              <Route exact path={ClientsSearchUrl} component={ContentContainer} />
              <Route exact path={ProfileContactUrl} component={Contact} />
              <Route path={SignaturePackageTrackingUrl} component={SignaturePackage} />
              <Route path={ClientProfileUrl} component={ClientProfile} />
              <Route path={ContentContainerUrl} component={ContentContainer} />
              <Redirect to={ContentContainerUrl} />
            </Switch>
          </AppLoader>
        </ErrorBoundary>
      </AppLayout>
    </OpenIdConnector>
  );
};

export default App;
