import {
  configGrid,
  configTranslation,
  injectReducer,
  injectSaga,
  injectTranslations,
  withRouter,
  withSaga,
  withStore,
  withTheming,
} from "ia-react-core";
import { FC } from "react";
import { compose } from "redux";


import App from "./App";
import IaSecTheme from "./themes/App.theme";
import { Language } from "./common/enum/Language";
import { AppState, initialAppState } from "./interfaces/AppState";
import { GlobalAction } from "./interfaces/GlobalAction";
import { State } from "./interfaces/State";
import appReducer from "./redux/App.reducer";
import appSaga from "./redux/App.saga";
import en from "./translations/en";
import fr from "./translations/fr";

interface ConfiguredAppProps {}

const ConfiguredApp = compose<FC<ConfiguredAppProps>>(
  withStore<ConfiguredAppProps, State>({ initialState: { App: initialAppState } }),
  withSaga,
  withTheming(IaSecTheme),
  configTranslation({
    saveCookie: true,
  }),
  withRouter,
  injectTranslations("App", [
    { language: Language.French, resource: fr },
    { language: Language.English, resource: en },
  ]),
  configGrid,
  injectReducer<ConfiguredAppProps, AppState, GlobalAction>({
    key: "App",
    initialState: {},
    reducer: appReducer,
    useScope: true,
  }),
  injectSaga({
    key: "App",
    saga: appSaga,
  }),
)(App);

export default ConfiguredApp;
