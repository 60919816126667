import { ColumnType } from "antd/lib/table";
import { InjectTranslationsProps } from "ia-react-core";
import React from "react";
import { Text } from "../../../../../../../common/common.style";
import { Attachment } from "../../../../../../../common/messages/interfaces/Attachment";

export const FromColumn = ({ t }: InjectTranslationsProps): ColumnType<Attachment> => ({
  title: t("documentsTab.fromName"),
  dataIndex: "fromName",
  width: "30%",
  key: "fromName",
  render: (value: string) => <Text>{value}</Text>,
});
