import { injectTranslations } from "ia-react-core";
import { FC } from "react";
import { compose } from "redux";

import { Language } from "../../../common/enum/Language";
import Create, { CreateProps } from "./Create";
import en from "./translations/en";
import fr from "./translations/fr";

export default compose<FC<CreateProps>>(
  injectTranslations("SignaturePackage.Create", [
    { language: Language.French, resource: fr },
    { language: Language.English, resource: en },
  ]),
)(Create);
