import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin: 3em auto;
`;

export const Title = styled.h2`
  font-family: ${(props) => props.theme.fontFamily.bold};
  font-size: 21px;
  font-weight: 700;
  font-style: normal;
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 15px;
  line-height: normal;
`;

export const InformationsContainer = styled.div`
  background: #ffffff;
  border: 1px solid ${(props) => props.theme.colors.gray2};
  padding: 20px;
  box-shadow: 2px 2px 2px rgba(204, 204, 204, 0.35);
`;

export const AdvisorsSection = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Separator = styled.hr`
  width: 90%;
  background-color: #ddd;
`;

export const FlexDiv = styled.div`
  display: flex;
  width: 33%;
`;
export const BranchDiv = styled.div`
  margin-top: 20px;
`;
export const BranchInfos = styled.div`
  display: flex;
`;

export const BranchSubTitle = styled.h4`
  font-weight: bold;
`;

export const TeamSubtitle = styled.h3`
  font-weight: bold;
  padding-left:40px;
`;

export const BranchInfo = styled.div`
  margin: 10px 0 0 10px;
  font-size: 14px;
`;

export const TeamSection = styled.div`
  background-color: #e0e4e9;
  margin-top: 20px;
  padding: 20px 0px;
  font-size: 14px;
`;

export const TeamMembers = styled.div`
  display: flex;
`;

export const TeamMember = styled.div`
  padding-left:50px;
  width: 33%;
`;

export const TeamMemberName = styled.div`
  font-weight: bold;
`;

export const BranchPhones = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const BranchPhone = styled.li`
  margin: 0;
  padding: 0;
`;
