import moment from "moment";
import * as React from "react";

require('moment/locale/fr');

export interface FormatDateProps {
    locale: string;
    format: string;
    children: string | Date;
    toLocalTimeZone?: boolean;
}

export interface DateTimeProps {
    locale: string;
    children: string | Date;
}

class FormatDate extends React.PureComponent<FormatDateProps> {    
  public static defaultProps = {
    toLocalTimeZone: false,
  }

  public render() {
    const { locale, children, format, toLocalTimeZone } = this.props;           
    moment.locale(locale);
    const date = moment.utc(children);
    if (!date.isValid()) {
      return '';
    }

    return toLocalTimeZone ? date.local().format(format) : date.format(format);
  }
}

class LongDateTime extends React.PureComponent<DateTimeProps> {
  public render() {
    const { locale, children } = this.props;
    return <FormatDate locale={locale} format="LLL">{children}</FormatDate>;        
  }
}

class ToLocalTimeLongDateTime extends React.PureComponent<DateTimeProps> {
  public render() {                
    const { locale, children } = this.props;       
    return <FormatDate toLocalTimeZone locale={locale} format="LLL">{children}</FormatDate>;
  }
}

class LongDate extends React.PureComponent<DateTimeProps> {
  public render() {                
    const { locale, children } = this.props;       
    return <FormatDate locale={locale} format="LL">{children}</FormatDate>;         
  }
}

class ToLocalTimezoneLongDate extends React.PureComponent<DateTimeProps> {
  public render() {                
    const { locale, children } = this.props;       
    return <FormatDate toLocalTimeZone locale={locale} format="LL">{children}</FormatDate>;
  }
}

class ShortDate extends React.PureComponent<DateTimeProps> {
  public render() {                
    const { locale, children } = this.props;       
    return <FormatDate locale={locale} format="L">{children}</FormatDate>;        
  }
}

class ToLocalTimezoneShortDate extends React.PureComponent<DateTimeProps> {
  public render() {                
    const { locale, children } = this.props;       
    return <FormatDate toLocalTimeZone locale={locale} format="L">{children}</FormatDate>;
  }
}

export { FormatDate, LongDateTime, ToLocalTimeLongDateTime, LongDate, ToLocalTimezoneLongDate, ShortDate, ToLocalTimezoneShortDate };
