import styled from "styled-components";

export const DocumentsTableContainer = styled.div`
  width: -webkit-fill-available;
  display: block;

  .ant-tag-checkable {
    display: inline-block;
    background: #ffffff;
    border: 1px solid #003da5;
    color: #003da5;
    border-radius: 4px !important;
    padding: 11px 16px;
    box-sizing: border-box;
    min-height: 40px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 16px;
    margin-right: 10px;
    line-height: 20px;
    border-left: 1px solid #003da5 !important;

    :hover{
      border-color: #2062d4;
      color: #2062d4;
    }
  }

  .ant-tag-checkable-checked {
    background: #e3f2fd;
    border: 2px solid #003da5 !important;
    color: #003da5;
    padding: 10px 15px;
  }

`;

export const FileContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FileIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  svg { 
    fill: ${(props) => props.theme.colors.light}
  }
`;
