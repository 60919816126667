import * as React from "react";
import Tabs from "react-responsive-tabs";

import { Styled } from "./StyledTabs.style";

// Extracted from https://github.com/maslianok/react-responsive-tabs/blob/master/src/index.js
export interface StyledTabsProps{
  items: object[] | object;
  selectedTabKey?: number | string;
  allowRemove?: boolean;
  removeActiveOnly?: boolean;
  showMore?: boolean;
  showInkBar?: boolean;
  transform?: boolean;
  transformWidth?: number;
  onChange?: (nextTabKey: number | string) => void;
  onRemove?: (key: number | string, event: React.MouseEvent<HTMLElement>) => void;
  resizeThrottle?: number;
  containerClass?: string;
  tabsWrapperClass?: string;
  tabClass?: string;
  panelClass?: string;
  showMoreLabel?: string | React.ReactNode;
}

const StyledTabs: React.FC<StyledTabsProps> = (props: StyledTabsProps) => (
  <Styled>
    <Tabs {...props} />
  </Styled>
);

export default StyledTabs;
