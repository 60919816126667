import { Advisor } from "../interfaces/Advisor";
import { RegulatoryRoleHelper } from "./RegulatoryRoleHelper";

export class AdvisorHelper {
  static compareByLastName(a: Advisor, b: Advisor): number {
    if (a.lastName < b.lastName) { return -1; }
    if (a.lastName > b.lastName) { return 1; }
    return 0;
  }

  static compareByRank(a: Advisor, b: Advisor): number {
    const compareRegulatory = RegulatoryRoleHelper.compare(a.regulatoryRole, b.regulatoryRole);
    if (compareRegulatory === 0) { return AdvisorHelper.compareByLastName(a, b); }
    return compareRegulatory;
  }
}
