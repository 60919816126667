/* eslint-disable no-empty */
import React, { useState } from 'react';
import { TFunction } from 'i18next';
import CheckableTag from 'antd/lib/tag/CheckableTag';
import SettingsTagsValues from '../../../../../common/enum/SettingsTagsValue';
import { REACT_APP_SHOW_ACCESS_SHARING, REACT_APP_TOGGLE_USERMANAGEMENT } from '../../../../../constants/API';

export interface DocumentsFilterProps {
  t: TFunction;
  onFilterChange: (selectedTag: SettingsTagsValues) => void;
}

/* eslint-disable no-console */
const SettingsTags: React.FC<DocumentsFilterProps> = (props: DocumentsFilterProps) => {
  const { t, onFilterChange } = props;
  const [selectedTag, setSelectedTag] = useState<string>(SettingsTagsValues.sharedAccess);
  const tagsData = [
    // { label: t("settingsTab.householdbutton"), value: SettingsTagsValues.household, className:"first-tag" },
    // { label: t("settingsTab.performancebutton"), value: SettingsTagsValues.performance, className:"" },
    ...REACT_APP_SHOW_ACCESS_SHARING ? [{ label: t("settingsTab.sharedAccessbutton"), value: SettingsTagsValues.sharedAccess }] : [],
    ...REACT_APP_TOGGLE_USERMANAGEMENT ? [{ label: t("settingsTab.userManagementbutton"), value: SettingsTagsValues.userManagement }] : [],
  ];

  const handleChange = (tag: SettingsTagsValues, checked: boolean) => {
    if(selectedTag != tag)
    {
        setSelectedTag(tag);
        onFilterChange(tag);
    }
  };

  return (
    <>
      {tagsData.map((tag) => (
        <CheckableTag
          key={tag.value}
          checked={tag.value ==  selectedTag}
          onChange={(checked) => handleChange(tag.value, checked)}>
          {tag.label}
        </CheckableTag>
      ))}
    </>
  );
};

export default SettingsTags;
