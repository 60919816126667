import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Container } from '@mui/material';
import { ContainerRow, TabContainer, ActiveNavStyle } from './Styles/baseStyle';
import { ContentPageContainer } from './Styles/ContentViewStyle';
import SubMenu from './SubMenu';
import { Switch, Route, RouteComponentProps } from 'react-router';
import ClientSearch from '../ClientSearch';
import CreateUser from '../CreateUser/CreateUser';

import {
    CreateUserUrl,
    ClientsSearchUrl,
    EditUserUrl
  } from "../../constants/Router";

export interface ContentContainerProps {}


export interface LocalState {
    hasSearched: boolean;
  }

  class ContentContainer extends React.PureComponent<ContentContainerProps, LocalState> {
    public constructor(props: ContentContainerProps) {
      super(props);
  
      this.state = {
        hasSearched: false,
      };
    }

  public render() {
    const { } = this.props;

    return (
      <Container>
        <ContainerRow style={{ justifyContent: 'space-between', alignItems: 'flex-end' }}>
          <SubMenu />
        </ContainerRow>

        <ContentPageContainer>
          <Switch>
            <Route path={ClientsSearchUrl} component={ClientSearch} />
            <Route path={CreateUserUrl} exact={true} component={CreateUser} />
            <Route path={EditUserUrl} exact={true} component={CreateUser} />
            <Route component={ClientSearch} />
          </Switch>
        </ContentPageContainer>
      </Container>
    );
  }
}

export default ContentContainer;
