import HttpError from "../common/components/ErrorBoundary/interface/HttpError";
import { SearchCriteria } from "../common/interfaces/SearchCriteria";
import { LimitSearchClient, REACT_APP_USE_TOKEN } from "../constants/API";

export const initialSearchCriteria: SearchCriteria = {
  text: "",
  limit: LimitSearchClient,
  repCodes: [],
};

export const initialAppState: AppState = {
  repCodes: [],
  isSupport: REACT_APP_USE_TOKEN,
  apiGatewayData: null,
  hasError: false,
  searchCriteria: initialSearchCriteria,
  hasUser: false,
  error: null,
};

export interface AppState {
  repCodes: string[];
  isSupport: boolean;
  apiGatewayData: any;
  hasError: boolean;
  searchCriteria: SearchCriteria;
  hasUser: boolean;
  error: HttpError|null;
}
