import { IPartyAccount, ICandidateAccount } from './Party';

export interface IParty {
  id: string;
  partyTypeCode: PartyTypeCode;
  accessCode: string;
  personalizedAccessCode: string;
  firstName: string;
  lastName: string;
  businessName: string;
  addresses: IPartyAddress[];
  emails: IPartyEmail[];
  dateOfBirth: Date;
  accounts: IPartyAccount[];
  preferedAccountIds: string[];
  candidateId: string;
  metadata: IMetadata[];
  isLocked: boolean;
}

export enum PartyTypeCode {
  Customer = 'customer',
  NonClient = 'non-client',
  Organization = 'organization',
}

export interface IPartyAddress {
  unitTypeCode: string; // "APT"
  unitNumber: string;
  streetNumber: string;
  streetName: string;
  municipality: string;
  postalCode: string;
  regionCode: string;
  countryCode: string;
}

export interface ICandidate {
  partyTypeCode: string; //  "candidate",
  firstName: string;
  lastName: string;
  languageCode: string;
  addresses: IPartyAddress[];
  phones: IPhone[];
  emails: IPartyEmail[];
  sources: ISourceParty[];
  accounts: ICandidateAccount[];
  candidateId: string;
}

export interface IPhone {
  phoneTypeCode: string; //  "home",
  phoneNumber: string;
  extension: string;
}

export interface IPartyEmail {
  emailTypeCode: string; // "main"
  emailAddress: string;
}

export interface ISourceParty {
  system: string; // "iasecurities",
}

export interface IMetadata {
  key: string;
  value: string[];
  system: string;
  lastModified: Date;
}
