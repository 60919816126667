import { TFunction } from "i18next";
import { HouseholdNoRoleType, HouseholdType } from "../../enum/HouseholdType";
import { IAccount, IPartyAccount } from "./Party";

export interface IAccountAccessSharingModel {
  id: string;
  partyId: string;
  name: string;
  role: string;
  noRole: number;
}

export const getAccountsFromRoot = (accounts: IAccount[], rootId: string[]): IAccount[] => {
  if (!rootId || rootId.length <= 0) {
    return accounts;
  }

  const filteredAccounts = accounts.filter((a) => rootId.some((id) => id === a.rootId));

  return filteredAccounts;
};

export const getOwnerAccounts = (accounts: IPartyAccount[]) => accounts.filter((w) => w.role === HouseholdType.OWNER || w.role === HouseholdType.COMPANY);

export const getOpenAccounts = (accounts: IAccount[]) => accounts.filter((w) => w.isOpen);

export const getShareableAccounts = (accounts: IPartyAccount[]) => accounts.filter((w) => w.role === HouseholdType.OWNER);

export const getCorporateAccounts = (accounts: IPartyAccount[]) => accounts.filter((w) => w.role === HouseholdType.OWNER && w.noRole === HouseholdNoRoleType.CORPORATE);

export interface IAccessSharingViewData {
  key: string;
  partyId: string;
  partyName: string;
  accountDisplayName: string;
  accountNumber: string;
  rowSpan: number;
  isLastRow: boolean;
  role: string;
  noRole: number;
}

export const getAccountRoleName = (role: string, t: TFunction) => {
  switch (role) {
    case HouseholdType.OWNER:
      return t('accessSharing.owner');
    case HouseholdType.COMPANY:
      return t('accessSharing.corporateOwner');
    case HouseholdType.SHARING:
      return t('accessSharing.thirdParty');
    default:
      return '';
  }
};

export const mapPartyToShareListView = (sharingAccounts: IPartyAccount[], accounts: IAccount[]): IAccessSharingViewData[] => {
  const results = [] as IAccessSharingViewData[];
  if (sharingAccounts !== undefined && sharingAccounts !== null) {
    let currentId = '';

    sharingAccounts.forEach((a, index) => {
      let isFirstElement = false;
      if (currentId !== a.partyId) {
        currentId = a.partyId;
        isFirstElement = true;
      }
      results.push({
        key: (a.id + a.partyId),
        role: a.role,
        noRole: a.noRole,
        partyId: a.partyId,
        partyName: a.name,
        accountNumber: a.id,
        accountDisplayName: getAccountName(accounts, a.id),
        rowSpan: isFirstElement ? sharingAccounts.filter((w) => w.partyId === a.partyId).length : 0,
        isLastRow: sharingAccounts.length === (index + 1),
      });
    });
  }

  return results;
};

export const getAccountName = (accounts: IAccount[], rootId: string) => {
  const childAccounts = getAccountsFromRoot(accounts, [rootId]);
  const openAccounts = getOpenAccounts(childAccounts);
  if (openAccounts.length > 0) {
    return openAccounts[0].name;
  }
  if (childAccounts.length > 0) {
    return childAccounts[0].name;
  }
  return '';
};
