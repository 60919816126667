import { API_IAS_SIGNATURE, REACT_APP_USE_MOCK_SIGNATURE } from "../../constants/API";
import { PaginatedResponse } from "../interfaces/PaginatedResponse";
import { RequestQuery } from "../interfaces/RequestQuery";
import { HttpGet } from "../utils/HttpClient";
import { SignaturePackage } from "./interfaces/SignaturePackage";
import SignaturePackageMockService from "./signaturePackage.mockService";
import { SignaturePackageMapper } from "./utils/SignaturePackageMapper";

/* eslint-disable @typescript-eslint/no-explicit-any */
class SignaturePackageService {
  public async search(queries: RequestQuery[], offset: number, limit: number): Promise<PaginatedResponse<SignaturePackage>> {
    if (REACT_APP_USE_MOCK_SIGNATURE) {
      const signaturePackageMockService = new SignaturePackageMockService();
      return signaturePackageMockService.search(offset, limit);
    }

    let paramUrl = "";

    queries.forEach((item: RequestQuery) => {
      paramUrl = `${paramUrl}&${item.queryString}=${item.queryValue}`;
    });

    if (paramUrl !== "") {
      paramUrl = paramUrl.substr(1);
    }

    const signaturePackages: SignaturePackage[] = [];

    const response: any = await HttpGet(`${API_IAS_SIGNATURE}?${paramUrl}&offset=${offset}&limit=${limit}`, {});
    const mapper = new SignaturePackageMapper();

    response.data.array.forEach((data: any) => {
      signaturePackages.push(mapper.mapSignaturePackage(data));
    });

    const paginatedResponse: PaginatedResponse<SignaturePackage> = {
      data: signaturePackages,
      limit,
      offset,
      total: signaturePackages.length,
    };

    return paginatedResponse;
  }

  public async getById(id: string): Promise<SignaturePackage> {
    const mapper = new SignaturePackageMapper();
    const response: any = await HttpGet(`${API_IAS_SIGNATURE}/${id}`, {});
    const signaturePackage: SignaturePackage = mapper.mapSignaturePackage(response.data);
    return signaturePackage;
  }
}

export default SignaturePackageService;
