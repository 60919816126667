import { BehaviorSubject } from 'rxjs';
import { httpService } from './http.service';

export enum LoginStatus {
    LOADING,
    SUCCESS,
    FAIL,
    EXPIRED,
}

const currentUserSubject = new BehaviorSubject(JSON.parse('{}'));
const currentStatusSubject = new BehaviorSubject(LoginStatus.LOADING);

export const authenticationService = {
  login,
  logout,
  setStatus,
  currentUser: currentUserSubject.asObservable(),
  status: currentStatusSubject.asObservable(),
  get currentUserValue() { return currentUserSubject.value; },
};

async function login() {
  try {
    setStatus(LoginStatus.LOADING);
    const user = await httpService.get('/FWMWPNS1/Web/AuthenticationEmploye');
    const { data } = user;
    currentUserSubject.next(data);
    setStatus(LoginStatus.SUCCESS);
  } catch {
    setStatus(LoginStatus.FAIL);
  }
}

function setStatus(status: number) {
  currentStatusSubject.next(status);
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('currentUser');
  currentUserSubject.next(null);
}
