import { TFunction } from "i18next";
import * as React from "react";
import { notification } from "antd";
import { CircularProgress } from "@material-ui/core";
import ClientActionEvent from "../../../../../common/client/enum/clientActionEvent";
import ClientHelper from "../../../../../common/client/utils/ClientHelper";
import StyledPopover from "../../../../../common/popover/StyledPopover";
import { InvisibleSeparator, LinkAction, LinkRouterAction } from "../../../../../common/popover/StyledPopover.style";
import { Action } from "../../../../../common/svg";
import { ClientProfileUrl, EditUserUrl } from "../../../../../constants/Router";
import { ActionMenu } from "../ClientSearchResult.style";
import { PartyTypeCode } from "../../../../CreateUser/Models/Client";
import { HttpPost } from "../../../../../common/utils/HttpClient";
import { API_PARTIES } from "../../../../../constants/API";

export interface ClientSearchResultActionsProps {
  id: string;
  canEdit: boolean;
  accessCode: string;
  partyTypeCode: string;
  t: TFunction;
  actionEvent: (id: string, action: ClientActionEvent, event?: React.MouseEvent<HTMLElement>) => void;
}

interface LocalState {
  visible: boolean;
  isLoading: boolean;
}

class ClientSearchResultActions extends React.PureComponent<ClientSearchResultActionsProps, LocalState> {
  constructor(props: ClientSearchResultActionsProps) {
    super(props);
    this.state = {
      visible: false,
      isLoading: false,
    };
  }

  private handleVisibleChange = (visible: boolean): void => {
    this.setState({ visible });
  };

  private contentAction = () => {
    const { t, id, canEdit, actionEvent, accessCode, partyTypeCode } = this.props;
    const isNonClient = partyTypeCode === PartyTypeCode.NonClient;
    const showActivationMenu = isNonClient && !accessCode;
    return (
      <>
        <div style={{ position: "relative" }}>{this.state.isLoading && <CircularProgress size={24} style={{ position: "absolute", top: "50%", left: "50%", marginTop: -12, marginLeft: -12 }} />}</div>
        {canEdit &&
        <>
          <LinkRouterAction onClick={(e) => actionEvent(id, ClientActionEvent.selectedClient, e)} to={EditUserUrl.replace(":id", id)}>{t("clientSearchResult.editCustomUser")}</LinkRouterAction>
          <InvisibleSeparator />
        </>}
        <LinkRouterAction onClick={(e) => actionEvent(id, ClientActionEvent.selectedClient, e)} to={ClientProfileUrl.replace(":id", id)}>{t("clientSearchResult.viewProfile")}</LinkRouterAction>
        {showActivationMenu && (
          <>
            <InvisibleSeparator />
            <LinkRouterAction onClick={() => this.handleSendActivationKey(id, t)} to="">
              {t("clientSearch.menuActivationKey")}
            </LinkRouterAction>
          </>
        )}
        <InvisibleSeparator />
        <LinkAction href={ClientHelper.getMirrorViewLink(id)} target="_blank">
          {t("App:mirrorView")}
        </LinkAction>

      </>
    );
  };

  public render() {
    const { visible } = this.state;

    return (
      <StyledPopover trigger="click" content={this.contentAction()} open={visible} onOpenChange={this.handleVisibleChange} placement="bottom">
        <ActionMenu>{Action(30)}</ActionMenu>
      </StyledPopover>
    );
  }
  public handleSendActivationKey = async (partyid: string, t: TFunction) => {
    this.setState({ isLoading: true });
    await HttpPost(`${API_PARTIES}/ActivationKey/${partyid}`, {})
      .then((data) => {
        const email = data.data as string;
        email && email !== ''
          ? this.openSuccessNotification(t("clientSearch.activationKeySuccessTitle"), `${t("clientSearch.activationKeySuccessMessage")} ${email}`)
          : this.openFailNotification(t("clientSearch.activationKeyErrorTitle"), t("clientSearch.activationKeyErrorMessage"));
        // eslint-disable-next-line no-alert
      })
      .catch((e) => {
        console.log(e);
        this.openFailNotification(t("clientSearch.activationKeyErrorTitle"), t("clientSearch.activationKeyErrorMessage"));
      });
    this.setState({ isLoading: false });
  };
  openSuccessNotification = (title: string, message: string) => {
    notification.success({
      message: title,
      description: message,
    });
  };
  openFailNotification = (title: string, message: string) => {
    notification.error({
      message: title,
      description: message,
    });
  };
}

export default ClientSearchResultActions;
