import { apply, Effect, put, select, takeLatest } from "@redux-saga/core/effects";

import { setError } from "../../../redux/app.actions";
import Section from "../../enum/Section";
import { selectLocale } from "../../redux/selector";
import AccountService from "../account.service";
import { Account } from "../interfaces/Account";
import { getAccountsClientInfoByClientIdsError, getAccountsClientInfoByClientIdsSuccess } from "./account.actions";
import { AccountActionTypes as types, GetAccountsClientInfoByClientIds } from "./account.actions.types";

/* eslint-disable @typescript-eslint/unbound-method */

function* handleGetAccountsClientInfoByClientIds({ clientIds }: GetAccountsClientInfoByClientIds): any {
  try {
    const service = new AccountService();
    const locale = yield select(selectLocale);
    const accounts: Account[] = yield apply(service, service.searchByClientIds, [clientIds, locale]);
    yield put(getAccountsClientInfoByClientIdsSuccess(accounts));
  } catch (error) {
    yield put(getAccountsClientInfoByClientIdsError());
    yield put(setError({ section: Section.account, error }));
  }
}

export default function* AccountSaga(): IterableIterator<Effect> {
  yield takeLatest(types.GET_ACCOUNTS_CLIENT_INFO_BY_CLIENTIDS, handleGetAccountsClientInfoByClientIds);
}
