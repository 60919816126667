import { ColumnType } from "antd/lib/table";
import * as React from "react";
import { ClientSearchResultAddress } from "..";
import { Client } from "../../../../../../common/client/interfaces/Client";
import { IPartyAddress } from "../../../../../../common/client/interfaces/Party";
import { ClientSearchResultTableProps } from "../ClientSearchResultTable";

function renderAddress(addresses: IPartyAddress[]): JSX.Element | string {
  if (addresses.length === 0) {
    return "";
  }

  const { municipality, postalCode, regionCode } = addresses[0];
  return <ClientSearchResultAddress state={regionCode} municipality={municipality} postalCode={postalCode} />;
}

export function ClientSearchResultColumnAddress(props: ClientSearchResultTableProps): ColumnType<Client> {
  const { t } = props;

  return {
    title: t("App:contactInformations.address"),
    dataIndex: "name",
    key: "name",
    render: (value: unknown, record: Client) => renderAddress(record.addresses),
  };
}
