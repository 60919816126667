export default {
  clientSearch: {
    title: "Recherche client",
    visitAx360: "Pour consulter ou modifier les préférences de livraison de documents d'un client, veuillez vous diriger vers ",
    Ax360Link: "AX360.",
  },
  createUser: {
    title: "Créer un utilisateur spécial",
    createUser: "Créer un utilisateur spécial",
    firstName: "Prénom",
    lastName: "Nom",
    language: "Langue",
    contact: "Coordonnées",
    phone: "Numéro de téléphone",
    email: "Courriel",
    address: "Adresse",
    number: "Numéro civique",
    street: "Rue",
    apt: "Appartement",
    city: "Ville",
    province: "Province/État",
    country: "Pays/Région",
    postal: "Code postal",
    review: "Reviser",
    create: "Création",
    add: "Ajout identifiant client",
    french: "Français",
    english: "Anglais",
    ClientIds: "Identifiant client",
    role: "Rôle",
    CO: "Propriétaire de l'entreprise",
    ThirdParty: "Tierce partie",
    PersonalInformation: "Informations personnelles",
    required: "Les champs soulignés sont obligatoires.",
    invalid: "Information non valide.",
    saved: "Enregistré avec succès",
    edit: "Modifier",
    incorrectEntry: "*Entrée incorrecte",
    ensureClientIdIsCorrect: "Veuillez vous assurer que l'identifiant client est saisi correctement.",
    warningEnsureClientIdExist: "Pour éviter les duplications, veuillez effectuer une recherche pour vous assurer que l'utilisateur n'existe pas déjà.",
    customUserTip: "Un utilisateur spécial est utilisé lorsque vous avez besoin de créer un accès pour un utilisateur non-client (ex: société par action ou tiers).",
    warning: "Avertissement!",
    ID: "Identifiant",
    update: "Mise à jour",
    countrySection: {
      selectCountry: "-- Sélectionner un pays --",
      canada: "Canada",
      usa: "États-Unis",
    },
    stateSection: {
      AB: "Alberta",
      BC: "Colombie-Britannique",
      MB: "Manitoba",
      NB: "Nouveau-Brunswick",
      NL: "Terre-Neuve-et-Labrador",
      NS: "Nouvelle-Écosse",
      ON: "Ontario",
      PE: "Île-du-Prince-Édouard",
      QC: "Québec",
      SK: "Saskatchewan",
      NT: "Territoires du Nord-Ouest",
      NU: "Nunavut",
      YT: "Yukon",
      AL: "Alabama",
      AK: "Alaska",
      AS: "Samoa américaine",
      AZ: "Arizona",
      AR: "Arkansas",
      CA: "Californie",
      CO: "Colorado",
      CT: "Connecticut",
      DE: "Delaware",
      DC: "District of Columbia",
      FL: "Floride",
      GA: "Géorgie",
      GU: "Guam",
      HI: "Hawaii",
      ID: "Idaho",
      IL: "Illinois",
      IN: "Indiana",
      IA: "Iowa",
      KS: "Kansas",
      KY: "Kentucky",
      LA: "Louisiane",
      ME: "Maine",
      MD: "Maryland",
      MA: "Massachusetts",
      MI: "Michigan",
      MN: "Minnesota",
      MS: "Mississippi",
      MO: "Missouri",
      MT: "Montana",
      NE: "Nebraska",
      NV: "Nevada",
      NH: "New Hampshire",
      NJ: "New Jersey",
      NM: "Nouveau-Mexique",
      NY: "New York",
      NC: "Caroline du Nord",
      ND: "Dakota du Nord",
      MP: "îles Mariannes du Nord",
      OH: "Ohio",
      OK: "Oklahoma",
      OR: "Oregon",
      PA: "Pennsylvanie",
      PR: "Puerto Rico",
      RI: "Rhode Island",
      SC: "Caroline du Sud",
      SD: "Dakota du Sud",
      TN: "Tennessee",
      TX: "Texas",
      UM: "Petites îles excentriques des États-Unis",
      UT: "Utah",
      VT: "Vermont",
      VA: "Virginie",
      VI: "îles Vierges américaines",
      WA: "Washington",
      WV: "Virginie-Occidentale",
      WI: "Wisconsin",
      WY: "Wyoming",
    },
    thirdPartyClientProvidedCopy: "Mon client m'a remis une copie signée du formulaire d'accès d'un tiers et une copie a été envoyée à l'Équipe Nouveaux comptes",
  },
  client: {
    clientId: "Identifiant client",
    personalizedAccessCode: "Nom d'utilisateur",
    noPersonalizedAccessCode: "Client non enregistré",
  },
  contactInformations: {
    address: "Adresse",
    email: "Courriel",
    website: "Site Web",
    phone: "Téléphone",
  },
  dateOfBirth: "Date de naissance",
  emailType: {
    main: "Principal",
    office: "Bureau",
  },
  gender: "Sexe",
  genderOptions: {
    M: "Homme",
    F: "Femme",
    I: "Non spécifié",
  },
  language: "Langue",
  languageOptions: {
    fr: "Français",
    en: "Anglais",
  },
  loadMore: "Voir plus",
  mirrorView: "Vue miroir",
  name: "Nom",
  phoneType: {
    principal: "Principal",
    cellphone: "Cellulaire",
    home: "Maison",
    office: "Bureau",
  },
  sin: "NAS",
  title: "Espace conseiller",
  advisor: "Représentant",
  accounts: "Comptes",
  tableLocale: {
    searchInProgress: "Recherche en cours",
    emptyText: "Aucune donnée",
  },
  clickToView: {
    show: "Afficher",
    hide: "Cacher",
  },
  show: "Afficher",
  repCode: "Code de représentant",
  choose: "Choisir",
  nonApplicable: "S/O",
  investmentObjective: {
    income: "Revenu",
    growth: "Croissance",
    speculative: "Spéculation",
    liquidity: "Sécurité du capital et encaisse",
    safety: "Sécuritaire",
    unknown: "Inconnu",
  },
  riskTolerance: {
    low: "Faible",
    medium: "Modérée",
    mediumHigh: "Modérée-Élevée",
    high: "Élevée",
    unknown: "Inconnue",
  },
  timeHorizon: {
    unknown: "Inconnu",
    years3AndLess: "Moins de 3 ans",
    years3To5: "3 à 5 ans",
    years6To10: "6 à 10 ans",
    years11To20: "11 à 20 ans",
    years20AndOver: "20 ans ou plus",
  },
  shortDateFormat: "DD MMM YYYY",
  status: "Statut",
  actions: "Actions",
};
