export const DashboardUrl = "/dashboard";
export const ClientsSearchUrl = "/clientSearch";
export const ClientProfileUrl = "/clientProfile/:id";
export const ProfileContactUrl = "/profile/contact";
export const SignaturePackageTrackingUrl = "/signature-packages";
export const SignaturePackageDetailsUrl = "/signature-packages/:id(\\d+)";
export const SignaturePackageCreateUrl = "/signature-packages/create";
export const ContentContainerUrl = "/";
export const CreateUserUrl = "/createUser";
export const EditUserUrl = "/createUser/:id";
