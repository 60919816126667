import { get, injectReducer } from "ia-react-core";
import { FC } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { initialAppState } from "../../interfaces/AppState";
import { State } from "../../interfaces/State";
import { setUser } from "../user/redux/user.actions";
import AppLoader, { AppLoaderDispatchToProps, AppLoaderMapStateToProps, AppLoaderProps } from "./AppLoader";
import appLoaderReducer from "./redux/appLoader.reducer";

const mapDispatchToProps: AppLoaderDispatchToProps = {
  setUser,
};

const mapStateToProps = (state: State): AppLoaderMapStateToProps => ({
  repCodes: get(state, "App.repCodes"),
  isSupport: get(state, "App.isSupport"), 
  hasUser: get(state, "App.hasUser"),
});

export default compose<FC<AppLoaderProps>>(
  injectReducer({
    key: "App",
    initialState: initialAppState,
    reducer: appLoaderReducer,
    useScope: false,
    cleanState: true,
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(AppLoader);
