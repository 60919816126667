import { TFunction } from "i18next";
import { ErrorMessage, SuccessMessage } from "ia-react-core";
import React from "react";
import { ResponseError } from "../../../../../../common/interfaces/ResponsePayload";
import ResponseErrorStatus from "../../../../../../common/messages/interfaces/ResponseErrorStatus";
import ResponseSuccessStatus from "../../../../../../common/messages/interfaces/ResponseSuccessStatus";

export interface SendMessageAlertResultsProps {
  error: ResponseError | undefined;
  displaySuccess: ResponseSuccessStatus | undefined;
  t: TFunction;
  showSendMessageAlert: boolean;
}

const SendMessageAlertResults: React.FC<SendMessageAlertResultsProps> = ({ error, displaySuccess, t, showSendMessageAlert }: SendMessageAlertResultsProps): JSX.Element => {
  let errorMessage = '';
  let successMessage = '';
  if (displaySuccess === ResponseSuccessStatus.Share) {
    successMessage = t("documentsTab.documentSent");
  } if (displaySuccess === ResponseSuccessStatus.Edelivery) {
    successMessage = t("documentDelivery.savePreferencesConfirmation");
  } if (displaySuccess === ResponseSuccessStatus.Reshared) {
    successMessage = t("documentsTab.documentShared");
  } if (displaySuccess === ResponseSuccessStatus.Unshared) {
    successMessage = t("documentsTab.documentUnshared");
  } else if (error && error.detail === 'NoEmailSent') {
    successMessage = t("documentsTab.NoEmailSent");
  } else if (error && error.status === ResponseErrorStatus.VirusScanError) {
    errorMessage = t('documentsTab.virusScanDetectedAnAnomaly', { 0: error.detail });
  } else if (error && error.status === ResponseErrorStatus.DocumentLoadFailed) {
    errorMessage = t('documentsTab.errorMessageDocumentLoadFailed', { 0: error.detail });
  } else if (error && error.status === ResponseErrorStatus.DocumentReshareFailed) {
    errorMessage = t('documentsTab.errorMessageDocumentReshareFailed', { 0: error.detail });
  } else if (error && error.status === ResponseErrorStatus.DocumentUnshareFailed) {
    errorMessage = t('documentsTab.errorMessageDocumentUnshareFailed', { 0: error.detail });
  } else if (error) {
    errorMessage = t('documentsTab.errorMessage');
  }

  return (
    <>
      {(error || displaySuccess) && showSendMessageAlert && (
        <>
          {successMessage !== '' && <SuccessMessage>{successMessage}</SuccessMessage>}
          {errorMessage !== '' && <ErrorMessage error={errorMessage} />}
        </>)}
    </>
  );
};

export default SendMessageAlertResults;
