import styled from "styled-components";

import { Container } from "../../../common/common.style";

export const ContactContainer = styled(Container)`
  border: none;
  position: relative;
`;

export const LoadingContainer = styled.div`
  margin-top: 25px;
`;
