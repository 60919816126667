import { InjectSagaProps, InjectTranslationsProps, Loader } from "ia-react-core";
import * as React from "react";
import { Helmet } from "react-helmet";

import { Container, LoadingContainer } from "../SignaturePackage.style";

export interface CreateProps {}

export interface CreateDispatchToProps {}

export interface CreateMapStateToProps {
  loading: boolean;
}

export interface CreateComposedProps
  extends CreateProps,
    CreateDispatchToProps,
    CreateMapStateToProps,
    InjectTranslationsProps,
    InjectSagaProps {}

export interface LocalState {
  hasSearched: boolean;
}

class Create extends React.PureComponent<CreateComposedProps, LocalState> {
  public render() {
    const { t, loading } = this.props;

    return (
      <Container>
        <Helmet>
          <title>{`${t("title")} - ${t("SignaturePackage:title")}`}</title>
        </Helmet>

        <h1>{`${t("SignaturePackage:title")} ${t("title")}`}</h1>

        {loading && (
          <LoadingContainer>
            <Loader />
          </LoadingContainer>
        )}
      </Container>
    );
  }
}

export default Create;
