import "antd/es/table/style/css";

import styled from "styled-components";

export const Styled = styled.div`
  overflow: auto hidden;

  .styled-table {
    table {
      border-collapse: collapse;
    }

    .ant-table {
      .ant-table-thead > tr > th {
        background: ${(props) => props.theme.colors.blue6};
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-family: ${(props) => props.theme.fontFamily.semiBold};
        padding: 10px 16px;
      }

      .ant-table-cell {
        color: ${(props) => props.theme.colors.black};
        font-size: 14px;
        padding: 8px 16px;
      }

      tr.ant-table-expanded-row,
      tr.ant-table-expanded-row > td,
      tr.ant-table-expanded-row:hover > td {
        background: ${(props) => props.theme.colors.gray1};
        padding: 8px 16px 32px 16px;
      }

      .ant-table-tbody {
        border-bottom-color: ${(props) => props.theme.colors.blue6};
        border-bottom-style: solid;
        border-bottom-width: 2px;

        .expendable-table .ant-table-tbody {
          border: none;
        }
      }
    }
  }

  .ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table {
    margin: 0;
  }

  .styled-table.column-top {
    .ant-table {
      .ant-table-tbody {
        .ant-table-cell {
          vertical-align: top;
        }
      }
    }
  }
`;

export const StyledExpendable = styled.div`
  .expendable-table {
    .ant-table {
      background: ${(props) => props.theme.colors.gray2};

      .ant-table-title {
        border: 0;
        background: ${(props) => props.theme.colors.gray1};
        padding: 16px 16px 5px 0;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-family: ${(props) => props.theme.fontFamily.semiBold};
        color: ${(props) => props.theme.colors.black};
      }
    }
  }
`;

export const SectionName = styled.h4``;

export const ExpandIcon = styled.button`
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;

  svg {
    vertical-align: middle;
  }
`;
