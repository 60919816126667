import * as React from "react";

import { Header } from "./BoxAction.style";

interface BoxActionHeaderProps extends React.Props<BoxActionHeader> {
  title: string;
}

class BoxActionHeader extends React.PureComponent<BoxActionHeaderProps> {
  public render() {
    const { title } = this.props;
    return <Header>{title}</Header>;
  }
}

export default BoxActionHeader;
