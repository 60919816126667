export default {
  title: "Clients",
  welcome: "Welcome",
  clientSearch: {
    submit: "Search",
    reset: "Reset",
    name: "Name or first name",
    accessCode: "Access Code",
    placeholder: "Name, Client ID or Username",
    filter: {
      repCode: "Representative Code",
      all: "All",
    },
    menuActivationKey : "Resend activation key",
    activationKeySuccessTitle : "Confirmation",
    activationKeySuccessMessage : "Activation key sent to this email",
    activationKeyErrorTitle : "Resend activation key - Fail",
    activationKeyErrorMessage : "Please try again"
  },
  clientSearchResult: {
    title: "client(s) found",
    for: "for",
    infoTab: "Informations",
    accountsTab: "Accounts",
    viewProfile: "View profile",
    editCustomUser: "Edit User",
    noClientsFound: "No clients found",
    columns: {
      clientPortal: "Client Portal",
      actions: "Actions",
      email: "Email",
    },
    preferedAccessCode: {
      title: "Prefered access code",
      request: "Show",
      processing: "In processing",
    },
  },
};
