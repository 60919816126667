import * as React from 'react';
import {
  CreateUserUrl,
  ClientsSearchUrl,
  ContentContainerUrl
} from "../../constants/Router";
import { Trans } from 'react-i18next';
import { TabMenuContainer, TabContainer, ActiveNavStyle } from './Styles/baseStyle';
import Tooltip from '@material-ui/core/Tooltip';
import Info from '@material-ui/icons/Info';

class SubMenu extends React.PureComponent {
  public render() {
    const isActiveSearch = (match: any, location: any) => location.pathname === ClientsSearchUrl || location.pathname === ContentContainerUrl;
    const isActiveCreate = (match: any, location: any) => location.pathname === CreateUserUrl;
    const createUserToolTip = <Trans i18nKey="createUser.customUserTip" />
    return (
      <TabMenuContainer>
        <TabContainer isActive={isActiveSearch} to={`${ClientsSearchUrl}`} activeStyle={ActiveNavStyle} style={{paddingTop:22}}>
          <Trans i18nKey="clientSearch.title" />
        </TabContainer>
        <TabContainer isActive={isActiveCreate} to={`${CreateUserUrl}`} activeStyle={ActiveNavStyle}>
          <Trans i18nKey="createUser.title"/>
          <Tooltip title={ <div style={{ borderRadius: '4px', margin: '0px', maxWidth: '300px', padding: '4px 8px', wordWrap: 'break-word', fontSize: '0.6875rem', backgroundColor: 'rgba(97, 97, 97, 0.92)', fontFamily: 'OpenSans-Regular,Open Sans,sans-serif', fontWeight: '500', position: 'relative' }}>{createUserToolTip} </div>} arrow>
            <Info style={{ color: '#2062D4', alignItems: 'center', alignSelf: 'center', fontWeight: 'bold', width: 18, marginLeft: 2 }} />
          </Tooltip>
        </TabContainer>
      </TabMenuContainer>
    );
  }
}

export default SubMenu;
