import * as React from "react";

import { ClientAccount } from "../../../../../common/client/interfaces/Account";
import ClientHelper from "../../../../../common/client/utils/ClientHelper";
import { Account, AccountLayout, AccountNumber, Accounts } from "../ClientSearchResult.style";

interface LocalProps {
  accounts: ClientAccount[];
  title: string;
  searchText: string;
}

function getAccounts(accounts: ClientAccount[], searchCriteria: string): JSX.Element {
  const accountsHtml: JSX.Element[] = accounts.map((account: ClientAccount, index: number) => {
    const clientId = ClientHelper.getClientId(searchCriteria);
    const highlight = searchCriteria === "" || !account.id.toUpperCase().includes(clientId);
    return (
      <Account key={account.id}>
        <AccountNumber highlight={!highlight}>{account.id}</AccountNumber>
        {index !== accounts.length - 1 && ", "}
      </Account>
    );
  });

  return <AccountLayout>{accountsHtml}</AccountLayout>;
}

const ClientSearchResultAccount: React.FC<LocalProps> = ({ accounts, title, searchText: searchCriteria }: LocalProps) => <Accounts title={title}>{getAccounts(accounts, searchCriteria)}</Accounts>;

export default ClientSearchResultAccount;
