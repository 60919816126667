import { ColumnType } from "antd/lib/table";
import * as React from "react";

import { Account } from "../../../../../../../common/account/interfaces/Account";
import StringValue from "../../../../../../../common/utils/Value";
import { OwnerObjectivesProps } from "../../../investorProfile/InvestmentObjectives/OwnerObjectives";
import { AccountTableProps } from "../AccountTable";

export function AccountTypeColumn(props: OwnerObjectivesProps | AccountTableProps): ColumnType<Account> {
  const { t } = props;
  
  return {
    title: "",
    dataIndex: "accountType",
    key: "accountType",
    width: "30%",
    render: (value: string, record: Account) => (
      <>
        <StringValue>{value}</StringValue> { record.isOpen ? "" : ` (${t("closed")})`}
      </>
    ),
  };
}
