import { cloneDeep } from "lodash";
import * as React from "react";
import { v4 as uuid } from "uuid";

import CheckboxButtonItem from "../../interfaces/CheckboxButtonItem";
import CheckboxButton from "./CheckboxButton";
import { CheckboxGroup, Title } from "./style/CheckboxButtons.style";

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface CheckboxButtonsProps {
  title?: string;
  items: CheckboxButtonItem[];
  allCheckboxText?: string;
  onCheckboxChanged: (items: CheckboxButtonItem[]) => void;
}

interface State {
  items: CheckboxButtonItem[];
}

class CheckboxButtons extends React.Component<CheckboxButtonsProps, State> {
  private allButtonId: any;

  constructor(props: CheckboxButtonsProps) {
    super(props);
    this.allButtonId = uuid();

    this.state = {
      items: [],
    };
  }

  public componentDidMount() {
    const items = this.initializeItems();
    this.setState({ items });
  }

  public onlyActivateButtonWithValue(value: string) {
    const defaultItems = this.initializeItems();
    const { onCheckboxChanged } = this.props;

    defaultItems.forEach((item) => {
      item.checked = item.value === value;
    });

    this.setState({ items: defaultItems }, () => {
      const { items } = this.state;
      onCheckboxChanged(items.filter((item) => item.id !== this.allButtonId));
    });
  }

  private updateStatusOfCheckboxAll = (): void => {
    this.setState(
      (prevState: State) => {
        const { items } = prevState;
        const allCheckedButton = items[items.length - 1];
        const itemsWithoutAll = items.filter((item) => item.id !== this.allButtonId);
        const hasAllChecked = itemsWithoutAll.filter((checkbox) => checkbox.checked).length === itemsWithoutAll.length;
        const hasNotChecked = itemsWithoutAll.filter((checkbox) => !checkbox.checked).length > 0;

        if (hasAllChecked) {
          allCheckedButton.checked = true;
        }

        if (hasNotChecked) {
          allCheckedButton.checked = false;
        }
      },
      () => {
        const { onCheckboxChanged } = this.props;
        const { items } = this.state;
        onCheckboxChanged(items.filter((item) => item.id !== this.allButtonId));
      },
    );
  };

  private allCustomAction = (allFilterItem: CheckboxButtonItem) => {
    this.setState(
      (prevState: State) => {
        const { items } = prevState;
        items.forEach((item) => {
          item.checked = allFilterItem.checked;
        });
      },
      () => {
        const { onCheckboxChanged } = this.props;
        const { items } = this.state;
        onCheckboxChanged(items.filter((item) => item.id !== this.allButtonId));
      },
    );
  };

  private initializeItems = (): CheckboxButtonItem[] => {
    const { allCheckboxText, items } = this.props;
    const newItems: CheckboxButtonItem[] = cloneDeep(items);

    if (allCheckboxText !== undefined && allCheckboxText !== "") {
      const allFilter: CheckboxButtonItem = {
        id: this.allButtonId,
        value: this.allButtonId,
        text: allCheckboxText,
        checked: true,
        toggleCustomAction: this.allCustomAction,
      };
      newItems.push(allFilter);
    }
    return newItems;
  };

  private checkboxChange = (itemChanged: CheckboxButtonItem): void => {
    this.setState(
      (prevState: State) => {
        const { items } = prevState;
        const toggledItem = items.find((i) => i.id === itemChanged.id);

        if (toggledItem) {
          toggledItem.checked = !toggledItem.checked;
        }
        return { items };
      },
      () => {
        const { items } = this.state;
        const { allCheckboxText, onCheckboxChanged } = this.props;
        if (itemChanged.id !== this.allButtonId) {
          if (allCheckboxText !== "" && allCheckboxText !== undefined) {
            this.updateStatusOfCheckboxAll();
          } else {
            onCheckboxChanged(items.filter((item) => item.id !== this.allButtonId));
          }
        }

        if (itemChanged.toggleCustomAction) {
          itemChanged.toggleCustomAction(itemChanged);
        }
      },
    );
  };

  public resetButtons() {
    this.setState({ items: this.initializeItems() });
  }

  public render() {
    const { items } = this.state;
    const { title } = this.props;
    return (
      <>
        <Title>{title}</Title>
        <CheckboxGroup>
          {items.map((checkbox: CheckboxButtonItem) => (
            <CheckboxButton key={checkbox.id} checkboxItem={checkbox} onChange={this.checkboxChange} />
          ))}
        </CheckboxGroup>
      </>
    );
  }
}

export default CheckboxButtons;
