enum ResponseErrorStatus {
  VirusScanError = "VirusScanError",
  Unknown = "Unknown",
  DocumentLoadFailed = "DocumentLoadFailed",
  EdeliveryFailed = "EdeliveryFailed",
  DocumentReshareFailed = "DocumentReshareFailed",
  DocumentUnshareFailed = "DocumentUnshareFailed"
}

export default ResponseErrorStatus;
