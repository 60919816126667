import { PaginatedResponse } from "../../interfaces/PaginatedResponse";
import { SearchCriteria } from "../../interfaces/SearchCriteria";
import { SearchKeyValue } from "../interfaces/SearchKeyValue";
import { SignaturePackage } from "../interfaces/SignaturePackage";

export enum SignaturePackageActionTypes {
  LOAD_MORE_SIGNATUREPACKAGES = "[SignaturePackage] Load More Signature Packages",
  SEARCH_SIGNATUREPACKAGE = "[SignaturePackage] Search Signature Package",
  SEARCH_SIGNATUREPACKAGE_SUCCESS = "[SignaturePackage] Search Signature Package Success",
  SEARCH_SIGNATUREPACKAGE_ERROR = "[SignaturePackage] Search Signature Package Error",
  SET_NB_SEARCH_LAUNCHED = "[SignaturePackage] Set nb search launched",
}

export interface LoadMoreSignaturePackages {
  type: SignaturePackageActionTypes.LOAD_MORE_SIGNATUREPACKAGES;
  searchCriteria: SearchCriteria;
  offset: number;
}
export interface SearchSignaturePackage {
  type: SignaturePackageActionTypes.SEARCH_SIGNATUREPACKAGE;
  searchCriteria: SearchCriteria;
}

export interface SearchSignaturePackageSuccess {
  type: SignaturePackageActionTypes.SEARCH_SIGNATUREPACKAGE_SUCCESS;
  paginatedResponse: PaginatedResponse<SignaturePackage>;
  searchKeyValue: SearchKeyValue;
}

export interface SearchSignaturePackageError {
  type: SignaturePackageActionTypes.SEARCH_SIGNATUREPACKAGE_ERROR;
  searchKeyValue: SearchKeyValue;
}

export interface SetNbSearchLaunched {
  type: SignaturePackageActionTypes.SET_NB_SEARCH_LAUNCHED;
  nbSearchLaunched: number;
}

export type SignaturePackageAction = LoadMoreSignaturePackages | SearchSignaturePackage | SearchSignaturePackageSuccess | SearchSignaturePackageError | SetNbSearchLaunched;
