// TODO: this.setState({ candidate: newCandidate }); this.state.candidate doesn't seem to be using. Refactoring to do and remove eslint-disable react/no-unused-state
/* eslint-disable react/no-unused-state */
import React, { Component, FC } from 'react';
import { Row, Col, Checkbox } from 'antd';
import { connect } from "react-redux";
import { Select, FormControl, FormHelperText, Dialog, DialogTitle, Button, DialogActions, CircularProgress } from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircle';
import Delete from '@material-ui/icons/Clear';
import WarningIcon from '@material-ui/icons/CancelRounded';
import { Trans } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { RouteChildrenProps } from "react-router";
import i18n from 'i18next';
import { ICandidate, PartyTypeCode } from './Models/Client';
import { userService } from './service/user.service';
import { HouseholdNoRoleType, HouseholdType } from './Models/Household';
import { UserSummary } from './UserSummary';
import { searchClientService } from './service/searchClient.service';
import {
  MainBox, Container, TextFieldIA, TextFieldLabel, SubTitle, IAButton, IALink, Title, LabelWhite, Warning, SelectFieldIA, TextResultFieldLabel, PhoneFieldIA,
} from './Styles/CreateUserStyle';
import { ContentContainerUrl } from "../../constants/Router";
import { getClient as getClientAction } from "../../common/client/redux/client.actions";
import { bindActionCreators, compose } from 'redux';
import ClientProfileSaga from '../ClientProfile/redux/clientProfile.saga';
import { injectSaga, injectReducer, get, Loader, InfoMessage } from 'ia-react-core';
import { initialClientState } from '../../interfaces/ClientState';
import ClientSearchReducer from '../ClientSearch/redux/ClientSearch.reducer';
import { Client } from '../../common/client/interfaces/Client';
import { State } from '../../interfaces/State';
import { LoadingContainer } from '../ClientSearch/ClientSearch.style';
import 'react-phone-input-2/lib/style.css'
import fr from 'react-phone-input-2/lang/fr.json'
import { Typography } from '@mui/material';
const {
  getAllCountriesName,
  getRegionsByCountryCode,
} = require('i18n-iso-countries-regions');


export interface CreateUserDispatchToProps {
  getClientAction : typeof getClientAction
}

interface CreateUserParam {
  id: string;
}

interface LocalState {
  isEdit: boolean,
  isReviewed: boolean,
  isSaveCompleted: boolean,
  isLoading: boolean,
  showSuccesfulMsg: boolean,
  errors: any[],
  nonClient: ICandidate,
  countries: any[],
  states: any[],
  candidate: ICandidate,
  client: Client,
  loading: boolean,
  locale: string,
  phoneCountry: string,
  phoneValid: boolean,
  changed: boolean,
}

export interface CreateUserStateToProps {
  client: Client;
  loading: boolean;
  locale: string;
}

export interface CreateUserComposedProps extends CreateUserDispatchToProps, CreateUserStateToProps, RouteChildrenProps<CreateUserParam> {}

class CreateUser extends Component<CreateUserComposedProps, LocalState> {
  public constructor(props: CreateUserComposedProps) {
    super(props);

    this.state={
      countries: [],
      states: [],
      isEdit: false,
      isReviewed: false,
      isSaveCompleted: false,
      isLoading: false,
      showSuccesfulMsg: false,
      errors: [],
      nonClient: {
        partyTypeCode: PartyTypeCode.NonClient,
        firstName: '',
        lastName: '',
        languageCode: 'en',
        sources: [
          {
            system: 'iasecurities',
          },
        ],
        phones: [{
          phoneTypeCode: 'home',
          phoneNumber: '1',
          extension: '',
        }],
        emails: [{
          emailTypeCode: 'main',
          emailAddress: '',
        }],
        addresses: [{ unitTypeCode: '', countryCode: '-', regionCode: '-', streetNumber: '', streetName: '', municipality: '', postalCode: '' }],
        accounts: [{
          id: '',
          role: HouseholdType.SHARING,
          noRole: HouseholdNoRoleType.JOINTPRIMARY,
          repCode:''
        }],
      } as ICandidate,
      candidate: {
        partyTypeCode: PartyTypeCode.NonClient,
        firstName: '',
        lastName: '',
        languageCode: 'en',
        sources: [
          {
            system: 'iasecurities',
          },
        ],
        phones: [{
          phoneTypeCode: 'home',
          phoneNumber: '',
          extension: '',
        }],
        emails: [{
          emailTypeCode: 'main',
          emailAddress: '',
        }],
        addresses: [{ unitTypeCode: '', countryCode: '-', regionCode: '-', streetNumber: '', streetName: '', municipality: '', postalCode: '' }],
        accounts: [{
          id: '',
          role: HouseholdType.SHARING,
          noRole: HouseholdNoRoleType.JOINTPRIMARY,
          repCode: ''
        }],
      } as ICandidate,
      client: {} as Client,
      loading: false,
      locale: '',
      phoneCountry: '',
      phoneValid: false,
      changed: false,
    };
  }

  public componentDidMount = () => {
    const { getClientAction, match } = this.props;
    if (match) {
      const { params } = match;
      if (params.id) {
        getClientAction(params.id, false);
      }
    }
    let countries = [];
    countries = getAllCountriesName(this.props.locale);
    countries.unshift(countries.splice(countries.findIndex((o:any)=>o.iso==='US'), 1)[0]);
    countries.unshift(countries.splice(countries.findIndex((o:any)=>o.iso==='CA'), 1)[0]);
    this.setState({countries: countries});
  };

  public componentDidUpdate = () => {
    const { client, match } = this.props;
    const { isEdit } = this.state;
    if (match?.params.id !== undefined && client!==undefined && !isEdit){
      this.handleChangeAddress('countryCode', {target:{value:client.addresses[0].countryCode}});
      this.setState({isEdit: true});
      this.setState({ phoneValid: true });
      this.setState({nonClient: {
          candidateId: client.id,
          partyTypeCode: PartyTypeCode.NonClient,
          firstName: client.firstName,
          lastName: client.lastName,
          languageCode: client.languageCode,
          sources: [
            {
              system: 'iasecurities',
            },
          ],
          phones: [{
            phoneTypeCode: client.phones[0].phoneTypeCode,
            phoneNumber: client.phones[0].phoneNumber,
            extension: client.phones[0].extension,
          }],
          emails: [{
            emailTypeCode: 'main',
            emailAddress: client.emails[0].emailAddress,
          }],
          addresses: [{ unitTypeCode: client.addresses[0].unitTypeCode?.toString(), unitNumber: client.addresses[0].unitNumber?.toString(), countryCode: client.addresses[0].countryCode, regionCode: client.addresses[0].regionCode, streetNumber: client.addresses[0].streetNumber, streetName: client.addresses[0].streetName, municipality: client.addresses[0].municipality, postalCode: client.addresses[0].postalCode }],
          accounts: client.accounts.map((a)=>({id: a.id, role: a.role, noRole: a.noRole, repCode: a.repCode})),
        } as ICandidate});
    }
  }

  private handleReview = async () => {
      this.setState({ isReviewed: false, isLoading: true });
      let errors = this.validate();
      errors = errors.concat(await this.validateClientId(true));
      errors = errors.concat(this.validateThirdPartyClientProvidedCopy());
      this.setState({ isReviewed: true, isLoading: false, errors });
    }

    private handleCreate = () => {
      const { nonClient, isEdit } = this.state;
      const errors = this.validate();
      if (errors.length === 0) {
        this.setState({ isLoading: true });
        userService.saveUser(nonClient, isEdit).then(() => {
          this.setState({ showSuccesfulMsg: true, isLoading: false });
        // eslint-disable-next-line no-alert
        }).catch((e) => {
          console.log(e);
          alert('Error see console for more details');
          this.setState({ isLoading: false });
        });
      }
    }

    private handleEdit = () => {
      this.setState({ isReviewed: false });
    }

    private handleAddClient = () => {
      const account = {
        id: '',
        role: HouseholdType.SHARING,
        noRole: HouseholdNoRoleType.JOINTPRIMARY,
        name: '',
        partyTypeCode: '',
        repCode: '',
        clientProvidedCopy: false
      };

      const listAccount = this.state.nonClient.accounts;
      listAccount.push(account);
      const newCandidate = Object.assign(this.state.nonClient, { accounts: listAccount });
      this.setState({ candidate: newCandidate });
    }

    private handleDeleteClient = () => {
      const listAccount = this.state.nonClient.accounts;
      listAccount.pop();
      const newCandidate = Object.assign(this.state.nonClient, { accounts: listAccount });
      this.setState({ candidate: newCandidate });
    }

    private handleChange(propertieName: string, event: any) {
      const newCandidate = Object.assign(this.state.nonClient, { [propertieName]: event.target.value });
      this.setState({ candidate: newCandidate });
    }

    private handleChangePhone(newPhone: any, country:any) {
      const phone = [{
        phoneTypeCode: 'home',
        phoneNumber: newPhone,
        extension: '',
      }];
      const newCandidate = Object.assign(this.state.nonClient, { phones: phone });
      const phoneValid = (country.format.match(/\./g) || []).length == newPhone.length;
      this.setState({ candidate: newCandidate, phoneCountry: country.countryCode, phoneValid });

    }

    private handleChangeEmail(event: any) {
      const email = [{
        emailTypeCode: 'main',
        emailAddress: event.target.value,
      }];

      const newCandidate = Object.assign(this.state.nonClient, { emails: email });
      this.setState({ candidate: newCandidate });
    }

    private handleChangeAddress(propertyName: string, event: any) {
      const address = {
        [propertyName]: event.target.value,
      };
      if (propertyName === 'countryCode') {
        let states = [];
        states = getRegionsByCountryCode(this.props.locale, event.target.value);
        this.setState({ states: states });
      }

      const newAddress = Object.assign(this.state.nonClient.addresses[0], address);
      const newCandidate = Object.assign(this.state.nonClient, { addresses: [newAddress] });
      this.setState({ candidate: newCandidate });
    }

    private handleChangeRootId(index: number, event: any) {
      const listAccount = this.state.nonClient.accounts;
      listAccount[index].id = event.target.value.toUpperCase();
      const newCandidate = Object.assign(this.state.nonClient, { accounts: listAccount });
      this.setState({ candidate: newCandidate });
    }

    private handleChangeRole(index: number, event: any) {
      const listAccount = this.state.nonClient.accounts;
      listAccount[index].noRole = this.GetRoleNo(event.target.value);
      listAccount[index].role = event.target.value;
      const newCandidate = Object.assign(this.state.nonClient, { accounts: listAccount });
      this.setState({ candidate: newCandidate, changed: !this.state.changed });
    }

    private handleChangeThirdPartyClientProvidedCopy(index: number, event: any) {
      const listAccount = this.state.nonClient.accounts;
      listAccount[index].clientProvidedCopy = event.target.checked as boolean;
      const newCandidate = Object.assign(this.state.nonClient, { accounts: listAccount });
      this.setState({ candidate: newCandidate, changed: !this.state.changed });
    }

    private handleChangeClient(index: number, name: string, partyTypeCode: string, repCode: string) {
      const listAccount = this.state.nonClient.accounts;
      if (index < listAccount.length) {
        listAccount[index].name = name;
        listAccount[index].partyTypeCode = partyTypeCode;
        listAccount[index].repCode = repCode;
        const newCandidate = Object.assign(this.state.nonClient, { accounts: listAccount });
        this.setState({ candidate: newCandidate });
      }
    }

    private handleSaveSuccesful() {
      this.setState({ isSaveCompleted: true });
    }

    private GetRoleNo(role: string) {
      if (role === HouseholdType.COMPANY) {
        return HouseholdNoRoleType.CORPORATE;
      }

      return HouseholdNoRoleType.JOINTPRIMARY;
    }

    private validate = () => {
      const errors = [];
      const regexName = new RegExp("[\\^$*%#`;:?/&()_+!><]{1,}|[- '.]{2,}|[0-9]{1,}");
      if (this.state.nonClient.firstName.length === 0) {
        errors.push('required');
      } else if (regexName.test(this.state.nonClient.firstName)) {
        errors.push('nameInvalid');
      }

      if (this.state.nonClient.lastName.length === 0) {
        errors.push('required');
      } else if (regexName.test(this.state.nonClient.lastName)) {
        errors.push('lastNameInvalid');
      }

      if (this.state.nonClient.addresses.length === 0 || this.state.nonClient.addresses[0].streetNumber.length === 0
            || this.state.nonClient.addresses[0].streetName.length === 0
            || this.state.nonClient.addresses[0].municipality.length === 0
            || this.state.nonClient.addresses[0].postalCode.length === 0) {
        errors.push('required');
      }
      if (this.state.nonClient.addresses.length > 0 && this.state.nonClient.addresses[0].streetNumber.length > 0) {
        const regexNumber = new RegExp('^[0-9]+$');
        if (!regexNumber.test(this.state.nonClient.addresses[0].streetNumber)) {
          errors.push('streetNumberInvalid');
        }
      }

      if (this.state.nonClient.addresses.length > 0 && this.state.nonClient.addresses[0].postalCode.length > 0) {
        let regexPostal = new RegExp('[A-CEGHJ-NPRSTVXY][0-9][A-CEGHJ-NPRSTV-Z] ?[0-9][A-CEGHJ-NPRSTV-Z][0-9]$');
        if (this.state.nonClient.addresses[0].countryCode === 'US') {
          regexPostal = new RegExp('[0-9]{5}$');
        }
        if ((this.state.nonClient.addresses[0].countryCode === 'US' || this.state.nonClient.addresses[0].countryCode === 'CA' ) && !regexPostal.test(this.state.nonClient.addresses[0].postalCode.toUpperCase())) {
          errors.push('postalInvalid');
        }
      }

      if (this.state.nonClient.emails.length === 0 || this.state.nonClient.emails[0].emailAddress.length === 0) {
        errors.push('required');
      } else {
        const regexpEmail = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$');
        if (!regexpEmail.test(this.state.nonClient.emails[0].emailAddress)) {
          errors.push('email');
        }
      }

      if (this.state.nonClient.phones.length === 0 || this.state.nonClient.phones[0].phoneNumber.length === 0) {
        errors.push('required');
      } else {
        if (!this.state.phoneValid){
          errors.push('phoneInvalid');
        }
      }

      if (this.state.nonClient.addresses[0].countryCode === '-') {
        errors.push('countryInvalid');
      }
      if (this.state.nonClient.addresses[0].regionCode === '-') {
        errors.push('regionInvalid');
      }

      if (this.state.nonClient.accounts.length === 0 || this.state.nonClient.accounts[0].id.length === 0) {
        errors.push('required');
      }

      return errors;
    }

    private validateThirdPartyClientProvidedCopy(){
      const errors: string[] = [];
      const clientIDCopyNotProvidedPrefix = 'clientIDCopyNotProvided';
      this.state.nonClient.accounts.forEach(async (account, index) => {
        if (this.shouldShowThirdPartyClientProvidedCopy(account.role) && !account.clientProvidedCopy) {
          errors.push('required');
          errors.push(`${clientIDCopyNotProvidedPrefix}${index}`);
        }
      });
      return errors;
    }

    private async validateClientId(isReview: boolean) {
      const errors: string[] = [];
      for (let index = 0; index < this.state.nonClient.accounts.length; index++) {
        const account = this.state.nonClient.accounts[index];
        if (account.id !== undefined && account.id.length > 0) {
          const client = await searchClientService.search(account.id, '', '', '');
          if (client !== undefined && client.length > 0) {
            const owner = client.filter((w) => (w.accounts.find((f) => f.id.toUpperCase() === account.id.toUpperCase() &&
              f.role === HouseholdType.OWNER) !== undefined));
            if (owner !== undefined && owner.length > 0) {
              const repCode = owner[0].accounts.filter((a) => a.id.toUpperCase() === account.id.toUpperCase())[0].repCode
              this.handleChangeClient(index, (owner[0].partyTypeCode === PartyTypeCode.Organization) ? owner[0].businessName : `${owner[0].firstName} ${owner[0].lastName}`, owner[0].partyTypeCode, repCode);
            } else {
              this.handleChangeClient(index, '', '', '');
              const err = [];
              err.push(`clientID${index}`);
              errors.push(`clientID${index}`)
              if (!isReview) {
                const newError = Object.assign(this.state.errors, err);
                this.setState({ errors: newError });
              }
            }
          } else {
            this.handleChangeClient(index, '', '', '');
            const err: string[] = this.state.errors;
            err.push(`clientID${index}`);
            errors.push(`clientID${index}`)
            if (!isReview) {
              const newError = Object.assign(this.state.errors, err);
              this.setState({ errors: newError });
            }
          }
        } else {
          this.handleChangeClient(index, '', '', '');
        }
      }
      return errors;
    }

    isFieldError = (field: string) => this.state.errors.find((s) => s === field); 

    isAnyClientFieldInError = () => this.state.errors.find((s) => (s || '').includes('clientID')); 

    shouldShowThirdPartyClientProvidedCopy = (role: string) => role !== HouseholdType.COMPANY;

    private renderClientList = (account: any, index: number) => (
      <React.Fragment key={`account${index}`}>
        <Row style={{ marginTop: 15 }} gutter={[16, 0]}>
          <Col span={8}>
            <FormControl>
              <TextFieldIA value={account.id} defaultValue={account.id} required={this.state.isReviewed} onChange={this.handleChangeRootId.bind(this, index)} onBlur={this.validateClientId.bind(this, false)} />
              {this.isFieldError(`clientID${index}`) && <FormHelperText error><Trans i18nKey="createUser.ensureClientIdIsCorrect" /> </FormHelperText> }
            </FormControl>
          </Col>
          <Col span={6} style={{paddingTop: 12}}>
            <TextResultFieldLabel>{account.name}</TextResultFieldLabel>
          </Col>
          <Col span={8}>
            <Select native input={<TextFieldIA />} value={account.role} defaultValue={account.role} onChange={this.handleChangeRole.bind(this, index)}>
              <option value={HouseholdType.SHARING}>{i18n.t('createUser.ThirdParty')} </option>
              {account.partyTypeCode === PartyTypeCode.Organization && <option value={HouseholdType.COMPANY}>{i18n.t('createUser.CO')}</option>}
            </Select>
          </Col>
          <Col span={2} style={{ textAlign: 'right' }}>
            <Delete style={{ color: '#2062D4', cursor: 'pointer' }} onClick={this.handleDeleteClient} />
          </Col>
        </Row>
        {
          this.shouldShowThirdPartyClientProvidedCopy(account.role) && 
          <Row style={{ marginTop: 5, paddingTop: 8 }}>
            <Checkbox checked={account.clientProvidedCopy} onChange={this.handleChangeThirdPartyClientProvidedCopy.bind(this, index)}>
              <Typography variant="body1" component="span" color={this.isFieldError(`clientIDCopyNotProvided${index}`) ? '#f44336' : ''}>
                {i18n.t("createUser.thirdPartyClientProvidedCopy")}
              </Typography>
            </Checkbox>
          </Row>
        }
      </React.Fragment>
    )

    render() {
      const { locale } = this.props;
      let { isEdit, countries } = this.state;

      const isValid = this.state.isReviewed && this.state.errors.length === 0;
      const stateOptionItems = this.state.states.map((s:any) =>
        <option key={s.iso} value={s.iso}>{s.name}</option>);
      stateOptionItems.unshift(<option value="-">-</option>);
      
      const phoneLocale = locale === 'fr' ? fr : {};

      if (this.state.isSaveCompleted) {
        return <Redirect to={ContentContainerUrl} />;
      }
      if (this.props.match?.params.id != undefined && !isEdit){
        return <LoadingContainer><Loader /></LoadingContainer>

      }

      return (
        <Container>
          { !this.isAnyClientFieldInError() && !this.state.isReviewed &&
            <InfoMessage style={{"marginBottom": "20px"}}>
              {i18n.t("createUser.warningEnsureClientIdExist")}
            </InfoMessage> 
          }
          { this.state.errors.length > 0 && this.state.isReviewed &&
          <Warning>
            <WarningIcon style={{ fontSize: 72, backgroundColor: 'transparent', color: 'rgb(255, 0, 0)', marginRight: 10 }} />
            <div>
              <Title>{i18n.t('createUser.warning')}</Title>
              {this.isFieldError('required') && <TextFieldLabel><Trans i18nKey="createUser.required" /></TextFieldLabel> }
              <TextFieldLabel><Trans i18nKey="createUser.invalid" /></TextFieldLabel>
            </div>
          </Warning>}
          {(!isValid) ?
            <form autoComplete="off">

              <MainBox>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <SubTitle><Trans i18nKey="createUser.PersonalInformation" /></SubTitle>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20, marginBottom: 20 }} gutter={[16, 16]}>
                  <Col span={12}>
                    <FormControl>
                      <TextFieldLabel shrink htmlFor="firstNameId"><Trans i18nKey="createUser.firstName" /> </TextFieldLabel>
                      <TextFieldIA id="firstNameId" required={this.state.isReviewed} defaultValue={this.state.nonClient.firstName} value={this.state.nonClient.firstName} onChange={this.handleChange.bind(this, 'firstName')} onBlur={this.validate} inputProps={{ maxLength: 25 }} />
                      {this.isFieldError('nameInvalid') && <FormHelperText error><Trans i18nKey="createUser.incorrectEntry" /> </FormHelperText> }
                    </FormControl>
                  </Col>
                  <Col span={12}>
                    <FormControl>
                      <TextFieldLabel shrink htmlFor="lastNameId"><Trans i18nKey="createUser.lastName" /></TextFieldLabel>
                      <TextFieldIA id="lastNameId" defaultValue={this.state.nonClient.lastName} value={this.state.nonClient.lastName} required={this.state.isReviewed} onChange={this.handleChange.bind(this, 'lastName')} onBlur={this.validate} inputProps={{ maxLength: 35 }} />
                      {this.isFieldError('lastNameInvalid') && <FormHelperText error><Trans i18nKey="createUser.incorrectEntry" /> </FormHelperText> }
                    </FormControl>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20, marginBottom: 20 }} gutter={[16, 16]}>
                  <Col span={24}>
                    <FormControl>
                      <TextFieldLabel shrink htmlFor="langueLbl"> <Trans i18nKey="createUser.language" /></TextFieldLabel>
                      <Select native labelId="langueLbl" id="select-langue" defaultValue={this.state.nonClient.languageCode} value={this.state.nonClient.languageCode} input={<SelectFieldIA />} onChange={this.handleChange.bind(this, 'languageCode')}>
                        <option value="en">{i18n.t('createUser.english')}</option>
                        <option value="fr">{i18n.t('createUser.french')}</option>
                      </Select>
                    </FormControl>
                  </Col>
                </Row>
              </MainBox>

              <MainBox style={{ marginTop: 20, marginBottom: 20 }}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <SubTitle>
                      <Trans i18nKey="createUser.contact" />
                    </SubTitle>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20, marginBottom: 20 }} gutter={[16, 16]}>
                  <Col span={24}>
                    <FormControl>
                      <TextFieldLabel shrink htmlFor="phoneId"><Trans i18nKey="createUser.phone" /></TextFieldLabel>
                      <PhoneFieldIA localization={phoneLocale} country={'ca'} preferredCountries={['ca','us']} value={this.state.nonClient.phones[0].phoneNumber} onChange={this.handleChangePhone.bind(this)} onBlur={this.validate} inputStyle={{backgroundColor: 'rgba(239, 241, 244, 1)',border: '1px solid rgb(190, 196, 206)',fontSize: 16,width: '276px',padding: '10px 12px 10px 60px',borderRadius: '0px',}} containerStyle={{marginTop:'25px'}} />

                      {this.isFieldError('phoneInvalid') && <FormHelperText error><Trans i18nKey="createUser.incorrectEntry" /> </FormHelperText> }
                    </FormControl>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20, marginBottom: 20 }} gutter={[16, 16]}>
                  <Col span={24}>
                    <FormControl>
                      <TextFieldLabel shrink><Trans i18nKey="createUser.email" /></TextFieldLabel>
                      <TextFieldIA id="emailId" value={this.state.nonClient.emails[0].emailAddress} required={this.state.isReviewed} error={this.isFieldError('email')} defaultValue={this.state.nonClient.emails[0].emailAddress} onChange={this.handleChangeEmail.bind(this)} onBlur={this.validate} inputProps={{ maxLength: 60 }} />
                      {this.isFieldError('email') && <FormHelperText error><Trans i18nKey="createUser.incorrectEntry" /> </FormHelperText> }
                    </FormControl>
                  </Col>
                </Row>

                <Row style={{ marginTop: 20, marginBottom: 20 }} gutter={[16, 16]}>
                  <Col span={24}>
                    <TextFieldLabel><Trans i18nKey="createUser.address" /> </TextFieldLabel>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col className="gutter-row" span={8}>
                    <FormControl>
                      <TextFieldLabel shrink htmlFor="numberId"><Trans i18nKey="createUser.number" /></TextFieldLabel>
                      <TextFieldIA id="numberId" defaultValue={this.state.nonClient.addresses[0].streetNumber} value={this.state.nonClient.addresses[0].streetNumber} required={this.state.isReviewed} onChange={this.handleChangeAddress.bind(this, 'streetNumber')} onBlur={this.validate} inputProps={{ maxLength: 10 }} />
                    </FormControl>
                    {this.isFieldError('streetNumberInvalid') && <FormHelperText error><Trans i18nKey="createUser.incorrectEntry" /> </FormHelperText> }
                  </Col>
                  <Col span={8}>
                    <FormControl>
                      <TextFieldLabel shrink htmlFor="streetId">
                        <Trans i18nKey="createUser.street" />
                      </TextFieldLabel>
                      <TextFieldIA required={this.state.isReviewed} id="streetId" defaultValue={this.state.nonClient.addresses[0].streetName} value={this.state.nonClient.addresses[0].streetName} onChange={this.handleChangeAddress.bind(this, 'streetName')} inputProps={{ maxLength: 60 }} />
                    </FormControl>
                  </Col>
                  <Col span={8}>
                    <FormControl>
                      <TextFieldLabel shrink htmlFor="aptId">
                        <Trans i18nKey="createUser.apt" />
                      </TextFieldLabel>
                      <TextFieldIA id="aptId" defaultValue={this.state.nonClient.addresses[0].unitNumber} value={this.state.nonClient.addresses[0].unitNumber} onChange={this.handleChangeAddress.bind(this, 'unitNumber')} inputProps={{ maxLength: 10 }} />
                    </FormControl>
                  </Col>
                </Row>

                <Row style={{ marginTop: 20, marginBottom: 20 }} gutter={[16, 16]}>
                  <Col span={8}>
                    <FormControl>
                      <TextFieldLabel shrink htmlFor="cityId">
                        <Trans i18nKey="createUser.city" />
                      </TextFieldLabel>
                      <TextFieldIA id="cityId" defaultValue={this.state.nonClient.addresses[0].municipality} value={this.state.nonClient.addresses[0].municipality} required={this.state.isReviewed} onChange={this.handleChangeAddress.bind(this, 'municipality')} inputProps={{ maxLength: 50 }} />
                    </FormControl>
                  </Col>
                  <Col span={8}>
                    <FormControl>
                      <TextFieldLabel shrink htmlFor="postalId">
                        <Trans i18nKey="createUser.postal" />
                      </TextFieldLabel>
                      <TextFieldIA required={this.state.isReviewed} id="postalId" defaultValue={this.state.nonClient.addresses[0].postalCode} value={this.state.nonClient.addresses[0].postalCode} onChange={this.handleChangeAddress.bind(this, 'postalCode')} onBlur={this.validate} inputProps={{ maxLength: 7 }} />
                      {this.isFieldError('postalInvalid') && <FormHelperText error><Trans i18nKey="createUser.incorrectEntry" /> </FormHelperText> }
                    </FormControl>
                  </Col>
                  <Col span={8}>
                    &nbsp;
                  </Col>
                </Row>

                <Row style={{ marginTop: 20, marginBottom: 20 }} gutter={[16, 16]}>
                  <Col span={8}>
                    <FormControl>
                      <TextFieldLabel shrink htmlFor="countryId">
                        <Trans i18nKey="createUser.country" />
                      </TextFieldLabel>
                      <Select native labelId="countryId" id="select-country" value={this.state.nonClient.addresses[0].countryCode} input={<SelectFieldIA />} onBlur={this.validate} onChange={this.handleChangeAddress.bind(this, 'countryCode')}>
                        <option value="-">{i18n.t('createUser.countrySection.selectCountry')}</option>
                        {countries.map((c:any)=> <option value={c.iso}>{c.name}</option>)}
                      </Select>
                      {this.isFieldError('countryInvalid') && <FormHelperText error><Trans i18nKey="createUser.incorrectEntry" /> </FormHelperText> }
                    </FormControl>
                  </Col>
                  <Col span={8}>
                    <FormControl>
                      <TextFieldLabel shrink htmlFor="provinceId">
                        <Trans i18nKey="createUser.province" />
                      </TextFieldLabel>
                      <Select native labelId="provinceId" id="select-province" defaultValue={this.state.nonClient.addresses[0].regionCode} value={this.state.nonClient.addresses[0].regionCode} input={<SelectFieldIA />} onBlur={this.validate} onChange={this.handleChangeAddress.bind(this, 'regionCode')}>
                        {stateOptionItems}
                      </Select>
                      {this.isFieldError('regionInvalid') && <FormHelperText error><Trans i18nKey="createUser.incorrectEntry" /> </FormHelperText> }
                    </FormControl>
                  </Col>
                  <Col span={8}>
                    &nbsp;
                  </Col>
                </Row>

              </MainBox>

              <MainBox style={{ marginBottom: 20 }}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <SubTitle>
                      <Trans i18nKey="createUser.ClientIds" />
                    </SubTitle>
                  </Col>
                </Row>
                <Row style={{ marginTop: 0, marginBottom: 20, backgroundColor: 'rgba(0, 61, 165, 1)', paddingTop: 8, paddingBottom: 8}} gutter={[16, 16]} >
                  <Col span={8}>
                    <LabelWhite><Trans i18nKey="createUser.ID" /></LabelWhite>
                  </Col>
                  <Col span={6}>
                    <LabelWhite> <Trans i18nKey="name" /></LabelWhite>
                  </Col>
                  <Col span={10}>
                    <LabelWhite> <Trans i18nKey="createUser.role" /></LabelWhite>
                  </Col>
                </Row>

                {this.state.nonClient.accounts.map((currElement, index) => this.renderClientList(currElement, index))}

                <Row>
                  <Col span={24}>
                    <IALink onClick={this.handleAddClient} style={{ padding: 10 }}>
                      <AddCircle /> <Trans i18nKey="createUser.add" />
                    </IALink>
                  </Col>
                </Row>
              </MainBox>

            </form> :
            <UserSummary nonClient={this.state.nonClient}></UserSummary>}
            <Row>
              <Col span={2}>
                <div style={{ position: 'relative' }}>
                  <IAButton disabled={this.state.isLoading} onClick={isValid ? this.handleCreate.bind(this) : this.handleReview.bind(this)}>{isValid ? (isEdit ? <Trans i18nKey="createUser.update" />: <Trans i18nKey="createUser.create" />): <Trans i18nKey="createUser.review" />}</IAButton>
                  {this.state.isLoading && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12 }} />}
                </div>
              </Col>
              <Col span={22}>
                {isValid && <IALink style={{ padding: 10 }} onClick={this.handleEdit}> <Trans i18nKey="createUser.edit" /></IALink>}
              </Col>
            </Row>

          <Dialog open={this.state.showSuccesfulMsg}>
            <DialogTitle id="alert-dialog-title"><Trans i18nKey="createUser.saved" /></DialogTitle>
            <DialogActions>
              <Button onClick={this.handleSaveSuccesful.bind(this)} color="primary" autoFocus>ok </Button>
            </DialogActions>
          </Dialog>

        </Container>
      );
    }
}

function mapDispatchToProps (dispatch:any) {
  return bindActionCreators({ getClientAction }, dispatch);
}

const mapStateToProps = (state: State): CreateUserStateToProps => {
  return({
  client: get(state, "App.ClientSearch.selectedClient") as unknown as Client,
  loading: get(state, "App.ClientSearch.loading") as unknown as boolean,
  locale: (get(state, "language.locale") as unknown) as string,
});
}

export default compose<FC<CreateUserComposedProps>>(injectSaga({
  key: "ClientProfile",
  saga: ClientProfileSaga,
}),   injectReducer({
  key: "ClientSearch",
  initialState: initialClientState,
  reducer: ClientSearchReducer,
  useScope: true,
  cleanState: true,
}),
connect(mapStateToProps, mapDispatchToProps))(CreateUser);
