import { ColumnType } from "antd/lib/table";
import * as React from "react";

import { ClientSearchResultActions } from "..";
import { Client } from "../../../../../../common/client/interfaces/Client";
import { ClientSearchResultTableProps } from "../ClientSearchResultTable";
import { PartyTypeCode } from "../../../../../../common/enum/PartyTypeCode";

export function ClientSearchResultColumnActions(props: ClientSearchResultTableProps): ColumnType<Client> {
  const { t, actionEvent } = props;

  return {
    title: t("clientSearchResult.columns.actions"),
    align: "center",
    dataIndex: "name",
    key: "name",
    render: (value: unknown, record: Client) => <ClientSearchResultActions t={t} id={record.id} canEdit={record.partyTypeCode==PartyTypeCode.NonClient && record.dateRegistered == null} accessCode={record.personalizedAccessCode} partyTypeCode={record.partyTypeCode} actionEvent={actionEvent} />,
  };
}
