import { ColumnType } from "antd/lib/table";
import { TFunction } from "i18next";
import * as React from "react";

import ClientActionEvent from "../../../../../common/client/enum/clientActionEvent";
import { Client } from "../../../../../common/client/interfaces/Client";
import { SearchCriteria } from "../../../../../common/interfaces/SearchCriteria";
import StyledTable from "../../../../../common/table/StyledTable";
import {
  ClientSearchResultColumnAccessCode,
  ClientSearchResultColumnActions,
  ClientSearchResultColumnAddress,
  ClientSearchResultColumnName,
} from "./ClientSearchResultColumn";

export interface ClientSearchResultTableProps {
  t: TFunction;
  data: Client[];
  actionEvent: (id: string, action: ClientActionEvent, event?: React.MouseEvent<HTMLElement>) => void;
  searchCriteria: SearchCriteria;
  locale: string;
}

const tableColumns = (props: ClientSearchResultTableProps): ColumnType<Client>[] => {
  const columns: ColumnType<Client>[] = [ClientSearchResultColumnName(props), ClientSearchResultColumnAddress(props), ClientSearchResultColumnAccessCode(props), ClientSearchResultColumnActions(props)];
  return columns;
};

const ClientSearchResultTable: React.FC<ClientSearchResultTableProps> = (props: ClientSearchResultTableProps) => { const { t, data } = props; return <StyledTable t={t} columns={tableColumns(props)} dataSource={data} rowKey="id" pagination={false} />; };

export default ClientSearchResultTable;
