import * as React from "react";
import { Col } from "react-grid-system";
import { Title } from "../../../../clientProfile.style";
import ContactInfo from "../ContactInfo";
import {
  LabeledValue,
  SectionContent,
  ClientPortalBusiness,
} from "../profileTab.style";
import { ProfileProps } from "./ProfileSelector";
import MirrorViewLink from "./MirrorViewLink";

export interface ProfileBusinessProps extends ProfileProps { }

export default class ProfileBusiness extends React.PureComponent<ProfileBusinessProps> {
  public render() {
    const { client, t, locale } = this.props;
   
    return (
      <>
        <Col md={4}>
          <ClientPortalBusiness>
            <Title>{t("profileTab.personalInformation.title")}</Title>
            <SectionContent>
              <LabeledValue label={t("profileTab.clientPortal")}>
                <MirrorViewLink client={client} t={t} />
              </LabeledValue>
              <LabeledValue label={t("App:client.personalizedAccessCode")}>
                {t("App:nonApplicable")}
              </LabeledValue>
            </SectionContent>
          </ClientPortalBusiness>
        </Col>
        <Col md={8}>
          <ContactInfo client={client} t={t} locale={locale} />
        </Col>
      </>
    );
  }
}
