import { get, getScoped, injectReducer, injectSaga, injectTranslations } from "ia-react-core";
import { FC } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { Client } from "../../common/client/interfaces/Client";
import { getClient, loadMoreClients, searchClient } from "../../common/client/redux/client.actions";
import { Language } from "../../common/enum/Language";
import { SearchCriteria } from "../../common/interfaces/SearchCriteria";
import { initialClientState } from "../../interfaces/ClientState";
import { State } from "../../interfaces/State";
import { resetError, setSearchCriteriaHistory } from "../../redux/app.actions";
import ClientSearch, { ClientSearchDispatchToProps, ClientSearchMapStateToProps, ClientSearchProps } from "./ClientSearch";
import ClientSearchReducer from "./redux/ClientSearch.reducer";
import ClientSearchSaga from "./redux/ClientSearch.saga";
import en from "./translations/en";
import fr from "./translations/fr";

const mapDispatchToProps: ClientSearchDispatchToProps = {
  searchClient,
  loadMoreClients,
  getClient,
  setSearchCriteriaHistory, 
  resetError,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: State, { scope }: any): ClientSearchMapStateToProps => ({
  clients: (getScoped(state, "clients", scope) as unknown) as Client[],
  criteria: (getScoped(state, "criteria", scope) as unknown) as string,
  repCodes: (get(state, "App.repCodes") as unknown) as string[],
  isSupport: (get(state, "App.isSupport") as unknown) as boolean,
  loading: (getScoped(state, "loading", scope) as unknown) as boolean,
  searchLoading: (getScoped(state, "searchLoading", scope) as unknown) as boolean,
  totalResults: (getScoped(state, "totalResults", scope) as unknown) as number,
  currentOffset: (getScoped(state, "currentOffset", scope) as unknown) as number,
  searchCriteria: (get(state, "App.searchCriteria", scope) as unknown) as SearchCriteria,
  locale: (get(state, "language.locale") as unknown) as string,
});

export default compose<FC<ClientSearchProps>>(
  injectTranslations("ClientSearch", [
    { language: Language.French, resource: fr },
    { language: Language.English, resource: en },
  ]),
  injectSaga({
    key: "ClientSearch",
    saga: ClientSearchSaga,
  }),
  injectReducer({
    key: "ClientSearch",
    initialState: initialClientState,
    reducer: ClientSearchReducer,
    useScope: true,
    cleanState: true,
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(ClientSearch);
