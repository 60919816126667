import { ColumnType } from "antd/lib/table";
import * as React from "react";

import { SignaturePackage } from "../../../../../../../common/signaturePackage/interfaces/SignaturePackage";
import ResultSigners from "../ResultSigners";
import { ResultTableProps } from "../ResultTable";

export function ResultColumnSigners(props: ResultTableProps): ColumnType<SignaturePackage> {
  const { t } = props;

  return {
    title: t("searchResult.columns.signers"),
    dataIndex: "signers",
    key: "signers",
    render: (value: unknown, record: SignaturePackage) => <ResultSigners signers={record.signers} />,
  };
}
