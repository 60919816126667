import { injectTranslations } from "ia-react-core";
import { FC } from "react";
import { compose } from "redux";

import { Language } from "../../common/enum/Language";
import SignaturePackage, { SignaturePackageProps } from "./SignaturePackage";
import en from "./translations/en";
import fr from "./translations/fr";

export default compose<FC<SignaturePackageProps>>(
  injectTranslations("SignaturePackage", [
    { language: Language.French, resource: fr },
    { language: Language.English, resource: en },
  ]),
)(SignaturePackage);
