import { User } from "../interfaces/User";

export enum UserActionTypes {
  GET_USER = "[User] Get User",
  GET_USER_SUCCESS = "[User] Get User Success",
  GET_USER_ERROR = "[User] Get User Error",
  SET_USER = "[User] Set User Information",
}

export interface GetUser {
  type: UserActionTypes.GET_USER;
}

export interface GetUserSuccess {
  type: UserActionTypes.GET_USER_SUCCESS;
  user: User;
}

export interface GetUserError {
  type: UserActionTypes.GET_USER_ERROR;
}

export interface SetUser {
  type: UserActionTypes.SET_USER;
  repCodes: string[];
  isSupport: boolean;
}

export type UserAction = GetUser | GetUserSuccess | GetUserError | SetUser;
