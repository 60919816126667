import { RenderExpandIconProps } from "rc-table/lib/interface";
import * as React from "react";

import { ArrowDown, ArrowUp } from "../svg";
import { ExpandIcon } from "./StyledTable.style";

export function expandIcon<RecordType>({ expanded, onExpand, record }: RenderExpandIconProps<RecordType>) {
  const arrow = expanded ? ArrowUp() : ArrowDown();
  return <ExpandIcon onClick={(event) => onExpand(record, event)}>{arrow}</ExpandIcon>;
}
