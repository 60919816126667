import { ColumnType } from "antd/lib/table";
import * as React from "react";

import { Client } from "../../../../../../common/client/interfaces/Client";
import { JointAccountHoldersTableProps } from "../JointAccountHoldersTable";

export function ClientIdsColumn(props: JointAccountHoldersTableProps): ColumnType<Client> {
  const { t } = props;  
  return {
    title: t("App:client.clientId"),
    width: "25%",
    render: (value: unknown, record: Client) => (
      <>
        {record.accounts.map((a) => a.id).join(", ")}          
      </>
    ),
  };
}
