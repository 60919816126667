export default {
  title: "Tableau de bord",
  clientSearch: {
    title: "Recherche Client",
    placeholder: "Rechercher par nom ou client ID",
    seeAll: "Voir tous les clients",
    goToClientProfile: "Accéder au profil client",
    autoComplete: {
      searchInProgress: "Recherche en cours",
      emptyResult: "Aucun résultat",
    },
  },
};
