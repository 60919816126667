import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    span {
        margin-bottom: 0px;
    }
`;

export const RightButton = styled.div`
    span {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }
`;

export const LeftButton = styled.div`
    span {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
`;
