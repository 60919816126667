import { API_CLAIMS } from "../../constants/API";
import { HttpGet } from "../utils/HttpClient";
import { User } from "./interfaces/User";

class UserService {
  public async getUser(): Promise<User> {
    const response = await HttpGet(`${API_CLAIMS}`, {});
    const customClaims = response.data;
    let repCodes = [];
    let roles = [];

    repCodes = customClaims && customClaims.fw_rep_codes ? JSON.parse(customClaims.fw_rep_codes) : response.fw_rep_codes ? response.fw_rep_codes : [];
    roles = customClaims && customClaims.fw_roles ? JSON.parse(customClaims.fw_roles) : response.fw_roles ? response.fw_roles : [];

    const user: User = {
      repCodes,
      roles,
    };

    return user;
  }
}

export default UserService;
