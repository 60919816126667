import styled from "styled-components";

export const Checkbox = styled.div<{ checked: boolean; hasIcon?: boolean }>`
  background-color: ${(props) => (props.checked ? props.theme.colors.blue12 : props.theme.colors.gray1)};
  white-space: pre-line;
  text-align: center;
  border: ${(props) => (props.checked ? "2px solid" : "1px solid")};
  border-color: ${(props) => (props.checked ? props.theme.colors.blue5 : props.theme.colors.blue6)};
  display: inline-block;
  margin: ${(props) => (props.checked ? "7px 6px 0 6px" : "7px 7px 0 7px")};
  padding: ${(props) => (props.checked ? "6px 16px 8px" : "7px 16px 9px")};
  cursor: pointer;
  height: 40px;
  border-radius: 4px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  flex: 0 0 auto;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .firstChild {
    margin-left: ${(props) => (props.checked ? "0" : "1px")} !important;
  }

  input {
    display: none;
  }

  label {
    cursor: pointer !important;
    margin-bottom: 0 !important;
    width: 100% !important;

    span {
      color: ${(props) => (props.checked ? props.theme.colors.blue5 : props.theme.colors.blue6)};
      font-size: 14px;
      font-family: ${(props) => props.theme.fontFamily.bold};
      font-weight: 700;
    }
  }

  &:hover {
    background-color: ${(props) => (props.checked ? props.theme.colors.blue5 : props.theme.colors.blue6)};
    border-color: ${(props) => (props.checked ? props.theme.colors.blue5 : props.theme.colors.blue6)};

    label {
      span {
        color: ${(props) => (props.checked ? props.theme.colors.blue12 : props.theme.colors.gray1)};
      }
    }
  }

  &:active {
    border-color: ${(props) => props.theme.colors.gray1};
  }
`;

export const Icon = styled.span`
  margin-right: 2px;
  svg {
    vertical-align: text-bottom;
    width: 15px;
  }
`;
