import { Reducer } from "redux";

import { ClientState, initialClientState } from "../../../../interfaces/ClientState";
import { JointAccountHolderActionTypes as types, JointAccountHoldersAction } from "./jointAccountHolders.actions.types";

/* eslint-disable no-restricted-syntax */
const JointAccountHoldersReducer: Reducer<ClientState, JointAccountHoldersAction> = (state = initialClientState, action): ClientState => {
  switch (action.type) {
    case types.GET_JOINT_ACCOUNT_HOLDERS_BY_ACCOUNTS_SUCCESS:
      return {
        ...state,
        jointAccountHolders: action.response,
      };
    case types.GET_JOINT_ACCOUNT_HOLDERS_BY_ACCOUNTS_ERROR:
      return {
        ...state,
        jointAccountHolders: [],
      };
    default:
      return state;
  }
};

export default JointAccountHoldersReducer;
