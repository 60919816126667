import { TFunction } from "i18next";
import * as React from "react";

import { Advisor } from "../../../../common/advisor/interfaces/Advisor";
import { Separator } from "../../../../common/popover/StyledPopover.style";
import AdvisorCard from "./components/advisor/AdvisorCard";
import BranchCard from "./components/advisor/branchCard";
import NationalBranchCard from "./components/advisor/nationalBranchCard";
import { AdvisorsSection, Container, InformationsContainer, Title } from "./components/styles/SearchResult.style";

export interface SearchResultProps {
  t: TFunction;
  repCode: string;
  advisors: Advisor[];
  branch: Advisor;
  team: Advisor[];
  locale: string;
}

const advisorsCard = (advisors: Advisor[], t: TFunction): JSX.Element[] => {
  const displayNationalBranchCard = advisors.some((advisor: Advisor) => advisor.isNationalBranch);
  const advisorsToDisplay = advisors.filter((advisor: Advisor) => !advisor.isNationalBranch);

  const cards = advisorsToDisplay.map((advisor: Advisor) => <AdvisorCard key={advisor.id} advisor={advisor} t={t} />);

  if (displayNationalBranchCard) {
    const nationalBranch = advisors.filter((advisor: Advisor) => advisor.isNationalBranch)[0];
    cards.unshift(<NationalBranchCard key={0} t={t} nationalBranch={nationalBranch} />);
  }

  return cards;
};

const SearchResult: React.FC<SearchResultProps> = ({ t, repCode, advisors, branch, team, locale }: SearchResultProps) => (
  <Container>
    <Title>{`${t("App:repCode")}: ${repCode}`}</Title>
    <InformationsContainer>
      <AdvisorsSection>{advisorsCard(advisors, t)}</AdvisorsSection>
      <Separator />
      <BranchCard branch={branch} t={t} team={team} locale={locale} />
    </InformationsContainer>
  </Container>
);

export default SearchResult;
