import { ColumnType } from "antd/lib/table";
import { InjectTranslationsProps } from "ia-react-core";
import moment from "moment";
import MessageDirection from "../../../../../../../common/messages/enums/MessageDirection";
import { Attachment } from "../../../../../../../common/messages/interfaces/Attachment";

export const DateColumn = ({ t }: InjectTranslationsProps, type: string): ColumnType<Attachment> => ({
  title: t("documentsTab.date"),
  dataIndex: type === MessageDirection.received ? "createdDateTime" : "reshareChangeDateTime",
  width: "10%",
  key: type === MessageDirection.received ? "createdDateTime" : "reshareChangeDateTime",
  render: (value: Date) => (
    moment(value).format("YYYY/MM/DD")
  ),
});
