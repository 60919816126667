import { Advisor } from '../../../../../../common/advisor/interfaces/Advisor';
import { Phone } from '../../../../../../common/interfaces/Phone';

export const getPrimaryEmail = (teamMember: Advisor, useDefaultEmail = false): string => {
  if (teamMember.emails === null || teamMember.emails === undefined || teamMember.emails.length === 0) {
    return '';
  }
  const primaryEmail = teamMember.emails.filter((e) => e.primary);
  if (primaryEmail.length === 0) {
    return useDefaultEmail ? teamMember.emails[0].emailAddress : '';
  }
  return primaryEmail[0].emailAddress;
};

export const getPrimaryPhone = (phones: Phone[]) => {
  const primaryPhone = phones.filter((p) => p.primary === true);
  return primaryPhone.length > 0 ? primaryPhone[0].phoneNumber : null;
};
