import { ColumnType } from "antd/lib/table";
import React from "react";
import { EyeFilled, EyeInvisibleFilled } from '@ant-design/icons';
import { Attachment } from "../../../../../../../common/messages/interfaces/Attachment";

export const ReadColumn = (): ColumnType<Attachment> => ({
  title: "",
  dataIndex: "isRead",
  width: "5%",
  key: "status",
  render: (value: string) => value ? <EyeFilled style={{ fontSize: '17px' }} /> : <EyeInvisibleFilled style={{ fontSize: '17px' }} />,
});
