import {
  FileDocument,
  FilePowerPoint,
  FileExcel,
  FileWord,
  FileImage,
  FilePdf,
} from "../../svg";

export default class MessagesHelper {
  static getFileIconByExtension(fileName: string) {
    const fileExtension = fileName.split(".").pop();
    if (fileExtension) {
      const fileExtensionToLowerCase = fileExtension.toLowerCase();
      switch (fileExtensionToLowerCase) {
        case "doc":
        case "docx":
          return FileWord();
        case "pdf":
          return FilePdf();
        case "ppt":
        case "pptx":
          return FilePowerPoint();
        case "xls":
        case "xlsx":
          return FileExcel();
        case "jpg":
        case "jpeg":
        case "png":
        case "bmp":
        case "gif":
        case "tif":
        case "tiff":
        case "heif":
        case "heic":
          return FileImage();
        default:
          return FileDocument();
      }
    }
    return FileDocument();
  }
}
