enum SignerStatus {
    pendingactivation = "pendingactivation",
    signingpending = "signingpending",
    signingcomplete = "signingcomplete",
    refused = "refused",
    expired = "expired",
    canceled = "canceled",
    locked = "locked",
    emailrebound = "emailrebound",
}

export default SignerStatus;
