export default {
  clientSearch: {
    title: "Client Search",
    visitAx360: "To view or change a client’s document delivery preferences, please visit ",
    Ax360Link: "AX360.",
  },
  createUser: {
    title: "Create Custom User",
    createUser: "Create Custom User",
    firstName: "First Name",
    lastName: "Last Name",
    language: "Language",
    contact: "Contact Information",
    phone: "Phone Number",
    email: "Email",
    address: "Address",
    number: "Number",
    street: "Street",
    apt: "Apartment",
    city: "City",
    province: "Province/State",
    country: "Country/Region",
    postal: "Postal Code",
    review: "Review",
    create: "Create",
    add: "Add a Client ID",
    french: "French",
    english: "English",
    ClientIds: "Client IDs",
    role: "Role",
    CO: "Corporate Owner",
    ThirdParty: "Third Party",
    PersonalInformation: "Personal Information",
    required: "The highlighted fields are required.",
    invalid: "The information you entered is invalid.",
    saved: "Saved successfully",
    edit: "Edit",
    incorrectEntry: "*Incorrect Entry",
    ensureClientIdIsCorrect: "Please ensure the client id is entered correctly.",
    warningEnsureClientIdExist: "To avoid duplication, please conduct a search to ensure the user does not already exist.",
    customUserTip: "A custom user is used when you need to create a non-client user access (i.e. corporation or third-party access).",
    warning: "Warning!",
    ID: "ID",
    update: "Update",
    countrySection: {
      selectCountry: "-- Select a country --",
      canada: "Canada",
      usa: "United States",
    },
    stateSection: {
      AB: "Alberta",
      BC: "British Columbia",
      MB: "Manitoba",
      NB: "New Brunswick",
      NL: "Newfoundland and Labrador",
      NS: "Nova Scotia",
      ON: "Ontario",
      PE: "Prince Edward Island",
      QC: "Quebec",
      SK: "Saskatchewan",
      NT: "Northwest Territories",
      NU: "Nunavut",
      YT: "Yukon",
      AL: "Alabama",
      AK: "Alaska",
      AS: "American Samoa",
      AZ: "Arizona",
      AR: "Arkansas",
      CA: "California",
      CO: "Colorado",
      CT: "Connecticut",
      DE: "Delaware",
      DC: "District of Columbia",
      FL: "Florida",
      GA: "Georgia",
      GU: "Guam",
      HI: "Hawaii",
      ID: "Idaho",
      IL: "Illinois",
      IN: "Indiana",
      IA: "Iowa",
      KS: "Kansas",
      KY: "Kentucky",
      LA: "Louisiana",
      ME: "Maine",
      MD: "Maryland",
      MA: "Massachusetts",
      MI: "Michigan",
      MN: "Minnesota",
      MS: "Mississippi",
      MO: "Missouri",
      MT: "Montana",
      NE: "Nebraska",
      NV: "Nevada",
      NH: "New Hampshire",
      NJ: "New Jersey",
      NM: "New Mexico",
      NY: "New York",
      NC: "North Carolina",
      ND: "North Dakota",
      MP: "Northern Mariana Islands",
      OH: "Ohio",
      OK: "Oklahoma",
      OR: "Oregon",
      PA: "Pennsylvania",
      PR: "Puerto Rico",
      RI: "Rhode Island",
      SC: "South Carolina",
      SD: "South Dakota",
      TN: "Tennessee",
      TX: "Texas",
      UM: "United States Minor Outlying Islands",
      UT: "Utah",
      VT: "Vermont",
      VA: "Virginia",
      VI: "Virgin Islands, U.S.",
      WA: "Washington",
      WV: "West Virginia",
      WI: "Wisconsin",
      WY: "Wyoming",
    },
    thirdPartyClientProvidedCopy: "My client has provided me a signed copy of the Third Party Access Form and a copy has been sent to New Accounts.",
  },
  client: {
    clientId: "Client ID",
    personalizedAccessCode: "Username",
    noPersonalizedAccessCode: "Client not registered",
  },
  dateOfBirth: "Date of birth",
  contactInformations: {
    address: "Address",
    email: "Email",
    website: "Website",
    phone: "Phone",
  },
  emailType: {
    main: "Main",
    office: "Office",
  },
  gender: "Gender",
  genderOptions: {
    M: "Man",
    F: "Woman",
    I: "Unspecified",
  },
  language: "Language",
  languageOptions: {
    fr: "French",
    en: "English",
  },
  loadMore: "Show more",
  mirrorView: "Mirror view",
  name: "Name",
  phoneType: {
    principal: "Main",
    cellphone: "Cellphone",
    home: "Home",
    office: "Office",
  },
  sin: "SIN",
  title: "Advisor Centre",
  advisor: "Advisor",
  accounts: "Accounts",
  tableLocale: {
    searchInProgress: "Search in progress",
    emptyText: "No data",
  },
  clickToView: {
    show: "Show",
    hide: "Hide",
  },
  show: "Show",
  repCode: "Representative Code",
  choose: "Choose",
  nonApplicable: "N/A",
  investmentObjective: {
    income: "Income",
    growth: "Growth",
    speculative: "Speculative",
    liquidity: "Liquidity",
    safety: "Safety",
    unknown: "Unknown",
  },
  riskTolerance: {
    low: "Low",
    medium: "Medium",
    mediumHigh: "Medium-High",
    high: "High",
    unknown: "Unknown",
  },
  timeHorizon: {
    unknown: "Unknown",
    years3AndLess: "under 3 years",
    years3To5: "3-5 years",
    years6To10: "6-10 years",
    years11To20: "11-20 years",
    years20AndOver: "20 years or more ",
  },
  shortDateFormat: "MMM DD, YYYY",
  status: "Status",
  actions: "Actions",
};
