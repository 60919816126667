/* eslint-disable no-empty */
import { ColumnType } from "antd/lib/table";
import { TableLocale } from "antd/lib/table/interface";
import { saveAs } from "file-saver";
import { InjectTranslationsProps } from "ia-react-core";
import React, { useCallback, useEffect, useState } from "react";
import DocumentsFilterValues from "../../../../../../common/enum/DocumentsFilterValues";
import { ResponseError } from "../../../../../../common/interfaces/ResponsePayload";
import MessageDirection from "../../../../../../common/messages/enums/MessageDirection";
import { Attachment } from "../../../../../../common/messages/interfaces/Attachment";
import ResponseErrorStatus from "../../../../../../common/messages/interfaces/ResponseErrorStatus";
import ResponseSuccessStatus from "../../../../../../common/messages/interfaces/ResponseSuccessStatus";
import MessagesService from "../../../../../../common/messages/messages.service";
import StyledTable from "../../../../../../common/table/StyledTable";
import { DateColumn, FileColumn, FromColumn } from "./DocumentsColumn";
import { ActionColumn } from "./DocumentsColumn/ActionColumn";
import { ReadColumn } from "./DocumentsColumn/ReadColumn";
import { StatusColumn } from "./DocumentsColumn/StatusColumn";
import DocumentsFilter from "./DocumentsFilter/DocumentsFilter";
import { DocumentsTableContainer } from "./DocumentsTable.style";

export interface DocumentsTableProps {
  attachments: Attachment[];
  loading: boolean;
  type: string;
  onError: (error: ResponseError) => void;
  onSuccess: (successStatus: ResponseSuccessStatus) => void;
  partyId: string;
  userCanSendDocuments: boolean;
}

const tableColumns = (props: InjectTranslationsProps,
  onLinkClicked: (id: string, fileName: string) => Promise<void>,
  onShareToggleClicked: (record: Attachment, isResharing: boolean, reason: string) => Promise<void>,
  type: string, userCanSendDocuments: boolean): ColumnType<Attachment>[] => {
  const columns: ColumnType<Attachment>[] =
    type === MessageDirection.received
      ? [DateColumn(props, type), FileColumn(props, onLinkClicked), FromColumn(props)]
      : [DateColumn(props, type), FileColumn(props, onLinkClicked), ReadColumn(), StatusColumn(props), FromColumn(props), ActionColumn(props, onLinkClicked, onShareToggleClicked, userCanSendDocuments)];
  return columns;
};

/* eslint-disable no-console */
interface DocumentsTableComposedProps extends DocumentsTableProps, InjectTranslationsProps {}
const DocumentsTable: React.FC<DocumentsTableComposedProps> = (props: DocumentsTableComposedProps) => {
  const { t, attachments, loading, onError, type, partyId, onSuccess, userCanSendDocuments } = props;
  const [displayedAttachments, setDisplayedAttachments] = useState<Attachment[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>(['']);

  const onFilterAttachments = useCallback((selectedValues: string[]): void => {
    setSelectedTags(selectedValues);
    const conditionForRead = selectedValues.includes(DocumentsFilterValues.Read);
    const conditionForUnread = selectedValues.includes(DocumentsFilterValues.Unread);
    const conditionForShared = selectedValues.includes(DocumentsFilterValues.Shared);
    const conditionForUnshared = selectedValues.includes(DocumentsFilterValues.Unshared);

    if ((selectedValues.length === 1 && selectedValues[0] === "")
    || (selectedValues.length === 3 && conditionForRead && conditionForUnread)
    || (selectedValues.length === 3 && conditionForShared && conditionForUnshared)
    || (selectedValues.length === 5)) {
      setDisplayedAttachments(attachments);
    } else {
      let state: Attachment[] = [];
      if (selectedValues.length === 4) {
        if (conditionForShared && conditionForUnshared) {
          state = attachments.filter((a) => a.isRead === (conditionForRead || !conditionForUnread));
        } else if (conditionForRead && conditionForUnread) {
          state = attachments.filter((a) => a.isUnshared === (!conditionForShared || conditionForUnshared));
        }
      } else if (selectedValues.length === 3) {
        state = attachments.filter((a) => a.isRead === (conditionForRead || !conditionForUnread) && a.isUnshared === (!conditionForShared || conditionForUnshared));
      } else if (selectedValues.length === 2) {
        if (conditionForRead || conditionForUnread) {
          state = attachments.filter((a) => a.isRead === (conditionForRead || !conditionForUnread));
        } else if (conditionForShared || conditionForUnshared) {
          state = attachments.filter((a) => a.isUnshared === (!conditionForShared || conditionForUnshared));
        }
      }
      setDisplayedAttachments(state);
    }
  }, [attachments]);

  useEffect(() => {
    setDisplayedAttachments(attachments);
    onFilterAttachments(selectedTags);
  }, [attachments, onFilterAttachments, selectedTags]);

  const locale: TableLocale = {
    emptyText: t("documentsTab.noDocument"),
  };

  const attachmentIsLoading = (attachmentId: string, attachmentLoading: boolean) => {
    const elementIndex = attachments.findIndex((a) => a.id === attachmentId);
    if (elementIndex !== -1) {
      const stateCopy = { ...attachments };
      const attachment = stateCopy[elementIndex];
      attachment.isDownloading = attachmentLoading;
      const state = displayedAttachments.filter((a) => a.id !== attachmentId);
      state.splice(elementIndex, 0, attachment);
      setDisplayedAttachments(state);
    }
  };

  const downloadAttachment = async (id: string, fileName: string): Promise<void> => {
    const service = new MessagesService();
    try {
      attachmentIsLoading(id, true);
      const data: Blob = await service.downloadAttachment(id);
      saveAs(data, fileName);
    } catch (error) {
      onError({
        status: ResponseErrorStatus.DocumentLoadFailed,
        detail: error as string,
      });
    }
    attachmentIsLoading(id, false);
  };

  const attachmentIsResharingChangeStatus = (attachmentId: string, attachmentResharing: boolean, isResharing: boolean) => {
    const elementIndex = attachments.findIndex((a) => a.id === attachmentId);
    if (elementIndex !== -1) {
      const stateCopy = { ...attachments };
      const attachment = stateCopy[elementIndex];
      if (isResharing) {
        attachment.isResharing = attachmentResharing;
      } else { attachment.isUnsharing = attachmentResharing; }
      const state = displayedAttachments.filter((a) => a.id !== attachmentId);
      state.splice(elementIndex, 0, attachment);
      setDisplayedAttachments(state);
    }
  };

  const shareToggleAttachment = async (record: Attachment, isResharing: boolean, reason: string): Promise<void> => {
    const service = new MessagesService();
    try {
      attachmentIsResharingChangeStatus(record.id, true, isResharing);
      const result = await service.toggleDocShare(record.documentId, partyId, reason);
      if (result.error) {
        onError(result.error);
      } else {
        onSuccess(isResharing ? ResponseSuccessStatus.Reshared : ResponseSuccessStatus.Unshared);
      }
      updateAttachementStatus(record.id, !isResharing);
    } catch (error) {
      onError({
        status: isResharing ? ResponseErrorStatus.DocumentReshareFailed : ResponseErrorStatus.DocumentUnshareFailed,
        detail: error as string,
      });
    }
    attachmentIsResharingChangeStatus(record.id, false, isResharing);
  };

  const updateAttachementStatus = (attachmentId: string, status: boolean) => {
    const elementIndex = attachments.findIndex((a) => a.id === attachmentId);
    if (elementIndex !== -1) {
      const stateCopy = { ...attachments };
      const attachment = stateCopy[elementIndex];
      attachment.isUnshared = status;
      const state = attachments.filter((a) => a.id !== attachmentId);
      state.splice(elementIndex, 0, attachment);
      setDisplayedAttachments(state);
    }
  };
  return (
    <>
      <DocumentsTableContainer>
        { type === MessageDirection.sent && <DocumentsFilter onFilterChange={onFilterAttachments} type={type} t={t} />}
        <StyledTable t={t} columns={tableColumns(props, downloadAttachment, shareToggleAttachment, type, userCanSendDocuments)} dataSource={displayedAttachments} rowKey="id" pagination={false} loading={loading} locale={locale} />
      </DocumentsTableContainer>
    </>
  );
};

export default DocumentsTable;
