/* eslint-disable @typescript-eslint/unbound-method */
import { apply, Effect, put, takeLatest } from "@redux-saga/core/effects";

import { setError } from "../../../../redux/app.actions";
import Section from "../../../enum/Section";
import ClientService from "../../client.service";
import { Client } from "../../interfaces/Client";
import {
  getJointAccountHoldersByAccountsError,
  getJointAccountHoldersByAccountsSuccess,
} from "./jointAccountHolders.actions";
import {
  GetJointAccountHoldersByAccounts,
  JointAccountHolderActionTypes as types,
} from "./jointAccountHolders.actions.types";

function* handleGetJointAccountHoldersByAccounts({ accounts, clientId }: GetJointAccountHoldersByAccounts) {
  try {
    const service = new ClientService();
    const response: Client[] = yield apply(service, service.getJointAccountHolders, [accounts, clientId]);
    yield put(getJointAccountHoldersByAccountsSuccess(response));
  } catch (error) {
    yield put(getJointAccountHoldersByAccountsError(accounts, clientId));
    yield put(setError({ section: Section.jointAccountHolders, error }));
  }
}

export default function* JointAccoundHolderSaga(): IterableIterator<Effect> {
  yield takeLatest(types.GET_JOINT_ACCOUNT_HOLDERS_BY_ACCOUNTS, handleGetJointAccountHoldersByAccounts);
}
