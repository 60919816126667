import { get, getScoped, injectReducer, injectSaga, injectTranslations } from "ia-react-core";
import { FC } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Client } from "../../common/client/interfaces/Client";
import { getClient } from "../../common/client/redux/client.actions";
import { selectHasOwnedAccounts, selectedClientLocked } from "../../common/client/redux/client.selector";
import { Language } from "../../common/enum/Language";
import { initialClientState } from "../../interfaces/ClientState";
import { State } from "../../interfaces/State";
import ClientProfile, { ClientProfileDispatchToProps, ClientProfileProps, ClientProfileStateToProps } from "./ClientProfile";
import { selectAccountsGroupByRepCodes } from "./redux/AccountsByAdvisorsGroupedByRepCodes.selector";
import { selectAccountsGroupByBankAccounts } from "./redux/AccountsGroupedByBankAccounts.selector";
import { selectAccountsGroupByOwner } from "./redux/AccountsGroupedByOwner.selector";
import ClientProfileReducer from "./redux/clientProfile.reducer";
import ClientProfileSaga from "./redux/clientProfile.saga";
import en from "./translations/en";
import fr from "./translations/fr";

const mapDispatchToProps: ClientProfileDispatchToProps = {
  getClient,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: State, { scope }: any): ClientProfileStateToProps => ({
  accountsByAdvisorsGroupedByRepCodes: selectAccountsGroupByRepCodes(state, scope),
  accountsGroupedByBankAccounts: selectAccountsGroupByBankAccounts(state, scope),
  accountsGroupedByOwner: selectAccountsGroupByOwner(state, scope),
  client: (getScoped(state, "selectedClient", scope) as unknown) as Client,
  clientHasOwnedAccounts: selectHasOwnedAccounts(state, scope),
  jointAccountHolders: (getScoped(state, "jointAccountHolders", scope) as unknown) as Client[],
  loading: (getScoped(state, "loading", scope) as unknown) as boolean,
  loadingAccounts: (getScoped(state, "loadingAccounts", scope) as unknown) as boolean,
  locale: (get(state, "language.locale") as unknown) as string,
  repCodes: get(state, "App.repCodes") as string[],
  selectedClientLocked: (getScoped(state, "isLocked", scope) as unknown) as boolean,
});

export default compose<FC<ClientProfileProps>>(
  injectTranslations("ClientProfile", [
    { language: Language.French, resource: fr },
    { language: Language.English, resource: en },
  ]),
  injectSaga({
    key: "ClientProfile",
    saga: ClientProfileSaga,
  }),
  injectReducer({
    key: "ClientProfile",
    initialState: initialClientState,
    reducer: ClientProfileReducer,
    useScope: true,
    cleanState: true,
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(ClientProfile);
