import en from "faker/locale/en";
import fr from "faker/locale/fr";
import { get, injectTranslations, withScope } from "ia-react-core";
import { FC } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { selectHasOwnedOpenAccounts } from "../../../../../common/client/redux/client.selector";
import { Language } from "../../../../../common/enum/Language";
import { State } from "../../../../../interfaces/State";
import { selectRequiredInformationLoaded } from "../../../redux/documentTab.selector";
import DocumentsTab, { DocumentsTabProps, DocumentsTabStateToProps } from "./DocumentsTab";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: State, { scope }: any): DocumentsTabStateToProps => ({
  partyId: get(state, "App.ClientProfile.selectedClient.id") as unknown as string,
  hasOwnedOpenAccounts: selectHasOwnedOpenAccounts(state, scope),
  requiredInformationLoaded: selectRequiredInformationLoaded(state, scope),
  userIsAdmin: get(state, "App.isSupport"),
});
  
export default compose<FC<DocumentsTabProps>>(
  injectTranslations("ClientProfile", [
    { language: Language.French, resource: fr },
    { language: Language.English, resource: en },
  ]),
  withScope,
  connect(mapStateToProps),
)(DocumentsTab);
