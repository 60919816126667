import { TFunction } from "i18next";
import * as React from "react";

import { SignerStatus } from "../../../../../../../../common/signaturePackage/enum";
import { SignaturePackage } from "../../../../../../../../common/signaturePackage/interfaces/SignaturePackage";
import SignaturePackageHelper from "../../../../../../../../common/signaturePackage/utils/SignaturePackageHelper";
import { Warning } from "../../../../../../../../common/svg";
import { Icon, Information, StatusContainer } from "../../../Result.style";

interface ResultColumnStatusAwaitingProps {
  signaturePackage: SignaturePackage;
  t: TFunction;
}

const ResultStatusAwaiting: React.FC<ResultColumnStatusAwaitingProps> = ({ signaturePackage, t }: ResultColumnStatusAwaitingProps) => {
  let status = t("SignaturePackage:status.awaiting");
  const signerAwaiting = SignaturePackageHelper.getAwaitingSigner(signaturePackage);
  if (signerAwaiting) {
    if (signerAwaiting.status === SignerStatus.emailrebound) {
      status = t("SignaturePackage:status.emailrebound");
    } else if (signerAwaiting.status === SignerStatus.locked) {
      status = t("SignaturePackage:status.locked");
    }
  }
  return (
    <StatusContainer>
      <Icon>{Warning(20, "#E71313")}</Icon>
      <Information>
        <span>{status}</span>
        {signerAwaiting && <span>{signerAwaiting.fullName}</span>}
      </Information>
    </StatusContainer>
  );
};

export default ResultStatusAwaiting;
