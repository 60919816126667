import { ColumnType } from "antd/lib/table";
import * as React from "react";

import StringValue from "../../../../../../../common/utils/Value";
import { AccountGroupedByBankAccount } from "../../../../../interfaces/AccountGroupedByBankAccount";
import { BankAccountsTableProps } from "../BankAccountsTable";

export function InstitutionNameColumn(props: BankAccountsTableProps): ColumnType<AccountGroupedByBankAccount> {
  const { t } = props;

  return {
    title: t("profileTab.bankAccountsInformation.institutionName"),
    dataIndex: "institutionName",
    key: "institutionName",
    render: (value: string, record: AccountGroupedByBankAccount) => (
      <>
        <StringValue>{record.institutionName}</StringValue> ({record.institutionNumber})
      </>
    ),
  };
}
