import { TFunction } from "i18next";
import { Form, FormCol, FormRow } from "ia-react-core";
import * as React from "react";
import { Link } from "react-router-dom";

import { Client } from "../../../../common/client/interfaces/Client";
import { ArrowRight, ClientProfileIcon } from "../../../../common/svg";
import AutoCompleteDataSource from "../AutoComplete/datasource";
import AutoCompleteControl from "../AutoCompleteControl/AutoCompleteControl";
import { FormColWithoutMargin, RouterIcon, SeeAllClient } from "./ClientSearch.style";

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface ClientSearchProps {
  t: TFunction;
  onSearch: (criteria: string) => void;
  loading: boolean;
  autoCompleteDataSource: AutoCompleteDataSource<Client>;
}

interface LocalState {
  selectedValue?: Client;
  clientSelected: boolean;
}

class ClientSearch extends React.PureComponent<ClientSearchProps, LocalState> {
  constructor(props: ClientSearchProps) {
    super(props);
    this.state = {
      clientSelected: false,
    };
  }

  public render() {
    const { t, loading, onSearch, autoCompleteDataSource } = this.props;
    const { clientSelected, selectedValue } = this.state;
    return (
      <Form defaultCol={4} name="clientSearchAutoComplete">
        <FormRow>
          <FormCol>
            <AutoCompleteControl
              fetchAction={onSearch}
              name="clientSearchAutoComplete"
              loading={loading}
              onSelected={(value: any) => {
                this.setState({ selectedValue: value, clientSelected: value != null });
              }}
              dataSource={autoCompleteDataSource}
              texts={{ searchInProgress: t("clientSearch.autoComplete.searchInProgress"), emptyResult: t("clientSearch.autoComplete.emptyResult"), placeholder: t("clientSearch.placeholder") }}
            />
          </FormCol>
        </FormRow>
        <FormRow>
          <FormCol>
            <RouterIcon isActive={clientSelected} title={t("clientSearch.goToClientProfile")}>
              <Link to={clientSelected && selectedValue ? `/clientProfile/${selectedValue.id}` : "#"}>
                {ClientProfileIcon()}
              </Link>
            </RouterIcon>
          </FormCol>
          <FormColWithoutMargin>
            <SeeAllClient>
              <Link to="/clients">
                {t("clientSearch.seeAll")}
                {ArrowRight()}
              </Link>
            </SeeAllClient>
          </FormColWithoutMargin>
        </FormRow>
      </Form>
    );
  }
}

export default ClientSearch;
