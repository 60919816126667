import { createSelector } from "reselect";
import { v4 as uuid } from "uuid";

import { Account } from "../../../common/account/interfaces/Account";
import { selectAccounts } from "../../../common/account/redux/account.selector";
import { AccountGroupedByOwner } from "../interfaces/AccountGroupedByOwner";

export const selectAccountsGroupByOwner = createSelector(selectAccounts, (accounts: Account[]): AccountGroupedByOwner[] => {
  let accountsByOwner: AccountGroupedByOwner[] = [];

  if (!accounts || accounts.length === 0) {
    return accountsByOwner;
  }

  const names = accounts.map((a) => a.name);
  const uniqueNames = names.filter((n, i) => names.indexOf(n) === i);

  accountsByOwner = uniqueNames.map((n) => {
    const asf: AccountGroupedByOwner = {
      owner: n,
      id: uuid(),
      accounts: accounts.filter((a) => a.name === n),
    };
    return asf;
  });

  return accountsByOwner;
});
