export default {
  title: "Profil Client",
  profileTab: {
    title: "Profil",
    personalInformation: {
      title: "Informations du client",
      employer: "Employeur",
      occupation: "Emploi",
      annualIncome: "Revenu annuel",
      netWorth: "Valeur nette",
      jointAccountHolder: "Titulaire de compte conjoint",
    },
    clientPortal: "Portail client",
    sendInvitation: "Envoyer une invitation",
    contactInformation: {
      title: "Informations de contact",
      Phone: "Téléphone",
    },
    advisorsInformation: {
      title: "Informations sur les représentants",
      representativeCode: "Code de représentant",
    },
    bankAccountsInformation: {
      title: "Comptes de banque",
      institutionName: "Institution",
      institutionNumber: "Numéro de l'institution",
      institutionBranch: "Numéro de la succursale",
      account: "Numéro de compte",
    },
  },
  investorTab: {
    title: "Profil de l'investisseur",
    knowledge: {
      title: "Connaissance des placements",
      level: {
        1: "Excellente",
        2: "Bonne",
        3: "Restreinte",
        4: "Faible / Nulle",
      },
    },
    objectives: {
      title: "Objectifs de placement, tolérance au risque et horizon de placement",
      accountTitle: "Compte",
      investmentObjectives: "Objectifs de placement",
      riskTolerance: "Tolérance au risque",
    },
    timeHorizon: {
      title: "Horizon de placement",
    },
  },
  documentsTab: {
    title: "Documents",
    file: "Fichier",
    date: "Date",
    noDocument: "Aucun document trouvé",
    documentSent: "Le ou les document(s) ont été partagés avec succès !",
    documentShared: "Le document a bien été partagé!",
    documentUnshared: "Le partage du document a bien été annulé!",
    errorMessage: "Une erreur est survenue. Veuillez réessayer plus tard.",
    virusScanDetectedAnAnomaly: "Nous n'avons pas pu compléter l'envoi de document(s). Un virus a été détecté dans le document \"{{0}}\"",
    errorMessageDocumentLoadFailed: "Une erreur s'est produite lors de la récupération du ou des documents. Veuillez réessayer plus tard.",
    errorMessageDocumentReshareFailed: "Une erreur s’est produite durant le nouveau partage du document. Veuillez réessayer plus tard.",
    errorMessageDocumentUnshareFailed: "Une erreur s’est produite durant l’annulation du partage du document. Veuillez réessayer plus tard.",
    NoEmailSent: "Les documents ont bien été partagés. Toutefois, pour des raisons techniques, nous n’avons pas été en mesure d’en aviser votre client par courriel.",
    sendADocument: "Envoyer un document",
    linkToDocumentList: "Retour",
    send: "Envoyer",
    fromName: "De",
    statusName: "Statut",
    actionName: "Actions",
    download: "Teléchargé",
    shared: "Partagé",
    unshared: "Non Partagé",
    shareAction: "Partager",
    unshareAction: "Annuler le partage",
    read: "Lu",
    unread: "Non Lu",
    clientNotRegistered: "Votre client n'est pas enregistré sur le portail client.",
    optionOnlyAvailableForRegisteredClient: "Cette option est seulement disponible pour les clients ayant complété leur inscription en ligne.",
    optionNotAvailableThirdParty: "La fonction de réinitialisation du mot de passe n’est pas encore disponible pour les utilisateurs spéciaux et les tiers. Veuillez communiquer avec l’équipe de soutien aux conseillers pour réinitialiser le mot de passe.",
    clientLocked: "La fonction de réinitialisation du mot de passe n’est pas accessible parce que ce compte est verrouillé. Veuillez communiquer avec l’équipe de soutien aux conseillers pour déverrouiller le compte et réinitialiser le mot de passe. ",
    fileTooLarge: "Le document n'a pas pu être téléverser. Le document dépasse la limite maximale permise de 20Mo.",
    unsupportedDocumentType: "Le document n'a pas pu être téléverser. Le format de fichier n'est pas autorisé.",
    sentbutton: "Envoyé",
    receivedbutton: "Reçu",
    maxFileSize: "Taille maximale autorisée: 20Mo",
    accountsMessage: "Veuillez sélectionner le compte relié à l'envoi du document. Si celui-ci s’applique à tous vos comptes clients vous pouvez sélectionner n'importe quel compte",
    documentsLabel: "Document(s)",
    messageNotAFileStorage: "La fonction d’échange de documents sécurisé vous permet, à vous et à vos clients, d’échanger des documents en toute sécurité, mais il ne s’agit pas d’une solution de stockage de fichiers. Nous vous recommandons fortement d’enregistrer les documents reçus dans les dossiers de vos clients.",
    sendDocumentConfirmationMessage: "Je reconnais que le document ou les documents ci-dessus sera ou seront transmis à {{0}} et que le client pourra y accéder à l’aide du portail client de iA Gestion privée de patrimoine inc., sous « Documents ». Le client recevra également un avis par courriel. Je confirme que je transmets ce document ou ces documents au bon client.",
    comingSoon: "À venir",
    unshareTitleModal: "Êtes-vous certain de vouloir annuler le partage de ce document?",
    unshareBodyModal: "Le document vas disparaître du Portail Client, mais sera toujours disponible pour téléchargement à partir de Portail Agent",
    unhsareCancelModal: "Pas maintenant",
    unhsareOkModal: "Confirmer",
    fileName: "Nom de fichier",
    details: "Détails",
    choose: "Choisi",
    unshareOption1: "Partage au mauvais client",
    unshareOption2: "Mauvaise date/période",
    unshareOption3: "Information n'est pas à jour",
    unshareOption4: "Document contient de l'information erroné",
    unshareOption5: "Autre",
  },
  closed: "fermé",
  logsTab: {
    title: "Logs",
    doneBy: "Par",
    details: "Détails",
    noData: "Pas de données à afficher",
    current: "Détails actuels",
    original: "Détails originaux",
    added: "Ajouté: ",
    removed: "Supprimé: ",
    accessShare: "Accès au compte accordé à",
    true: "Oui",
    false: "Non",
    consent: {
      marketing: "Expérience client distinctive changé à \"{{0}}\"",
      sollicitation: "Promotions changé à \"{{0}}\"",
    },
    affiliatesChanges: "Partage avec Entités affiliées: {{0}}",
    thirdPartyChanges: "Partage avec Fournisseurs de services tiers: {{0}}",
    True: "Oui",
    False: "No",
    consentUpdated: "Modifié sur {{0}}",
    affiliateConsentVerbiage: "Version de consentement d'affiliation: {{0}}",
    thirdPartyConsentVerbiage: "Version de consentement tiers: {{0}}",
    clientportalias: "Portail Client",
    mobileappiapw: "l'Application Mobile",
    cob: "COB",
    cpm: "Portail CMP",
    isElectronic: "Sans papier",
    isPaper: "Papier",
    statements: "Relevés de portefeuille et rapport annuel",
    managedStatement: "Revue trimestrielle du portefeuille",
    taxDocuments: "Feuillets fiscaux",
    tradeConfirmation: "Avis d'exécution",
    changedTo: " changée en ",
    clientId: " (Identifiant client: {{0}})",
    mailNotification: "Avis par courriel pour les nouveaux documents",
    mailNotificationYes: "OUI",
    mailNotificationNo: "NON",
    changedTo2: " – passe à ",
    providedAccess: "a reçu un accès de consultation pour",
    removedAccess: "s’est vu retirer l’accès de consultation pour",
    temporaryPasswordGenerated: "Mot de passe temporaire généré",
    unlockAccountMessage: "Compte déverrouillé",
    emailNotification: " l’option {{0}} est activée pour les notifications par courriel. ",
    and: " et",
    documentShared: "Documents partagés",
    clientHasSharedDocuments: "Le client a partagé des documents à son conseiller ({{0}}).",
    advisorHasSharedDocuments: "L'utilisateur ({{0}}) a partagé des documents à son client.",
  },
  settingsTab: {
    title: "Paramètres",
    householdbutton: "Comptes liés",
    performancebutton: "Rendement",
    sharedAccessbutton: "Partage d'accès",
    userManagementbutton: "Gestion d'utilisateurs",
  },
  userManagement: {
    accountActions: "Opérations sur le compte",
    generateTemporaryPassword: "Générer un mot de passe temporaire",
    newPasswordToTransmit: "Nouveau mot de passe à transmettre",
    iHaveVerifiedIdentity: "J'ai vérifié mon client",
    generate: "Générer",
    cancel: "Annuler",
    aboutToGenerateNewPasswordMessage: "Vous êtes sur le point de générer un nouveau mot de passe pour cette personne.",
    wantToContinue: "Voulez-vous continuer?",
    unlockAccount: "Déverrouiller le compte",
    accountLocked: "Compte verrouillé",
    clientLockedMessage: "Il n’est pas possible de générer un mot de passe temporaire, car ce compte est verrouillé en raison d’un trop grand nombre de tentatives pour entrer le mot de passe. Veuillez vérifier l’identité de votre client avant de déverrouiller le compte.",
    accountUnlockedSuccess: "Le compte a bien été déverrouillé.",
    accountUnlockTitleFailure: "Le déverrouillage a échoué",
    messageFailure: "Veuillez réessayer ou contacter l'équipe de support pour obtenir de l'assistance.",
    passwordTitleFailure: "Echec lors de la réinitialisation du mot de passe"
  },
  documentDelivery: {
    documents: "Documents",
    clientId: "Identifiant",
    name: "Nom",
    isPaperless: "Sans papier",
    emailNotificationConsent: "Consentement pour les notifications courriels",
    Statement: "Relevés de portefeuille et rapport annuel",
    ManagedStatement: "Revue trimestrielle du portefeuille",
    TaxDocument: "Feuillets fiscaux",
    TradeConfirmation: "Avis d'exécution",
    disabledTaxSlipsMsg: "Veuillez noter que la préférence de livraison de vos feuillets fiscaux ne peut être modifiée durant la saison fiscale s'étalant du 29 décembre au 30 avril.",
    apply: "Appliquer",
    reset: "Réinitialiser",
    savePreferencesConfirmation: "Vos modifications ont été enregistrées avec succès.",
    savePreferencesFailure: "Une erreur est survenue à l’enregistrement de vos préférences. Veuillez réessayer.",
    confirm: "Confirmation",
    confirmationMessageTitle: "Message de confirmation",
    errorMessageTitle: "Message d'erreur",
    error: "Erreur",
    disabledWarning: "Vous pouvez uniquement modifier les préférences de livraison des titulaires de compte. Ce paramètre est désactivé pour les utilisateurs partageant des accès.",
    paperlessNote: "Nous vous enverrons un avis par courriel lorsqu’un nouveau document sera accessible dans le portail clientInformation.",
  },
  accessSharing: {
    recipient: "Info du récipiendaire",
    clientIds: "Identifiants client",
    name: "Nom",
    username: "Nom d'utilisateur",
    clientId: "Identifiant client",
    confirmMsg: "Vous donnez accès à ces comptes:",
    msgEditError: "Client introuvable",
    accesToMyAccount: "Accès à vos identifiants client",
    deleteMember: "Retirer l'accès",
    deleteMemberConfirmation: "Voulez-vous vraiment supprimer l'accès?",
    select: "SELECTIONNER",
    add: "Ajouter",
    clientName: "Nom",
    noData: "Aucun récipiendaire",
    noOwnerAccountWarning: "Le partage d'accès est seulement disponible au propriétaire d'un compte.",
    corpAccountWarning: "Partagez le compte compagnie directement à travers le profile de la compagnie.",
    owner: "Propriétaire",
    thirdParty: "Tièrce partie",
    corporateOwner: "Propriétaire corporatif",
    next: "Suivant",
    confirm: "CONFIRMATION",
    previous: "Retour",
    search: "Rechercher",
    allAccounts: "Tout",
    error: "Erreur",
    subMenuAccessSharing: "Partage d'accès",
    thirdPartyClientProvidedCopy: "Mon client m'a remis une copie signée du formulaire d'accès d'un tiers et une copie a été envoyée à l'Équipe Nouveaux comptes",
  },
};
