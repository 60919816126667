import * as React from 'react';
import { TFunction } from 'i18next';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import ClientService from '../../../../../../common/client/client.service';
import UserManagementFooter from './UserManagementFooter';
import Dialog, { DialogAnswer } from '../../../../../../common/components/Dialog/Dialog';
import { ErrorMessage, Loader, SuccessMessage, WarningMessage } from 'ia-react-core';
import { Container, Header, Label, PasswordText } from './UserManagement.style';
import { InfoMessage } from 'ia-react-core';
import { REACT_APP_ALLOW_NONCLIENT_PASSWORD_RESET } from "../../../../../../constants/API";
import { Paragraphe } from '../../../../../../common/common.style';
import { GetIsStorageValueByKeyAndValue, SetStorageKeyValue } from '../../../../../../common/utils/sessionManager';
import { IParty, PartyTypeCode } from '../../../../../../common/client/interfaces/Party';

const clientService = new ClientService();

export interface UserManagementProps {
  client: IParty;
  isLocked: boolean;
  t: TFunction;
}

const UserManagement: React.FC<UserManagementProps> = ({ client, isLocked, t }: UserManagementProps): JSX.Element => {
  if (client.partyTypeCode === PartyTypeCode.NonClient && !REACT_APP_ALLOW_NONCLIENT_PASSWORD_RESET){
    return (
      <>
        <Header>{ t('userManagement.accountActions') }</Header>
        <InfoMessage>
          {t("documentsTab.optionNotAvailableThirdParty")}
        </InfoMessage>
      </>
    );
  }
  if (!client.dateRegistered) {
    return (
      <>
        <Header>{ t('userManagement.accountActions') }</Header>
        <InfoMessage>
          {t("documentsTab.clientNotRegistered")}
          <br />
          {t("documentsTab.optionOnlyAvailableForRegisteredClient")}
        </InfoMessage>
      </>
    );
  }
  const [ isIdentityVerifiedChecked, setIsIdentityVerifiedChecked] = React.useState(false);
  const [ newPassword, setNewPassword ] = React.useState('');
  const [ unlockAccountSuccess, setUnlockAccountSuccess ] = React.useState(false);
  const [ isProcessing, setIsProcessing ] = React.useState(false);
  const [ errorMessage, setErrorMessage ] = React.useState('');
  const [ errorTitleMessage, setErrorTitleMessage ] = React.useState('');
  const [ shouldShowDialog, setShowDialog ] = React.useState(false);
  const userUnlockOnSession = isLocked ? GetIsStorageValueByKeyAndValue('accountStatusUpdated', client.id) : false;
  const [ userStillLocked, setUserStillLocked ] = React.useState(isLocked && !userUnlockOnSession);

  const handleDialogResult = async (answer: DialogAnswer) => {
    setShowDialog(false);
    if (answer === DialogAnswer.Yes) {
      await generatePassword();
    }
  }

  const generatePassword = async () => {
    try {
      setIsProcessing(true);
      setErrorMessage('');
      setErrorTitleMessage('');
      setUnlockAccountSuccess(false);
      const resetResult = await clientService.generateTemporaryExtranetPassword(client.id);
      setNewPassword(resetResult?.data?.password);
    } catch (error) {
      setNewPassword('');
      setErrorMessage(t('userManagement.messageFailure'));
      setErrorTitleMessage(t('userManagement.passwordTitleFailure'));
      console.log(`Failure on trying to reset password for partyId ${client.id}. Details: ${error}`)
    } finally {
      setIsProcessing(false);
    }
  }

  const unlockAccount = async () => {
    try {
      setIsProcessing(true);
      setErrorMessage('');
      setErrorTitleMessage('');
      const unlockAccountResult = await clientService.unlockExtranetAccount(client.id, client.personalizedAccessCode);
      setUnlockAccountSuccess(true);
      setUserStillLocked(false);
      SetStorageKeyValue('accountStatusUpdated', client.id);
    } catch (error) {
      setUnlockAccountSuccess(false);
      setUserStillLocked(true);
      setErrorMessage(t('userManagement.messageFailure'));
      setErrorTitleMessage(t('userManagement.accountUnlockTitleFailure'));
      console.log(`Failure on trying to unlock account for partyId ${client.id}. Details: ${error}`)
    } finally {
      setIsIdentityVerifiedChecked(false);
      setIsProcessing(false);
    }
  }

  const renderGeneratePasswordDialog = () => {
    const titleLabel = t('userManagement.aboutToGenerateNewPasswordMessage');
    const subTitleLabel = t('userManagement.wantToContinue');
    const generateLabel = t('userManagement.generate');
    const cancelLabel = t('userManagement.cancel');

    return (
      <Dialog
          isOpen={shouldShowDialog}
          onDialogClose={handleDialogResult}
          t={t}
          title={titleLabel}
          subTitle={subTitleLabel}
          overriddenYesLabel={generateLabel}
          overriddenNoLabel={cancelLabel}
        />
    );
  }

  return (
    <>
      { shouldShowDialog && renderGeneratePasswordDialog() }
      <Header>{ t('userManagement.accountActions') }</Header>
      <Container>
        <div style={{position: 'relative'}}>
          { isProcessing && <div style={{position: 'absolute',left: '545px', zIndex:2}}><Loader /></div> }
          { userStillLocked && !errorMessage &&
            <WarningMessage messageTitle={t('userManagement.accountLocked')} style={{marginBottom: '15px', marginTop: '10px'}}>{t('userManagement.clientLockedMessage')}</WarningMessage>
          }
        </div>
        { errorMessage && <ErrorMessage error={errorMessage} errorTitle={errorTitleMessage} style={{marginBottom: '15px'}}/> }
        {newPassword &&
          <>
            <Label>{ t('userManagement.newPasswordToTransmit') }:</Label>
            <PasswordText>{ newPassword }</PasswordText>
          </>
        }
        {unlockAccountSuccess && <SuccessMessage>{t('userManagement.accountUnlockedSuccess')}</SuccessMessage>}
        {!newPassword && !isProcessing &&
          <FormControlLabel
              control={<Checkbox
                checked={isIdentityVerifiedChecked}
                onChange={() => setIsIdentityVerifiedChecked(!isIdentityVerifiedChecked)} />}
              label={<Typography style={Paragraphe}>{t('userManagement.iHaveVerifiedIdentity')}</Typography>} />
        }
        <UserManagementFooter
          isLocked = {userStillLocked}
          onGeneratePassword={() => setShowDialog(true)}
          onUnlockAccount={() => unlockAccount()}
          disabled={!isIdentityVerifiedChecked}
          t={t}
          isLoading={isProcessing} />
      </Container>


    </>
  );
};

export default UserManagement;
