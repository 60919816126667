import { TFunction } from "i18next";
import { InjectTranslationsProps, Loader } from "ia-react-core";
import React from "react";
import { Helmet } from "react-helmet";

import AdvisorService from "../../../common/advisor/advisor.service";
import { Advisor } from "../../../common/advisor/interfaces/Advisor";
import { Relationship } from "../../../common/advisor/interfaces/Relationship";
import { Team } from "../../../common/advisor/interfaces/Team";
import ErrorBoundary from "../../../common/components/ErrorBoundary";
import NoResult from "../../../common/components/NoResult";
import Section from "../../../common/enum/Section";
import { resetError as resetErrorAction, setError as setErrorAction } from "../../../redux/app.actions";
import { ContactContainer, LoadingContainer } from "./Contact.style";
import Search from "./Search/Search";
import SearchResult from "./SearchResult/SearchResult";

export interface ContactDispatchToProps {
  setError: typeof setErrorAction;
  resetError: typeof resetErrorAction;
}

export interface ContactStateToProps {
  repCodes: string[];
  isSupport: boolean;
  locale:string;
}

export interface ContactConnectedProps extends InjectTranslationsProps, ContactStateToProps, ContactDispatchToProps {
  advisors: Advisor[];
  t: TFunction;
}

export interface LocalState {
  repCode: string;
  team?: Team;
  loading: boolean;
  hasSearched: boolean;
}

class Contact extends React.PureComponent<ContactConnectedProps, LocalState> {
  private advisorService = new AdvisorService();

  constructor(props: ContactConnectedProps) {
    super(props);

    this.state = {
      repCode: "",
      loading: false,
      hasSearched: false,
    };
  }

 private onSearch = (repCode: string) => {
   if (repCode === "") return;
   
   this.setState({ repCode, loading: true, team: undefined, hasSearched: false });
   this.getAdvisorInfo(repCode);
 };

  private getAdvisorInfo = async (repCode: string) => {
    const { setError, resetError } = this.props;

    try {
      resetError();
      const teamByRepCode = await this.advisorService.getTeamByRepCodes([repCode]);    
      this.setState({ team: teamByRepCode[repCode], loading: false, hasSearched: true });
    } catch (error) {
      setError({ section: Section.contact, error });
    }
  }; 

  public render() {
    const { t, repCodes, isSupport, i18n, locale } = this.props;
    const { repCode, team, loading, hasSearched } = this.state;
    
    if (team !== undefined) {
      const brancId = team.Branches[0].id;
      team.Assistants = team.Assistants.filter((assistant: Advisor) => assistant.relationships.find((relation: Relationship) => relation.relationPartyId === brancId));
    }
    
    return (
      <ContactContainer>
        <Helmet>
          <title>{t("title")}</title>
        </Helmet>
        <Search t={t} onSearch={(rc: string) => this.onSearch(rc)} repCodes={repCodes} isSupport={isSupport} />
        <ErrorBoundary sections={[Section.contact]}>
          { loading && <LoadingContainer><Loader /></LoadingContainer>}
          {repCode !== "" && team && <SearchResult t={t} repCode={repCode} advisors={team.Advisors} branch={team.Branches[0]} team={team.Assistants} locale={locale} />}
          {hasSearched && !team && <NoResult section={Section.contact} />}
        </ErrorBoundary>
      </ContactContainer>
    );
  }
}

export default Contact;
