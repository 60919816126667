import { ColumnType } from "antd/lib/table";
import { TFunction } from "i18next";
import * as React from "react";

import { Client } from "../../../../../common/client/interfaces/Client";
import StyledTable from "../../../../../common/table/StyledTable";
import { Title } from "../../../clientProfile.style";
import { ClientIdsColumn, DateOfBirthColumn, NameColumn, SinColumn } from "./JointAccountHoldersColumn";
import { AdditionnalInfoContainer } from "./profileTab.style";

export interface JointAccountHoldersTableProps {
  t: TFunction;
  jointAccountHolders: Client[];
  locale: string;
}

const tableColumns = (props: JointAccountHoldersTableProps): ColumnType<Client>[] => {
  const columns: ColumnType<Client>[] = [NameColumn(props), DateOfBirthColumn(props), SinColumn(props), ClientIdsColumn(props)];
  return columns;
};

const JointAccountHoldersTable: React.FC<JointAccountHoldersTableProps> = (props: JointAccountHoldersTableProps) => {
  const { jointAccountHolders, t } = props;
  if (jointAccountHolders == null || jointAccountHolders.length === 0) {
    return null;
  }

  return (
    <AdditionnalInfoContainer>
      <Title>{t("profileTab.personalInformation.jointAccountHolder")}</Title>
      <StyledTable t={t} columns={tableColumns(props)} dataSource={jointAccountHolders} rowKey="id" pagination={false} />
    </AdditionnalInfoContainer>
  );
};

export default JointAccountHoldersTable;
