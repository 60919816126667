import fr from "./states.fr.json";
import en from "./states.en.json";
import { Language } from "../../enum/Language";

interface FormatRegionProps{
    regionCode: string;
    countryCode: string;
    locale: string;
}

export function FormatRegion(props: FormatRegionProps): string | undefined {
  const { regionCode, countryCode, locale } = props;

  const file = locale === Language.French ? fr : en;

  const country = file.find((p) => p.abbreviation === countryCode); 
  if (!country) return undefined;

  const state = country.states.find((s) => s.abbreviation === regionCode);
  return state ? state.name : undefined;
}
