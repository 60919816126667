import { TFunction } from "i18next";
import { Loader } from "ia-react-core";
import * as React from "react";

import { AccountGroupedByOwner } from "../../../../interfaces/AccountGroupedByOwner";
import { Accounts, AccountTitle } from "../investorProfileTab.style";
import OwnerObjectives from "./OwnerObjectives";

export interface InvestmentObjectivesProps {
  t: TFunction;
  dataSource: AccountGroupedByOwner[];
  locale: string;
  loadingAccounts: boolean;
}

const InvestmentObjectives: React.FC<InvestmentObjectivesProps> = (props: InvestmentObjectivesProps) => {
  const { loadingAccounts, t, dataSource, locale } = props;
  return (
    <Accounts>
      <AccountTitle>{t("investorTab.objectives.accountTitle")}</AccountTitle>
      {loadingAccounts && (
        <Loader />
      )}
      {!loadingAccounts && (
        dataSource.map((item: AccountGroupedByOwner) => (<OwnerObjectives key={item.id} t={t} dataSource={item} locale={locale} loadingAccounts={loadingAccounts}></OwnerObjectives>))
      )}
    </Accounts>);
};

export default InvestmentObjectives;
