import { Reducer } from "redux";

import { ClientState, initialClientState } from "../../../interfaces/ClientState";
import { AdvisorAction, AdvisorActionTypes as types } from "./advisor.actions.types";

const AdvisorReducer: Reducer<ClientState, AdvisorAction> = (state = initialClientState, action): ClientState => {
  switch (action.type) {
    case types.GET_ADVISORS_BY_REPCODES:
      return { ...state, teamByRepCode: {} };
    case types.GET_ADVISORS_BY_REPCODES_SUCCESS:
      return { ...state, teamByRepCode: action.teamByRepCode };
    case types.GET_ADVISORS_BY_REPCODES_ERROR:
      return { ...state, teamByRepCode: {} };
    default:
      return state;
  }
};

export default AdvisorReducer;
