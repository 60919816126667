import { getScoped, injectReducer, injectSaga, injectTranslations } from "ia-react-core";
import { FC } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Client } from "../../common/client/interfaces/Client";
import { loadMoreClients, searchClientAutoComplete } from "../../common/client/redux/client.actions";
import ClientAutoCompleteDataSource from "../../common/client/utils/ClientAutoCompleteDataSource";
import { Language } from "../../common/enum/Language";
import { initialClientState } from "../../interfaces/ClientState";
import { State } from "../../interfaces/State";
import Dashboard, { DashboardProps } from "./Dashboard";
import DashboardReducer from "./redux/dashboard.reducer";
import DashboardSaga from "./redux/dashboard.saga";
import en from "./translations/en";
import fr from "./translations/fr";

interface DispatchToProps {
  searchClientAutoComplete: typeof searchClientAutoComplete;
  loadMoreClients: typeof loadMoreClients;
}

const mapDispatchToProps: DispatchToProps = {
  searchClientAutoComplete,
  loadMoreClients,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: State, { scope }: any) => {
  const clients = (getScoped(state, "clients", scope) as unknown) as Client[];

  return {
    autoCompleteDataSource: new ClientAutoCompleteDataSource(clients),
    criteria: getScoped(state, "criteria", scope),
    loading: getScoped(state, "loading", scope),
    totalResults: getScoped(state, "totalResults", scope),
    currentOffset: getScoped(state, "currentOffset", scope),
  };
};

export default compose<FC<DashboardProps>>(
  injectTranslations("Dashboard", [
    { language: Language.French, resource: fr },
    { language: Language.English, resource: en },
  ]),
  injectSaga({
    key: "Dashboard",
    saga: DashboardSaga,
  }),
  injectReducer({
    key: "Dashboard",
    initialState: initialClientState,
    reducer: DashboardReducer,
    useScope: true,
    cleanState: true,
  }),

  connect(mapStateToProps, mapDispatchToProps),
)(Dashboard);
