import { injectTranslations } from "ia-react-core";

import { Language } from "../../enum/Language";
import { NoResult } from "./NoResult";
import en from "./translations/en";
import fr from "./translations/fr";

export default 
injectTranslations("NoResult", [
  { language: Language.French, resource: fr },
  { language: Language.English, resource: en },
])(NoResult);
