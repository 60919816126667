import { get, getScoped, injectTranslations } from "ia-react-core";
import { FC } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { Language } from "../../../common/enum/Language";
import { State } from "../../../interfaces/State";
import { resetError, setError } from "../../../redux/app.actions";
import Contact, { ContactConnectedProps, ContactDispatchToProps, ContactStateToProps } from "./Contact";
import en from "./translations/en";
import fr from "./translations/fr";

const mapDispatchToProps: ContactDispatchToProps = {
  setError,
  resetError,
};

const mapStateToProps = (state: State, { scope }: any): ContactStateToProps => ({
  repCodes: get(state, "App.repCodes"),
  isSupport: get(state, "App.isSupport"),
  locale: (get(state, "language.locale") as unknown) as string,
});

export default compose<FC<ContactConnectedProps>>(
  injectTranslations("ProfileContact", [
    { language: Language.French, resource: fr },
    { language: Language.English, resource: en },
  ]),
  connect(mapStateToProps, mapDispatchToProps),
)(Contact);
