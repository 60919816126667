import { ColumnType } from "antd/lib/table";
import * as React from "react";

import { ClientSearchResultAccount, ClientSearchResultTitle } from "..";
import { Client } from "../../../../../../common/client/interfaces/Client";
import ClientHelper from "../../../../../../common/client/utils/ClientHelper";
import { ClientSearchResultTableProps } from "../ClientSearchResultTable";

export function ClientSearchResultColumnName(props: ClientSearchResultTableProps): ColumnType<Client> {
  const { t, searchCriteria, actionEvent } = props;

  return {
    title: t("App:name"),
    dataIndex: "name",
    key: "name",
    render: (value: unknown, record: Client) => (
      <>
        <ClientSearchResultTitle fullName={ClientHelper.getFullName(record)} id={record.id} actionEvent={actionEvent} />
        <ClientSearchResultAccount accounts={record.accounts} title={t("App:client.clientId")} searchText={searchCriteria.text} />
      </>
    ),
  };
}
