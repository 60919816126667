import * as React from "react";
import { Col, Row } from "react-grid-system";

import ErrorBoundary from "../../../../../common/components/ErrorBoundary";
import Section from "../../../../../common/enum/Section";
import { ClientProfileComposedProps } from "../../../ClientProfile";
import { Title } from "../../../clientProfile.style";
import AccountAdvisorTable from "./AccountsAdvisorsTable/AccountAdvisorTable";
import BankAccountsTable from "./BankAccountsTable/BankAccountsTable";
import ProfileSelector from "./BaseInformations/ProfileSelector";
import JointAccountHoldersTable from "./JointAccountHoldersTable";
import { SectionContainer } from "./profileTab.style";
import { PartyTypeCode } from "../../../../../common/client/interfaces/Party";

export interface ProfileTabProps extends ClientProfileComposedProps {}

export default class ProfileTab extends React.PureComponent<ProfileTabProps> {
  public render() {
    const { client, jointAccountHolders, t, accountsByAdvisorsGroupedByRepCodes, i18n, accountsGroupedByBankAccounts, loadingAccounts, locale, clientHasOwnedAccounts } = this.props;
    if (!client) {
      return null;
    }

    return (
      <>
        <Row>
          <ProfileSelector client={client} t={t} locale={locale} />
        </Row>
        <Row>
          <Col md={12}>
            <JointAccountHoldersTable t={t} jointAccountHolders={jointAccountHolders} locale={locale} />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <SectionContainer>
              <Title>{t("profileTab.advisorsInformation.title")}</Title>
              <ErrorBoundary sections={[Section.account, Section.advisor]}>
                <AccountAdvisorTable t={t} dataSource={accountsByAdvisorsGroupedByRepCodes} locale={locale} loadingAccounts={loadingAccounts} />
              </ErrorBoundary>
            </SectionContainer>
          </Col>
        </Row>
        {
          clientHasOwnedAccounts &&
          <Row>
            <Col md={12}>
              <SectionContainer>
                <Title>{t("profileTab.bankAccountsInformation.title")}</Title>
                <ErrorBoundary sections={[Section.account, Section.advisor]}>
                  <BankAccountsTable t={t} dataSource={accountsGroupedByBankAccounts} locale={locale} loadingAccounts={loadingAccounts} />
                </ErrorBoundary>
              </SectionContainer>
            </Col>
          </Row>
        }
        </>
    );
  }
}
