import * as React from "react";
import { TFunction } from "i18next";
import ClientHelper from "../../../../../../common/client/utils/ClientHelper";

import { Client } from "../../../../../../common/client/interfaces/Client";
import ProfileCustomer from "./ProfileCustomer";
import ProfileBusiness from "./ProfileBusiness";

export interface ProfileProps {
  client: Client;
  t: TFunction;
  locale: string;
}

export default class ProfileSelector extends React.PureComponent<ProfileProps> {
  public render() {
    const { client, t, locale } = this.props;
    if (!client) {
      return null;
    }

    const isOrganization = ClientHelper.isOrganization(client.partyTypeCode);

    return (
      <>
        {!isOrganization && <ProfileCustomer client={client} t={t} locale={locale} />}
        {isOrganization && <ProfileBusiness client={client} t={t} locale={locale} />}
      </>
    );
  }
}
