import moment from "moment";
import * as React from "react";

interface LocalProps {
  children: string;
  format: string;
  locale: string;
}

const ResultDate: React.FC<LocalProps> = ({ children, format, locale }: LocalProps) => <>{moment(children).locale(locale).format(format)}</>;

export default ResultDate;
