import { User } from "../interfaces/User";
import { GetUser, GetUserError, GetUserSuccess, SetUser, UserActionTypes as types } from "./user.actions.types";

export const getUser = (): GetUser => ({ type: types.GET_USER });

export const getUserSuccess = (user: User): GetUserSuccess => ({ type: types.GET_USER_SUCCESS, user });

export const getUserError = (): GetUserError => ({ type: types.GET_USER_ERROR });

export const setUser = (repCodes: string[], isSupport: boolean): SetUser => ({ type: types.SET_USER, repCodes, isSupport });
