import { ColumnType } from "antd/lib/table";
import * as React from "react";

import { AccountByAdvisorGroupedByRepCode } from "../../../../../interfaces/AccountByAdvisorGroupedByRepCode";
import { AccountAdvisorTableProps } from "../AccountAdvisorTable";

export function CodeColumn(props: AccountAdvisorTableProps): ColumnType<AccountByAdvisorGroupedByRepCode> {
  const { t } = props;

  return {
    title: t("profileTab.advisorsInformation.representativeCode"),
    dataIndex: "repCode",
    key: "repCode",
    render: (value: string) => <>{value}</>,
  };
}
