import { API_EVENTS } from "../../constants/API";
import { HttpGet } from "../utils/HttpClient";

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
class EventService {  
  public async getLogEventFilteredByCategories(partyId: string, categories: string[]) {
    try {
      const payloadCategories = categories.map((c) => (`payloadCategories=${c}`)).join('&');
      const response = await HttpGet(`${API_EVENTS}/iasecurities/OwnerLogs/${partyId}?${payloadCategories}`);
      return response.data;
    } catch (err) {
      console.error(err);
      return null;
      
    }
  }
}

export default EventService;
