import * as React from 'react';
import { Dialog as MuiDialog, Stack } from '@mui/material';
import { TFunction } from 'i18next';
import { ButtonsContainer, Container, Title } from './Dialog.style';
import background from './questionImage.png'; // relative path to image
import { Button } from 'antd';
import { iaButtonStyle, iaWhiteButtonStyle } from '../../common.style';

export enum DialogAnswer {
    Yes,
    No
}

interface DialogProps {
    isOpen: boolean;
    t: TFunction;
    title: string;
    subTitle: string;
    onDialogClose: (answer: DialogAnswer) => void;
    overriddenYesLabel?: string;
    overriddenNoLabel?: string;
}
/**
 * This is a customizable Dialog that allows you to show a Yes/No (label is customizable too)
 * Params:
 * @title = Translated title in Bold text
 * @subTitle = Translated subTitle
 * @overriddenYesLabel = 
 * @overriddenNoLabel = Translated subTitle
 */
const Dialog: React.FC<DialogProps> = ({isOpen, t, onDialogClose, title, subTitle, overriddenYesLabel, overriddenNoLabel}: DialogProps): JSX.Element => {
    const yesLabel = overriddenYesLabel || t('dialog.yes')
    const noLabel = overriddenNoLabel || t('dialog.no')
    return (
        <MuiDialog open={isOpen} onClose={() => onDialogClose(DialogAnswer.No)} fullWidth={true} maxWidth="sm">
            <Container>
                <Stack style={{ height: '48px', width: '48px', marginBottom: '24px' }}>
                    <img src={background} />
                </Stack>
                <Stack style={{ marginBottom: '24px' }}>
                    <Title size='md'>{ title }</Title>
                    { subTitle }
                </Stack>
                <ButtonsContainer>
                    <Button size="large" style={iaButtonStyle} onClick={() => onDialogClose(DialogAnswer.Yes)}>
                        { yesLabel }
                    </Button>
                    <Button size="large" style={iaWhiteButtonStyle} onClick={() => onDialogClose(DialogAnswer.No)}>
                        { noLabel }
                    </Button>
                </ButtonsContainer>
            </Container>
        </MuiDialog>
    )
}

export default Dialog;