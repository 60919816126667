import { ColumnType } from "antd/lib/table";
import { TFunction } from "i18next";
import moment from "moment";
import * as React from "react";

import { SignaturePackageStatus } from "../../../../../../../common/signaturePackage/enum";
import { SignaturePackage } from "../../../../../../../common/signaturePackage/interfaces/SignaturePackage";
import { Cancel, Done, Warning } from "../../../../../../../common/svg";
import { ResultTableProps } from "../ResultTable";
import { ResultStatus, ResultStatusAwaiting } from "./status";

function renderStatus(props: { signaturePackage: SignaturePackage; t: TFunction; locale: string }): JSX.Element | null {
  const { signaturePackage, t, locale } = props;
  const date = moment(signaturePackage.status.statusModifiedDate).locale(locale).format(t("App:shortDateFormat"));

  switch (signaturePackage.status.transactionStatus) {
    case SignaturePackageStatus.expired:
      return (
        <ResultStatus date={date} title={t("SignaturePackage:status.expired")}>
          {Warning(20, "#E71313")}
        </ResultStatus>
      );
    case SignaturePackageStatus.awaiting:
      return <ResultStatusAwaiting {...props} />;
    case SignaturePackageStatus.cancelled:
      return (
        <ResultStatus date={date} title={t("SignaturePackage:status.cancelled")}>
          {Cancel(20)}
        </ResultStatus>
      );
    case SignaturePackageStatus.completed:
      return (
        <ResultStatus date={date} title={t("SignaturePackage:status.completed")}>
          {Done(20)}
        </ResultStatus>
      );
    case SignaturePackageStatus.refused:
      return (
        <ResultStatus date={date} title={t("SignaturePackage:status.refused")}>
          {Warning(20, "#E71313")}
        </ResultStatus>
      );
    default:
      return null;
  }
}

export function ResultColumnStatus(props: ResultTableProps): ColumnType<SignaturePackage> {
  const { t, locale } = props;

  return {
    title: t("App:status"),
    dataIndex: "status",
    key: "status",
    width: 190,
    render: (value: unknown, record: SignaturePackage) => renderStatus({ t, signaturePackage: record, locale }),
  };
}
