export default {
  nameSearchTitle: "Nom du client ou # du dossier",
  repCodeSearchTitle: "Code de représentant",
  submit: "Rechercher",
  reset: "Réinitialiser",
  statusSubTitle: "Statuts",
  searchResult: {
    title: "dossier(s) de signature trouvé(s)",
    for: "pour",
    noItemsFound: "Aucun dossier de signature trouvé",
    columns: {
      createdOn: "Créé le",
      createdBy: "Créé par",
      documents: "Document(s)",
      signers: "Signataire(s)",
      package: "Dossier",
    },
    actions: {
      viewDetails: "Voir les détails du dossier",
      updateEmail: "Mettre à jour le courriel",
      unlockSigner: "Déverrouiller le signataire",
      cancel: "Annuler le dossier de signature",
      downloadEvidenceSummary: "Télécharger preuve de signature électronique (.pdf)",
      downloadSignedDocuments: "Télécharger documents signés (.zip)",
    },
  },
};
