import { Table } from "antd";
import { SpinProps } from "antd/lib/spin";
import { TableProps } from "antd/lib/table";
import { TableLocale } from "antd/lib/table/interface";
import { TFunction } from "i18next";
import { Loader } from "ia-react-core";
import * as React from "react";

import { Styled } from "./StyledTable.style";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface StyledTableProps<RecordType extends object = any> extends TableProps<RecordType> {
  t: TFunction;
}

const StyledTable: React.FC<StyledTableProps> = (props: StyledTableProps) => {
  const { loading, locale, t, className } = props;

  const resultLoading = React.useMemo<boolean | SpinProps | undefined>(() => {
    if (typeof loading === "boolean") {
      return { spinning: loading, indicator: <Loader /> };
    }
    return loading;
  }, [loading]);

  const resultLocale = React.useMemo<TableLocale>(() => {
    if (!locale) {
      let emptyText = t("App:tableLocale.emptyText");
      if (resultLoading) {
        if ((typeof resultLoading === "boolean" && resultLoading) || (typeof resultLoading === "object" && resultLoading.spinning)) {
          emptyText = t("App:tableLocale.searchInProgress");
        }
      }
      return { emptyText };
    }
    return locale;
  }, [locale, resultLoading, t]);

  const resultClassName = React.useMemo<string | undefined>(() => {
    const defautlClassName = "styled-table";
    if (!className) {
      return defautlClassName;
    }
    return `${defautlClassName} ${className}`;
  }, [className]);

  return (
    <Styled>
      <Table {...props} className={resultClassName} loading={resultLoading} locale={resultLocale} />
    </Styled>
  );
};

export default StyledTable;
