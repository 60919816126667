import { ColumnType } from "antd/lib/table";
import { ExpandableConfig } from "antd/lib/table/interface";
import { TFunction } from "i18next";
import { RenderExpandIconProps } from "rc-table/lib/interface";
import * as React from "react";

import { expandIcon } from "../../../../../../common/table/ExpandIcon";
import StyledTable from "../../../../../../common/table/StyledTable";
import { AccountByAdvisorGroupedByRepCode } from "../../../../interfaces/AccountByAdvisorGroupedByRepCode";
import { accountsExpandedRow } from "../AccountsTable/AccountsExpandedRow";
import { NamesColumn } from "./Columns";
import { CodeColumn } from "./Columns/CodeColumn";

export interface AccountAdvisorTableProps {
  t: TFunction;
  dataSource: AccountByAdvisorGroupedByRepCode[];
  locale: string;
  loadingAccounts: boolean;
}

const tableColumns = (props: AccountAdvisorTableProps): ColumnType<AccountByAdvisorGroupedByRepCode>[] => {
  const columns: ColumnType<AccountByAdvisorGroupedByRepCode>[] = [CodeColumn(props), NamesColumn(props)];
  return columns;
};

const expandable = (props: AccountAdvisorTableProps): ExpandableConfig<AccountByAdvisorGroupedByRepCode> => ({
  rowExpandable: (record: AccountByAdvisorGroupedByRepCode) => record.accounts.length > 0,
  expandedRowRender: (record: AccountByAdvisorGroupedByRepCode) => accountsExpandedRow(record.accounts, record.repCode, props.t),
  expandIconColumnIndex: 2,
  expandIcon: (expandProps: RenderExpandIconProps<AccountByAdvisorGroupedByRepCode>) => expandIcon<AccountByAdvisorGroupedByRepCode>(expandProps),
});

const AccountAdvisorTable: React.FC<AccountAdvisorTableProps> = (props: AccountAdvisorTableProps) => {
  const { dataSource, loadingAccounts, t } = props;
  return <StyledTable t={t} loading={loadingAccounts} columns={tableColumns(props)} dataSource={dataSource} rowKey="repCode" pagination={false} expandable={expandable(props)} />;
};

export default AccountAdvisorTable;
