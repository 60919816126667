import { ColumnType } from "antd/lib/table";
import { TFunction } from "i18next";
import * as React from "react";

import { Account } from "../../../../../../common/account/interfaces/Account";
import ExpandableStyledTable from "../../../../../../common/table/ExpandableStyledTable";
import { AccountTypeColumn, IdColumn, ProductTypeColumn } from "./Columns";

export interface AccountTableProps {
  t: TFunction;
  dataSource: Account[];
}

const tableColumns = (props: AccountTableProps): ColumnType<Account>[] => {
  const columns: ColumnType<Account>[] = [IdColumn(), AccountTypeColumn(props), ProductTypeColumn()];
  return columns;
};

const AccountTable: React.FC<AccountTableProps> = (props: AccountTableProps) => {
  const { dataSource, t } = props;
  return <ExpandableStyledTable t={t} columns={tableColumns(props)} dataSource={dataSource} rowKey="id" title={() => t("App:accounts")} />;
};

export default AccountTable;
