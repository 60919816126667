import axios, { AxiosResponse } from "axios";

/* eslint-disable @typescript-eslint/no-explicit-any */
export const axiosInstance = axios.create(
  {
    transformResponse: (data: any) => {
      try {
        const refMap: any = {};
        return JSON.parse(data, function parse(key, value) {
          if (key === '$id') {
            refMap[value] = this;
            // return undefined so that the property is deleted
            return undefined;
          }

          if (value && value.$ref) { return refMap[value.$ref]; }

          return value;
        });
      } catch {
        return data;
      }
    },
  },
);

export function HttpGet(url: string, options: any = {}): Promise<any> {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(url, options)
      .then((response: AxiosResponse<any>) => resolve(response.data))
      .catch((error: any) => reject(error));
  });
}

export function HttpGetBlob(url: string): Promise<Blob> {
  return new Promise((resolve, reject) => {
    axiosInstance.get(url, { responseType: "blob" })
      .then((response: AxiosResponse<Blob>) => resolve(response.data))
      .catch((error: any) => reject(error));
  });
}

export function HttpPost(url: string, body: any, options: any = {}): Promise<any> {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(url, body, options)
      .then((response: AxiosResponse<any>) => resolve(response.data))
      .catch((error: any) => reject(error));
  });
}

export function HttpPut(url: string, body: any, options: any = {}): Promise<any> {
  return new Promise((resolve, reject) => {
    axiosInstance
      .put(url, body, options)
      .then((response: AxiosResponse<any>) => resolve(response.data))
      .catch((error: any) => reject(error));
  });
}

export function HttpPatch(url: string, options: any = {}): Promise<any> {
  return new Promise((resolve, reject) => {
    axiosInstance.patch(url, options, { headers: { 'Content-Type': 'application/json-patch+json; charset=UTF-8' } })
      .then((response: AxiosResponse<any>) => resolve(response.data))
      .catch((error: any) => reject(error));
  });
}
