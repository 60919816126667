export default {
  title: "Dashboard",
  clientSearch: {
    title: "Client Search",
    placeholder: "Search by full name or client ID",
    seeAll: "See all clients",
    goToClientProfile: "Go to client profile",
    autoComplete: {
      searchInProgress: "Search in progress",
      emptyResult: "No result found",
    },
  },
};
