import { ColumnType } from "antd/lib/table";
import { TFunction } from "i18next";
import * as React from "react";

import { SearchCriteria } from "../../../../../../common/interfaces/SearchCriteria";
import SignaturePackageActionEvent from "../../../../../../common/signaturePackage/enum/SignaturePackageActionEvent";
import { SignaturePackage } from "../../../../../../common/signaturePackage/interfaces/SignaturePackage";
import StyledTable from "../../../../../../common/table/StyledTable";
import {
  ResultColumnActions,
  ResultColumnCreatedBy,
  ResultColumnCreatedOn,
  ResultColumnDocuments,
  ResultColumnPackage,
  ResultColumnSigners,
  ResultColumnStatus,
} from "./ResultColumn";

export interface ResultTableProps {
  t: TFunction;
  data: SignaturePackage[];
  actionEvent: (id: string, action: SignaturePackageActionEvent, event?: React.MouseEvent<HTMLElement>) => void;
  searchCriteria: SearchCriteria;
  locale: string;
  isSupport: boolean;
}

const tableColumns = (props: ResultTableProps): ColumnType<SignaturePackage>[] => {
  const columns: ColumnType<SignaturePackage>[] = [
    ResultColumnPackage(props),
    ResultColumnCreatedOn(props),
    ResultColumnCreatedBy(props),
    ResultColumnSigners(props),
    ResultColumnDocuments(props),
    ResultColumnStatus(props),
    ResultColumnActions(props),
  ];
  return columns;
};

const ResultTable: React.FC<ResultTableProps> = (props: ResultTableProps) => {
  const { t, data } = props;
  return <StyledTable t={t} columns={tableColumns(props)} dataSource={data} rowKey="id" pagination={false} className="column-top" />;
};

export default ResultTable;
