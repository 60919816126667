import { TFunction } from "i18next";
import * as React from "react";

import { SecondaryButton } from "../../../../../common/common.style";
import NoResult from "../../../../../common/components/NoResult";
import Section from "../../../../../common/enum/Section";
import { SearchCriteria } from "../../../../../common/interfaces/SearchCriteria";
import SignaturePackageActionEvent from "../../../../../common/signaturePackage/enum/SignaturePackageActionEvent";
import { SignaturePackage } from "../../../../../common/signaturePackage/interfaces/SignaturePackage";
import ResultTable from "./components/ResultTable";
import { Container, LoadMoreContainer, Title } from "./Result.style";

export interface TrackingSearchResultProps {
  t: TFunction;
  isSupport: boolean;
  locale: string;
  data: SignaturePackage[];
  searchCriteria: SearchCriteria;
  totalResultCount: number;
  loadMore: () => void;
  loading: boolean;
  searchLoading: boolean;
  onActionEvent: (id: string, action: SignaturePackageActionEvent, event?: React.MouseEvent<HTMLElement>) => void;
}

export interface LocalState {
  loadingMore: boolean;
}

class TrackingSearchResult extends React.PureComponent<TrackingSearchResultProps, LocalState> {
  constructor(props: TrackingSearchResultProps) {
    super(props);
    this.state = {
      loadingMore: false,
    };
  }

  public componentDidUpdate(prevProps: TrackingSearchResultProps) {
    const { data, searchCriteria } = this.props;

    if (prevProps.searchCriteria !== searchCriteria || prevProps.data.length !== data.length) {
      this.setState({ loadingMore: false });
    }
  }

  private loadMoreSignaturePackages = () => {
    const { loadMore } = this.props;
    const { loadingMore } = this.state;
    if (loadingMore) {
      return;
    }

    this.setState({ loadingMore: true }, () => {
      loadMore();
    });
  };

  public render() {
    const { totalResultCount, data, t, onActionEvent, searchCriteria, locale, searchLoading, isSupport } = this.props;

    if (searchLoading) {
      return null;
    }

    return (
      <Container>
        {data.length === 0 && <NoResult section={Section.clientSearch} />}
        {data.length > 0 && (
          <>
            <Title>{`${totalResultCount} ${t("searchResult.title")}`}</Title>
            <ResultTable data={data} t={t} actionEvent={onActionEvent} searchCriteria={searchCriteria} locale={locale} isSupport={isSupport} />
            {data.length < totalResultCount && (
              <LoadMoreContainer>
                <SecondaryButton onClick={this.loadMoreSignaturePackages}>{t("App:loadMore")}</SecondaryButton>
              </LoadMoreContainer>
            )}
          </>
        )}
      </Container>
    );
  }
}

export default TrackingSearchResult;
