import { Reducer } from "redux";

import { AppState, initialAppState } from "../../../interfaces/AppState";
import { UserAction } from "../../user/redux/user.actions.types";
import UserReducer from "../../user/redux/user.reducer";

const reducers = [UserReducer];

const appLoaderReducer: Reducer<AppState, UserAction> = (state = initialAppState, action): AppState => reducers.reduce((currentState, reducer) => reducer(currentState, action), state);

export default appLoaderReducer;
