import styled from "styled-components";

export const Setting = styled.div<{ open: boolean }>`
  position: fixed;
  right: ${(props) => (props.open ? "0" : "-320px")};
  top: 25%;
  z-index: 99999;
  display: flex;
  flex-wrap: nowrap;
  transition: 0.3s right;
`;

export const Icon = styled.button`
  box-shadow: 0px 0px 4px #000;
  display: block;
  width: 40px;
  height: 40px;
  cursor: pointer;
  flex: 0 0 40px;
`;

export const FormContainer = styled.div`
  width: 320px;
  min-height: 200px;
  border: 1px solid #000;
  background: #fff;
  margin: 0 0 0 2px;
  box-shadow: 0px 0px 4px #000;
  padding: 20px;
`;

export const Title = styled.h4`
  margin: 0 0 15px 0;
`;
