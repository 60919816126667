import { createSelector } from "reselect";

import { SignaturePackage } from "../../../../common/signaturePackage/interfaces/SignaturePackage";
import {
  selectCurrentOffset,
  selectSignaturePackages,
} from "../../../../common/signaturePackage/redux/signaturePackage.selector";
import { LimitSearchSignaturePackage } from "../../../../constants/API";

export const selectSignaturePackagesWithOffset = createSelector(selectSignaturePackages, selectCurrentOffset, (signaturePackages: SignaturePackage[], currentOffset: number): SignaturePackage[] => {
  if (signaturePackages.length === 0) {
    return signaturePackages;
  }
  const limit = LimitSearchSignaturePackage;
  const nbToDisplay = Math.min(signaturePackages.length, currentOffset + limit);
  return signaturePackages.slice(0, nbToDisplay);
});
