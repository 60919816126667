import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import MuiExpansionPanel from '@mui/material/Accordion';
import { IconButton, TextField } from '@mui/material';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const ContainerRow = styled.div`
    display: flex;
    flex-direction: row;
`;

export const PageTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 38px 0px 10px 0px;
    font-size: 24px;
    font-family: ${(props) => props.theme.fontBold};
    font-weight: ${(props) => props.theme.fontBoldTitleWeight};
`;

export const BoxShadow = styled.div`
    background: #FFF;
    padding: 15px;
    box-shadow: 2px 2px 2px rgba(102, 102, 102, 0.349019607843137);
`;

export const MainBox = styled.div`
    background: #FFF;
    box-shadow: 2px 2px 2px rgba(204, 204, 204, 0.35);
`;

export const P = styled.div`
    font-family: 'OpenSans',  'Open Sans';
    font-style: normal;
    font-size: 16px;
`;
export const LoadStampContainer = styled.div<{ color?: string }>`
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    flex-grow:1;
`;

export const DataLoadStampContainer = styled.div<{ color?: string, marginTop?: number }>`
    font-family: 'OpenSans-Regular', 'Open Sans', sans-serif !important;
    font-size: 14px;
    font-style: normal;
    font-weight : 400!important;
    margin-top: ${(props) => props.marginTop ? props.marginTop : 0}px
`;

export const Label = styled.div`
    font-family: 'OpenSans-Regular', 'Open Sans';
    font-style: normal;
    font-size: 14px;
    font-weight:400;
`;

export const LabelBold = styled.div`
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 18px;
    font-weight:650;
`;

export const LabelBoldSubtitle = styled.div`
  font-family: 'Open Sans Bold', 'Open Sans Regular', 'Open Sans', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 16px !important;
`;

export const LabelBoldBigSubtitle = styled.div`
  font-family: 'Montserrat SemiBold', 'Montserrat Regular', 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 32px;
  text-align: center;
`;

export const LabelBigSubtitle = styled.div`
  font-family: 'Montserrat Regular', 'Montserrat', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 32px;
  text-align: center;
`;

export const LabelMonetary = styled.div`
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-size: 18Px;
    font-weight:400;
`;

export const H3 = styled.div`
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
`;

export const BoxHeader = styled.div`
    font-family: Montserrat;
    font-weight: 100;
    font-size: 20px;
    color: #000;
    line-height: 22px;
`;

export const HeaderPageContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
`;

export const HeaderSmall = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 11px;
    font-weight: 800;
    color: #666666;
`;



export const Legendbox = styled.div<{ color: string, size: string, font?: string }>`
  width:  ${(props) => props.size};
  min-width:${(props) => props.size};
  height: ${(props) => props.size};
  background: inherit;
  background-color: ${(props) => props.color};
  border: none;
  font-size: ${(props) => props.font ? props.font : '12px'};
  color: #FFFFFF;
  padding-top: 5px;
  text-align: center;
  font-family: 'Open Sans Bold', 'Open Sans Regular', 'Open Sans', sans-serif;
  font-weight: 700;
  font-style: normal;
  `;

export const ContainerLegend = styled.div`
    display: flex;
    flex-direction: row;
    padding: 3px;
    width: 250px;
    text-align: left;
    line-height: 15px;
    align-self: flex-end;
    align-items: center;
    font-size: 12px;
`;


export const TabMenuContainer = styled.div`
    display: flex;

    & > a {
        color: #003DA5;

        &:link, visited {
            text-decoration: none;
        }

        &:hover, active {
            text-decoration: none;
            background: #003DA5;
            color: #FFF;
        };
    }
`;

export const TabContainer = styled(NavLink)`
    padding: 15px 40px;
    margin-right: 10px;
    border-top: 5px solid #EFF1F4;
    font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    color: #333333;
    background: rgba(187, 194, 202, 1);
`;

export const ActiveNavStyle = {
  background: '#FFF',
  boxShadow: '2px 0 2px -2px rgba(204, 204, 204, 0.35)',
  borderTop: '5px solid #003ba9',
  textDecoration: 'none',
  color: '#000',
  fontWeigth: '500',
  fontFamily: "Open Sans, Helvetica Neue, Helvetica,Arial, sans-serif",
  cursor: 'default',
};

export const FilterContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
    padding-left: 4px;
`;

export const LinkButton = styled.span`
  font-family :'Open Sans', sans-serif;
  padding: 0px;
  color: #003da5;
  cursor:pointer;
  &:active{
    color: #003da5;
  }
`;

export const UpdateDataContainer = styled.div`
    font-family: "OpenSans-SemiBold", "Open Sans SemiBold", "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight : 650;
    color: #2062D4;
    text-align: left;
    &:hover {
      text-decoration:underline!important;
    }
    .MuiIconButton-root:hover {
      background-color: rgba(0, 0, 0, 0)!important;
    }
`;
