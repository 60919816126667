import { Reducer } from "redux";

import { AccountAction } from "../../../common/account/redux/account.actions.types";
import AccountReducer from "../../../common/account/redux/account.reducer";
import { ClientAction } from "../../../common/client/redux/client.actions.types";
import ClientReducer from "../../../common/client/redux/client.reducer";
import { ClientState, initialClientState } from "../../../interfaces/ClientState";

const reducers = [ClientReducer, AccountReducer];

const DashboardReducer: Reducer<ClientState, ClientAction & AccountAction> = (state = initialClientState, action): ClientState => reducers.reduce((currentState, reducer) => reducer(currentState, action), state);

export default DashboardReducer;
