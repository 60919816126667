import { API_PARTIES } from "../../constants/API";
import { HouseholdType } from "../enum/HouseholdType";
import { PaginatedResponse } from "../interfaces/PaginatedResponse";
import { ResponsePayload } from "../interfaces/ResponsePayload";
import { HttpGet, HttpPost } from "../utils/HttpClient";
import PersonalizedAccessCode from "./components/PersonalizedAccessCode";
import { ClientAccount } from "./interfaces/Account";
import { Client } from "./interfaces/Client";
import { IPartyAccount } from "./interfaces/Party";
import ClientHelper from "./utils/ClientHelper";

class ClientService {
  public async searchByFullName(fullName: string, repCodes: string[], offset: number, limit: number): Promise<PaginatedResponse<Client>> {
    const response: unknown = await HttpGet(`${API_PARTIES}?fullName=${fullName}&offset=${offset}&limit=${limit}${this.getQueryRepcodes(repCodes)}`, {});
    return this.filterAccountsByRepCode(response as PaginatedResponse<Client>, repCodes);
  }

  public async searchClientByMultiParams(clientId: string, username: string, fullName: string, repCodes: string[]) {
    if (fullName) return this.searchByFullName(fullName, repCodes, 0, 1000);
    if (username) return this.searchByAccessCode(username, repCodes);
    if (clientId) return this.searchByAccountId(clientId, repCodes);
    return null;
  }

  public async searchByAccessCode(accessCode: string, repCodes: string[]): Promise<PaginatedResponse<Client>> {
    const response: unknown = await HttpGet(`${API_PARTIES}?personalizedAccessCode=${accessCode}${this.getQueryRepcodes(repCodes)}`, {});
    return this.filterAccountsByRepCode(response as PaginatedResponse<Client>, repCodes);
  }

  public async searchByAccountId(searchCriteria: string, repCodes: string[]): Promise<PaginatedResponse<Client>> {
    const clientId = ClientHelper.getClientId(searchCriteria);
    const response: unknown = await HttpGet(`${API_PARTIES}?accounts=${clientId}${this.getQueryRepcodes(repCodes)}`, {});
    const filtredPaginatedResponse = this.filterAccountsByRepCode(response as PaginatedResponse<Client>, repCodes);

    return this.cleanClientsByClientId(filtredPaginatedResponse, clientId);
  }

  public async getByClientId(clientId: string): Promise<ResponsePayload<Client>> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: any = await HttpGet(`${API_PARTIES}/${clientId}`, {});
    return response;
  }

  public async getJointAccountHolders(accounts: ClientAccount[], clientId: string): Promise<Client[]> {
    const jointAccounts = accounts.filter((a) => a.role === HouseholdType.OWNER && (a.noRole === 21 || a.noRole === 22));
    if (jointAccounts.length > 0) {
      const accountsQuery = jointAccounts.map((a) => a.id).join("&accounts=");
      const response: unknown = await HttpGet(`${API_PARTIES}?accounts=${accountsQuery}&fields=Data(id,partyTypeCode,firstName,lastname,businessname,dateofbirth,sin,accounts)`, {});
      const joints = this.cleanJointAccountHolders(response as PaginatedResponse<Client>, clientId, jointAccounts);
      return joints;
    }

    return Promise.resolve<Client[]>(new Array<Client>());
  }

  public async generateTemporaryExtranetPassword(partyId: string): Promise<{data: {partyId: string; password: string}}> {
    return await HttpPost(`${API_PARTIES}/resetPassword/${partyId}`, {});
  }

  public async unlockExtranetAccount(partyId: string, personalizedAccessCode: string): Promise<{data: {message: string}}> {
    return await HttpPost(`${API_PARTIES}/unlock/${partyId}?username=${personalizedAccessCode}`, {});
  }

  public async getClientLockedById(partyId: string): Promise<ResponsePayload<any>> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: any = await HttpGet(`${API_PARTIES}/IsLocked/${partyId}`, {});
    return response;
  }
  
  private getQueryRepcodes(repCodes: string[]): string {
    const repCodesQuery: string = repCodes.length === 0 ? "" : `&repCodes=${repCodes.join("&repCodes=")}`;
    // repCodesQuery += "&repCodesFilterExcludeShared=true";
    return repCodesQuery;
  }

  private filterAccountsByRepCode = (response: PaginatedResponse<Client>, repCodes: string[]): PaginatedResponse<Client> => {
    if (repCodes.length === 0 || response.data.length === 0) {
      return response;
    }
    response.data.forEach((client: Client) => {
      client.accounts = client.accounts.filter((account: IPartyAccount) => repCodes.includes(account.repCode));
      client.requestedAccessCode = false;
    });

    return response;
  };

  private cleanClientsByClientId = (response: PaginatedResponse<Client>, clientId: string): PaginatedResponse<Client> => {
    const clients = [...response.data];

    response.data = clients.filter((client: Client) => client.accounts.filter((account: IPartyAccount) => account.id.toUpperCase() === clientId.toUpperCase()).length > 0);

    return response;
  };

  private cleanJointAccountHolders = (response: PaginatedResponse<Client>, clientId: string, jointAccounts: ClientAccount[]) => {
    const jointAccountHolders = response.data.filter((x) => x.id !== clientId);
    const jointAccountIds = jointAccounts.map((a) => a.id);

    jointAccountHolders.forEach((jointAccountHolder) => {
      jointAccountHolder.accounts = jointAccountHolder.accounts.filter((a) => jointAccountIds.includes(a.id));
    });

    return jointAccountHolders;
  }

  private formatFieldsString = (fields: string[]): string => `fields=Data(${fields.map((f) => f)})`
}

export default ClientService;
