import { MirrorviewLink, REACT_APP_URL_EN, REACT_APP_URL_FR } from "../../../constants/API";
import { ClientProfileUrl } from "../../../constants/Router";
import { EmptyValue } from "../../constants";
import { DomainUrl } from "../../enum/DomainUrl";
import { PartyTypeCode } from "../../enum/PartyTypeCode";
import PhoneTypeCode from "../../enum/PhoneTypeCode";
import { Phone } from "../../interfaces/Phone";
import { Client } from "../interfaces/Client";

export default class ClientHelper {
  static getFullName(client: Client) {
    if (!client) {
      return EmptyValue;
    }

    return this.isOrganization(client.partyTypeCode) ? client.businessName : `${client.firstName} ${client.lastName}`;
  }

  static isOrganization(partyTypeCode: string) {
    return partyTypeCode.toLowerCase() === PartyTypeCode.Organization.toLowerCase();
  }

  static getMirrorViewLink(id: string) {
    const appUrl = window.location.origin.toLowerCase().includes(DomainUrl.IASA.toLowerCase()) ? REACT_APP_URL_EN : REACT_APP_URL_FR;
    const url = `${appUrl}${MirrorviewLink}${id}`;
    return url;
  }

  static getClientId(value: string) {
    const clientId = (value.length > 6 ? value.substr(0, 6) : value).toUpperCase();
    return clientId;
  }

  static clientProfileUrl(id: string): string {
    return ClientProfileUrl.replace(":id", id);
  }

  static getClientPhoneNumber(client: Client): string {
    const { phones } = client;
    if (phones.length === 0) {
      return "";
    }

    const primaryPhones = phones.filter((p: Phone) => p.phoneTypeCode === PhoneTypeCode.Principal);
    if (primaryPhones.length > 0) {
      return primaryPhones[0].phoneNumber;
    }

    return phones[0].phoneNumber;
  }

  static getClientEmail(client: Client): string {
    const { emails } = client;

    if (emails !== undefined && emails.length > 0) {
      return emails[0].emailAddress;
    }

    return "";
  }
}
