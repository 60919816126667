import styled from "styled-components";
import Colors from "../../../../../../themes/colors";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Label = styled.div`
  font-family: 'Open Sans SemiBold','Open Sans Regular','Open Sans',sans-serif;
  font-size: 14px;
  font-weight: 650;
`;

export const PasswordText = styled.div`
  font-family: 'Open Sans SemiBold','Open Sans Regular','Open Sans',sans-serif;
  font-size: 14px;
  color: #379D00;
  font-weight: 400;
`;

export const Header = styled.div`
    background: ${Colors.cobalt};
    font-size: 14px;
    font-family: 'Open Sans SemiBold','Open Sans Regular','Open Sans',sans-serif;
    font-weight: 650;
    color: ${Colors.white};
    padding: 10px 16px 10px 16px;
    margin-bottom: 24px;
`;