import { Account } from "../interfaces/Account";

export enum AccountActionTypes {
  GET_ACCOUNTS_CLIENT_INFO_BY_CLIENTIDS = "[ACCOUNT] Get Accounts Client Information by ClientIds",
  GET_ACCOUNTS_CLIENT_INFO_BY_CLIENTIDS_SUCCESS = "[ACCOUNT] Get Accounts Client Information by ClientIds Success",
  GET_ACCOUNTS_CLIENT_INFO_BY_CLIENTIDS_ERROR = "[ACCOUNT] Get Accounts Client Information by ClientIds Error",
}

export interface GetAccountsClientInfoByClientIds {
  type: AccountActionTypes.GET_ACCOUNTS_CLIENT_INFO_BY_CLIENTIDS;
  clientIds: string[];
}

export interface GetAccountsClientInfoByClientIdsSuccess {
  type: AccountActionTypes.GET_ACCOUNTS_CLIENT_INFO_BY_CLIENTIDS_SUCCESS;
  accounts: Account[];
}

export interface GetAccountsClientInfoByClientIdsError {
  type: AccountActionTypes.GET_ACCOUNTS_CLIENT_INFO_BY_CLIENTIDS_ERROR;
}

export type AccountAction = GetAccountsClientInfoByClientIds | GetAccountsClientInfoByClientIdsSuccess | GetAccountsClientInfoByClientIdsError;
