import { InfoMessage, InjectReducerProps, InjectSagaProps, InjectTranslationsProps, Loader } from "ia-react-core";
import * as React from "react";
import { Helmet } from "react-helmet";
import { RouteChildrenProps } from "react-router-dom";
import { animateScroll } from "react-scroll";
import ClientActionEvent from "../../common/client/enum/clientActionEvent";
import { Client } from "../../common/client/interfaces/Client";
import {
  getClient as getClientAction,
  loadMoreClients as loadMoreClientsAction,
  searchClient as searchClientAction,
} from "../../common/client/redux/client.actions";
import ErrorBoundary from "../../common/components/ErrorBoundary";
import Section from "../../common/enum/Section";
import { SearchCriteria, SelectedItem } from "../../common/interfaces/SearchCriteria";
import { ClearSession } from "../../common/utils/sessionManager";
import { LimitSearchClient, REACT_APP_AX360_URL_EN, REACT_APP_AX360_URL_FR } from "../../constants/API";
import { ClientState } from "../../interfaces/ClientState";
import {
  resetError as resetErrorAction,
  setSearchCriteriaHistory as setSearchCriteriaHistoryAction,
} from "../../redux/app.actions";
import { ClientSearchContainer, LoadingContainer } from "./ClientSearch.style";
import ClientSearchResult from "./components/ClientSearchResult/ClientSearchResult";
import Search from "./components/Search/Search";
import { Link } from "../../common/common.style";
import { DomainUrl } from "../../common/enum/DomainUrl";

export interface ClientSearchProps {}

export interface ClientSearchDispatchToProps {
  searchClient: typeof searchClientAction;
  getClient: typeof getClientAction;
  loadMoreClients: typeof loadMoreClientsAction;
  setSearchCriteriaHistory: typeof setSearchCriteriaHistoryAction;
  resetError: typeof resetErrorAction;
}

export interface ClientSearchMapStateToProps {
  clients: Client[];
  criteria: string;
  repCodes: string[];
  isSupport: boolean;
  loading: boolean;
  searchLoading: boolean;
  totalResults: number;
  currentOffset: number;
  searchCriteria: SearchCriteria;
  locale: string;
}

export interface ClientSearchComposedProps extends ClientSearchProps, ClientSearchMapStateToProps, ClientSearchDispatchToProps, InjectTranslationsProps, InjectReducerProps<ClientState>, InjectSagaProps, RouteChildrenProps<{}, SearchCriteria> {}

export interface LocalState {
  hasSearched: boolean;
}
class ClientSearch extends React.PureComponent<ClientSearchComposedProps, LocalState> {
  public constructor(props: ClientSearchComposedProps) {
    super(props);

    this.state = {
      hasSearched: false,
    };
  }

  private onSearch = (text: string, repCodes: string[]): void => {
    const { searchClient } = this.props;
    searchClient({ text, repCodes, limit: LimitSearchClient });
    this.setState({ hasSearched: true });
  };

  private onReset = (): void => {
    const { searchClient, repCodes, isSupport, resetError } = this.props;
    resetError();
    if (!isSupport) {
      searchClient({ text: "", repCodes, limit: LimitSearchClient });
    }
    this.setState({ hasSearched: !isSupport });
  };

  private onActionEvent = (id: string, action: ClientActionEvent, event?: React.MouseEvent<HTMLElement>): void => {
    const { getClient, setSearchCriteriaHistory, searchCriteria, clients } = this.props;

    switch (action) {
      case ClientActionEvent.requestPreferedAccessCode:
        getClient(id, false);
        break;
      case ClientActionEvent.selectedClient:
        if (event) {
          const selectedItem: SelectedItem = {
            id,
            position: {
              x: event.clientX,
              y: event.clientY,
            },
          };

          setSearchCriteriaHistory({ ...searchCriteria, selectedItem, limit: clients.length });
        }
        break;
      default:
        break;
    }
  };

  public componentDidUpdate = (prevProps: ClientSearchComposedProps) => {
    const { clients, searchCriteria } = this.props;
    if (prevProps.clients.length === 0 && clients.length > 0 && searchCriteria.selectedItem) {
      animateScroll.scrollTo(searchCriteria.selectedItem.position.y);
    }
    ClearSession();
  };

  public componentDidMount = () => {
    const { searchClient, searchCriteria, isSupport } = this.props;
    if (!isSupport || searchCriteria.selectedItem) {
      searchClient(searchCriteria);
      this.setState({ hasSearched: true });
    }
    ClearSession();
  };

  public render() {
    const {
      t, i18n, clients, searchCriteria, repCodes, loading, totalResults, currentOffset, loadMoreClients, searchLoading, isSupport, locale,
    } = this.props;
    const { hasSearched } = this.state;
    const ax360Url = locale.substring(0, 2) == "en" ? REACT_APP_AX360_URL_EN : REACT_APP_AX360_URL_FR;

    return (
      <ClientSearchContainer>

        <InfoMessage style={{ marginBottom: '12px' }}>
          {t("App:clientSearch.visitAx360")}
          <Link href={ax360Url} target="_blank" rel="noopener noreferrer">
          {t('App:clientSearch.Ax360Link')}
          </Link>
        </InfoMessage>

        <Search t={t} onSearch={this.onSearch} onReset={this.onReset} loading={loading} repCodes={repCodes} searchCriteria={searchCriteria} isSupport={isSupport} />

        <ErrorBoundary sections={[Section.clientSearch]}>
          {hasSearched && (
            <ClientSearchResult
              t={t}
              locale={locale}
              loading={loading}
              data={clients}
              searchCriteria={searchCriteria}
              totalResultCount={totalResults}
              loadMore={() => loadMoreClients(searchCriteria, currentOffset)}
              onActionEvent={this.onActionEvent}
              searchLoading={searchLoading}
            />
          )}
        </ErrorBoundary>

        {loading && (
          <LoadingContainer>
            <Loader />
          </LoadingContainer>
        )}
      </ClientSearchContainer>
    );
  }
}

export default ClientSearch;
