import { InjectTranslationsProps, MainLoader } from "ia-react-core";
import * as React from "react";
import { Helmet } from "react-helmet";
import "react-responsive-tabs/styles.css";
import { RouteChildrenProps } from "react-router";
import { Client } from "../../common/client/interfaces/Client";
import { getClient as getClientAction } from "../../common/client/redux/client.actions";
import ClientHelper from "../../common/client/utils/ClientHelper";
import BackLink from "../../common/components/BackLink/BackLink";
import ErrorBoundary from "../../common/components/ErrorBoundary";
import { PartyTypeCode } from "../../common/enum/PartyTypeCode";
import Section from "../../common/enum/Section";
import StyledTabs from "../../common/tab/StyledTabs";
import { REACT_APP_USE_CLIENT_TO_ADVISOR_MESSAGING } from "../../constants/API";
import { ClientsSearchUrl } from "../../constants/Router";
import { ClientName, ClientProfileContainer } from "./clientProfile.style";
import DocumentsTab from "./components/tabs/documents";
import InvestorProfileTab from "./components/tabs/investorProfile/InvestorProfileTab";
import LogsTab from "./components/tabs/logs/LogsTab";
import ProfileTab from "./components/tabs/profile/ProfileTab";
import { AccountByAdvisorGroupedByRepCode } from "./interfaces/AccountByAdvisorGroupedByRepCode";
import { AccountGroupedByBankAccount } from "./interfaces/AccountGroupedByBankAccount";
import { AccountGroupedByOwner } from "./interfaces/AccountGroupedByOwner";
import SettingsTab from "./components/tabs/settings/SettingsTab";
import { ClearSession } from "../../common/utils/sessionManager";

export interface ClientProfileProps {}

export interface ClientProfileDispatchToProps {
  getClient: typeof getClientAction;
}

export interface ClientProfileStateToProps {
  accountsByAdvisorsGroupedByRepCodes: AccountByAdvisorGroupedByRepCode[];
  accountsGroupedByBankAccounts: AccountGroupedByBankAccount[];
  accountsGroupedByOwner: AccountGroupedByOwner[];
  client: Client;
  jointAccountHolders: Client[];
  loading: boolean;
  loadingAccounts: boolean;
  clientHasOwnedAccounts: boolean;
  locale: string;
  repCodes: string[];
  selectedClientLocked : boolean;
}

interface ClientProfilParam {
  id: string;
}

export interface ClientProfileComposedProps extends ClientProfileProps, ClientProfileStateToProps, ClientProfileDispatchToProps, InjectTranslationsProps, RouteChildrenProps<ClientProfilParam> {}

class ClientProfile extends React.PureComponent<ClientProfileComposedProps> {
  private getTabs() {
    const { t, client, clientHasOwnedAccounts } = this.props;
    const tabs = [
      { title: t("profileTab.title"), getContent: () => <ProfileTab {...this.props} /> },
      { title: t("investorTab.title"), getContent: () => <InvestorProfileTab {...this.props} /> },
    ];

    const displayDocumentTab: boolean = REACT_APP_USE_CLIENT_TO_ADVISOR_MESSAGING && client.partyTypeCode !== PartyTypeCode.Organization && clientHasOwnedAccounts;
    if (displayDocumentTab) {
      tabs.push({ title: t("documentsTab.title"), getContent: () => <DocumentsTab client={client} /> });
    }

    tabs.push({ title: t("settingsTab.title"), getContent: () => <SettingsTab {...this.props} /> });
    tabs.push({ title: t("logsTab.title"), getContent: () => <LogsTab {...this.props} /> });
    return tabs;
  }

  public componentDidMount = () => {
    const { getClient, match } = this.props;
    if (match) {
      const { params } = match;
      if (params.id) {
        getClient(params.id, true);
        ClearSession();
      }
    }
  };

  public render() {
    const { t, client, history } = this.props;
    if (!client) {
      return <MainLoader />;
    }

    return (
      <ClientProfileContainer>
        <Helmet>
          <title>{t("title")}</title>
        </Helmet>
        <BackLink to={{ pathname: ClientsSearchUrl, state: history.location.state }}>{t("App:clientSearch.title")}</BackLink>
        <ErrorBoundary sections={[Section.clientProfile]}>
          <>
            <ClientName>{ClientHelper.getFullName(client)}</ClientName>
            <StyledTabs items={this.getTabs()} />
          </>
        </ErrorBoundary>
      </ClientProfileContainer>
    );
  }
}

export default ClientProfile;
