import "antd/es/tree-select/style/css";

import { TFunction } from "i18next";
import { Control, ControlRenderProp } from "ia-react-core";
import React from "react";

import { Container, GlobalTreeViewSelectStyle } from "./RepCodeSelector.style";
import RepCodeSelectorTreeSelect from "./RepCodeSelectorTreeSelect";

export interface RepCodeSelectorProps {
  t: TFunction;
  name: string;
  repCodes: string[];
}

class RepCodeSelector extends React.PureComponent<RepCodeSelectorProps> {
  private renderEmptyControl = (): React.ReactNode => {
    const { repCodes, name } = this.props;
    const repCode = repCodes.length === 1 ? repCodes[0] : "";
    return (
      <Control name={name} defaultValue={repCode}>
        {() => <input type="hidden" name={name} />}
      </Control>
    );
  };

  public render() {
    const { repCodes, name, t } = this.props;

    if (repCodes.length <= 1) {
      this.renderEmptyControl();
    }

    return (
      <Container>
        <Control name={name} defaultValue={repCodes}>
          {({ onValueChange, value }: ControlRenderProp<string[], Element>) => (
            <>
              <GlobalTreeViewSelectStyle />
              <RepCodeSelectorTreeSelect t={t} onValueChange={onValueChange} value={value} repCodes={repCodes} />
            </>
          )}
        </Control>
      </Container>
    );
  }
}

export default RepCodeSelector;
