import { ColumnType } from "antd/lib/table";
import * as React from "react";

import { Client } from "../../../../../../common/client/interfaces/Client";
import Formatter from "../../../../../../common/utils/Formatter";
import { HidableText } from "../../../../interfaces/HidableText";
import HidableInfo from "../../../HidableInfo";
import { JointAccountHoldersTableProps } from "../JointAccountHoldersTable";

export function SinColumn(props: JointAccountHoldersTableProps): ColumnType<Client> {
  const { t } = props;
  const hidableText: HidableText = {
    show: t("App:clickToView.show"),
    hide: t("App:clickToView.hide"),
  };

  return {
    title: t("App:sin"),
    dataIndex: "sin",
    width: "25%",
    key: "sin",
    render: (value: string) => (
      <HidableInfo info={Formatter.formatSin(value)} text={hidableText} />
    ),
  };
}
