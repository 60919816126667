import {
  CheckboxItemControl,
  Form,
  FormCol,
  FormRow,
  InjectReducerProps,
  InjectSagaProps,
  MainLoader,

  TextControl,
} from "ia-react-core";
import * as React from "react";
import { REACT_APP_USE_MOCK_ADVISOR, REACT_APP_USE_TOKEN } from "../../constants/API";
import { State } from "../../interfaces/State";
import { SubmitButton } from "../common.style";
import { setUser as setUserAction } from "../user/redux/user.actions";
import { FormContainer, Icon, Setting, Title } from "./AppLoader.style";

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface AppLoaderProps {
  children: React.ReactNode;
}

export interface AppLoaderDispatchToProps {
  setUser: typeof setUserAction;
}

export interface AppLoaderMapStateToProps {
  repCodes: string[];
  isSupport: boolean;
  hasUser: boolean;
}

export interface AppLoaderComposedProps extends AppLoaderProps, AppLoaderDispatchToProps, AppLoaderMapStateToProps, InjectReducerProps<State>, InjectSagaProps {}

interface LocalState {
  menuOpen: boolean;
}

interface FormValue {
  isSupport: boolean;
  repCodes: string;
}

class AppLoader extends React.PureComponent<AppLoaderComposedProps, LocalState> {
  constructor(props: AppLoaderComposedProps) {
    super(props);

    this.state = {
      menuOpen: false,
    };
  }

  private submit = (formValues: FormValue) => {
    const { setUser } = this.props;
    const repCodes =
      formValues.repCodes === ""
        ? []
        : formValues.repCodes
          .trim()
          .split(",")
          .map((repcode: string) => repcode.trim());

    setUser(repCodes, formValues.isSupport);

    this.setState({ menuOpen: false });
  };

  private toggleMenu = () => {
    const { menuOpen } = this.state;
    this.setState({ menuOpen: !menuOpen });
  };

  public componentDidMount = () => {
    const { setUser } = this.props;
    if (REACT_APP_USE_TOKEN) {
      // axiosInstance.defaults.headers.Bearer = `${REACT_APP_MOCK_TOKEN}`;
      setUser([], true);
    }
  };

  public render() {
    const { children, repCodes, hasUser } = this.props;

    if (!hasUser) {
      return <MainLoader />;
    }

    if (!REACT_APP_USE_MOCK_ADVISOR) {
      return <div id="appDynamicsMonitoring">{children}</div>;
    }

    const { menuOpen } = this.state;
    return (
      <>
        <Setting open={menuOpen}>
          <Icon onClick={this.toggleMenu}>
            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
              <g>
                <path d="M0,0h24v24H0V0z" fill="none" />
                <path d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z" />
              </g>
            </svg>
          </Icon>
          <FormContainer>
            <Form<FormValue> defaultCol={4} name="advisorMock" onSubmit={(formValues: any) => this.submit(formValues)} initialValues={{ repCodes: repCodes.join(","), isSupport: true }}>
              <Title>Change advisor information</Title>
              <FormRow>
                <FormCol label="RepCodes separate by comma (,)">
                  <TextControl name="repCodes" placeholder="RepCodes separate by comma (,)" />
                </FormCol>
              </FormRow>
              <FormRow>
                <FormCol>
                  <CheckboxItemControl name="isSupport" label="User is support user" />
                </FormCol>
              </FormRow>
              <FormRow>
                <FormCol>
                  <SubmitButton>Apply</SubmitButton>
                </FormCol>
              </FormRow>
            </Form>
          </FormContainer>
        </Setting>
        {children}
      </>
    );
  }
}

export default AppLoader;
