enum InvestmentObjectiveLevel{
    Unknown,
    Income,
    Growth,
    Speculative,
    Liquidity,
    Safety
}

export default InvestmentObjectiveLevel;
