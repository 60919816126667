/* eslint-disable react/no-unused-prop-types */
import { TFunction } from "i18next";
import * as React from "react";

import { Client } from "../../../../../../common/client/interfaces/Client";
import { LongDate } from "../../../../../../common/components/FormatDate";
import Formatter from "../../../../../../common/utils/Formatter";
import Value from "../../../../../../common/utils/Value";
import { HidableText } from "../../../../interfaces/HidableText";
import HidableInfo from "../../../HidableInfo";
import { LabeledValue, SectionContent } from "../profileTab.style";

export interface PersonalInformationCustomerProps {
  client: Client;
  t: TFunction;
  locale: string;
}

const hasOccupations = (client: Client): boolean => client && client.occupations && client.occupations.length > 0;

const getEmployer = (client: Client): string | undefined => hasOccupations(client)
  ? client.occupations[0].employer
  : undefined;

const getOccupationDescription = (client: Client): string | undefined => hasOccupations(client)
  ? client.occupations[0].description
  : undefined;

const getNetWorth = (client: Client): string | undefined => client.financialProfile
  ? client.financialProfile.netWorth
  : undefined;

const getAnnualIncome = (client: Client): string | undefined => client.financialProfile
  ? client.financialProfile.annualIncome
  : undefined;

const getInformations = ({ client, locale, t }: PersonalInformationCustomerProps): JSX.Element[] => {
  const hidableText: HidableText = {
    show: t("App:clickToView.show"),
    hide: t("App:clickToView.hide"),
  };

  const items = [
    { label: "gender", labelSource: "App:", value: t(`App:genderOptions.${client.genderCode}`) },
    { label: "employer", value: <Value>{getEmployer(client)}</Value> },
    { label: "dateOfBirth", labelSource: "App:", value: <LongDate locale={locale}>{client.dateOfBirth}</LongDate> },
    { label: "occupation", value: <Value>{getOccupationDescription(client)}</Value> },
    { label: "sin", labelSource: "App:", value: <HidableInfo info={Formatter.formatSin(client.sin)} text={hidableText} /> },
    { label: "annualIncome", value: <Value>{getAnnualIncome(client)}</Value> },
    { label: "language", labelSource: "App:", value: t(`App:languageOptions.${client.languageCode}`) },
    { label: "netWorth", value: <Value>{getNetWorth(client)}</Value> },
  ];

  return items.map((i) => (
    <LabeledValue key={i.label} label={t(`${i.labelSource ? i.labelSource : "profileTab.personalInformation."}${i.label}`)}>
      {i.value}
    </LabeledValue>
  ));
};

const PersonalInformationCustomer: React.FC<PersonalInformationCustomerProps> = (props: PersonalInformationCustomerProps) => <SectionContent>{getInformations(props)}</SectionContent>;

export default PersonalInformationCustomer;
