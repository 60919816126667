import { Account } from "../../account/interfaces/Account";

export interface IAccount extends IPartyBaseAccount {
  accountType: string;
  name: string;
  groupedAccountName: string;
  repCode: string;
  openDate: Date | null;
  closingDate: Date | null;
  isOpen: boolean;
}

export enum PartyTypeCode {
  Customer = 'customer',
  NonClient = 'non-client',
  Organization = 'organization',
}

export interface IParty {
  id: string;
  partyTypeCode: PartyTypeCode;
  accessCode: string;
  personalizedAccessCode: string;
  firstName: string;
  lastName: string;
  businessName: string;
  dateRegistered: Date;
  addresses: IPartyAddress[];
  emails: IPartyEmail[];
  dateOfBirth: Date;
  accounts: IPartyAccount[];
  preferedAccountIds: string[];
  candidateId: string;
  metadata: IMetadata[];
  languageCode: string;
  isLocked:boolean;
}

export interface IPartyAddress {
  unitTypeCode: string; // "APT"
  unitNumber: string;
  streetNumber: string;
  streetName: string;
  municipality: string;
  postalCode: string;
  regionCode: string;
  countryCode: string;
}

export interface IPartyEmail {
  emailTypeCode: string; // "main"
  emailAddress: string;
}

export interface IMetadata {
  key: string;
  value: string[];
  system: string;
  lastModified: Date;
}

export interface IPartyBaseAccount {
  id: string;
  rootId: string;
}

export interface IPartyAccount extends IPartyBaseAccount {
  id: string;
  role: string;
  noRole: number;
  accounts: Account[];
  repCode: string;
  partyId: string;
  name: string;
}
