export default {
  title: "Coordonnées du conseiller",
  iAWealthAdviceCentre: "iA Patrimoine, Centre de conseils",
  team: "Équipe",
  regulatoryRoles: {
    trader: "Commerçant",
    licensedAssistant: "Adjoint autorisé",
    chiefComplianceOfficer: "Chef de la conformité",
    supervisor: "Superviseur",
    investmentAdvisor: "Conseiller en placement",
    corporateBranchManager: "Directeur de succursale de l’entreprise",
    seniorInvestmentAdvisor: "Conseiller principal en placements",
    branchAdministrator: "Administrateur de succursale (AS)",
    seniorFinancialAdvisor: "Conseiller financier principal",
    regionalVicePresident: "Conseiller principal en placements",
    supervisingPortfolioManager: "Gestionnaire de portefeuille",
    seniorWealthAdvisor: "Conseiller principal, gestion de patrimoine",
    seniorWealthAdvisorFemale: "Conseillère en gestion de patrimoine principale",
    wealthAdvisor: "Conseiller en gestion de patrimoine",
    associateInvestmentAdvisor: "Conseiller en placements associé",
    portfolioManager: "Gestionnaire de portefeuille",
    seniorPortfolioManager: "Gestionnaire de portefeuille principal",
    unlicensedAssistant: "",
    seniorBranchManager: "Directeur principal de succursale",
    portfolioManagerUnderSupervision: "Gestionnaire de portefeuille",
    chiefFinancialOfficer: "Chef des finances",
    qcFinancialPlanner: "QC Planificateur financier",
    institutionalSupervisor: "Institutionnel Superviseur",
    executive: "Conseiller en placement",
    other: "",
  },
};
