import { get, getScoped } from "ia-react-core";
import { createSelector } from "reselect";
import { State } from "../../../interfaces/State";
import { Account } from "../../account/interfaces/Account";
import { selectAccounts } from "../../account/redux/account.selector";
import { HouseholdType } from "../../enum/HouseholdType";
import { ClientAccount } from "../interfaces/Account";
import { Client } from "../interfaces/Client";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectClientWithScope = (state: State, scope: any): Client => (getScoped(state, "selectedClient", scope) as unknown) as Client;
export const selectClient = (state: State): Client => (get(state, "App.ClientProfile.selectedClient") as unknown) as Client;
export const selectedClientLocked = (state: State): boolean => (get(state, "App.ClientProfile.isLocked") as unknown) as boolean;

export const selectHasOwnedAccounts = createSelector(selectClientWithScope, (client: Client) => client ? client.accounts.filter((account) => account.role !== HouseholdType.SHARING).length > 0 : false);

export const selectOwnedOpenAccounts = createSelector(
  selectClientWithScope, 
  selectAccounts, 
  (client: Client, allAccounts: Account[]) => openOwnedAccountFilter(client, allAccounts),
);

export const selectHasOwnedOpenAccounts = createSelector(
  selectClientWithScope, 
  selectAccounts, 
  (client: Client, allAccounts: Account[]) => {
    const ownedOpenAccounts = openOwnedAccountFilter(client, allAccounts);
    return ownedOpenAccounts.length > 0;
  },
);

const openOwnedAccountFilter = (client: Client, allAccounts: Account[]): Account[] => {
  const { accounts } = client;
  const ownedAccounts = accounts.filter((a: ClientAccount) => a.role !== HouseholdType.SHARING);
  const ownedOpenAccounts = allAccounts.filter((account: Account) => account.isOpen && ownedAccounts.some((a) => account.rootId === a.id));
  return ownedOpenAccounts;
};
