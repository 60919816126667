import { InjectSagaProps, InjectTranslationsProps, Loader } from "ia-react-core";
import * as React from "react";
import { Helmet } from "react-helmet";
import { animateScroll } from "react-scroll";

import ErrorBoundary from "../../../common/components/ErrorBoundary";
import Section from "../../../common/enum/Section";
import { SearchCriteria, SelectedItem } from "../../../common/interfaces/SearchCriteria";
import { SignaturePackageStatus, SignerStatus } from "../../../common/signaturePackage/enum";
import SignaturePackageActionEvent from "../../../common/signaturePackage/enum/SignaturePackageActionEvent";
import { SignaturePackage } from "../../../common/signaturePackage/interfaces/SignaturePackage";
import {
  loadMoreSignaturePackages as loadMoreSignaturePackagesAction,
  searchSignaturePackage as searchSignaturePackageAction,
} from "../../../common/signaturePackage/redux/signaturePackage.actions";
import { LimitSearchSignaturePackage } from "../../../constants/API";
import {
  resetError as resetErrorAction,
  setSearchCriteriaHistory as setSearchCriteriaHistoryAction,
} from "../../../redux/app.actions";
import { Container, LoadingContainer } from "../SignaturePackage.style";
import TrackingSearchResult from "./components/Result/Result";
import Search from "./components/Search/Search";
import StatusFilters from "./components/StatusFilters";

/* eslint-disable no-console */
export interface TrackingSearchProps {}

export interface TrackingSearchDispatchToProps {
  searchSignaturePackage: typeof searchSignaturePackageAction;
  loadMoreSignaturePackages: typeof loadMoreSignaturePackagesAction;
  setSearchCriteriaHistory: typeof setSearchCriteriaHistoryAction;
  resetError: typeof resetErrorAction;
}

export interface TrackingSearchMapStateToProps {
  signaturePackages: SignaturePackage[];
  criteria: string;
  repCodes: string[];
  isSupport: boolean;
  loading: boolean;
  searchLoading: boolean;
  totalResults: number;
  currentOffset: number;
  searchCriteria: SearchCriteria;
  locale: string;
}

export interface TrackingSearchComposedProps extends TrackingSearchProps, TrackingSearchDispatchToProps, TrackingSearchMapStateToProps, InjectTranslationsProps, InjectSagaProps {}

export interface LocalState {
  hasSearched: boolean;
  selectedStatusFilter: string[];
}

class TrackingSearch extends React.PureComponent<TrackingSearchComposedProps, LocalState> {
  public constructor(props: TrackingSearchComposedProps) {
    super(props);

    this.state = {
      hasSearched: false,
      selectedStatusFilter: this.defaultActiveStatusFilters(),
    };
  }

  private onSearch = (text: string, repCodes: string[]): void => {
    const { searchSignaturePackage } = this.props;
    const { selectedStatusFilter } = this.state;
    if (selectedStatusFilter.length > 0) {
      searchSignaturePackage({ text, repCodes, limit: LimitSearchSignaturePackage, statusFilters: selectedStatusFilter });
      this.setState({ hasSearched: true });
    }
  };

  private onReset = (): void => {
    const { searchSignaturePackage, repCodes, isSupport, resetError } = this.props;
    resetError();
    if (!isSupport) {
      searchSignaturePackage({ text: "", repCodes, limit: LimitSearchSignaturePackage });
    }
    this.setState({ hasSearched: !isSupport });
  };

  private onActionEvent = (id: string, action: SignaturePackageActionEvent, event?: React.MouseEvent<HTMLElement>): void => {
    const { setSearchCriteriaHistory, searchCriteria, signaturePackages } = this.props;

    switch (action) {
      case SignaturePackageActionEvent.unlock:
        break;
      case SignaturePackageActionEvent.selectedSignaturePackage:
        if (event) {
          const selectedItem: SelectedItem = {
            id,
            position: {
              x: event.clientX,
              y: event.clientY,
            },
          };

          setSearchCriteriaHistory({ ...searchCriteria, selectedItem, limit: signaturePackages.length });
        }
        break;
      default:
        break;
    }
  };

  public componentDidUpdate = (prevProps: TrackingSearchComposedProps) => {
    const { signaturePackages, searchCriteria } = this.props;
    if (signaturePackages) {
      if (prevProps.signaturePackages.length === 0 && signaturePackages.length > 0 && searchCriteria.selectedItem) {
        animateScroll.scrollTo(searchCriteria.selectedItem.position.y);
      }
    }
  };

  public componentDidMount = () => {
    const { searchSignaturePackage, searchCriteria, isSupport } = this.props;
    if (isSupport && searchCriteria.selectedItem) {
      searchSignaturePackage(searchCriteria);
      this.setState({ hasSearched: true });
    }
  };

  // Replace this by redux state saved value if necessary for page data persistence
  private defaultActiveStatusFilters = (): Array<SignaturePackageStatus | SignerStatus> => [
    SignaturePackageStatus.awaiting,
    SignaturePackageStatus.completed,
    SignaturePackageStatus.refused,
    SignaturePackageStatus.cancelled,
    SignaturePackageStatus.expired,
    SignerStatus.emailrebound,
    SignerStatus.locked,
  ];

  private onFiltersChanged = (statusActive: string[]): void => {
    this.setState({ selectedStatusFilter: statusActive });
  };

  public render() {
    // eslint-disable-next-line object-curly-newline
    const { t, i18n, searchCriteria, repCodes, loading, isSupport, signaturePackages, totalResults, currentOffset, searchLoading, loadMoreSignaturePackages, locale } = this.props;
    const { hasSearched } = this.state;
    return (
      <Container>
        <Helmet>
          <title>{`${t("title")} - ${t("SignaturePackage:title")}`}</title>
        </Helmet>

        <Search t={t} onSearch={this.onSearch} onReset={this.onReset} loading={loading} repCodes={repCodes} searchCriteria={searchCriteria} isSupport={isSupport} />
        <StatusFilters t={t} defaultActiveStatus={this.defaultActiveStatusFilters()} onFilterChanged={this.onFiltersChanged} />
        <ErrorBoundary sections={[Section.signaturePackageSearch]}>
          {hasSearched && (
            <TrackingSearchResult
              t={t}
              locale={locale}
              loading={loading}
              data={signaturePackages}
              searchCriteria={searchCriteria}
              totalResultCount={totalResults}
              loadMore={() => loadMoreSignaturePackages(searchCriteria, currentOffset)}
              onActionEvent={this.onActionEvent}
              searchLoading={searchLoading}
              isSupport={isSupport}
            />
          )}
        </ErrorBoundary>

        {loading && (
          <LoadingContainer>
            <Loader />
          </LoadingContainer>
        )}
      </Container>
    );
  }
}

export default TrackingSearch;
