import styled from 'styled-components';
import { withStyles, Theme, InputBase, createStyles, InputLabel, Button } from '@material-ui/core';
import { MuiTelInput } from 'mui-tel-input'
import PhoneInput from 'react-phone-input-2'

export const MainBox = styled.div`
    background: #FFF;
    padding: 15px;
    box-shadow: rgba(102, 102, 102, 0.35) 1px 1px 1px 2px;
`;

export const Container = styled.div`
    margin: 20px;

`;

export const SubTitle = styled.div`
    font-family: 'Open Sans SemiBold', 'Open Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    color: #333333;
`;

export const Title = styled.div`
    font-family: 'Open Sans SemiBold', 'Open Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    color: #333333;
    font-size: 24px;
    padding-bottom: 3px;
`;

export const StepContainer = styled.div`
    padding-bottom: 20px;
`;

export const FontBold = styled.div`
    font-weight: 700;
`;

export const IALink = styled.div`
    color: #2062D4;
    font-family: 'Open Sans SemiBold', 'Open Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const Warning = styled.div`
  font-family: 'Open Sans SemiBold', 'Open Sans', sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #1E1E1E;
  font-size: 14px;
  margin-top:20px;
  margin-bottom:20px;
  padding:20px;
  background-color: rgba(255, 0, 0, 0.0980392156862745);
  border: solid 1px rgb(255, 0, 0);
  width:100%;
  display: flex;
  flex-direction: row;
`;

export const LabelWhite = styled.span`
    font-family: 'Open Sans SemiBold', 'Open Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    color: #FFFFFF;
    font-size: 14px;
`;

export const TextFieldIA = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },

      '& input:invalid ': {
        borderColor: 'red',
      },
    },

    input: {
      position: 'relative',
      backgroundColor: 'rgba(239, 241, 244, 1)',
      border: '1px solid rgb(190, 196, 206)',
      fontSize: 16,
      width: '250px',
      padding: '10px 12px',
      fontFamily: [
        'Open Sans',

      ].join(','),
      '&:focus': {
        borderColor: theme.palette.primary.main,
      },
    },
  }))(InputBase);

  export const PhoneFieldIA = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },

      '& input:invalid ': {
        borderColor: 'red',
      },
    },

    input: {
      position: 'relative',
      backgroundColor: 'rgba(239, 241, 244, 1)',
      border: '1px solid rgb(190, 196, 206)',
      fontSize: 16,
      width: '250px',
      padding: '10px 12px',
      fontFamily: [
        'Open Sans',

      ].join(','),
      '&:focus': {
        borderColor: theme.palette.primary.main,
      },
    },
  }))(PhoneInput);

  export const SelectFieldIA = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },

      '& input:invalid ': {
        borderColor: 'red',
      },
    },

    input: {
      position: 'relative',
      backgroundColor: 'rgba(239, 241, 244, 1)',
      border: '1px solid rgb(190, 196, 206)',
      fontSize: 16,
      width: '238px',
      padding: '10px 12px',
      fontFamily: [
        'Open Sans',

      ].join(','),
      '&:focus': {
        borderColor: theme.palette.primary.main,
      },
    },
  }))(InputBase);

export const TextFieldLabel = withStyles(() =>
  createStyles({
    root: {
      fontFamily: "'Open Sans SemiBold', 'Open Sans', sans-serif",
      fontWeight: 600,
      fontStyle: 'normal',
      fontSize: 14,
      color: '#1E1E1E',
      transform: 'none !important',
    },
  }))(InputLabel);

export const TextResultFieldLabel = withStyles(() =>
  createStyles({
    root: {
      fontFamily: "'OpenSans-Regular', 'Open Sans', sans-serif",
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: 14,
      color: '#1b1b1b',
      transform: 'none !important',
      lineHeight: 'normal',
      letterSpacing: 'normal'
    },
  }))(InputLabel);

export const IAButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 14,
    padding: '6px 12px',
    margin: '4px 0px 4px 8px',
    color: '#fff',
    lineHeight: 1.5,
    backgroundColor: '#2062d4',
    borderColor: '#2062d4',
    borderRadius: 5,
    fontFamily: ['OpenSans', 'Open Sans'].join(','),
    '&:hover': {
      backgroundColor: '#2062d4',
      borderColor: '#2062d4',
      color: '#fff',
      boxShadow: 'none',
    },
    '&:disabled': {
      backgroundColor: 'rgba(187, 194, 202, 1)',
      borderColor: 'rgba(187, 194, 202, 1)',
      color: '#fff',
      boxShadow: 'none',
    },
  },
})(Button);
