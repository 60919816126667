import {
  AdvisorActionTypes as types,
  GetAdvisorsByRepCodes,
  GetAdvisorsByRepCodesError,
  GetAdvisorsByRepCodesSuccess,
} from "./advisor.actions.types";
import { TeamByRepCode } from "../interfaces/TeamByRepCode";

export const getAdvisorsByRepCodes = (repCodes: string[]): GetAdvisorsByRepCodes => ({ type: types.GET_ADVISORS_BY_REPCODES, repCodes });

export const getAdvisorsByRepCodesSuccess = (teamByRepCode: TeamByRepCode): GetAdvisorsByRepCodesSuccess => ({ type: types.GET_ADVISORS_BY_REPCODES_SUCCESS, teamByRepCode });

export const getAdvisorsByRepCodesError = (): GetAdvisorsByRepCodesError => ({ type: types.GET_ADVISORS_BY_REPCODES_ERROR });
