import { API_ACCOUNTS, API_ACCOUNTS_V2 } from "../../constants/API";
import { IAccountNotificationDto } from "../client/interfaces/Account";
import { IAccount } from "../client/interfaces/Party";
import { HttpGet, HttpPost } from "../utils/HttpClient";
import { Account } from "./interfaces/Account";

class AccountService {
  public async searchByClientIds(clientIds: string[], lang: string): Promise<Account[]> {
    const q = clientIds.join("&clientIds=");
    const response = await HttpGet(`${API_ACCOUNTS}/client/info?clientIds=${q}&lang=${lang}`, {});
    const accounts: Account[] = response.data as Account[];
    return accounts;
  }

  public async sendAccountNotification(itemsToSend: IAccountNotificationDto) {
    try {
      const response = await HttpPost(`${API_ACCOUNTS}/SendAccountNotification`, itemsToSend);
      return response as string;
    } catch (err) {
      return null;
    }
  }

  public async searchAccountsByClientIds(ids: string, subsidiary: string, lang: string): Promise<IAccount[]> {
    const response = await HttpGet(`${API_ACCOUNTS}/client?${ids}&lang=${lang}&subsidiary=${subsidiary}`, {});
    const accounts: IAccount[] = response.data.accounts as IAccount[];
    return accounts;
  }
}

export default AccountService;
