export default {
  nameSearchTitle: "Client name or signature package #",
  repCodeSearchTitle: "Representative Code",
  submit: "Search",
  reset: "Reset",
  statusSubTitle: "Status",
  searchResult: {
    title: "signature packages(s) found",
    for: "for",
    noItemsFound: "No signature packages found",
    columns: {
      createdOn: "Created on",
      createdBy: "Created by",
      documents: "Document(s)",
      signers: "Signer(s)",
      package: "Package",
    },
    actions: {
      viewDetails: "View package details",
      updateEmail: "Update signer's email",
      unlockSigner: "Unlock signer",
      cancel: "Cancel the signature package",
      downloadEvidenceSummary: "Download Evidence Summary (.pdf)",
      downloadSignedDocuments: "Download Signed documents (.zip)",
    },
  },
};
