import styled from "styled-components";

import { Container as DefaultContainer } from "../../common/common.style";

export const Container = styled(DefaultContainer)`
  border: none;
  position: relative;
`;

export const LoadingContainer = styled.div`
  margin-top: 50px;
`;

export const Title = styled.h1`
  font-family: ${(props) => props.theme.fontFamily.bold};
  font-size: 24px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 20px;
  line-height: normal;
  letter-spacing: normal;
  font-style: normal;
`;
