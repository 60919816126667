import 'react-responsive-tabs/styles.css';
import styled from "styled-components";

export const Styled = styled.div`
    .RRT__tab {
        background: #BBC2CA;
        color: #2062DA;
        margin-right: 10px;
        margin-top: 5px;
        padding: 15px 35px;
        font-size: 14px;
        border-width: 0px;
    }
    
    .RRT__tab:hover {
        background: #2062d4;
        color: #FFFFFF;
    }
    
    .RRT__tab--selected:hover {
        background-color: #FFFFFF;
        color: #333333;
        cursor: auto;
    }
        
    .RRT__tab--selected {
        background: #FFFFFF;
        color: #333333;
        font-weight: bold;
        margin-top: 0px;
        border-top-width: 5px;
        border-top-color: #003da5;
    }
    
    .RRT__panel {
        border: none;
        background: #FFFFFF;
        padding: 30px 20px;
    }
  `;
