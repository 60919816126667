import { createGlobalStyle } from 'styled-components';

export const GlobalAccessSharingTableStyle = createGlobalStyle`
.groupColumn {
  font-family: 'OpenSans-SemiBold', 'Open Sans SemiBold', 'Open Sans' !important;
  font-style: normal;
  font-size: 14px !important;
  font-weight: 700 !important;
  background-color: #EFF1F4;
  vertical-align: top;
}

.notOwnedAccount {
  background-color: #EFF1F4;
}

.ant-steps-item-icon {
  background-color: #003ea5d9 !important;
  border-color: #003ea5d9 !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff !important;
  border-color: #003da5 !important;
}

.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fff !important;
  border-color: rgba(0,0,0,.25) !important;
}

.ant-btn-primary {
  color: #fff;
  background-color: #003da5;
  border-color: #003da5;
  text-shadow: 0 -1px 0 rgba(0,0,0,.12);
  box-shadow: 0 2px 0 rgba(0,0,0,.045);
}
.ant-modal-footer button+button {
  margin-bottom: 0;
  margin-left: 8px;
}

`;
