/* eslint-disable @typescript-eslint/no-explicit-any */
import { InjectTranslationsProps } from "ia-react-core";
import React, { Component, ErrorInfo, ReactNode } from "react";

import Section from "../../enum/Section";
import { Warning } from "../../svg";
import { Container } from "./ErrorBoundary.style";
import HttpError from "./interface/HttpError";

/* eslint-disable no-console */
export interface ErrorBoundaryMapStateToPropsProps {
  error: HttpError | null;
}

export interface ErrorBoundaryProps {
  children: ReactNode;
  sections: Section[];
}

interface LocalState {
  hasError: boolean;
}

export interface ErrorBoundaryComposedProps extends ErrorBoundaryProps, InjectTranslationsProps, ErrorBoundaryMapStateToPropsProps {}

export default class ErrorBoundary extends Component<ErrorBoundaryComposedProps, LocalState> {
  private acceptedErrors: number[] = [400, 404];
  
  public constructor(props: ErrorBoundaryComposedProps) {
    super(props);
    
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError() {
    return { hasError: true };
  }

  // set the types for error  and info
  public componentDidCatch(error: Error, info: ErrorInfo) {
    console.error("ErrorBoundary caught an error", error, info);
  }

  private boundaryShouldBeRaised = (error: any): boolean => error === undefined || error.response === undefined || this.acceptedErrors.some((item: any) => error.response.status === item) === false;

  private hasSectionsError = () => {
    const { error, sections } = this.props;

    if (!error) {
      return false;
    }

    if (this.boundaryShouldBeRaised(error.error)) {
      if (sections.some((section: Section) => (error && section === error.section))) {
        return true;
      }
    }

    return false;
  }

  public render() {
    const { children, t } = this.props;
    const { hasError } = this.state;

    if (hasError || this.hasSectionsError()) {
      return (
        <>
          <Container>
            {Warning(44)}
            <div>{t("error")}</div>
          </Container>
        </>
      );
    }

    return children;
  }
}
