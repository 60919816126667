import { TextInput as IATextInput } from "ia-react-core";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const TextInput = styled(IATextInput)`
  background: ${(props) => props.theme.colors.gray1};
  border: none;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray4};
  padding: 3px 2px;
  color: ${(props) => props.theme.colors.BLACK};
  margin: 20px 0;
`;

export const LoadingContainer = styled.div`
  padding: 15px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
`;

export const LoadingText = styled.span`
  flex: 0 0 150px;
`;

export const LoadingControl = styled.div`
  flex: 0 0 24px;
`;

export const Suggestions = styled.div<{ isLoading: boolean }>`
  position: absolute;
  background: #fff;
  box-shadow: 0px 0px 4px #000;
  height: ${(props) => (props.isLoading ? "50px" : "auto")};
  max-height: 230px;
  width: 100%;
  overflow: ${(props) => (props.isLoading ? "hidden" : "hidden auto")};
  top: 65px;
  z-index: 999;
`;

export const EmptyResult = styled.div`
  padding: 15px;
`;

export const Options = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const Option = styled.li`
  margin: 0;
  padding: 10px;
  list-style: none;
  cursor: pointer;
  border-top: 1px solid #aaa;

  :hover {
    background: rgba(185, 185, 185, 0.5);
  }
`;

export const SearchFieldContainer = styled.div<{ hasValueSelected: boolean }>`
  position: relative;
  input {
    padding-right: ${(props) => (props.hasValueSelected ? "45px" : "inherit")};
  }
`;

export const RemoveSelection = styled.button`
  position: absolute;
  width: 38px;
  height: 38px;
  right: 0;
  bottom: 21px;
  border: none;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.colors.primary};
    svg {
      fill: #fff;
    }
  }
`;
