import { ColumnType } from "antd/lib/table";
import * as React from "react";

import { SignaturePackage } from "../../../../../../../common/signaturePackage/interfaces/SignaturePackage";
import ResultActions from "../ResultActions";
import { ResultTableProps } from "../ResultTable";

export function ResultColumnActions(props: ResultTableProps): ColumnType<SignaturePackage> {
  const { t, actionEvent, isSupport } = props;

  return {
    title: t("App:actions"),
    align: "center",
    dataIndex: "name",
    key: "name",
    render: (value: unknown, record: SignaturePackage) => <ResultActions isSupport={isSupport} t={t} signaturePackage={record} actionEvent={actionEvent} />,
  };
}
