import { Col, FormCol, FormRow } from "ia-react-core";
import styled from "styled-components";
import { StyledSection } from "../../../clientProfile.style";

export const Span = styled.span`
  font-family: ${(props) => props.theme.fontFamily.regular};
  margin-bottom: 0;
  font-size: 14px;
  line-height: 13px;
  font-weight: 400;
  color: #333333;
`;

export const Value = styled(Span)`
  color: #666666;
`;

export const Label = styled(Span)`
  font-weight: 600;
  display: block;
`;

export const ProfileContainer = styled(Col)``;
export const PersonalInfoContainer = styled(StyledSection)``;

export const ContactInformationContainer = styled(StyledSection)`
  height: 100%;
`;

export const ClientPortal = styled(StyledSection)`
  margin-top: 1px;
`;

export const ClientPortalBusiness = styled(ClientPortal)`
  height:100%;
`;

export const SectionContent = styled(FormRow)``;

export const LabeledValue = styled(FormCol)`
  font-size: 14px;
  color: #666666;

  fieldset {
    margin-bottom: 12px;

    legend {
      font-size: 14px;
      margin-bottom: 0;
      color: ${(props) => props.theme.colors.black};
    }
  }
`;

export const LabeledValueNotPad = styled(LabeledValue)`
  padding:0;
`;

export const ClickableItem = styled.button`
  background: ${(props) => props.theme.colors.transparent};
  border: none;
  cursor: pointer;
  text-align: left;
  color: ${(props) => props.theme.colors.blue4};
  padding: 0px;

  :hover {
    text-decoration: underline;
  }

  :focus {
    outline: 0;
  }
`;

export const SectionContainer = styled(StyledSection)`
  margin: 10px 0;
`;

export const AdditionnalInfoContainer = styled(StyledSection)`
  margin-top: 20px;
`;
