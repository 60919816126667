import { TFunction } from "i18next";
import * as React from "react";
import { v4 as uuid } from "uuid";

import CheckboxButtons from "../../../../common/components/CheckBox/components/CheckboxButtons/CheckboxButtons";
import CheckboxButtonItem from "../../../../common/components/CheckBox/interfaces/CheckboxButtonItem";
import { SignerStatus } from "../../../../common/signaturePackage/enum";
import SignaturePackageStatus from "../../../../common/signaturePackage/enum/SignaturePackageStatus";
import { StatusFiltersContainer } from "./StatusFilters.style";

interface Props {
  t: TFunction;
  onFilterChanged: (selectedValues: string[]) => void;
  defaultActiveStatus: Array<SignaturePackageStatus | SignerStatus>;
}

class StatusFilters extends React.PureComponent<Props> {
  private statusFiltersChanged = (changedItems: CheckboxButtonItem[]): void => {
    const { onFilterChanged } = this.props;
    const selectedValues = changedItems.filter((item) => item.checked);
    onFilterChanged(selectedValues.map((i) => i.value));
  };

  private buildDefaultCheckboxItems = (): CheckboxButtonItem[] => {
    const allStatus = [SignaturePackageStatus.refused, SignaturePackageStatus.expired, SignerStatus.locked, SignerStatus.emailrebound, SignaturePackageStatus.awaiting, SignaturePackageStatus.completed, SignaturePackageStatus.cancelled];
    return allStatus.map((status) => this.checkboxItemForStatus(status));
  };

  private checkboxItemForStatus = (status: SignaturePackageStatus | SignerStatus): CheckboxButtonItem => {
    const { t, defaultActiveStatus } = this.props;
    return { id: uuid(), value: status, text: t(`SignaturePackage:status.${status}`), checked: defaultActiveStatus.includes(status) };
  };

  public render(): JSX.Element {
    const { t } = this.props;
    return (
      <StatusFiltersContainer>
        <CheckboxButtons items={this.buildDefaultCheckboxItems()} title={`${t("statusSubTitle")}`} onCheckboxChanged={this.statusFiltersChanged} />
      </StatusFiltersContainer>
    );
  }
}

export default StatusFilters;
