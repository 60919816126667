import { AppState } from "../interfaces/AppState";
import { GlobalAction } from "../interfaces/GlobalAction";
import { AppActionTypes as types } from "./app.actions.types";

export default function appReducer(state: AppState, action: GlobalAction): AppState {
  switch (action.type) {
    case types.SET_SEARCH_CRITERIA_HISTORY:
      return { ...state, searchCriteria: action.searchCriteria };
    case types.SET_ERROR:
      return { ...state, error: action.error };
    case types.RESET_ERROR:
      return { ...state, error: null };
    default:
      return state;
  }
}
