import { TFunction } from "i18next";
import * as React from "react";
import { Col } from "react-grid-system";

import PersonalizedAccessCode from "../../../../../../common/client/components/PersonalizedAccessCode";
import { Client } from "../../../../../../common/client/interfaces/Client";
import { Title } from "../../../../clientProfile.style";
import ContactInfo from "../ContactInfo";
import { ClientPortal, LabeledValue, PersonalInfoContainer, SectionContent } from "../profileTab.style";
import MirrorViewLink from "./MirrorViewLink";
import PersonalInformationCustomer from "./PersonalInformationCustomer";

export interface ProfileProps {
  client: Client;
  t: TFunction;
  locale: string;
}

export interface ProfileCustomerProps extends ProfileProps {}

export default class ProfileCustomer extends React.PureComponent<ProfileProps> {
  public render() {
    const { client, t, locale } = this.props;

    return (
      <>
        <Col md={8}>
          <PersonalInfoContainer>
            <Title>{t("profileTab.personalInformation.title")}</Title>
            <PersonalInformationCustomer client={client} t={t} locale={locale} />
          </PersonalInfoContainer>
          <ClientPortal>
            <SectionContent>
              <LabeledValue label={t("profileTab.clientPortal")}>
                <MirrorViewLink client={client} t={t} />  
              </LabeledValue>
              <LabeledValue label={t("App:client.personalizedAccessCode")}>
                <PersonalizedAccessCode t={t}>{client.personalizedAccessCode}</PersonalizedAccessCode>
              </LabeledValue> 
            </SectionContent>
          </ClientPortal>
        </Col>
        <Col md={4}>
          <ContactInfo client={client} t={t} locale={locale} />
        </Col>
      </>
    );
  }
}
