import { Reducer } from "redux";

import { SignaturePackageAction } from "../../../../common/signaturePackage/redux/signaturePackage.actions.types";
import SignaturePackageReducer from "../../../../common/signaturePackage/redux/signaturePackage.reducer";
import { initialSignaturePackageState, SignaturePackageState } from "../../../../interfaces/SignaturePackageState";

/* eslint-disable no-restricted-syntax */
const reducers = [SignaturePackageReducer];

const SignaturePackageTrackingReducer: Reducer<SignaturePackageState, SignaturePackageAction> = (state = initialSignaturePackageState, action): SignaturePackageState => reducers.reduce((currentState, reducer) => reducer(currentState, action), state);

export default SignaturePackageTrackingReducer;
