import { Col, Container, InjectReducerProps, InjectSagaProps, InjectTranslationsProps, Row } from "ia-react-core";
import * as React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Client } from "../../common/client/interfaces/Client";
import { searchClientAutoComplete as searchClientAutoCompleteAction } from "../../common/client/redux/client.actions";
import BoxAction from "../../common/components/BoxAction";
import { ProfileContactUrl } from "../../constants/Router";
import { ClientState } from "../../interfaces/ClientState";
import AutoCompleteDataSource from "./components/AutoComplete/datasource";
import ClientSearch from "./components/ClientSearch/ClientSearch";
import { DashboardContainer } from "./dashboard.style";

export interface DashboardProps { }

export interface DashboardComposedProps extends DashboardProps, InjectTranslationsProps, InjectReducerProps<ClientState>, InjectSagaProps {
  loading: boolean;
  searchClientAutoComplete: typeof searchClientAutoCompleteAction;
  autoCompleteDataSource: AutoCompleteDataSource<Client>;
}

class Dashboard extends React.PureComponent<DashboardComposedProps> {
  private onSearch = (searchCriteria: string): void => {
    const { searchClientAutoComplete } = this.props;
    searchClientAutoComplete(searchCriteria);
  };

  public render() {
    const { t, loading, autoCompleteDataSource } = this.props;

    return (
      <DashboardContainer>
        <Helmet>
          <title>{t("title")}</title>
        </Helmet>
        <Container>
          <Row>
            <Col xs={4}>
              <BoxAction title={t("clientSearch.title")}>
                <ClientSearch t={t} onSearch={this.onSearch} loading={loading} autoCompleteDataSource={autoCompleteDataSource} />
              </BoxAction>
            </Col>
            <Col xs={4}>
              <Link to={ProfileContactUrl}>Advisor contact card</Link>
            </Col>
          </Row>
        </Container>
      </DashboardContainer>
    );
  }
}

export default Dashboard;
