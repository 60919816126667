export const API_ACCOUNTS = process.env.REACT_APP_API_ACCOUNTS;

export const API_ACCOUNTS_V2 = process.env.REACT_APP_API_ACCOUNTS_V2;

export const API_PARTIES = process.env.REACT_APP_API_PARTIES;

export const IS_LOCAL_DEV_ENV: boolean = process.env.REACT_APP_MOCK_EXTRANET_CONTEXT ? process.env.REACT_APP_MOCK_EXTRANET_CONTEXT === "true" : false;

export const API_IAS_SIGNATURE = process.env.REACT_APP_API_IAS_SIGNATURE;

export const API_ROOT = process.env.REACT_APP_API_ROOT;

export const API_ADVISORS = process.env.REACT_APP_API_ADVISORS;

export const API_MESSAGING = process.env.REACT_APP_API_MESSAGING;

export const API_EVENTS = process.env.REACT_APP_API_EVENTS;

export const API_CLAIMS = process.env.REACT_APP_API_CLAIMS;

export const API_GATEWAY_CLIENTID_FR: string = process.env.REACT_APP_API_GATEWAY_CLIENTID_FR ? process.env.REACT_APP_API_GATEWAY_CLIENTID_FR : "";

export const API_GATEWAY_CLIENTID_EN: string = process.env.REACT_APP_API_GATEWAY_CLIENTID_EN ? process.env.REACT_APP_API_GATEWAY_CLIENTID_EN : "";

export const API_GATEWAY_SECRET: string = process.env.REACT_APP_API_GATEWAY_SECRET ? process.env.REACT_APP_API_GATEWAY_SECRET : "";

export const API_GATEWAY_AUTHORITY: string = process.env.REACT_APP_API_GATEWAY_AUTHORITY ? process.env.REACT_APP_API_GATEWAY_AUTHORITY : "";

export const REACT_APP_USE_TOKEN: boolean = process.env.REACT_APP_USE_TOKEN ? process.env.REACT_APP_USE_TOKEN === "true" : false;

export const REACT_APP_MOCK_TOKEN: string = process.env.REACT_APP_MOCK_TOKEN ? process.env.REACT_APP_MOCK_TOKEN : "";

export const REACT_APP_URL_EN: string = process.env.REACT_APP_URL_EN ? process.env.REACT_APP_URL_EN : "";

export const REACT_APP_URL_FR: string = process.env.REACT_APP_URL_FR ? process.env.REACT_APP_URL_FR : "";

export const REACT_APP_USE_MOCK_ADVISOR: boolean = process.env.REACT_APP_USE_MOCK_ADVISOR ? process.env.REACT_APP_USE_MOCK_ADVISOR === "true" : false;

export const REACT_APP_USE_MOCK_SIGNATURE: boolean = process.env.REACT_APP_USE_MOCK_SIGNATURE ? process.env.REACT_APP_USE_MOCK_SIGNATURE === "true" : false;

export const REACT_APP_USE_CLIENT_TO_ADVISOR_MESSAGING: boolean = window.location.href.indexOf("messaging-on") > 0 || (process.env.REACT_APP_USE_CLIENT_TO_ADVISOR_MESSAGING ? process.env.REACT_APP_USE_CLIENT_TO_ADVISOR_MESSAGING === "true" : false);

export const REACT_APP_USE_ADVISOR_TO_CLIENT_MESSAGING: boolean = window.location.href.indexOf("messaging-on") > 0 || (process.env.REACT_APP_USE_ADVISOR_TO_CLIENT_MESSAGING ? process.env.REACT_APP_USE_ADVISOR_TO_CLIENT_MESSAGING === "true" : false);

export const REACT_APP_SHOW_CONTACT_ADDRESS: boolean = process.env.REACT_APP_SHOW_CONTACT_ADDRESS ? process.env.REACT_APP_SHOW_CONTACT_ADDRESS === "true" : false;

export const REACT_APP_SHOW_ACCESS_SHARING: boolean = process.env.REACT_APP_SHOW_ACCESS_SHARING ? process.env.REACT_APP_SHOW_ACCESS_SHARING === "true" : false;

export const REACT_APP_TOGGLE_USERMANAGEMENT: boolean = process.env.REACT_APP_TOGGLE_USERMANAGEMENT ? process.env.REACT_APP_TOGGLE_USERMANAGEMENT === "true" : false;

export const REACT_APP_ALLOW_NONCLIENT_PASSWORD_RESET : boolean = process.env.REACT_APP_ALLOW_NONCLIENT_PASSWORD_RESET ? process.env.REACT_APP_ALLOW_NONCLIENT_PASSWORD_RESET === "true" : false;

export const LimitSearchClient = 10;

export const LimitSearchSignaturePackage = 10;

export const LimitSearchClientAutoComplete = 10;

export const ClientPortal = "/FWMWPNS1/";

export const MirrorviewLink = "/FWMWPNS1/mirrorview/?id=";

export const REACT_APP_AX360_URL_EN: string = process.env.REACT_APP_AX360_URL_EN ? process.env.REACT_APP_AX360_URL_EN : "";

export const REACT_APP_AX360_URL_FR: string = process.env.REACT_APP_AX360_URL_FR ? process.env.REACT_APP_AX360_URL_FR : "";
