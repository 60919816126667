import { Table, Input } from "antd";
import * as React from "react";
import moment from "moment";
import { Row, Container } from "ia-react-core";
import { saveAs } from 'file-saver';
import { ClientProfileComposedProps } from "../../../ClientProfile";
import { EventLog, EventLogCategory } from "./EventLog";
import EventService from "../../../../../common/event/event.service";
import MessagesService from "../../../../../common/messages/messages.service";

const service = new EventService();

const { Search } = Input;

export interface LogsTabProps extends ClientProfileComposedProps {}
interface ConsentLog {
  source: string;
  context: string;
  collectionDateUTC: string;
  consentDefinition: {
    processingPurpose: {
      id: string;
      name: string;
    };
    consentPurpose: {
      id: string;
      name: string;
    };
    version: string;
  };
  value: boolean;
  modifiedBy: string;
}

interface State {
  isLoading: boolean;
  results: EventLog[] | null;
  searchValue: string;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
class LogsTab extends React.Component<LogsTabProps, State> {
  constructor(props: LogsTabProps) {
    super(props);

    this.state = {
      isLoading: false,
      results: null as EventLog[] | null,
      searchValue: '',
    };
  }

  getEdeliveryPreferencesText = (eventLog: EventLog, expandedRow: boolean) => {
    const { t } = this.props;
    if (eventLog.payload) {
      const result = JSON.parse(eventLog.payload);
      if (expandedRow){
        return (<span></span>);
      }
      if (!expandedRow) {
        if (result.NewValue.DocumentPreferences !== undefined){
          return this.deliveryPreferenceDetails(result);
        } else
        {
          return this.deliveryPreferenceDetailsLegacy(result);
        }
      }
    }
    return (<span></span>);
  };

  deliveryPreferenceDetails = (result:any) => {
    const { t } = this.props;
    var display: { doc: string; value: string; deliveryChanged:boolean; notificationEnabled: boolean; }[] = []

    result.NewValue.DocumentPreferences.forEach((doc:any) =>{
      const old = result.OldValue.DocumentPreferences.find((element:any) => element.DocumentType === doc.DocumentType);
      if (doc.IsPaperless === old.IsPaperless && doc.IsEmailNotification === old.IsEmailNotification) return;
      let docType = '';
      switch(doc.DocumentType){
        case 'TaxDocument':
          docType = t('logsTab.taxDocuments');
          break;
        case 'Statement':
          docType = t('logsTab.statements');
          break;
        case 'TradeConfirmation':
          docType = t('logsTab.tradeConfirmation');
          break;
        case 'ManagedStatement':
          docType = t('logsTab.managedStatement');
          break;
        default:
          docType = doc.DocumentType;
      }
      display.push({
        doc: docType,
        value: doc.IsPaperless ? t('logsTab.isElectronic') : t('logsTab.isPaper'),
        deliveryChanged: doc.IsPaperless !== old.IsPaperless,
        notificationEnabled: doc.IsEmailNotification,
      });
    })

    return (
      <span>
        {t('logsTab.clientId', { 0: result.NewValue.ClientId })}<br/>
        {display.map(function(item, i){
          const primaryText = item.deliveryChanged ? <>{t('logsTab.changedTo')}<b>{item.value}</b></> : <></>;
          const notificationText = (item.doc === t('logsTab.tradeConfirmation') && item.value ===t('logsTab.isElectronic'))  ? (`${item.deliveryChanged ? t('logsTab.and'): ''}${t('logsTab.emailNotification', { 0: item.notificationEnabled ? t('logsTab.True').toUpperCase() : t('logsTab.False').toUpperCase()})}`) : '';
          return <span key={i}>
            <b>{item.doc}</b>{primaryText}{notificationText}<br/>
          </span>
        })
        }
    </span>);
  }

  deliveryPreferenceDetailsLegacy = (result:any) => {
    const { t } = this.props;

    var display = []
    if (result.OldValue.TaxDocument != result.NewValue.TaxDocument){
      display.push({
        doc: t('logsTab.taxDocuments'),
        value: result.NewValue.TaxDocument ? t('logsTab.isElectronic') : t('logsTab.isPaper'),
        client: t('logsTab.clientId', { 0: result.NewValue.ClientId })
      });
    }
    if (result.OldValue.TradeConfirmation != result.NewValue.TradeConfirmation){
      display.push({
        doc: t('logsTab.tradeConfirmation'),
        value: result.NewValue.TradeConfirmation ? t('logsTab.isElectronic') : t('logsTab.isPaper'),
        client: t('logsTab.clientId', { 0: result.NewValue.ClientId })
      });
    }
    if (result.OldValue.Statement != result.NewValue.Statement){
      display.push({
        doc: t('logsTab.statements'),
        value: result.NewValue.Statement ? t('logsTab.isElectronic') : t('logsTab.isPaper'),
        client: t('logsTab.clientId', { 0: result.NewValue.ClientId })
      });
    }
    if (result.OldValue.ManagedStatement != result.NewValue.ManagedStatement){
      display.push({
        doc: t('logsTab.managedStatement'),
        value: result.NewValue.ManagedStatement ?t('logsTab.isElectronic') : t('logsTab.isPaper'),
        client: t('logsTab.clientId', { 0: result.NewValue.ClientId })
      });
    }
    return (
      <span>
        {
        display.map(function(item, i){
          return <span key={i}>
            <b>{item.doc}</b>{t('logsTab.changedTo')}<b>{item.value}</b>{item.client}<br/>
          </span>
        })
      }
      </span>);
  }

  getAccountSharingText = (eventLog: EventLog, expandedRow: boolean) => {
    const { t } = this.props;
    if (eventLog.payload) {
      const payloadObj = JSON.parse(eventLog.payload, this.toCamelCase);
      if (payloadObj) {
        if (expandedRow) {
          const oldValue = `Old values: ${this.getPartyAccountText(payloadObj.oldValue)}`;
          let sharee = '';
          if (payloadObj.custom.shareeName && eventLog.owner === payloadObj.custom.sharer) {
            sharee = ` Sharee : ${payloadObj.custom.sharee}`;
          }

          return (<span>{oldValue + sharee}</span>);
        }
        if (!expandedRow) {
          return (<span>{`Added: ${this.getPartyAccountText(payloadObj.add)} Removed: ${this.getPartyAccountText(payloadObj.remove)}${this.getSharingCustomPayloadtext(eventLog)}`}</span>);
        }
      }
    }
    return <></>
  }

  getMailNotificationText = (eventLog: EventLog, expandedRow: boolean) => {
    const { t } = this.props;
    if (eventLog.payload) {
      const result = JSON.parse(eventLog.payload);
      if (expandedRow){
        return (<span></span>);
      }
      if (!expandedRow) {
        if (result.OldValue.Value != result.NewValue.Value){
          console.log(result.NewValue.Value[0]);
          var display = {
            doc: t('logsTab.mailNotification'),
            value: result.NewValue.Value[0] === 'true' ? t('logsTab.mailNotificationYes'):  t('logsTab.mailNotificationNo')
          };

          return (<span><b>{display.doc}</b>{t('logsTab.changedTo2')}<b>{display.value}</b></span>);
        }

      }
    }
    return (<span></span>);
  };

  getConsentText = (eventLog: EventLog, expendedRow: boolean) => {
    const { t } = this.props;
    const formatConsentsText = (consents: ConsentLog[]) => {
      if (!consents || consents?.length === 0) {
        return (<span></span>);
      }
      return (<span>
        {
          consents.map(consent => {
            return (
              t(`logsTab.consent.${consent.consentDefinition.consentPurpose.name.toLowerCase()}`, { 0: t(`logsTab.${consent.value.toString()}`) })
            )
          }).join("; ")
        }</span>);
    }

    if (eventLog.payload) {
      const payloadObj = JSON.parse(eventLog.payload, this.toCamelCase);
      if (payloadObj) {
        const newValues: ConsentLog[] = payloadObj.newValue;
        if (expendedRow) {
          return <></>;
        }

        return formatConsentsText(newValues);
      }
    }
    return (<span></span>);
  };

  getHouseholdText = (eventLog: EventLog, expendedRow: boolean) => {
    const { t } = this.props;

    const getPartyAccountNumberText = (values: string[]): string => (values && values.length > 0) ? values.join(', ') : 'none';

    if (eventLog.payload) {
      const payloadObj = JSON.parse(eventLog.payload, this.toCamelCase);
      if (payloadObj) {
        const newValues: ConsentLog[] = payloadObj.newValue;
        if (expendedRow) {
          return <>{t(`logsTab.original`)}: {getPartyAccountNumberText(payloadObj.oldValue)}</>
        }

        return <>{t(`logsTab.added`)}{getPartyAccountNumberText(payloadObj.add)}; {t(`logsTab.removed`)}{getPartyAccountNumberText(payloadObj.remove)}</>
      }
    }
    return (<span></span>);
  };

  componentDidMount() {
    this.getEventLog();
  }

  onDetailClick = (record: EventLog) => <div> {this.getEventLogDetails(record, true)} </div>

  getEventLog = async () => {
    const { client } = this.props;

    this.setState({ isLoading: true });
    const eventLogs = await service.getLogEventFilteredByCategories(client.id,
      [
        EventLogCategory.LOGIN,
        EventLogCategory.MOBILE_LOGIN,
        EventLogCategory.SHARED,
        EventLogCategory.UNSHARED,
        EventLogCategory.DELIVERYPREDERENCES_UPDATED,
        EventLogCategory.MAILNOTIFICATION_UPDATED,
        EventLogCategory.NONCLIENT,
        EventLogCategory.ACCOUNT_SHARING,
        EventLogCategory.CONSENT,
        EventLogCategory.PASSWORD_RESET,
        EventLogCategory.ACCOUNT_HOUSEHOLD,
        EventLogCategory.DOCUMENT_SHARING,
        EventLogCategory.UNLOCK_ACCOUNT,
      ]
    );
    if (eventLogs) {
      this.setState({ results: eventLogs, isLoading: false });
    }
  }

  getEventLogDetails = (eventLog: EventLog, expandedRow: boolean) => {
    switch (eventLog.payloadCategory) {
      case EventLogCategory.LOGIN:
        return (<span></span>);
      case EventLogCategory.MOBILE_LOGIN:
        return (<span></span>);
      case EventLogCategory.UNSHARED:
        return this.getDocumentToggleShareText(eventLog, expandedRow);
      case EventLogCategory.SHARED:
        return this.getDocumentToggleShareText(eventLog, expandedRow);
      case EventLogCategory.NONCLIENT:
        return this.getNonClientText(eventLog, expandedRow);
      case EventLogCategory.ACCOUNT_SHARING:
        return this.getAccountShareText(eventLog, expandedRow);
      case EventLogCategory.DELIVERYPREDERENCES_UPDATED:
        return this.getEdeliveryPreferencesText(eventLog, expandedRow);
      case EventLogCategory.MAILNOTIFICATION_UPDATED:
        return this.getMailNotificationText(eventLog, expandedRow);
      case EventLogCategory.ACCOUNT_SHARING:
        return this.getAccountSharingText(eventLog, expandedRow);
      case EventLogCategory.CONSENT:
        return this.getConsentText(eventLog, expandedRow);
      case EventLogCategory.PASSWORD_RESET:
        return this.getResetPasswordText(expandedRow);
      case EventLogCategory.ACCOUNT_HOUSEHOLD:
        return this.getHouseholdText(eventLog, expandedRow);
      case EventLogCategory.DOCUMENT_SHARING:
        return this.getDocumentSharingText(eventLog, expandedRow);
      case EventLogCategory.UNLOCK_ACCOUNT:
        return this.getUnlockAccountText(expandedRow);
      default:
        return (<span></span>);
    }
  };

  getFileExtension = (fileName: string) => {
    const fileExtension = fileName ? fileName.split('.').pop() : '';
    if (fileExtension) {
      return fileExtension.toLowerCase();
    }
    return '';
  };

  getDocumentToggleShareText = (eventLog: EventLog, expandedRow: boolean) => {
    const { t } = this.props;
    if (eventLog.payload) {
      const payloadObj = JSON.parse(eventLog.payload, this.toCamelCase);
      if (payloadObj) {
        if (expandedRow && payloadObj.unsharedMessage && payloadObj.unsharedMessage.length > 0) {
          return (
            <span>
              {t(`documentsTab.${payloadObj.unsharedMessage}`) !== `documentsTab.${payloadObj.unsharedMessage}` ? t(`documentsTab.${payloadObj.unsharedMessage}`) : payloadObj.unsharedMessage}
            </span>);
        }
        if (!expandedRow) {
          const status = (payloadObj.documentReadStatus && payloadObj.documentReadStatus.length > 0) ? t(`documentsTab.${payloadObj.documentReadStatus.toLowerCase()}`) : '';
          let filename = '';
          let docId = '';
          if (payloadObj.documents && payloadObj.documents.length > 0) {
            filename = typeof payloadObj.documents === 'object' ? payloadObj.documents[0].name : payloadObj.documents;
            docId = typeof payloadObj.documents === 'object' ? payloadObj.documents[0].docId : '';
          }
          return (
            <span aria-hidden style={{ cursor: 'pointer', color: '#003da5' }} onClick={this.clickHandler(`${docId}`, `${filename}`)}>
              {`${status} - ${filename}`}
            </span>
          );
        }
      }
    }
    return (<span></span>);
  };

  getUserDisplay = (client:any) => {
    if (client["firstName"] === undefined || client["lastName"] === undefined || client["addresses"] === undefined || client["emails"] === undefined || client["phones"] === undefined )
      return (<span></span>)
    return (<span>{client.firstName} {client.lastName}<br/>{client.emails[0].emailAddress}<br/>{client.phones[0].phoneNumber}<br/>
    {client.addresses[0].streetNumber} {client.addresses[0].streetName} <br/> {client.addresses[0].municipality} {client.addresses[0].regionCode} <br/> {client.addresses[0].postalCode}</span>)
  };

  getNonClientText = (eventLog: EventLog, expandedRow: boolean) => {
    const { t } = this.props;
    if (!expandedRow){
      return (
        <span>{eventLog.message}</span>
      );
    }
    else{
      if (eventLog.payload) {
        const payloadObj = JSON.parse(eventLog.payload, this.toCamelCase);
        if (payloadObj) {
          return (<div>
            <div style={{display:'flex'}}>
              {payloadObj.custom.sharee &&
                <div style={{display:'flex',flexDirection:'column', padding:'20px'}}>
                <b>{t(`logsTab.current`)}</b>
                <br/>
                {this.getUserDisplay(payloadObj.custom.sharee)}
              </div>}
              {payloadObj.custom.original &&
                <div style={{display:'flex',flexDirection:'column', padding:'20px'}}>
                <b>{t(`logsTab.original`)}</b>
                <br/>
                {this.getUserDisplay(payloadObj.custom.original)}
              </div>}
            </div>
          </div>)
        }
      }
      return (<span></span>)
    }
  };

  getAccountShareText = (eventLog: EventLog, expandedRow: boolean) => {
    const { t } = this.props;
    if (eventLog.payload) {
      const payloadObj = JSON.parse(eventLog.payload, this.toCamelCase);
      if (payloadObj) {
         if (payloadObj && payloadObj.sharee) {
           if (!expandedRow){
             return this.getSharingText(payloadObj);
           }
         }
      }
    }
    return (<span></span>)
  };

  getDocumentSharingText = (eventLog: EventLog, expendedRow: boolean) => {
    const { t } = this.props;
    if (eventLog.payload) {
      const payloadObj = JSON.parse(eventLog.payload, this.toCamelCase);
      if (payloadObj) {
        if (expendedRow && payloadObj.documents && payloadObj.documents.length > 0) {
          return `${t('logsTab.documentShared')}: ${payloadObj.documents.map((d: any) => d.name).join(', ')}`;
        }
        return eventLog.owner === payloadObj.sharerId ? t('logsTab.clientHasSharedDocuments', { 0: payloadObj.clientId }) : t('logsTab.advisorHasSharedDocuments', { 0: eventLog.modifiedBy });
      }
    }

    return (<span></span>)
  };

  getSharingCustomPayloadtext = (eventLog: EventLog): string => {
    if (eventLog.payload) {
      const payloadObj = JSON.parse(eventLog.payload);
      if (payloadObj.custom && payloadObj.custom.sharee && payloadObj.custom.sharer) {
        if (payloadObj.custom.shareeName && eventLog.owner === payloadObj.custom.sharer) {
          return ` to ${payloadObj.custom.shareeName}`;
        }
      }
    }

    return '';
  };

  getPartyAccountText = (values: any[]): string => {
    const val = (values && values.length > 0) ? values.map((a) => this.getPartyAccountTextItem(a)) : ['none'];
    return val.join(', ');
  };

  getSharingText = (value: any) : string => {
    const { t } = this.props;
    console.log(value);
    if (value.shareeName && value.add.clientId && value.add.clientId !== null){
      return `${value.shareeName} ${t(`logsTab.providedAccess`)} (${value.add.owner},${value.add.clientId})`
    } else if (value.shareeName && value.removed.clientId && value.removed.clientId !== null){
      return `${value.shareeName} ${t(`logsTab.removedAccess`)} (${value.removed.owner},${value.removed.clientId})`
    }
    return ''
  }

  getPartyAccountTextItem = (account: any): string => `${account.id} (${account.role})`;

  getDocumentToggleConsentText = (eventLog: EventLog, expandedRow: boolean) => {
    const { t } = this.props;
    if (eventLog.payload) {
      const result = JSON.parse(eventLog.payload, this.toCamelCase);
      if (expandedRow){
        const source =  result.source ? t('logsTab.consentUpdated', { 0: t(`logsTab.${result.source}`)}) :  '';

        return (
          <span>
            {source}
            <br/>
            {result.affiliateCurrentVersion ? t('logsTab.affiliateConsentVerbiage', { 0: result.affiliateCurrentVersion }) : ''}
            <br/>
            {result.thirdPartyCurrentVersion ? t('logsTab.thirdPartyConsentVerbiage', { 0: result.thirdPartyCurrentVersion }) : ''}
          </span>);
      }
      if (!expandedRow) {
        const finalAffDetails = result.affiliateCurrentValue ? t('logsTab.affiliatesChanges', { 0: t(`logsTab.${result.affiliateCurrentValue}`)}) : '';
        const finalThirdPartyDetails = result.thirdPartyCurrentValue ? t('logsTab.thirdPartyChanges', { 0: t(`logsTab.${result.thirdPartyCurrentValue}`)}) : '';
        return (
          <span>
            {finalAffDetails} <br/> {finalThirdPartyDetails}
          </span>);
      }
    }
    return (<span></span>);
  };

  getResetPasswordText = (expandedRow: boolean) => {
    const { t } = this.props;
    if (!expandedRow){
      return (<span>{t(`logsTab.temporaryPasswordGenerated`)}</span>)
    }
    return (<span></span>)
  };

  getUnlockAccountText = (expandedRow: boolean) => {
    const { t } = this.props;
    if (!expandedRow){
      return (<span>{t(`logsTab.unlockAccountMessage`)}</span>)
    }
    return (<span></span>)
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toCamelCase = (key: any, value: any): string => {
    if (value && typeof value === 'object') {
      // eslint-disable-next-line no-restricted-syntax
      for (const k in value) {
        if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
          value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
          delete value[k];
        }
      }
    }
    return value;
  };

  private clickHandler = (documentId: string, fileName: string) => {
    return async () => {
      await this.handleOpenDocument(documentId, fileName);
    };
  }

  // eslint-disable no-console
  private handleOpenDocument = async (documentId: string, fileName: string) => {
    const messageService = new MessagesService();
    try {
      const data: Blob = await messageService.downloadAttachment(documentId);
      saveAs(data, fileName);
    } catch (error) {
      console.log(error);
    }
  };

  onSearch = (value: string) => this.setState({ searchValue: value });

  textContent(elem: React.ReactElement | string): string {
    if (!elem) {
      return '';
    }
    if (typeof elem === 'string') {
      return elem;
    }

    const children = elem.props && elem.props.children;
    return this.textContent(children);
  }

  filterLog(arr: any[], searchKey: string) {
    return arr.filter((obj) => {
      return Object.keys(obj).some((key) => {
        if (!['timeStamp', 'payloadCategory', 'modifiedBy', 'message'].includes(key)) {
          return false;
        }
        if (key === 'timeStamp') {
          return (moment(obj[key]).format('YYYY/MM/DD HH:mm')).toUpperCase().includes(searchKey.toUpperCase());
        }
        if (typeof obj[key] === 'string' && (obj[key] as string).toUpperCase().includes(searchKey.toUpperCase())) {
          return true;
        }
        const logDetailsExpanded = this.getEventLogDetails(obj as EventLog, true);
        const logDetails = this.getEventLogDetails(obj as EventLog, false);

        return (this.textContent(logDetailsExpanded).toUpperCase().includes(searchKey.toUpperCase()) || (this.textContent(logDetails).toUpperCase().includes(searchKey.toUpperCase())));
      });
    });
  }

  public render() {
    const { t } = this.props;
    const renderTimeStamp = (value: Date) => (
      moment(value).format("YYYY/MM/DD HH:mm")
    );

    const renderDetails = (row: EventLog) => this.getEventLogDetails(row, false);

    const columns = [
      {
        title: 'Date',
        dataIndex: 'timeStamp',
        key: 'timeStamp',
        render: renderTimeStamp,
      },
      {
        title: 'Actions',
        dataIndex: 'payloadCategory',
        key: 'payloadCategory',
      },
      {
        title: t('logsTab.doneBy'),
        dataIndex: 'modifiedBy',
        key: 'modifiedBy',
      },
      {
        title: t('logsTab.details'),
        dataIndex: '',
        key: 'message',
        render: renderDetails,
      },

    ];

    let data = [] as EventLog[];
    const { results, isLoading, searchValue } = this.state;
    if (results) {
      data = this.filterLog(results, searchValue);
    }

    return (
      <div>
        <Container>
          <Row>
            <Search placeholder="Search..." onSearch={this.onSearch} style={{ width: 200 }} />
          </Row>
        </Container>
        <Table locale={{ emptyText: t('logsTab.noData') }} className="word-break" rowKey="id" dataSource={data} columns={columns} expandedRowRender={this.onDetailClick} loading={isLoading} />
      </div>
    );
  }
}

export default LogsTab;
