import { httpService } from './http.service';
import { HttpPost, HttpPatch, HttpGet } from '../../../common/utils/HttpClient';
import { ICandidate } from '../Models/Client';
import { IDeliveryPreferences } from '../Models/DocumentPreference';
import { API_PARTIES } from "../../../constants/API";


export const userService = {
  saveUser,
  sendActivationKey,
};

async function saveUser(user: ICandidate, isEdit: boolean) {
  if (!isEdit){
    const response = await HttpPost(`${API_PARTIES}`, user, {});
    return response.data.data as ICandidate;
  }
  else {
    let patchDoc = [];
    patchDoc.push({op:"replace", path:"/firstName", value:user.firstName})
    patchDoc.push({op:"replace", path:"/lastName", value:user.lastName})
    patchDoc.push({op:"replace", path:"/languageCode", value:user.languageCode})
    patchDoc.push({op:"replace", path:"/addresses/0/unitNumber", value:user.addresses[0].unitNumber})
    patchDoc.push({op:"replace", path:"/addresses/0/unitTypeCode", value:user.addresses[0].unitTypeCode})
    patchDoc.push({op:"replace", path:"/addresses/0/streetNumber", value:user.addresses[0].streetNumber})
    patchDoc.push({op:"replace", path:"/addresses/0/streetName", value:user.addresses[0].streetName})
    patchDoc.push({op:"replace", path:"/addresses/0/municipality", value:user.addresses[0].municipality})
    patchDoc.push({op:"replace", path:"/addresses/0/regionCode", value:user.addresses[0].regionCode})
    patchDoc.push({op:"replace", path:"/addresses/0/countryCode", value:user.addresses[0].countryCode})
    patchDoc.push({op:"replace", path:"/phones/0/phoneNumber", value:user.phones[0].phoneNumber})
    patchDoc.push({op:"replace", path:"/emails/0/emailAddress", value:user.emails[0].emailAddress})
    patchDoc.push({op:"remove", path:"/accounts"})
    patchDoc.push({op:"add", path:"/accounts", value:[]})
    user.accounts.map(a=>{
      patchDoc.push({op:"add", path:"/accounts/-", value:{id:a.id, role:a.role, noRole:a.noRole, repCode: a.repCode}})
    })



    await HttpPatch(`${API_PARTIES}/${user.candidateId}`, patchDoc);
    const updated = await HttpGet(`${API_PARTIES}/${user.candidateId}`);
    await HttpPost(`${API_PARTIES}/ActivationKey/${user.candidateId}`,{})
    return updated.data.data as ICandidate;
  }
}

async function sendActivationKey(partyId: string) {
  try {
    const response = await httpService.post(`${API_PARTIES}/ActivationKey/${partyId}`, null);
    return response.data.data as string;
  } catch (err) {
    console.log(err);
    return null;
  }
}
