import { TFunction } from "i18next";
import * as React from "react";

import TimeHorizon from "../../../../../../common/client/enum/TimeHorizon";
import TimeHorizonHelper from "../../../../../../common/client/utils/TimeHorizonHelper";
import { LabeledValueNotPad } from "../../profile/profileTab.style";

export interface TimeHorizonInfoProps {
  t: TFunction;
  data: TimeHorizon;
}

const TimeHorizonInfo: React.FC<TimeHorizonInfoProps> = (props: TimeHorizonInfoProps) => {
  const { t, data } = props;

  return (
    <>
      <LabeledValueNotPad label={t("investorTab.timeHorizon.title")}>
        {TimeHorizonHelper.getLabel(data, t)}
      </LabeledValueNotPad>
    </>);
};

export default TimeHorizonInfo;
