export enum HouseholdType {
    OWNER = 'CO',
    SHARING = 'SH',
    COMPANY = 'CORP',
}

export enum HouseholdNoRoleType {
    INDIVIDUAL = 11,
    JOINTPRIMARY = 21,
    JOINTSECONDARY = 22,
    CORPORATE = 31
}
