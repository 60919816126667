import { ColumnType } from "antd/lib/table";
import * as React from "react";

import { Account } from "../../../../../../../common/account/interfaces/Account";
import StringValue from "../../../../../../../common/utils/Value";

export function IdColumn(): ColumnType<Account> {
  return {
    title: "",
    dataIndex: "id",
    key: "id",
    width: "15%",
    render: (value: string) => <StringValue>{value}</StringValue>,
  };
}
