import { TreeSelect } from "antd";
import { TreeSelectProps } from "antd/lib/tree-select";
import { TFunction } from "i18next";
import { ControlOnValueChangeOptions } from "ia-react-core";
import { DataNode, LabelValueType } from "rc-tree-select/lib/interface";
import React from "react";

export interface RepCodeSelectorProps {
  t: TFunction;
  repCodes: string[];
  value: string[];
  onValueChange: (value: string[], options?: ControlOnValueChangeOptions) => void;
}

const { SHOW_ALL } = TreeSelect;
const allValue = "all";

class RepCodeSelectorTreeSelect extends React.PureComponent<RepCodeSelectorProps> {
  private mapRepCodeToLabeledValue = (): DataNode[] => {
    const { repCodes, t } = this.props;
    const data: DataNode[] = [];

    const repCodesNode = repCodes.map((repCode: string) => {
      const node: DataNode = {
        title: repCode,
        value: repCode,
        key: repCode,
      };
      return node;
    });

    const all: DataNode = {
      title: t("clientSearch.filter.all"),
      value: allValue,
      key: "all",
      children: repCodesNode,
    };

    data.push(all);

    return data;
  };

  private onChange = (values: string[]) => {
    const { onValueChange } = this.props;
    const valuesWithoutAll = values.filter((value: string) => value !== allValue);

    onValueChange(valuesWithoutAll);
  };

  private formatValuesSelected = (options: LabelValueType[]): React.ReactNode => {
    let value = options
      .filter((option: LabelValueType) => option.value !== allValue)
      .map((option: LabelValueType) => option.value)
      .join(", ");

    if (value === "") {
      const { repCodes } = this.props;
      value = repCodes.join(", ");
    }

    return <>{value}</>;
  };

  private dropdownVisibleChange = (visible: boolean): void => {
    const { value, repCodes, onValueChange } = this.props;
    if (!visible && value.length === 0) {
      onValueChange(repCodes);
    }
  };

  private treeSelectProps = () => {
    const { value } = this.props;
    const tProps: TreeSelectProps<string[]> = {
      treeData: this.mapRepCodeToLabeledValue(),
      value,
      onChange: this.onChange,
      treeCheckable: true,
      showCheckedStrategy: SHOW_ALL,
      placeholder: "",
      maxTagCount: 0,
      maxTagPlaceholder: this.formatValuesSelected,
      style: {
        width: "100%",
      },
      treeDefaultExpandAll: true,
      showSearch: false,
      className: value.length === 0 ? "no-data" : "has-data",
      showArrow: true,
      dropdownClassName: "ias-treeview-select",
      onDropdownVisibleChange: this.dropdownVisibleChange,
    };

    return tProps;
  };

  public render() {
    return (
      <>
        <TreeSelect {...this.treeSelectProps()} />
      </>
    );
  }
}

export default RepCodeSelectorTreeSelect;
