import { ColumnType } from "antd/lib/table";
import * as React from "react";

import { Account } from "../../../../../../../common/account/interfaces/Account";
import StringValue from "../../../../../../../common/utils/Value";

export function ProductTypeColumn(): ColumnType<Account> {
  return {
    title: "",
    dataIndex: "productType",
    key: "productType",
    render: (value: string) => <StringValue>{value}</StringValue>,
  };
}
