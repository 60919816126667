export default {
  title: "Clients",
  welcome: "Bienvenue",
  clientSearch: {
    submit: "Rechercher",
    reset: "Réinitialiser",
    name: "Nom ou prénom",
    accessCode: "Code d'accès",
    placeholder: "Nom, Identifiant client ou Nom d'utilisateur",
    filter: {
      repCode: "Code(s) de représentant ",
      all: "Tous",
    },
    menuActivationKey : "Renvoyer la clé d'activation",
    activationKeySuccessTitle : "Confirmation",
    activationKeySuccessMessage : "La clé d'activation a été envoyée au courriel suivant",
    activationKeyErrorTitle : "Renvoyer la clé d'activation - Échec",
    activationKeyErrorMessage : "Essayez à nouveau"
  },
  clientSearchResult: {
    title: "client(s) trouvé(s)",
    for: "pour",
    infoTab: "Informations",
    accountsTab: "Accounts",
    viewProfile: "Voir le profil",
    editCustomUser: "Modifier l'utilisateur",
    noClientsFound: "Aucun client trouvé",
    columns: {
      clientPortal: "Portail client",
      actions: "Actions",
      email: "Courriel",
    },
    preferedAccessCode: {
      title: "Code d'accès personnalisé",
      request: "Afficher",
      processing: "En traitement",
    },
  },
};
