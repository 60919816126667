import { TFunction } from "i18next";
import { Col, Control, Form, Row } from "ia-react-core";
import * as React from "react";

import { SearchCriteria } from "../../../../../common/interfaces/SearchCriteria";
import { Title } from "../../../SignaturePackage.style";
import { Label, SearchContainer, SubmitButton, TextControl } from "./Search.style";
import SearchButtonReset from "./SearchButtonReset";

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface SearchProps {
  t: TFunction;
  onSearch: (criteria: string, repCodes: string[]) => void;
  onReset: () => void;
  loading: boolean;
  repCodes: string[];
  searchCriteria: SearchCriteria;
  isSupport: boolean;
}

// eslint-disable-next-line no-useless-escape
const regexSearchFormater = /[-`~!#$%^&*()_|+=?;:±£¢¤¬¦²³¼½¾"<>\{\}\[\]\\\/]/gi;
const formName = "signaturePackageTracking";

class Search extends React.PureComponent<SearchProps> {
  private search = (formValues: SearchCriteria): void => {
    const { onSearch, onReset, loading, repCodes } = this.props;

    if (!("text" in formValues) && !("repCodes" in formValues)) {
      onReset();
      return;
    }

    if (!formValues.text && !formValues.repCodes) {
      onSearch("", repCodes);
      return;
    }

    const repCodesUseToSearch = formValues.repCodes.length !== 0 ? formValues.repCodes : repCodes;

    if (!loading) {
      onSearch(formValues.text.trim(), repCodesUseToSearch);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private searchCriteriaFormater = (value: any) => {
    if (!value) {
      return value;
    }

    if (Object.prototype.toString.call(value) === "[object Array]") {
      return value;
    }

    if (value === " ") {
      return "";
    }

    return value.replace(regexSearchFormater, "");
  };

  private renderAdvisorSelector = () => {
    const { repCodes, t, isSupport } = this.props;
    const name = "repCodes";

    if (!isSupport) {
      return (
        <Control name={name} defaultValue={repCodes}>
          {() => <input type="hidden" name={name} />}
        </Control>
      );
    }

    return (
      <Col xs={12} md={3} p-b-xs={16}>
        <Label>{t("repCodeSearchTitle")}</Label>
        <TextControl
          name={name}
          valueToStateFormatters={[{ formatter: (value: any) => value.replace(regexSearchFormater, ""), deferred: true }]}
          stateToValueFormatters={[
            {
              formatter: this.searchCriteriaFormater,
            },
          ]}
        />
      </Col>
    );
  };

  public render() {
    const { t, loading, searchCriteria } = this.props;

    return (
      <SearchContainer>
        <Title>{`${t("SignaturePackage:title")}`}</Title>

        <Form<SearchCriteria> defaultCol={1} name={formName} onSubmit={(formValues: SearchCriteria) => this.search(formValues)} initialValues={searchCriteria}>
          <Row>
            {this.renderAdvisorSelector()}
            <Col xs={12} md={4} p-b-xs={16}>
              <Label>{t("nameSearchTitle")}</Label>
              <TextControl
                name="text"
                valueToStateFormatters={[{ formatter: (value: any) => value.replace(regexSearchFormater, ""), deferred: true }]}
                stateToValueFormatters={[
                  {
                    formatter: this.searchCriteriaFormater,
                  },
                ]}
              />
            </Col>
            <Col xs={12} md={5} p-t-md={28}>
              <SubmitButton disabled={loading}>{t("submit")}</SubmitButton>
              <SearchButtonReset name={t("reset")} disabled={loading} formName={formName} />
            </Col>
          </Row>
        </Form>
      </SearchContainer>
    );
  }
}

export default Search;
