import { TFunction } from "i18next";
import * as React from "react";

import { Client } from "../../../../../common/client/interfaces/Client";
import AddressComponent from "../../../../../common/components/ContactDetails/AddressComponent";
import { EmptyValue } from "../../../../../common/constants";
import { Title } from "../../../clientProfile.style";
import { REACT_APP_SHOW_CONTACT_ADDRESS } from "../../../../../constants/API";
import { ContactInformationContainer, LabeledValue, SectionContent } from "./profileTab.style";

export interface ContactInfoProps {
  client: Client;
  t: TFunction;
  locale: string;
}

export default class ContactInfo extends React.PureComponent<ContactInfoProps> {
  private getAddress() {
    const { client, locale } = this.props;
    const { addresses } = client;
    if (!addresses || addresses.length === 0) {
      return EmptyValue;
    }

    return <AddressComponent address={addresses[0]} locale={locale} />;
  }

  private getPhone() {
    const { client } = this.props;
    const { phones } = client;
    
    if (!phones) {
      return EmptyValue;
    }
    
    const phone = phones.find((p) => p.phoneTypeCode === "principal" || p.phoneTypeCode === "home");
    return phone ? `${phone.phoneNumber}` : EmptyValue;
  }

  private getEmail() {
    const { client } = this.props;
    const { emails } = client;

    if (!emails || emails.length === 0) {
      return EmptyValue;
    }

    return emails[0].emailAddress;
  }

  private renderInfo() {
    const { t } = this.props;
    let items = [];

    if (REACT_APP_SHOW_CONTACT_ADDRESS) {
      items = [
        { label: "address", labelSource: "App:contactInformations.", value: this.getAddress() },
        { label: "Phone", value: this.getPhone() },
        { label: "email", labelSource: "App:contactInformations.", value: this.getEmail() },
      ];
    } else {
      items = [
        { label: "Phone", value: this.getPhone() },
        { label: "email", labelSource: "App:contactInformations.", value: this.getEmail() },
      ];
    }

    return items.map((i) => (
      <LabeledValue key={i.label} label={t(`${i.labelSource ? i.labelSource : "profileTab.contactInformation."}${i.label}`)}>
        {i.value}
      </LabeledValue>
    ));
  }

  public render() {
    const { t } = this.props;
    
    return (
      <ContactInformationContainer>
        <Title>{t("profileTab.contactInformation.title")}</Title>
        <SectionContent>{this.renderInfo()}</SectionContent>
      </ContactInformationContainer>
    );
  }
}
