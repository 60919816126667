import { Typography } from '@mui/material';
import { Advisor } from "../../../../../../common/advisor/interfaces/Advisor";
import * as React from 'react';
import { getPrimaryEmail, getPrimaryPhone } from './helper';
import { EmailSection, PhoneSection } from './styles/advisorCard.style';
import { TeamMember } from './styles/SearchResult.style';

interface ILocalProps {
  teamMember: Advisor;
  locale: string;
}

const TeamMemberCard: React.FC<ILocalProps> = ({ teamMember, locale }: ILocalProps) => {
  const primaryPhone = getPrimaryPhone(teamMember.phones);
  const primaryEmail: string = getPrimaryEmail(teamMember);
  const firstName = teamMember.preferredName !== '' ? teamMember.preferredName : teamMember.firstName;
  const language = locale.substr(0, 2);
  let titleInformation = '';
  if (teamMember.titles && teamMember.titles.length > 0 && teamMember.titles.filter((t) => t.language === language).length > 0) {
    const title = teamMember.titles.find((t) => t.language === language);
    titleInformation = title != null ? title.text : '';
  }
  return (
    <TeamMember>
      <Typography variant="h6" component="div">
        {firstName} {teamMember.lastName}
      </Typography>
      {titleInformation !== '' && <div>{titleInformation}</div>}
      {primaryPhone && <PhoneSection>{primaryPhone}</PhoneSection>}
      {primaryEmail !== '' && (
        <EmailSection>
          <a href={`mailto:${primaryEmail}`}>{primaryEmail}</a>
        </EmailSection>
      )}
    </TeamMember>
  );
};

export default TeamMemberCard;
