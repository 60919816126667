import * as React from "react";

import CheckboxButtonItem from "../../interfaces/CheckboxButtonItem";
import { Checkbox, Icon } from "./style/CheckboxButton.style";

export interface CheckboxButtonProps {
  checkboxItem: CheckboxButtonItem;
  onChange: (item: CheckboxButtonItem) => void;
}

const CheckboxButton = ({ checkboxItem, onChange }: CheckboxButtonProps) => (
  <Checkbox checked={checkboxItem.checked} onClick={() => onChange(checkboxItem)}>
    <label>
      {checkboxItem.iconSvg && <Icon>{checkboxItem.iconSvg}</Icon>}
      <span>{checkboxItem.text}</span>
    </label>
  </Checkbox>
);

export default CheckboxButton;
