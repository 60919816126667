import { authenticationService } from '../service/authentication.service';

export function authHeader() {
  const currentUser = authenticationService.currentUserValue;
  if (currentUser && currentUser.bearer) {
    return {
      headers: {
        Bearer: currentUser.bearer,
      },
    };
  } 
  return {};
}
