import { FormCol, PrimarySubmitButton, TextControl as IATextControl, TextInput as IATextInput } from "ia-react-core";
import styled from "styled-components";

export const Title = styled.h2`
  font-family: ${(props) => props.theme.fontFamily.semiBold};
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  color: ${(props) => props.theme.colors.BLACK};
  margin-bottom: 3px;
  line-height: normal;
`;

export const TextInput = styled(IATextInput)`
  background: ${(props) => props.theme.colors.gray1};
  border: none;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray4};
  padding: 3px 2px;
  color: ${(props) => props.theme.colors.BLACK};
  margin: 20px 0;
`;

export const TextControl = styled(IATextControl)`
  background: ${(props) => props.theme.colors.gray1};
  border: none;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray4};
  padding: 3px 2px;
  color: ${(props) => props.theme.colors.BLACK};
  margin: 20px 0;
`;

export const SubmitButton = styled(PrimarySubmitButton)``;

export const SeeAllClient = styled.div`
  a {
    color: ${(props) => props.theme.colors.primary};
    text-decoration: none;
    font-size: 14px;
    font-family: ${(props) => props.theme.fontFamily.bold};

    :hover {
      text-decoration: underline;
    }
  }

  svg {
    display: inline-block;
    vertical-align: middle;
    fill: ${(props) => props.theme.colors.primary};
  }
`;

export const FormColWithoutMargin = styled(FormCol)`
  > div {
    margin-bottom: 0;
    text-align: right;
  }
`;

export const RouterIcon = styled.div<{ isActive: boolean }>`
    &&& {
        margin: 10px 15px;
        height: 34px;
        display: block;
        a {
            color: ${(props) => (props.isActive ? "#003da5" : "#668bc9")};
            cursor: ${(props) => (props.isActive ? "pointer" : "default")};
            fill: currentColor;
        }
    }
`;
