enum Section {
    application = "application",
    advisor = "advisor",
    messages = "messages",
    account = "account",
    clientSearch = "clientSearch",
    clientProfile = "clientProfile",
    contact = "contact",
    jointAccountHolders = "jointAccountHolders",
    signaturePackageSearch = "signaturePackageSearch",
    unknown = "unknown",
}

export default Section;
