import * as React from "react";

import { Icon, Information, StatusContainer } from "../../../Result.style";

interface ResultColumnStatusProps {
  title: string;
  date: string;
  children: JSX.Element;
}

const ResultStatus: React.FC<ResultColumnStatusProps> = ({ title, date, children }: ResultColumnStatusProps) => (
  <StatusContainer>
    <Icon>{children}</Icon>
    <Information>
      <span>{title}</span>
      <span>{date}</span>
    </Information>
  </StatusContainer>
);

export default ResultStatus;
