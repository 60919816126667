import { TFunction } from "i18next";
import * as React from "react";

import StyledPopover from "../../../../../../common/popover/StyledPopover";
import { InvisibleSeparator, LinkAction, LinkRouterAction, Separator } from "../../../../../../common/popover/StyledPopover.style";
import { SignaturePackageStatus, SignerStatus } from "../../../../../../common/signaturePackage/enum";
import SignaturePackageActionEvent from "../../../../../../common/signaturePackage/enum/SignaturePackageActionEvent";
import { SignaturePackage } from "../../../../../../common/signaturePackage/interfaces/SignaturePackage";
import SignaturePackageHelper from "../../../../../../common/signaturePackage/utils/SignaturePackageHelper";
import { Action } from "../../../../../../common/svg";
import { DateDiffInDays } from "../../../../../../common/utils/utils";
import { ActionMenu } from "../Result.style";

export interface ResultActionsProps {
  signaturePackage: SignaturePackage;
  isSupport: boolean;
  t: TFunction;
  actionEvent: (id: string, action: SignaturePackageActionEvent, event?: React.MouseEvent<HTMLElement>) => void;
}

interface LocalState {
  visible: boolean;
}

class ResultActions extends React.PureComponent<ResultActionsProps, LocalState> {
  constructor(props: ResultActionsProps) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  private handleVisibleChange = (visible: boolean): void => {
    this.setState({ visible });
  };

  private contentAction = () => {
    const { t, signaturePackage, actionEvent } = this.props;
    const { id } = signaturePackage;
    return (
      <>
        <LinkRouterAction onClick={(e) => actionEvent(id.toString(), SignaturePackageActionEvent.selectedSignaturePackage, e)} to={SignaturePackageHelper.detailsUrl(id.toString())}>
          {t("searchResult.actions.viewDetails")}
        </LinkRouterAction>
        {this.getCustomActionByStatus()}
      </>
    );
  };

  private getCustomActionByStatus = (): JSX.Element | null => {
    const { signaturePackage } = this.props;
    switch (signaturePackage.status.transactionStatus) {
      case (SignaturePackageStatus.expired, SignaturePackageStatus.refused, SignaturePackageStatus.cancelled):
        return null;
      case SignaturePackageStatus.awaiting:
        return this.getAwaitingActions();
      case SignaturePackageStatus.completed:
        return this.getCompletedActions();
      default:
        return null;
    }
  };

  private getAwaitingActions = (): JSX.Element => {
    const { signaturePackage, t, actionEvent } = this.props;
    const { id } = signaturePackage;
    const signerAwaiting = SignaturePackageHelper.getAwaitingSigner(signaturePackage);
    let title = t("searchResult.actions.cancel");
    let action: SignaturePackageActionEvent = SignaturePackageActionEvent.cancel;

    if (signerAwaiting) {
      if (signerAwaiting.status === SignerStatus.emailrebound) {
        title = t("searchResult.actions.updateEmail");
        action = SignaturePackageActionEvent.emailBounce;
      }

      if (signerAwaiting.status === SignerStatus.locked) {
        title = t("searchResult.actions.unlockSigner");
        action = SignaturePackageActionEvent.unlock;
      }
    }

    return (
      <>
        <Separator />
        <LinkAction
          onClick={(e) => {
            e.preventDefault();
            actionEvent(id.toString(), action, e);
          }}
          href={`#${action.toString()}`}
        >
          {title}
        </LinkAction>
      </>
    );
  };

  private getCompletedActions = (): JSX.Element | null => {
    const { isSupport, actionEvent, t, signaturePackage } = this.props;
    const { id } = signaturePackage;
    const canDownloadZipLink = this.canDownloadZipLink();

    if (!isSupport && !canDownloadZipLink) {
      return null;
    }

    return (
      <>
        {isSupport && (
          <>
            <InvisibleSeparator />
            <LinkAction
              onClick={(e) => {
                e.preventDefault();
                actionEvent(id.toString(), SignaturePackageActionEvent.downloadEvidenceSummary, e);
              }}
              href="#downloadEvidenceSummary"
            >
              {t("searchResult.actions.downloadEvidenceSummary")}
            </LinkAction>
          </>
        )}
        {canDownloadZipLink && (
          <>
            <InvisibleSeparator />
            <LinkAction
              onClick={(e) => {
                e.preventDefault();
                actionEvent(id.toString(), SignaturePackageActionEvent.downloadSignedDocuments, e);
              }}
              href="#downloadSignedDocuments"
            >
              {t("searchResult.actions.downloadSignedDocuments")}
            </LinkAction>
          </>
        )}
      </>
    );
  };

  private canDownloadZipLink = (): boolean => {
    const { signaturePackage } = this.props;
    const transactionDateWith90DaysAdded = new Date(signaturePackage.createdTime);
    transactionDateWith90DaysAdded.setDate(transactionDateWith90DaysAdded.getDate() + 90);

    return DateDiffInDays(new Date(), transactionDateWith90DaysAdded) > 0;
  };

  public render() {
    const { visible } = this.state;

    return (
      <StyledPopover trigger="click" content={this.contentAction()} visible={visible} onVisibleChange={this.handleVisibleChange} placement="bottomRight">
        <ActionMenu>{Action(30)}</ActionMenu>
      </StyledPopover>
    );
  }
}

export default ResultActions;
