import { ColumnType } from "antd/lib/table";
import * as React from "react";

import { ResultPackage } from "..";
import { SignaturePackage } from "../../../../../../../common/signaturePackage/interfaces/SignaturePackage";
import { ResultTableProps } from "../ResultTable";

export function ResultColumnPackage(props: ResultTableProps): ColumnType<SignaturePackage> {
  const { t, actionEvent } = props;

  return {
    title: t("searchResult.columns.package"),
    dataIndex: "package",
    key: "package",
    render: (value: unknown, record: SignaturePackage) => <ResultPackage id={record.id.toString()} actionEvent={actionEvent} />,
  };
}
