export default {
  title: "Signature Packages",
  status: {
    awaiting: "Awaiting signature",
    expired: "Expired",
    cancelled: "Cancelled",
    locked: "Signer locked",
    completed: "Completed",
    refused: "Refused",
    emailrebound: "Email rebound",
    turnPending: "Turn pending",
  },
};
