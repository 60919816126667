import * as React from 'react';
import { Input, Button, Table, Tree, Modal, Steps, Radio, Checkbox } from 'antd';
import styled from 'styled-components';
import { CheckOutlined, SyncOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { TFunction } from 'i18next';
import { IAccount, IParty } from '../../../../../../common/client/interfaces/Party';
import { HouseholdNoRoleType, HouseholdType } from '../../../../../../common/enum/HouseholdType';
import { getAccountRoleName, getAccountsFromRoot, getCorporateAccounts, getOwnerAccounts, getShareableAccounts, IAccountAccessSharingModel } from '../../../../../../common/client/interfaces/AccesSharing';
import { subsidiaryName } from '../../../../../../common/client/interfaces/Client';
import AccountService from '../../../../../../common/account/account.service';
import ClientService from '../../../../../../common/client/client.service';

const iaButtonStyle = {
  borderColor: '#003ea5d9',
  backgroundColor: '#003ea5d9',
  color: 'white',
  fontSize: '14px',
  fontFamily: 'Open Sans SemiBold',
  fontWeight: 600,
  fontStyle: 'normal',
};

const { TreeNode } = Tree;
const { Step } = Steps;

const SearchFormContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width:100%;
    padding-top: 10px;
`;

const SharingViewContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ConfirmContainer = styled.div`
    text-align : center;
    width : 100%;
`;

const TreeContainer = styled.div`
    border-style: solid;
    border-width: 1px;
    padding: 5px;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 15px;
    overflow-x: hidden;
    overflow-y: scroll;
    max-height:300px
`;

const ErrorContainer = styled.div`
    display: flex;
    height: 70px;
    margin-top: 15px;
    padding-left: 100px;
    padding-top: 10px;
`;

const SearchButtonContainer = styled.div`
    margin-bottom: 10px;
    margin-top: 10px;
`;

export const Title = styled.div`
    font-family: 'Open Sans SemiBold', 'Open Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    color: #333333;
    font-size: 24px;
    padding-bottom: 3px;
`;

export const FontBold = styled.div`
    font-weight: 700;
`;

export const StepContainer = styled.div`
    padding-bottom: 20px;
`;

export interface IProps {
  client: IParty;
  isEdit: boolean;
  partyId?: string;
  sharingAccounts: IAccountAccessSharingModel[] | null;
  saveSharingAccounts: any;
  t: TFunction;
  lang: string;
  repCodes: string[];
}

export interface ITreeData {
  title: string;
  key: string;
  children: ITreeData[];
  disableCheckbox: boolean;
}

const accountService = new AccountService();
const clientService = new ClientService();

export class EditSharing extends React.Component<IProps> {
  state = {
    current: 0,
    modalAddVisible: false,
    name: '',
    clientId: '',
    username: '',
    msgError: '',
    selectedAccount: [] as string[],
    isLoading: false,
    results: null as IParty[] | null,
    partyIdFound: null as string | null,
    accounts: null as IAccount[] | null,
    role: HouseholdType.SHARING,
    clientProvidedCopy: false,
    clientProvidedCopyError: false,
  };

  resetState(modalAddVisible: boolean) {
    this.setState({
      current: 0,
      modalAddVisible,
      name: '',
      clientId: '',
      username: '',
      msgError: '',
      selectedAccount: [],
      isLoading: false,
      results: null,
      partyIdFound: null,
      accounts: null,
      role: HouseholdType.SHARING,
      clientProvidedCopy: false,
      clientProvidedCopyError: false,
    });
  }

  handleChange(event: any, ele: string) {
    this.setState({ [ele]: event.target.value });
  }

  handleSearch = async () => {
    this.setState({ isLoading: true });
    const clients = await clientService.searchClientByMultiParams(this.state.clientId,
      this.state.username, this.state.name, this.props.repCodes);
    this.setState({ results: clients?.data ?? [], isLoading: false });
  }

  handleClientProvidedCopy = () => this.setState({ clientProvidedCopy: !this.state.clientProvidedCopy })

  getAccounts = async () => {
    const userAccounts = getShareableAccounts(this.props.client.accounts);
    this.setState({ isLoading: true });
    const ids = `clientIds=${userAccounts.map((a: { id: any }) => a.id).join(',')}`;
    // const responseAccount = await httpService.get(`/FWMWPNS1/api/Accounts/Client?${ids}&lang=${this.props.lang}&subsidiary=${subsidiaryName}`);
    const responseAccount = await accountService.searchAccountsByClientIds(ids, this.props.lang, subsidiaryName);
    this.setState({ accounts: responseAccount, isLoading: false });
  }

  onRoleChange = (e: any) => {
    if (this.state.partyIdFound) {
      this.preselectAccounts(this.state.partyIdFound, e.target.value);
    }
  };

  selectUser = (partyId: string) => {
    this.setState({ current: 1, partyIdFound: partyId });
    this.preselectAccounts(partyId, null);
  }

  isShareeOwner = (partyId: string | null, accountId: string) => {
    if (this.props.sharingAccounts && partyId) {
      return this.props.sharingAccounts.some((w) => w.id === accountId && w.partyId === partyId && w.role === HouseholdType.OWNER);
    }
    return false;
  }

  isShareeCorporateOwner = (partyId: string | null, accountId: string) => {
    if (this.props.sharingAccounts && partyId) {
      return this.props.sharingAccounts.some((w) => w.id === accountId && w.partyId === partyId && w.role === HouseholdType.COMPANY && w.noRole === HouseholdNoRoleType.CORPORATE);
    }
    return false;
  }

  preselectAccounts = (partyId: string, role: string | null) => {
    if (this.props.sharingAccounts) {
      const commonAccounts = this.props.sharingAccounts.filter((w) => w.partyId === partyId);

      let currentRole = role;
      if (currentRole == null) {
        const shereeHasCorporateAccounts = commonAccounts.some((x) => x.role === HouseholdType.COMPANY && x.noRole === HouseholdNoRoleType.CORPORATE);
        currentRole = (shereeHasCorporateAccounts && this.isSharerHasCorporateAccounts()) ? HouseholdType.COMPANY : HouseholdType.SHARING;
      }

      const defaultSelectedAccountIds = commonAccounts.filter((w) => w.role === currentRole).map((x) => x.id);
      this.setState({ role: currentRole, selectedAccount: defaultSelectedAccountIds });
    }
  }

  isSharerHasCorporateAccounts = () => getCorporateAccounts(this.props.client.accounts).length > 0

  isSelectionNotAvailable = (accountId: string) => {
    const isShareeOwner = this.isShareeOwner(this.state.partyIdFound, accountId);
    const isShareeCorporateOwner = this.isShareeCorporateOwner(this.state.partyIdFound, accountId);
    return isShareeOwner || (!this.isSharerHasCorporateAccounts() && isShareeCorporateOwner);
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  save = async (fromId: string, toId: string, accounts: string[]) => {
    this.setState({ isLoading: true });
    this.props.saveSharingAccounts(fromId, toId, accounts, this.state.role);
    this.setState({ isLoading: false });
  }

  onCheck = (checkedKey: any) => {
    const accounts = checkedKey as string[];
    const account = accounts.filter((w) => w !== '-1');
    this.setState({ selectedAccount: account });
  }

  handleCancel = () => {
    this.resetState(false);
  };

  showAddModal = () => {
    this.resetState(true);
    this.getAccounts();

    if (this.props.isEdit && this.props.partyId) {
      this.selectUser(this.props.partyId);
    }
  };

  renderTreeNodes = (data: any[]) =>
    data.map((item) => {
      if (item.children) {
        return (
          <TreeNode title={item.title} key={item.key} data={item} disableCheckbox={item.disableCheckbox}>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.key} {...item} />;
    });

  renderSharingSucces = (accountList: ITreeData[]) => (
    <ConfirmContainer>
      <h1><CheckOutlined style={{ color: 'green' }} />{this.props.t('accessSharing.confirm')}</h1>
      <p>{this.props.t('accessSharing.confirmMsg')}</p>

      {this.state.selectedAccount.map((item) => <p key={item}>{accountList.filter((w) => w.key === item)[0].title}</p>)}

    </ConfirmContainer>
  )

  handleSearchChange(event: any, ele: string) {
    this.setState({ [ele]: event.target.value });
  }

  next = () => {
    const { client } = this.props;

    if (this.state.current === 1 && (this.state.partyIdFound)) {
      this.setState({ clientProvidedCopyError: undefined });
      if (this.state.role === HouseholdType.SHARING && !this.state.clientProvidedCopy) {
        this.setState({ clientProvidedCopyError: true });
        return;
      }
      this.save(client.id, this.state.partyIdFound, this.state.selectedAccount);
    }

    if (this.state.current === 2) {
      this.handleCancel();
      return;
    }

    this.setState({ current: this.state.current + 1 });
  }

  prev = () => {
    this.setState({ current: this.state.current - 1 });
  }

  isDisabled = (field: string) => {
    if (field === 'name') {
      return (this.state.username.length !== 0 || this.state.clientId.length !== 0);
    }
    if (field === 'clientId') {
      return (this.state.username.length !== 0 || this.state.name.length !== 0);
    }
    if (field === 'username') {
      return (this.state.clientId.length !== 0 || this.state.name.length !== 0);
    }
    return false;
  }

  renderFormIdentification = () => {
    const renderName = (row: IParty) => (
      <div>{row.lastName}, {row.firstName}</div>
    );

    const renderClientId = (row: IParty) => {
      const ownerAccountIds = getOwnerAccounts(row.accounts).map((d) => d.id);
      if (ownerAccountIds.length > 0) {
        return (
          <div>{ownerAccountIds.join(', ')}</div>
        );
      }
      return null;
    };

    const renderSelectUser = (row: IParty) => (
      <Button disabled={row.id === this.props.client.id} onClick={() => { this.selectUser(row.id); }}>{this.props.t('accessSharing.select')}</Button>
    );

    const renderRoleName = (row: IParty) => {
      const account = row.accounts.find((a) => a.id === this.state.clientId);
      if (account) {
        const roleName = getAccountRoleName(account.role, this.props.t);
        return (<div>{roleName}</div>);
      }
      return null;
    };

    const columns = [
      {
        title: this.props.t('accessSharing.clientName'),
        dataIndex: '',
        key: 'name',
        render: renderName,
      },
      {
        title: this.props.t('accessSharing.username'),
        dataIndex: 'personalizedAccessCode',
        key: 'personalizedAccessCode',
      },
      {
        title: this.props.t('accessSharing.clientId'),
        dataIndex: '',
        key: 'clientId',
        render: renderClientId,
      },
      {
        title: 'Action',
        dataIndex: '',
        key: 'x',
        render: renderSelectUser,
      },
    ];

    if (this.state.clientId.length !== 0) {
      const roleColumn =
      {
        title: 'Role',
        dataIndex: '',
        key: 'role',
        render: renderRoleName,
      };

      columns.splice(3, 0, roleColumn);
    }

    return (
      <div>
        <Title>{this.props.t('accessSharing.search')}</Title>
        <SearchFormContainer>
          <SharingViewContainer>
            <FontBold><label>{this.props.t('accessSharing.name')}</label></FontBold>
            <Input disabled={this.isDisabled('name')} value={this.state.name} onChange={(e) => this.handleSearchChange(e, 'name')} />
          </SharingViewContainer>
          <SharingViewContainer>
            <FontBold><label>{this.props.t('accessSharing.clientId')}</label></FontBold>
            <Input disabled={this.isDisabled('clientId')} value={this.state.clientId} onChange={(e) => this.handleSearchChange(e, 'clientId')} />
          </SharingViewContainer>
          <SharingViewContainer>
            <FontBold><label>{this.props.t('accessSharing.username')}</label></FontBold>
            <Input disabled={this.isDisabled('username')} value={this.state.username} onChange={(e) => this.handleSearchChange(e, 'username')} />
          </SharingViewContainer>
        </SearchFormContainer>

        <SearchButtonContainer>
          <Button disabled={this.state.name.length === 0 && this.state.clientId.length === 0 && this.state.username.length === 0} type="primary" icon={<SearchOutlined />} style={iaButtonStyle} onClick={this.handleSearch}>{this.props.t('accessSharing.search')}</Button>
        </SearchButtonContainer>

        {this.state.results ?
          <Table
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
            rowKey="id"
            dataSource={this.state.results}
            columns={columns}
            loading={this.state.isLoading}
          />
          : null}
      </div>
    );
  }

  renderSelectMyAccount = (treeData: any) => (
    <div>
      <Radio.Group onChange={this.onRoleChange} value={this.state.role}>
        <Radio value={HouseholdType.SHARING}> {this.props.t('accessSharing.thirdParty')}</Radio>
        <Radio disabled={!this.isSharerHasCorporateAccounts()} value={HouseholdType.COMPANY}>{this.props.t('accessSharing.corporateOwner')}</Radio>
      </Radio.Group>

      {this.state.isLoading ?
        <TreeContainer>
          <SyncOutlined />
        </TreeContainer> : false}

      {this.state.accounts && this.state.accounts.length > 0 ?
        (
          <TreeContainer>
            <Tree checkable defaultExpandAll onCheck={this.onCheck} checkedKeys={this.state.selectedAccount}>
              {this.renderTreeNodes(treeData)}
            </Tree>
          </TreeContainer>
        ) : null}

      {this.state.role === HouseholdType.SHARING &&
        <Checkbox style={{ paddingTop: "12px" }} checked={this.state.clientProvidedCopy} onChange={() => this.handleClientProvidedCopy()}>
          <div style={this.state.clientProvidedCopyError ? { color: '#f44336' } : {}}>
            {this.props.t("accessSharing.thirdPartyClientProvidedCopy")}
          </div>
        </Checkbox>}
    </div>
  )

  renderSucces = () => (
    <ConfirmContainer>
      <h1><CheckOutlined style={{ color: 'green' }} />Confirmation</h1>
    </ConfirmContainer>
  )

  public render() {
    const { client } = this.props;
    const { accounts } = this.state;
    const rootTreeData: ITreeData[] = [];
    if (client && accounts && this.state.partyIdFound) {
      const clientAccounts = getShareableAccounts(client.accounts);
      clientAccounts.forEach((a) => {
        rootTreeData.push({ title: a.id, key: a.id, disableCheckbox: this.isSelectionNotAvailable(a.id) } as ITreeData);
      });
      rootTreeData.forEach((r) => {
        const childAccount = getAccountsFromRoot(accounts, [r.key]);

        const accountTreeData: ITreeData[] = [];
        childAccount.forEach((a) => {
          accountTreeData.push({ title: a.accountType, key: a.id, disableCheckbox: true } as ITreeData);
        });
        r.children = accountTreeData;
      });
    }

    const treeData = [
      {
        title: this.props.t('accessSharing.allAccounts'),
        key: '-1',
        children: rootTreeData,
      },
    ];

    const stepsAccesSharing = [{
      title: this.props.t('accessSharing.recipient'),
      content: this.renderFormIdentification(),
    }, {
      title: this.props.t('accessSharing.clientIds'),
      content: this.renderSelectMyAccount(treeData),
    }, {
      title: 'Confirmation',
      content: this.renderSucces(),
    }];

    const modalTitle = this.props.t('accessSharing.subMenuAccessSharing');

    const buttonFooter = [
      <Button key="previous" style={{ marginLeft: 8 }} onClick={this.prev} disabled={this.state.current < 1}>{this.props.t('accessSharing.previous')}</Button>,
      <Button
        key="next"
        type="primary"
        onClick={this.next}
        style={iaButtonStyle}
        disabled={(this.state.current === 0 && !this.state.partyIdFound) || (this.state.current === 1 && this.state.selectedAccount.length === 0)}
      >
        {this.state.current !== 2 ? this.props.t('accessSharing.next') : 'OK'}
      </Button>,
    ];

    if (this.state.current === 0 || this.props.isEdit) {
      buttonFooter.shift();
    }

    return (
      <div>
        <Modal title={modalTitle} centered visible={this.state.modalAddVisible} onOk={this.handleCancel} onCancel={this.handleCancel} width={600} footer={buttonFooter} maskClosable={false}>
          <StepContainer>
            <Steps current={this.state.current}>
              {stepsAccesSharing.map((item) => <Step key={item.title} title={item.title} />)}
            </Steps>
          </StepContainer>
          {this.state.msgError !== '' && !this.state.isLoading ?
            <ErrorContainer className="errorMsg">
              <b>{this.props.t('accessSharing.error')}</b> <br /> {this.state.msgError}
            </ErrorContainer> : null}
          {this.state.isLoading ?
            <SharingViewContainer style={{ height: 300 }}>
              <SyncOutlined spin />
            </SharingViewContainer> :
            stepsAccesSharing[this.state.current].content}
        </Modal>
        {this.props.isEdit ?
          <Button shape="circle" icon={<EditOutlined />} onClick={this.showAddModal} /> :
          <Button disabled={this.props.sharingAccounts === null} key="add" type="primary" size="large" onClick={this.showAddModal} style={iaButtonStyle}>{this.props.t('accessSharing.add')}</Button>}

      </div>
    );
  }
}
