import { TeamByRepCode } from "../interfaces/TeamByRepCode";

export enum AdvisorActionTypes {
  GET_ADVISORS_BY_REPCODES = "[Advisor] Get Advisors by Rep Codes",
  GET_ADVISORS_BY_REPCODES_SUCCESS = "[Advisor] Get Advisors by Rep Codes Success",
  GET_ADVISORS_BY_REPCODES_ERROR = "[Advisor] Get Advisors by Rep Codes Error",
}

export interface GetAdvisorsByRepCodes {
  type: AdvisorActionTypes.GET_ADVISORS_BY_REPCODES;
  repCodes: string[];
}

export interface GetAdvisorsByRepCodesSuccess {
  type: AdvisorActionTypes.GET_ADVISORS_BY_REPCODES_SUCCESS;
  teamByRepCode: TeamByRepCode;
}

export interface GetAdvisorsByRepCodesError {
  type: AdvisorActionTypes.GET_ADVISORS_BY_REPCODES_ERROR;
}

export type AdvisorAction = GetAdvisorsByRepCodes | GetAdvisorsByRepCodesSuccess | GetAdvisorsByRepCodesError;
