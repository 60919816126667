import { httpService } from './http.service';
import { IParty } from '../Models/Client';
import { API_PARTIES, IS_LOCAL_DEV_ENV } from '../../../constants/API';

export const searchClientService = {
  search,
};

async function search(clientId: string, username: string, fullname: string, email: string) {
  const getBaseUrl = () => {
    // For unknown reason, in upper env. we need to proxy the parties call throught clientPortalIas
    //   and locally, we can't use it 🤔. => Locally = S3 / Upper envs = S1
    return IS_LOCAL_DEV_ENV ? API_PARTIES : "/FWMWPNS1/api/Parties";
  }
  try {
    let params = `personalizedAccessCode=${username}&fullName=${fullname}&email=${email}`;
    if (clientId !== '') {
      params += `&accounts=${clientId}`;
    }

    const response = await httpService.get(`${getBaseUrl()}?${params}`);
    const results = response.data.data as IParty[];
    if (results.length === 0 && username) {
      const responseAccessCode = await httpService.get(`${getBaseUrl()}?accessCode=${username}`);
      return responseAccessCode.data.data as IParty[];
    }
        
    return results;
  } catch (err) {
    return [] as IParty[];
  }
}
