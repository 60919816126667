import styled from "styled-components";

export const Container = styled.div`
  margin: 15px 0px;
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(32, 98, 212, 1);
  display: flex;
  padding: 5px;
  align-items: center;
  font-size: 14px;
  
  div {
    margin-left: 5px;
  }
`;
