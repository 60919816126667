import * as React from "react";
import { IPartyAddress } from "../../client/interfaces/Party";
import { FormatCountry } from "../../utils/CountryFormatter";
import Formatter from "../../utils/Formatter";
import { FormatRegion } from "../../utils/RegionFormatter/RegionFormatter";
import { AddressContainer, AddressLine } from "./AddressComponent.style";

interface LocalProps {
  address: IPartyAddress;
  locale: string;
}

const postalCode = (value: string): string | undefined => Formatter.formatPostalCode(value);
const unitNumber = (value: number): string => (value ? `${value},` : "");
const country = (code: string, language: string): string => {
  const value = FormatCountry({ code, language });
  return value || "";
};
const region = (address: IPartyAddress, locale: string): string => {
  const value = FormatRegion({ countryCode: address.countryCode, regionCode: address.regionCode, locale });
  return value ? ` (${value})` : "";
};

const AddressComponent: React.FC<LocalProps> = ({ address, locale }: LocalProps) => (
  <AddressContainer>
    <AddressLine>
      {address.unitTypeCode} {unitNumber(parseInt(address.unitNumber))} {address.streetNumber} {address.streetName}
    </AddressLine>
    <AddressLine>{address.municipality}{region(address, locale)}</AddressLine>
    <AddressLine>{country(address.countryCode, locale)} {postalCode(address.postalCode)}</AddressLine>
  </AddressContainer>
);

export default AddressComponent;
