import { Account } from "../interfaces/Account";
import {
  AccountActionTypes as types,
  GetAccountsClientInfoByClientIds,
  GetAccountsClientInfoByClientIdsError,
  GetAccountsClientInfoByClientIdsSuccess,
} from "./account.actions.types";

export const getAccountsClientInfoByClientIds = (clientIds: string[]): GetAccountsClientInfoByClientIds => ({ type: types.GET_ACCOUNTS_CLIENT_INFO_BY_CLIENTIDS, clientIds });

export const getAccountsClientInfoByClientIdsSuccess = (accounts: Account[]): GetAccountsClientInfoByClientIdsSuccess => ({ type: types.GET_ACCOUNTS_CLIENT_INFO_BY_CLIENTIDS_SUCCESS, accounts });

export const getAccountsClientInfoByClientIdsError = (): GetAccountsClientInfoByClientIdsError => ({ type: types.GET_ACCOUNTS_CLIENT_INFO_BY_CLIENTIDS_ERROR });
