import * as React from 'react';

// import { Advisor } from '~/common/advisor/interfaces/Advisor';
import { Advisor } from "../../../../../../common/advisor/interfaces/Advisor";
import { Email } from "../../../../../../common/advisor/interfaces/Email";
import { TradeName } from "../../../../../../common/advisor/interfaces/TradeName";
import { SemiBold } from "../../../../../../common/common.style";
import { RegulatoryRole } from "../../../../../../common/enum/RegulatoryRole";
import { Website } from "../../../../../../common/interfaces/Website";
import { User } from "../../../../../../common/svg";
import {
  Container,
  EmailSection,
  Icon,
  Informations,
  Name,
  OrganisationSection,
  WebsitesSection,
  PhoneSection,
} from './styles/advisorCard.style';
import { getPrimaryPhone } from './helper';
import { TFunction } from 'i18next';

interface ILocalProps {
  advisor: Advisor;
  t: TFunction;
}

const emailSection = (emails: Email[]): JSX.Element | null => {
  if (!emails) {
    return null;
  }

  const primaryEmail = emails.find((e: Email) => e.primary);
  return primaryEmail
    ? <a href={`mailto:${primaryEmail.emailAddress}`}>{primaryEmail.emailAddress}</a>
    : null;
};

const websiteSection = (websites: Website[]): JSX.Element | null => {
  if (!websites) {
    return null;
  }

  const website = websites.find((w: Website) => w.primary);
  return website
    ? <a href={`${website.url}`}>{website.url}</a>
    : null;
};

const tradeNameSection = (tradeNames: TradeName[]): JSX.Element | null => {
  if (!tradeNames) {
    return null;
  }

  const item = tradeNames.find((tradeName: TradeName) => tradeName.primary);
  return item
    ? <>{` | ${item.name}`}</>
    : null;
};

const roleSection = (regulatoryRole: RegulatoryRole, t: TFunction): JSX.Element | null => <SemiBold>{t(`regulatoryRoles.${regulatoryRole}`)}</SemiBold>;

const formatPhone = (phone: string): JSX.Element => {
  const linkPhoneNumber = phone !== null ? phone.replace(/\D/g, '') : '';
  const linkToPhone = `tel:${linkPhoneNumber}`;
  return (<a href={linkToPhone}>{phone}</a>);
};

const AdvisorCard: React.FC<ILocalProps> = ({ advisor, t }: ILocalProps) => {
  const primaryPhone = getPrimaryPhone(advisor.phones);

  return (
    <Container>
      <Icon>{User(40)}</Icon>
      <Informations>
        <Name>
          {advisor.preferredName ? advisor.preferredName : advisor.firstName} {advisor.lastName}
        </Name>
        <OrganisationSection>
          {roleSection(advisor.regulatoryRole, t)}
          {tradeNameSection(advisor.tradeNames)}
        </OrganisationSection>
        {primaryPhone && <PhoneSection>{formatPhone(primaryPhone)}</PhoneSection>}
        <EmailSection>{emailSection(advisor.emails)}</EmailSection>
        <WebsitesSection>{websiteSection(advisor.websites)}</WebsitesSection>
      </Informations>
    </Container>
  );
};

export default AdvisorCard;
