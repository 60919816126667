import { get, getScoped, injectReducer, injectSaga, injectTranslations } from "ia-react-core";
import { FC } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { Language } from "../../../common/enum/Language";
import { SearchCriteria } from "../../../common/interfaces/SearchCriteria";
import {
  loadMoreSignaturePackages,
  searchSignaturePackage,
} from "../../../common/signaturePackage/redux/signaturePackage.actions";
import { initialSignaturePackageState } from "../../../interfaces/SignaturePackageState";
import { State } from "../../../interfaces/State";
import { resetError, setSearchCriteriaHistory } from "../../../redux/app.actions";
import { selectSignaturePackagesWithOffset } from "./redux/signaturePackage.selector";
import SignaturePackageTrackingReducer from "./redux/Tracking.reducer";
import SignaturePackageTrackingSaga from "./redux/Tracking.saga";
import TrackingSearch, {
  TrackingSearchDispatchToProps,
  TrackingSearchMapStateToProps,
  TrackingSearchProps,
} from "./TrackingSearch";
import en from "./translations/en";
import fr from "./translations/fr";

const mapDispatchToProps: TrackingSearchDispatchToProps = {
  searchSignaturePackage,
  loadMoreSignaturePackages,
  setSearchCriteriaHistory,
  resetError,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: State, { scope }: any): TrackingSearchMapStateToProps => ({
  signaturePackages: selectSignaturePackagesWithOffset(state, scope),
  criteria: (getScoped(state, "criteria", scope) as unknown) as string,
  repCodes: (get(state, "App.repCodes") as unknown) as string[],
  isSupport: (get(state, "App.isSupport") as unknown) as boolean,
  loading: (getScoped(state, "loading", scope) as unknown) as boolean,
  searchLoading: (getScoped(state, "searchLoading", scope) as unknown) as boolean,
  totalResults: (getScoped(state, "totalResults", scope) as unknown) as number,
  currentOffset: (getScoped(state, "currentOffset", scope) as unknown) as number,
  searchCriteria: (get(state, "App.searchCriteria", scope) as unknown) as SearchCriteria,
  locale: (get(state, "language.locale") as unknown) as string,
});

export default compose<FC<TrackingSearchProps>>(
  injectTranslations("SignaturePackageTracking", [
    { language: Language.French, resource: fr },
    { language: Language.English, resource: en },
  ]),
  injectSaga({
    key: "SignaturePackageTracking",
    saga: SignaturePackageTrackingSaga,
  }),
  injectReducer({
    key: "SignaturePackageTracking",
    initialState: initialSignaturePackageState,
    reducer: SignaturePackageTrackingReducer,
    useScope: true,
    cleanState: true,
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(TrackingSearch);
