import styled from "styled-components";

export const Name = styled.span`
  margin-top: 50px;
`;

export const Accounts = styled.span`
  display: block;
  font-size: 12px;
  margin-top: 2px;
`;
