import styled from "styled-components";

export const AddressContainer = styled.p`
  margin: 3px 0px;
`;

export const AddressLine = styled.span`
  display: block;
  font-family: ${(props) => props.theme.fontFamily.regular};
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  color: #666666;
`;
