import { Reducer } from "redux";

import { AccountAction } from "../../../common/account/redux/account.actions.types";
import AccountReducer from "../../../common/account/redux/account.reducer";
import { AdvisorAction } from "../../../common/advisor/redux/advisor.actions.types";
import AdvisorReducer from "../../../common/advisor/redux/advisor.reducer";
import {
  JointAccountHoldersAction,
} from "../../../common/client/jointAccountHolders/redux/jointAccountHolders.actions.types";
import JointAccountHoldersReducer from "../../../common/client/jointAccountHolders/redux/jointAccountHolders.reducer";
import { ClientAction } from "../../../common/client/redux/client.actions.types";
import ClientReducer from "../../../common/client/redux/client.reducer";
import { ClientState, initialClientState } from "../../../interfaces/ClientState";

const reducers = [ClientReducer, AccountReducer, JointAccountHoldersReducer, AdvisorReducer];

const ClientProfileReducer: Reducer<ClientState, ClientAction & AccountAction & JointAccountHoldersAction & AdvisorAction> = (state = initialClientState, action): ClientState => reducers.reduce((currentState, reducer) => reducer(currentState, action), state);

export default ClientProfileReducer;
