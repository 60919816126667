import { Action, clearFormByName, submitFormByName } from "ia-react-core";
import * as React from "react";
import { connect } from "react-redux";
import { ResetButton } from "../../../../../common/common.style";

export interface SearchButtonResetProps {
  name: string;
  disabled: boolean;
  formName: string;
}

interface MapDispatchToProps {
  dispatchClearAndSubmitForm: () => void;
}

const mapDispatchToProps = (dispatch: React.Dispatch<Action>, { formName }: SearchButtonResetProps) => ({
  dispatchClearAndSubmitForm: () => {
    dispatch(clearFormByName(formName));
    dispatch(submitFormByName(formName));
  },
});

type Props = SearchButtonResetProps & MapDispatchToProps;
const SearchButtonReset: React.FC<Props> = ({ name, disabled, dispatchClearAndSubmitForm }: Props) => (
  <ResetButton disabled={disabled} type="button" onClick={dispatchClearAndSubmitForm}>
    {name}
  </ResetButton>
);
export default connect(null, mapDispatchToProps)(SearchButtonReset);
