import { ColumnType } from "antd/lib/table";
import * as React from "react";

import { Item, List } from "../../../../../../../common/common.style";
import { AccountByAdvisorGroupedByRepCode } from "../../../../../interfaces/AccountByAdvisorGroupedByRepCode";
import { AccountAdvisorTableProps } from "../AccountAdvisorTable";

export function NamesColumn(props: AccountAdvisorTableProps): ColumnType<AccountByAdvisorGroupedByRepCode> {
  const { t } = props;

  return {
    title: t("App:advisor"),
    dataIndex: "names",
    key: "names",
    render: (value: string[]) => (
      <List>
        {value.map((name: string) => (
          <Item key={name}>{name}</Item>
        ))}
      </List>
    ),
  };
}
