import { MessagesBySeverity } from "../common/interfaces/ResponsePayload";
import { SearchKeyValue } from "../common/signaturePackage/interfaces/SearchKeyValue";
import { SignaturePackage } from "../common/signaturePackage/interfaces/SignaturePackage";

export interface SignaturePackageState {
  signaturePackages: SignaturePackage[];
  searchKeyValueErrors: SearchKeyValue[];
  loading: boolean;
  totalResults: number;
  currentOffset: number;
  messages?: MessagesBySeverity;
  searchLoading: boolean;
  nbSearchLaunched: number;
}

export const initialSignaturePackageState: SignaturePackageState = {
  signaturePackages: [],
  searchKeyValueErrors: [],
  loading: false,
  currentOffset: 0,
  totalResults: 0,
  searchLoading: false,
  nbSearchLaunched: 0,
};
