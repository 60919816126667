import { createSelector } from "reselect";
import { Account } from "../../../common/account/interfaces/Account";
import { selectAccounts } from "../../../common/account/redux/account.selector";
import { Advisor } from "../../../common/advisor/interfaces/Advisor";
import { TeamByRepCode } from "../../../common/advisor/interfaces/TeamByRepCode";
import { selectTeamByRepCode } from "../../../common/advisor/redux/advisor.selector";
import { Client } from "../../../common/client/interfaces/Client";
import { selectClientWithScope } from "../../../common/client/redux/client.selector";
import { AccountByAdvisorGroupedByRepCode } from "../interfaces/AccountByAdvisorGroupedByRepCode";

export const selectAccountsGroupByRepCodes = createSelector(selectClientWithScope, selectTeamByRepCode, selectAccounts, (client: Client, teamByRepCode: TeamByRepCode, accounts: Account[]): AccountByAdvisorGroupedByRepCode[] => {
  const groups: AccountByAdvisorGroupedByRepCode[] = [];

  if (!client || (!accounts || accounts.length === 0) || !teamByRepCode) {
    return groups;
  }

  const repCodes = client.accounts.map((account) => account.repCode).filter((v, i, a) => a.indexOf(v) === i);

  repCodes.forEach((repCode) => {
    const accountsGroup = accounts.filter((account: Account) => account.repCode === repCode);
    if (accountsGroup.length === 0) {
      return;
    }
    
    const teams = teamByRepCode[repCode];
    if (teams) {
      const advisorsGroup = teams.Advisors;
      const names = advisorsGroup.map((advisor: Advisor) => `${advisor.lastName}, ${advisor.firstName}`);
      groups.push({ repCode, names, accounts: accountsGroup });
    }
  });

  return groups;
});
