import * as React from "react";

import { ClickableItem } from "./tabs/profile/profileTab.style";

interface HideInfoProps {
    click: () => void;
    info: string | JSX.Element | undefined;
    hide: string;
}

const HideInfo: React.FC<HideInfoProps> = ({ info, click, hide }: HideInfoProps) => {
  if (info === undefined) return null;

  return (
    <>
      {info}&nbsp;(<ClickableItem onClick={click}>{hide}</ClickableItem>)
    </>);
};

export default HideInfo;
