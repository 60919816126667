import { TFunction } from "i18next";
import * as React from "react";

import { RiskTolerance } from "../../../../../../common/client/interfaces/RiskTolerance";
import { RiskToleranceHelper } from "../../../../../../common/client/utils/RiskToleranceHelper";
import StyledDoughnut from "../../../../../../common/components/Chart/StyledDoughnut";
import { ChartContainer, ChartTitle } from "../investorProfileTab.style";

export interface InvestmentObjectivesChartProps {
  t: TFunction;
  data: RiskTolerance[];
}

const RiskToleranceChart: React.FC<InvestmentObjectivesChartProps> = (props: InvestmentObjectivesChartProps) => {
  const { t, data } = props;
  return (
    <ChartContainer>
      <ChartTitle>{t("investorTab.objectives.riskTolerance")}</ChartTitle>
      <StyledDoughnut data={RiskToleranceHelper.getRiskToleranceDataChart(data, t)} />
    </ChartContainer>);
};

export default RiskToleranceChart;
