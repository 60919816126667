import { ControlOnValueChangeOptions } from "ia-react-core";
import * as React from "react";

import { Container } from "./AutoComplete.style";
import AutoCompleteSearchField from "./components/AutoCompleteSearchField";
import AutoCompleteSuggestions from "./components/AutoCompleteSuggestion";
import AutoCompleteDataSource from "./datasource";

export interface AutoCompleteProps<Value> {
  name: string;
  fetchAction: (criteria: string) => void;
  loading: boolean;
  onSelected: (value?: Value, options?: ControlOnValueChangeOptions) => void;
  dataSource: AutoCompleteDataSource<Value>;
  texts: AutoCompleteText;
}

export interface AutoCompleteText {
  searchInProgress: string;
  emptyResult: string;
  placeholder: string;
}

export interface AutoCompleteOption<Value> {
  label?: React.ReactNode;
  value: Value;
  id: string;
}

interface LocalState {
  showSuggestions: boolean;
  searchField: string;
  hasValueSelected: boolean;
}

class AutoComplete<Value> extends React.PureComponent<AutoCompleteProps<Value>, LocalState> {
  constructor(props: AutoCompleteProps<Value>) {
    super(props);

    this.state = {
      showSuggestions: false,
      searchField: "",
      hasValueSelected: false,
    };
  }

  private onSelected = (option: AutoCompleteOption<Value>) => {
    const { onSelected, dataSource } = this.props;

    this.setState(
      {
        showSuggestions: false,
        searchField: dataSource.selectedResult(option),
        hasValueSelected: true,
      },
      () => {
        onSelected(option.value);
      },
    );
  };

  private onValueChange = (value: string) => {
    const { fetchAction } = this.props;
    const showSuggestions = value !== "";

    this.setState({ showSuggestions, searchField: value }, () => {
      if (showSuggestions) {
        fetchAction(value);
      }
    });
  };

  private removeSelection = () => {
    const { onSelected } = this.props;

    this.setState(
      {
        showSuggestions: false,
        searchField: "",
        hasValueSelected: false,
      },
      () => {
        onSelected();
      },
    );
  };

  public render() {
    const { name, dataSource, loading, texts } = this.props;
    const { showSuggestions, searchField, hasValueSelected } = this.state;

    return (
      <Container>
        <AutoCompleteSearchField name={name} onValueChange={this.onValueChange} placeholder={texts.placeholder} value={searchField} hasValueSelected={hasValueSelected} onRemove={this.removeSelection} />
        <AutoCompleteSuggestions<Value> dataSource={dataSource} value={searchField} loading={loading} texts={texts} onSelected={this.onSelected} show={showSuggestions} />
      </Container>
    );
  }
}

export default AutoComplete;
