import { TFunction } from "i18next";
import { Col, Form, Row, SelectControl, TextControl } from "ia-react-core";
import * as React from "react";

import { SubmitButton } from "../../../../common/common.style";
import { FormContainer, Label, Title } from "./Search.style";

export interface SearchProps {
  t: TFunction;
  onSearch: (repCode: string) => void;
  repCodes: string[];
  isSupport: boolean;
}

interface FormValues {
  repCode: string;
}

const formName = "profileContact";
class Search extends React.PureComponent<SearchProps> {
  private search = (formValues: FormValues) => {
    const { onSearch } = this.props;
    onSearch(formValues.repCode.trim().toUpperCase());
  };

  public render() {
    const { t, repCodes, isSupport } = this.props;

    const options = repCodes.map((repCode: string) => ({ label: repCode, value: repCode }));
    options.unshift({ label: t("App:choose"), value: "" });

    return (
      <>
        <Title>{t("title")}</Title>

        <FormContainer>
          <Form<FormValues> defaultCol={1} name={formName} onSubmit={(formValues: FormValues) => this.search(formValues)} initialValues={{ repCode: "" }}>
            <Row>
              <Col xs={12} md={4} p-b-xs={16}>
                <Label>{t("App:repCode")}</Label>
                {!isSupport && <SelectControl name="repCode" options={options} />}
                {isSupport && <TextControl name="repCode" placeholder={t("App:repCode")} />}
              </Col>
              <Col xs={12} md={4} p-t-md={28}>
                <SubmitButton>{t("App:show")}</SubmitButton>
              </Col>
            </Row>
          </Form>
        </FormContainer>
      </>
    );
  }
}

export default Search;
