import { TextControl as IATextControl } from "ia-react-core";
import styled from "styled-components";

import { SubmitButton as PrimarySubmitButton } from "../../../../../common/common.style";

export const SearchContainer = styled.div`
  form {
    background: #e0e4e9;
    box-shadow: 2px 2px 2px rgba(204, 204, 204, 0.35);
    padding: 20px;
  }
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  font-style: normal;
  font-family: ${(props) => props.theme.fontFamily.semiBold};
  font-size: 14px;
`;

export const TextControl: typeof IATextControl = styled(IATextControl)`
  font-size: 14px;
`;

export const H1 = styled.h1`
  margin: 0 0 10px 0;
`;

export const SubmitButton = styled(PrimarySubmitButton)`
  margin-right: 8px;
`;
