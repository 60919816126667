import { TFunction } from "i18next";
import * as React from "react";
import ClientActionEvent from "../../../../common/client/enum/clientActionEvent";
import { Client } from "../../../../common/client/interfaces/Client";
import { SecondaryButton } from "../../../../common/common.style";
import NoResult from "../../../../common/components/NoResult";
import Section from "../../../../common/enum/Section";
import { SearchCriteria } from "../../../../common/interfaces/SearchCriteria";
import { Container, LoadMoreContainer, Title, TitleSection } from "./ClientSearchResult.style";
import ClientSearchResultTable from "./components/ClientSearchResultTable";
export interface ClientSearchResultProps {
  t: TFunction;
  locale: string;
  data: Client[];
  searchCriteria: SearchCriteria;
  totalResultCount: number;
  loadMore: () => void;
  loading: boolean;
  searchLoading: boolean;
  onActionEvent: (id: string, action: ClientActionEvent, event?: React.MouseEvent<HTMLElement>) => void;
}

export interface LocalState {
  loadingMore: boolean;
}

class ClientSearchResult extends React.PureComponent<ClientSearchResultProps, LocalState> {
  constructor(props: ClientSearchResultProps) {
    super(props);
    this.state = {
      loadingMore: false,
    };
  }

  public componentDidUpdate(prevProps: ClientSearchResultProps) {
    const { data, searchCriteria } = this.props;

    if (prevProps.searchCriteria !== searchCriteria || prevProps.data.length !== data.length) {
      this.setState({ loadingMore: false });
    }
  }

  private loadMoreClients = () => {
    const { loadMore } = this.props;
    const { loadingMore } = this.state;
    if (loadingMore) {
      return;
    }

    this.setState({ loadingMore: true }, () => {
      loadMore();
    });
  };

  public render() {
    const { totalResultCount, data, t, onActionEvent, searchCriteria, locale, searchLoading } = this.props;
    if (searchLoading) {
      return null;
    }

    return (
      <Container>
        {data.length === 0 && <NoResult section={Section.clientSearch} />}
        {data.length > 0 && (
          <>
            <TitleSection>
              <Title>{`${totalResultCount} ${t("clientSearchResult.title")}`}</Title>
            </TitleSection>
            <ClientSearchResultTable data={data} t={t} actionEvent={onActionEvent} searchCriteria={searchCriteria} locale={locale} />
            {data.length < totalResultCount && (
              <LoadMoreContainer>
                <SecondaryButton onClick={this.loadMoreClients}>{t("App:loadMore")}</SecondaryButton>
              </LoadMoreContainer>
            )}
          </>
        )}
      </Container>
    );
  }
}

export default ClientSearchResult;
