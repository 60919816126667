/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { API_ADVISORS } from "../../constants/API";
import { RegulatoryRole } from "../enum/RegulatoryRole";
import { HttpGet } from "../utils/HttpClient";
import { Advisor } from "./interfaces/Advisor";
import { AdvisorPartyTypeCode } from "./interfaces/AdvisorPartyTypeCode";
import { Relationship } from "./interfaces/Relationship";
import { RelationshipTypeCode } from "./interfaces/RelationshipTypeCode";
import { RepCode } from "./interfaces/RepCode";
import { Team } from "./interfaces/Team";
import { TeamByRepCode } from "./interfaces/TeamByRepCode";
import { AdvisorHelper } from "./utils/AdvisorHelper";

class AdvisorService {
  public async getTeamByRepCodes(repCodes: string[]): Promise<TeamByRepCode> {
    const formattedRepCodes = repCodes.join("&repCodes=");
    const response: unknown = await HttpGet(`${API_ADVISORS}?repCodes=${formattedRepCodes}`, {});
    const advisors = response as Advisor[];
    return this.mapAdvisorsToTeam(advisors);
  }

  public async getAdvisorsMock(): Promise<TeamByRepCode> {
    return new Promise((resolve) =>
      setTimeout(resolve, 20, this.mapAdvisorsToTeam([
        {
          id: "000001",
          firstName: "Cindy",
          lastName: "Tecca",
          preferredName: "Red Angel",
          addresses: [],
          phones: [],
          lastModified: new Date(),
          emails: [],
          loading: false,
          hasError: false,
          isNationalBranch: false,
          repCodes: [{ code: "SES5" }, { code: "SSJD" }],
          tradeNames: [],
          websites: [],
          titles: [],
          relationshipCount: 0,
          relationships: [],
          partyTypeCode: AdvisorPartyTypeCode.Advisor,
          regulatoryRole: RegulatoryRole.Trader,
        },
      ])));
  }

  private mapAdvisorsToTeam(financialProfessionals: Advisor[]): TeamByRepCode {
    const teamByRepCodes: { [repcode: string]: Team } = {};
    const advisors = financialProfessionals.filter((a: Advisor) => a.partyTypeCode === AdvisorPartyTypeCode.Advisor);
    advisors.forEach((advisor: Advisor) => {
      const assistants: Advisor[] = advisor.relationships
        .filter((r: Relationship) => r.relationshipTypeCode === RelationshipTypeCode.Assistant)
        .map((r: Relationship) => r.relationParty);

      advisor.repCodes.forEach((repCode: RepCode) => {
        const { code } = repCode;
        this.createTeamIfNotExist(teamByRepCodes, code);

        teamByRepCodes[code].Advisors.push(advisor);
        teamByRepCodes[code].Assistants = teamByRepCodes[code].Assistants.concat(assistants);
      });
    });

    const branches = financialProfessionals.filter((a: Advisor) => a.partyTypeCode === AdvisorPartyTypeCode.Branch);
    branches.forEach((branch: Advisor) => {
      branch.repCodes.forEach((repCode: RepCode) => {
        const { code } = repCode;
        this.createTeamIfNotExist(teamByRepCodes, code);
        teamByRepCodes[code].Branches.push(branch);
      });
    });

    for (const key in teamByRepCodes) {
      // Equivalent to a Distinct.
      const advisorsSorted = this.getDistinctAdvisor(teamByRepCodes[key].Advisors).sort(AdvisorHelper.compareByRank);
      const branchesSorted = this.getDistinctAdvisor(teamByRepCodes[key].Branches).sort(AdvisorHelper.compareByRank);
      const assistantsSorted = this.getDistinctAdvisor(teamByRepCodes[key].Assistants).sort(AdvisorHelper.compareByRank);

      teamByRepCodes[key].Advisors = advisorsSorted;
      teamByRepCodes[key].Branches = branchesSorted;
      teamByRepCodes[key].Assistants = assistantsSorted;
    }

    return teamByRepCodes;
  }

  private getDistinctAdvisor(advisors: Advisor[]): Advisor[] {
    const uniqueAdvisors: Advisor[] = [];
    advisors.forEach((advisor: Advisor) => {
      if (uniqueAdvisors.find((a) => a.firstName === advisor.firstName && a.lastName === advisor.lastName) === undefined) {
        uniqueAdvisors.push(advisor);
      }
    });
    return uniqueAdvisors;
  }

  private createTeamIfNotExist(teamByRepCodes: TeamByRepCode, code: string) {
    if (!teamByRepCodes[code]) {
      teamByRepCodes[code] = {
        Advisors: [],
        Assistants: [],
        Branches: [],
      };
    }
  }
}

export default AdvisorService;
