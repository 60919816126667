import { ColumnType } from "antd/lib/table";
import { InjectTranslationsProps } from "ia-react-core";
import React from "react";
import { Attachment } from "../../../../../../../common/messages/interfaces/Attachment";
import DocumentActions from "./DocumentActions";

export const ActionColumn = (props: InjectTranslationsProps, 
  onLinkClicked: (id: string, fileName: string) => Promise<void>, 
  onShareToggleClicked: (record: Attachment, isResharing: boolean, reason: string) => Promise<void>,
  userCanSendDocuments: boolean): ColumnType<Attachment> => ({
  title: props.t("documentsTab.actionName"),
  width: "10%",
  key: "action",
  render: (value: unknown, record: Attachment) => userCanSendDocuments && <DocumentActions props={props} record={record} onLinkClicked={onLinkClicked} onShareToggleClicked={onShareToggleClicked} />,
});
