import styled from "styled-components";
import { Button, SemiBold, SubmitButton } from "../../../../../../common/common.style";

export const SubmitDocumentButton = styled(SubmitButton)`
  margin-top: 20px;
  margin-left: auto;
  align-self: center;
`;

export const DisabledButton = styled(Button) <{ disabled: true }>`
  margin-top: 20px;
  margin-left: auto;
  height: 40px;
  align-self: center;
`;

export const SendDocumentContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SendDocumentSubtitle = styled.h3`
  font-family: ${(props) => props.theme.fontFamily.bold};
  margin: 20px 0 0 0;
`;

export const Subtitle = styled.p`
  margin-bottom: 5px;
  margin-top: 0;
  font-size: 14px;
  font-family: ${(props) => props.theme.fontFamily.bold};
`;

export const MaxSizeText = styled(SemiBold)`
  margin-bottom: 3px;
  font-size: 14px;
`;

export const AccountSelector = styled.div`
  flex: 0 0 100%;
  width: 450px;
`;

export const AccountSelectorSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  width: 100%;
`;

export const ConfirmationSection = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Confirmation = styled.div`
  background-color: ${(props) => props.theme.colors.infoBackground};
  margin: 20px 20px 0 0;
  
  label {
    margin: 15px;
    font-size: 14px;
  }
`;
