import * as React from 'react';

import { Phone } from "../../../../../../common/interfaces/Phone";
import { BranchPhone, BranchPhones } from './styles/SearchResult.style';

interface ILocalProps {
    phones: Phone[];
  }

const formatPhone = (phone: Phone): JSX.Element => {
  const linkPhoneNumber = phone !== null ? phone.phoneNumber.replace(/\D/g, '') : '';
  const linkToPhone = `tel:${linkPhoneNumber}`;

  return (<BranchPhone key={linkPhoneNumber}><a href={linkToPhone}>{phone.phoneNumber}</a></BranchPhone>);
};

const BranchPhoneCard: React.FC<ILocalProps> = ({ phones }: ILocalProps) => {
  const phoneList = phones.map((phone) => formatPhone(phone));

  return (
    <BranchPhones>
      {phoneList}
    </BranchPhones>
  );
};

export default BranchPhoneCard;
