import { FormRow as IAFormRow, PrimaryButton, PrimarySubmitButton } from "ia-react-core";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import Colors from "../themes/colors";

export const Container = styled.div``;
export const RelativeContainer = styled(Container)`
  position: relative;
`;

export const Text = styled.span`
  font-family: 'Open Sans SemiBold','Open Sans Regular','Open Sans',sans-serif;
`;

export const Button = styled(PrimaryButton)`
  padding: 10px 25px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  padding: 0 30px;
  border-radius: 3px;
`;

export const FormRow = styled(IAFormRow)`
  flex-basis: 100%;
`;

export const SubmitButton = styled(PrimarySubmitButton)`
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  padding: 0 30px;
  border-radius: 3px;
`;

export const SubmitButtonWhite = styled(SubmitButton)`
  height: 38px;
  line-height: 38px;
  font-size: 14px;
  padding: 0 30px;
  color: ${(props) => props.theme.colors.blue5};
  background-color: ${(props) => props.theme.colors.gray1};
  box-shadow: 0px 1px 0px #aaaaaa;
  border: 1px solid ${(props) => props.theme.colors.gray1};
  border-radius: 3px;
  outline: none;

  :hover {
    border: 1px solid ${(props) => props.theme.colors.blue5};
    background-color: ${(props) => props.theme.colors.gray1};
  }

  :active,
  :focus {
    border: 1px solid ${(props) => props.theme.colors.blueGray3};
    background-color: ${(props) => props.theme.colors.blueGray3};
    color: ${(props) => props.theme.colors.white};
  }

  :disabled {
    border: 1px solid ${(props) => props.theme.colors.gray1};
    background-color: ${(props) => props.theme.colors.gray1};
    color: #aaaaaa;
    box-shadow: none;
  }
`;

export const SecondaryButton = styled(Button)`
  background: transparent;
  border: 1px solid #aaaaaa;
  color: ${(props) => props.theme.colors.blue5};
  box-shadow: none;
  outline: none;
  border-radius: 3px;

  :hover {
    border: 1px solid ${(props) => props.theme.colors.blue5};
    background-color: rgba(21, 85, 212, 0.05);
  }

  :active,
  :focus {
    border: 1px solid ${(props) => props.theme.colors.blue5};
    background-color: rgba(32, 98, 212, 0.15);
  }

  :disabled {
    border: 1px solid ${(props) => props.theme.colors.gray1};
    background-color: transparent;
    color: #aaaaaa;
    box-shadow: none;
  }
`;

export const ResetButton = styled(PrimaryButton)`
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  background: transparent;
  color: ${(props) => props.theme.colors.blue5};
  border: none;
  box-shadow: none;
  padding: 0 15px;
  border-radius: 3px;

  :hover,
  :active,
  :focus {
    background: transparent;
    color: ${(props) => props.theme.colors.blue5};
    border: none;
    box-shadow: none;
    outline: none;
  }

  :hover {
    background: rgba(32, 98, 212, 0.05);
  }

  :disabled {
    background: transparent;
    color: ${(props) => props.theme.colors.blue5};
    border: none;
  }
`;

export const LinkRouter = styled(RouterLink)`
  color: ${(props) => props.theme.colors.blue5};
`;

export const StyledLinkRouterWithIcon = styled(LinkRouter)`
  margin-bottom: 8px;
  display: block;

  svg {
    vertical-align: text-top;
    margin-left: -6px;
  }
`;

export const Link = styled.a`
  color: ${(props) => props.theme.colors.blue5};
`;

export const LinkBold = styled(LinkRouter)`
  font-family: ${(props) => props.theme.fontFamily.semiBold};
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const Item = styled.li`
  margin: 0;
  padding: 0;
`;

export const SemiBold = styled.span`
  font-family: ${(props) => props.theme.fontFamily.semiBold};
`;

interface ButtonLinkLookAlikeProps {
  disabled: boolean;
}

export const ButtonLinkLookAlike = styled.button<ButtonLinkLookAlikeProps>`
  background: none;
  padding: 0;
  border: none;
  text-decoration: underline;
  text-align: left;
  cursor: ${(props) => props.disabled ? "progress" : "pointer"};
  color: #333333;
  font-family: ${(props) => props.theme.fontFamily.semiBold};
`;

export const ButtonActionLookAlike = styled.button<ButtonLinkLookAlikeProps>`
  background: none;
  padding: 7px 2px 7px 15px;
  width: 100%;
  border: none;
  text-align: left;
  cursor: ${(props) => props.disabled ? "text" : "pointer"};
  color: ${(props) => props.disabled ? "#7F7F7F" : "#333333"};
  font-family: ${(props) => props.theme.fontFamily.semiBold};
  position: relative;

  disabled {
    color: gray;
  }

  :hover {
    background-color: #e3f2fd;
    color: ${(props) => props.disabled ? "#7F7F7F" : "#2062d4"};
  }
`;

export const iaButtonStyle = {
  margin: '0px',
  height: '40px',
  borderColor: '#003ea5d9',
  backgroundColor: '#003ea5d9',
  color: '#ffffff',
  fontSize: '14px',
  fontFamily: ['OpenSans-SemiBold', 'Open Sans SemiBold', 'Open Sans Regular', 'Open Sans', 'sans-serif'].join(', '),
  fontWeight: 650,
  fontStyle: 'normal',
  borderRadius: '4px',
  padding: '0 20px'
};

export const iaButtonStyleDisabled = {
  margin: '0px',
  height: '40px',
  fontSize: '14px',
  fontFamily: ['OpenSans-SemiBold', 'Open Sans SemiBold', 'Open Sans Regular', 'Open Sans', 'sans-serif'].join(', '),
  backgroundColor: '#b4b4b4',
  color: Colors.white,
  fontWeight: 650,
  fontStyle: 'normal',
  borderRadius: '4px',
  lineHeight: 'normal',
  padding: '0 20px'
};

export const iaWhiteButtonStyle = {
  margin: '0px',
  width: '170px',
  height: '45px',
  borderColor: '#003ea5d9',
  backgroundColor: Colors.white,
  color: '#003ea5d9',
  fontSize: '14px',
  fontFamily: 'Open Sans SemiBold',
  fontWeight: 600,
  fontStyle: 'normal'
};

/* Typography using https://app.zeplin.io/styleguide/5ecbf99930c6ab4948ea111e/textstyles */
export const Paragraphe = {
  fontSize: 14,
  fontFamily: ['Open Sans Regular', 'Open Sans', 'sans-serif'].join(', '),
  fontWeight: 400,
  color: '#000000',
  fontStyle: 'normal'
}

export const Heading5 = {
  fontSize: 17,
  fontFamily: ['Open Sans Bold', 'Open Sans Regular', 'Open Sans', 'sans-serif'].join(','),
  fontWeight: 700,
  color: '#000000',
  fontStyle: 'normal'
}
