import styled from "styled-components";

import { Container } from "../../common/common.style";

export const ClientProfileContainer = styled(Container)`
  border: none;
  position: relative;
  margin: 20px 0px;
`;

export const ClientName = styled.h2` 
  font-size: 24px;
  color: #333333;
  font-family: ${(props) => props.theme.fontFamily.regular};
  font-weight: 700;
  margin-bottom: 18px
`;

export const WarningMessageContainer = styled(Container)`
   background:${(props) => props.theme.colors.warning};
   padding:15px;
   width:80%;   
`;

export const StyledSection = styled.div`
  background-color: ${(props) => props.theme.colors.gray2};
  padding-top: 34px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 27px;
`;

export const Title = styled.h2`
  font-family: ${(props) => props.theme.fontFamily.regular};
  font-weight: 700;
  margin-bottom: 22px;
  font-size: 21px;
  line-height: 21px;
  color: #333333;
`;
