import * as React from "react";
import { LinkProps } from "react-router-dom";

import { StyledLinkRouterWithIcon } from "../../common.style";
import { ArrowLeft } from "../../svg";

export interface BackLinkProps extends LinkProps {
    children: React.ReactChildren;
}
  
const BackLink: React.FC<BackLinkProps> = (props: BackLinkProps) => {
  const { children } = props;
  return <StyledLinkRouterWithIcon {...props}>{ArrowLeft()}{children}</StyledLinkRouterWithIcon>;
};
  
export default BackLink;
