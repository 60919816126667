import { Select } from 'antd';
import styled from 'styled-components';

export const ContentPageContainer = styled.div` 
    background-color: #FFFFFF;
    padding: 20px;
    min-height: 300px;
`;

export const { Option } = Select;

export const RowContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 12px;
`;