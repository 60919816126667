import "antd/es/popover/style/css";

import { Popover } from "antd";
import { PopoverProps } from "antd/lib/popover";
import * as React from "react";

import { GlobalTooltipStyle, Styled } from "./StyledPopover.style";

export interface StyledPopoverProps extends PopoverProps {}

const style: React.CSSProperties = {
  backgroundColor: "#000",
};

const StyledPopover: React.FC<StyledPopoverProps> = (props: StyledPopoverProps) => (
  <Styled>
    <GlobalTooltipStyle />
    <Popover overlayClassName="ias-popover" {...props} style={style} />
  </Styled>
);

export default StyledPopover;
