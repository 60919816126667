import { Reducer } from "redux";

import { AppState, initialAppState } from "../../../interfaces/AppState";
import { IasRoleSupport } from "../../constants";
import { GetUserSuccess, UserAction, UserActionTypes as types } from "./user.actions.types";

const handleGetUserSuccess = (state: AppState, { user }: GetUserSuccess) => {
  const isSupport: boolean = user.roles.filter((role: string) => role === IasRoleSupport).length > 0;
  return {
    ...state,
    repCodes: user.repCodes,
    isSupport,
    searchCriteria: { ...state.searchCriteria, repCodes: user.repCodes },
    hasUser: true,
  };
};

const UserReducer: Reducer<AppState, UserAction> = (state = initialAppState, action): AppState => {
  switch (action.type) {
    case types.GET_USER:
      return { ...state, repCodes: [], hasError: false, hasUser: false };
    case types.GET_USER_SUCCESS:
      return handleGetUserSuccess(state, action);
    case types.GET_USER_ERROR:
      return { ...state, repCodes: [], hasError: true, hasUser: false };
    case types.SET_USER:
      return { ...state, repCodes: action.repCodes, isSupport: action.isSupport, searchCriteria: { ...state.searchCriteria, repCodes: action.repCodes }, hasUser: true };
    default:
      return state;
  }
};

export default UserReducer;
