import * as Countries from "i18n-iso-countries";
import en from "i18n-iso-countries/langs/en.json";
import fr from "i18n-iso-countries/langs/fr.json";

interface FormatCountryProps {
  language: string;
  code: string;
}

export function FormatCountry(props: FormatCountryProps): string {
  const { code, language } = props;

  Countries.registerLocale(en);
  Countries.registerLocale(fr);

  return Countries.getName(code, language) || '';
}
