import { TFunction } from "i18next";

import TimeHorizon from "../enum/TimeHorizon";

export default class TimeHorizonHelper {
  static getLabel = (data: TimeHorizon, t: TFunction): string => {
    switch (data) {
      case TimeHorizon.Years3AndLess:
        return t("App:timeHorizon.years3AndLess");
      case TimeHorizon.Years3To5:
        return t("App:timeHorizon.years3To5");
      case TimeHorizon.Years6To10:
        return t("App:timeHorizon.years6To10");
      case TimeHorizon.Years11To20:
        return t("App:timeHorizon.years11To20");
      case TimeHorizon.Years20AndOver:
        return t("App:timeHorizon.years20AndOver");
      default:
        return t("App:timeHorizon.unknown");
    }
  };
}
