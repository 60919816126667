import * as React from "react";

import ClientActionEvent from "../../../../../common/client/enum/clientActionEvent";
import ClientHelper from "../../../../../common/client/utils/ClientHelper";
import { LinkBold } from "../../../../../common/common.style";

interface LocalProps {
  id: string;
  fullName: string;
  actionEvent: (id: string, action: ClientActionEvent, event?: React.MouseEvent<HTMLElement>) => void;
}

const ClientSearchResultTitle: React.FC<LocalProps> = ({ id, fullName, actionEvent }: LocalProps) => <LinkBold onClick={(e) => actionEvent(id, ClientActionEvent.selectedClient, e)} to={ClientHelper.clientProfileUrl(id)}>{fullName}</LinkBold>;

export default ClientSearchResultTitle;
