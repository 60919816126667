import { API_MESSAGING } from "../../constants/API";
import { ResponsePayloadDotNetCore } from "../interfaces/ResponsePayload";
import { HttpGet, HttpGetBlob, HttpPost } from "../utils/HttpClient";
import MessageDirection from "./enums/MessageDirection";
import { Attachment } from "./interfaces/Attachment";
import { Message } from "./interfaces/Message";

/* eslint-disable @typescript-eslint/no-explicit-any */
class MessagesService {
  public async getAttachmentsForParty(partyId: string, page: number, pageSize: number, direction?: string): Promise<Attachment[]> {      
    let url = `${API_MESSAGING}/Attachments?membersId=${partyId}&page=${page}&pageSize=${pageSize}`;
    if (direction) {
      url = `${url}&direction=${direction}`;
      if (direction === MessageDirection.sent) {
        url = `${url}&includeUnshared=true`;
      }
    }
    const response = await HttpGet(url);
    return response.data as Attachment[];
  }

  public async downloadAttachment(id: string): Promise<Blob> {
    const response = await HttpGetBlob(`${API_MESSAGING}/Attachments/${id}`);
    return response;
  }
 
  public async sendMessage(fromId: string, toId: string, attachments: File[]): Promise<ResponsePayloadDotNetCore<Message>> {
    const requestBody = new FormData();
    requestBody.append("fromId", fromId);
    requestBody.append("toId", toId);
    attachments.forEach((attachment) => {
      requestBody.append("files", attachment, attachment.name);
    });
    const response = await HttpPost(`${API_MESSAGING}`, requestBody);
    return response;
  }
  
  public async toggleDocShare(id: string, partyId: string, reason: string): Promise<ResponsePayloadDotNetCore<Message>> {
    const requestBody = new FormData();
    requestBody.append("partyId", partyId);
    requestBody.append("documentId", id);
    requestBody.append("unshareReason", reason);
    const response = await HttpPost(`${API_MESSAGING}/ToggleDocShare`, requestBody);
    return response;
  }
}

export default MessagesService;
