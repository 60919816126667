import { ColumnType } from "antd/lib/table";
import * as React from "react";

import StringValue from "../../../../../../../common/utils/Value";
import { AccountGroupedByBankAccount } from "../../../../../interfaces/AccountGroupedByBankAccount";
import { BankAccountsTableProps } from "../BankAccountsTable";

export function AccountNumberColumn(props: BankAccountsTableProps): ColumnType<AccountGroupedByBankAccount> {
  const { t } = props;

  return {
    title: t("profileTab.bankAccountsInformation.account"),
    dataIndex: "accountNumber",
    key: "accountNumber",
    render: (value: string) => <StringValue>{value}</StringValue>,
  };
}
