import HttpError from "../common/components/ErrorBoundary/interface/HttpError";
import { SearchCriteria } from "../common/interfaces/SearchCriteria";

export enum AppActionTypes {
  SET_SEARCH_CRITERIA_HISTORY = "[App] Set Search Criteria History",
  SET_ERROR = "[App] Set Error",
  RESET_ERROR = "[App] Reset Error",
}

export interface SetSearchCriteriaHistory {
  type: AppActionTypes.SET_SEARCH_CRITERIA_HISTORY;
  searchCriteria: SearchCriteria;
}

export interface SetError {
  type: AppActionTypes.SET_ERROR;
  error: HttpError;
}

export interface ResetError {
  type: AppActionTypes.RESET_ERROR;
}

export type AppAction = SetSearchCriteriaHistory | SetError | ResetError;
