import { injectTranslations } from "ia-react-core";
import { FC } from "react";
import { compose } from "redux";
import { Language } from "../../../../../../common/enum/Language";
import en from "../../../../translations/en";
import fr from "../../../../translations/fr";
import DocumentsTable, { DocumentsTableProps } from "./DocumentsTable";

export default compose<FC<DocumentsTableProps>>(
  injectTranslations("ClientProfile", [
    { language: Language.French, resource: fr },
    { language: Language.English, resource: en },
  ]),
)(DocumentsTable);
