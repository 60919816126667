import { Loader } from "ia-react-core";
import * as React from "react";

import { AutoCompleteOption, AutoCompleteText } from "../AutoComplete";
import {
  EmptyResult,
  LoadingContainer,
  LoadingControl,
  LoadingText,
  Option,
  Options,
  Suggestions,
} from "../AutoComplete.style";
import AutoCompleteDataSource from "../datasource";

export interface AutoCompleteSuggestionsProps<Value> {
  loading: boolean;
  onSelected: (option: AutoCompleteOption<Value>) => void;
  dataSource: AutoCompleteDataSource<Value>;
  texts: AutoCompleteText;
  value: string;
  show: boolean;
}

class AutoCompleteSuggestions<Value> extends React.PureComponent<AutoCompleteSuggestionsProps<Value>> {
  public render() {
    const { dataSource, loading, texts, value, onSelected, show } = this.props;
    if (!show) {
      return null;
    }
    return (
      <Suggestions isLoading={loading}>
        {loading && (
          <LoadingContainer>
            <LoadingText>{texts.searchInProgress}</LoadingText>
            <LoadingControl>
              <Loader size={24} />
            </LoadingControl>
          </LoadingContainer>
        )}
        {!loading && (
          <>
            {dataSource.options.length > 0 && (
              <Options>
                {dataSource.options.map((option: AutoCompleteOption<Value>) => (
                  <Option key={option.id} onClick={() => onSelected(option)}>
                    {dataSource.formatResult(option, value)}
                  </Option>
                ))}
              </Options>
            )}
            {dataSource.options.length === 0 && <EmptyResult>{texts.emptyResult}</EmptyResult>}
          </>
        )}
      </Suggestions>
    );
  }
}

export default AutoCompleteSuggestions;
