import { ColumnType } from "antd/lib/table";
import * as React from "react";

import { SignaturePackage } from "../../../../../../../common/signaturePackage/interfaces/SignaturePackage";
import ResultDate from "../ResultDate";
import { ResultTableProps } from "../ResultTable";

export function ResultColumnCreatedOn(props: ResultTableProps): ColumnType<SignaturePackage> {
  const { t, locale } = props;

  return {
    title: t("searchResult.columns.createdOn"),
    dataIndex: "createdOn",
    key: "createdOn",
    width: 125,
    render: (value: unknown, record: SignaturePackage) => (
      <ResultDate format={t("App:shortDateFormat")} locale={locale}>
        {record.createdTime}
      </ResultDate>
    ),
  };
}
