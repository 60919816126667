import { ColumnType } from "antd/lib/table";
import * as React from "react";

import { Client } from "../../../../../../common/client/interfaces/Client";
import ClientHelper from "../../../../../../common/client/utils/ClientHelper";
import { JointAccountHoldersTableProps } from "../JointAccountHoldersTable";

export function NameColumn(props: JointAccountHoldersTableProps): ColumnType<Client> {
  const { t } = props;
  
  return {
    title: t("App:name"),
    dataIndex: "name",
    width: "25%",
    key: "name",
    render: (value: unknown, record: Client) => (
      <>
        {ClientHelper.getFullName(record)}          
      </>
    ),
  };
}
