import * as React from "react";
import { v4 as uuid } from "uuid";

import { Item, List } from "../../../../../../common/common.style";
import { Signer } from "../../../../../../common/signaturePackage/interfaces/Signer";

interface LocalProps {
  signers: Signer[];
}

const signersRender = (signers: Signer[]) => signers.map((signer: Signer) => <Item key={uuid()}>{signer.fullName}</Item>);

const ResultSigners: React.FC<LocalProps> = ({ signers }: LocalProps) => <List>{signersRender(signers)}</List>;

export default ResultSigners;
