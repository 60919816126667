import { getScoped } from "ia-react-core";
import { createSelector } from "reselect";

import { State } from "../../../interfaces/State";
import { Advisor } from "../interfaces/Advisor";
import { AdvisorGroupByRepCode } from "../interfaces/AdvisorGroupByRepCode";
import { TeamByRepCode } from "../interfaces/TeamByRepCode";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectTeamByRepCode = (state: State, scope: any): TeamByRepCode => (getScoped(state, "teamByRepCode", scope) as unknown) as TeamByRepCode;

export const selectRepCodes = (state: State): string[] => state.App.repCodes;

export const selectAdvisorsGroupByRepCodes = createSelector(selectRepCodes, selectTeamByRepCode, (repCodes: string[], teamByRepCode: TeamByRepCode): AdvisorGroupByRepCode[] => {
  const groups: AdvisorGroupByRepCode[] = [];
  if (!repCodes || repCodes.length === 0) {
    return groups;
  }
  repCodes.forEach((repCode) => {
    const advisorsGroup = teamByRepCode[repCode].Advisors;
    const names = advisorsGroup.map((advisor: Advisor) => `${advisor.lastName}, ${advisor.firstName}`);
    groups.push({ repCode, names });
  });
  return groups;
});
