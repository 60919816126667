import { ClientAccount } from "../../interfaces/Account";
import { Client } from "../../interfaces/Client";

export enum JointAccountHolderActionTypes {
    GET_JOINT_ACCOUNT_HOLDERS_BY_ACCOUNTS = "[Client] Get Joint Account Holders by Accounts",
    GET_JOINT_ACCOUNT_HOLDERS_BY_ACCOUNTS_SUCCESS = "[Client] Get Joint Account Holders by Accounts Success",
    GET_JOINT_ACCOUNT_HOLDERS_BY_ACCOUNTS_ERROR = "[Client] Get Joint Account Holders by Accounts Error",
}

export interface GetJointAccountHoldersByAccounts {
    type: JointAccountHolderActionTypes.GET_JOINT_ACCOUNT_HOLDERS_BY_ACCOUNTS;
    accounts: ClientAccount[];
    clientId: string;
}

export interface GetJointAccountHoldersByAccountsSuccess {
    type: JointAccountHolderActionTypes.GET_JOINT_ACCOUNT_HOLDERS_BY_ACCOUNTS_SUCCESS;
    response: Client[];
}

export interface GetJointAccountHoldersByAccountsError {
    type: JointAccountHolderActionTypes.GET_JOINT_ACCOUNT_HOLDERS_BY_ACCOUNTS_ERROR;
    accounts: ClientAccount[];
    clientId: string;
}

export type JointAccountHoldersAction = GetJointAccountHoldersByAccounts | GetJointAccountHoldersByAccountsSuccess | GetJointAccountHoldersByAccountsError;
