import { InfoMessage, InjectTranslationsProps, Loader } from "ia-react-core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Client } from "../../../../../common/client/interfaces/Client";
import { SecondaryButton } from "../../../../../common/common.style";
import ToggleButtonCombo, { ComboButtonProps } from "../../../../../common/components/ToggleButtonCombo";
import { ResponseError } from "../../../../../common/interfaces/ResponsePayload";
import MessageDirection from "../../../../../common/messages/enums/MessageDirection";
import { Attachment } from "../../../../../common/messages/interfaces/Attachment";
import ResponseErrorStatus from "../../../../../common/messages/interfaces/ResponseErrorStatus";
import ResponseSuccessStatus from "../../../../../common/messages/interfaces/ResponseSuccessStatus";
import MessagesService from "../../../../../common/messages/messages.service";
import { AddWithCircle, ArrowLeft } from "../../../../../common/svg";
import { REACT_APP_USE_ADVISOR_TO_CLIENT_MESSAGING } from "../../../../../constants/API";
import { BackToDocumentList, Container, Header, InformativeMessage, LoadMoreContainer, TableSection, ToggleSendDocumentButton } from "./DocumentsTab.style";
import DocumentsTable from "./DocumentsTable";
import SendDocuments from "./SendDocument";
import SendMessageAlertResults from "./SendMessageAlertResults/SendMessageAlertResults";

const service = new MessagesService();
export interface DocumentsTabStateToProps {
  partyId: string;
  hasOwnedOpenAccounts: boolean;
  requiredInformationLoaded: boolean;
  userIsAdmin: boolean;
}

export interface DocumentsTabProps {
  client: Client;
}

interface DocumentsTabComposedProps extends DocumentsTabStateToProps, DocumentsTabProps, InjectTranslationsProps { }
const DocumentsTab: React.FC<DocumentsTabComposedProps> = ({ partyId, hasOwnedOpenAccounts, client, requiredInformationLoaded, userIsAdmin, t }: DocumentsTabComposedProps): JSX.Element => {
  const [displaySendDocument, setDisplaySendDocument] = useState<boolean>(false);
  const [displaySuccess, setDisplaySuccess] = useState<ResponseSuccessStatus | undefined>();
  const [displayError, setDisplayError] = useState<ResponseError | undefined>();
  const [attachmentsLoading, setAttachmentsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [attachments, setAttachment] = useState<Attachment[]>([]);
  const [showLoadMore, setShowLoadMore] = useState<boolean>(false);
  const [filterValueSelected, setFilterValueSelected] = useState<string>(MessageDirection.received);
  const [showSendMessageAlert, setShowSendMessageAlert] = React.useState(true);
  const pageSize = 10;

  const sentFilterProperties: ComboButtonProps = useMemo(() => ({ text: t("documentsTab.sentbutton"), value: MessageDirection.sent, defaultChecked: filterValueSelected === MessageDirection.sent }), [filterValueSelected, t]);
  const receivedFilterProperties: ComboButtonProps = useMemo(() => ({ text: t("documentsTab.receivedbutton"), value: MessageDirection.received, defaultChecked: filterValueSelected === MessageDirection.received }), [filterValueSelected, t]);

  const getAttachments = useCallback(async () => {
    setAttachmentsLoading(true);
    setDisplayError(undefined);
    try {
      const pagedAttachments = await service.getAttachmentsForParty(partyId, page, pageSize, filterValueSelected);
      setShowLoadMore(pagedAttachments.length === pageSize);
      if (page === 0) {
        setAttachment(pagedAttachments);
      } else {
        setAttachment((previousValue) => previousValue.concat(pagedAttachments));
      }
    } catch (error) {
      setAttachment([]);
      setShowLoadMore(false);
      setDisplaySuccess(undefined);
      setDisplayError({
        status: ResponseErrorStatus.DocumentLoadFailed,
        detail: error as string,
      });
      setShowSendMessageAlert(true);
      setTimeout(() => { setShowSendMessageAlert(false); }, 5000);
    }

    setAttachmentsLoading(false);
  }, [partyId, page, filterValueSelected]);

  useEffect(() => {
    if (partyId) {
      getAttachments();
    } else {
      setAttachment([]);
    }
  }, [getAttachments, partyId]);

  const onTogglingSendDocument = (visible: boolean): void => {
    setDisplaySuccess(undefined);
    setDisplayError(undefined);
    setDisplaySendDocument(visible);
    onFilterSelected(MessageDirection.sent);
  };

  const onSuccess = (responseSuccessStatus: ResponseSuccessStatus): void => {
    setDisplaySendDocument(false);
    setDisplayError(undefined);
    setDisplaySuccess(responseSuccessStatus);
    setShowSendMessageAlert(true);
    setTimeout(() => { setShowSendMessageAlert(false); }, 5000);
    if (page !== 0) {
      setPage(0);
    } else {
      getAttachments();
    }
  };

  const onError = (error: ResponseError): void => {
    if (error !== undefined && error.status) {
      if (error.detail === 'NoEmailSent') {
        setDisplaySendDocument(false);
        getAttachments();
      }
      setDisplaySuccess(undefined);
      setDisplayError(error);
      setShowSendMessageAlert(true);
      setTimeout(() => { setShowSendMessageAlert(false); }, 5000);
    }
  };

  const onSend = (): void => {
    setDisplayError(undefined);
    setDisplaySuccess(undefined);
    setShowSendMessageAlert(false);
  };

  const onFilterSelected = (value: string): void => {
    setPage(0);
    setFilterValueSelected(value);
  };

  if (!requiredInformationLoaded) {
    return <Loader />;
  }

  if (!client.dateRegistered) {
    return (
      <InfoMessage>
        {t("documentsTab.clientNotRegistered")}
        <br />
        {t("documentsTab.optionOnlyAvailableForRegisteredClient")}
      </InfoMessage>
    );
  }

  const userCanSendDocuments = !userIsAdmin && hasOwnedOpenAccounts;
  return (
    <Container>
      {displaySendDocument && <BackToDocumentList onClick={() => onTogglingSendDocument(false)}>{ArrowLeft()}{t("documentsTab.linkToDocumentList")}</BackToDocumentList>}
      {!displaySendDocument && REACT_APP_USE_ADVISOR_TO_CLIENT_MESSAGING && (
        <Header>
          <ToggleButtonCombo firstButton={receivedFilterProperties} secondButton={sentFilterProperties} onValueChange={onFilterSelected} />
          { userCanSendDocuments && <ToggleSendDocumentButton onClick={() => onTogglingSendDocument(true)}>{t("documentsTab.sendADocument")}{AddWithCircle()}</ToggleSendDocumentButton>}
        </Header>
      )}
      <InformativeMessage>{t("documentsTab.messageNotAFileStorage")}</InformativeMessage>
      <SendMessageAlertResults t={t} error={displayError} displaySuccess={displaySuccess} showSendMessageAlert={showSendMessageAlert} />
      {!displaySendDocument && (
        <TableSection>
          <DocumentsTable attachments={attachments} loading={attachmentsLoading} onError={onError} onSuccess={onSuccess} type={filterValueSelected} partyId={partyId} userCanSendDocuments={userCanSendDocuments}/>
        </TableSection>)}
      {!displaySendDocument && showLoadMore &&
        <LoadMoreContainer>
          <SecondaryButton onClick={() => setPage(page + 1)}>{t('App:loadMore')}</SecondaryButton>
        </LoadMoreContainer>}
      {displaySendDocument && <SendDocuments onSuccess={onSuccess} onError={onError} onSend={onSend} client={client} />}
    </Container>
  );
};

export default DocumentsTab;
