import { ColumnType } from "antd/lib/table";
import * as React from "react";

import { Client } from "../../../../../../common/client/interfaces/Client";
import { LongDate } from "../../../../../../common/components/FormatDate";
import { JointAccountHoldersTableProps } from "../JointAccountHoldersTable";

export function DateOfBirthColumn(props: JointAccountHoldersTableProps): ColumnType<Client> {
  const { t, locale } = props;

  return {
    title: t("App:dateOfBirth"),
    dataIndex: "dateOfBirth",
    width: "25%",
    key: "dateOfBirth",
    render: (value: Date) => (
      <LongDate locale={locale}>{value}</LongDate>
    ),
  };
}
