import { Reducer } from "redux";

import { LimitSearchSignaturePackage } from "../../../constants/API";
import { initialSignaturePackageState, SignaturePackageState } from "../../../interfaces/SignaturePackageState";
import SearchType from "../enum/SearchType";
import {
  SearchSignaturePackage,
  SearchSignaturePackageError,
  SearchSignaturePackageSuccess,
  SetNbSearchLaunched,
  SignaturePackageAction,
  SignaturePackageActionTypes as types,
} from "./signaturePackage.actions.types";

/* eslint-disable no-restricted-syntax */

const SignaturePackageReducer: Reducer<SignaturePackageState, SignaturePackageAction> = (state = initialSignaturePackageState, action): SignaturePackageState => {
  switch (action.type) {
    case types.SEARCH_SIGNATUREPACKAGE:
      return handleSearchSignaturePackage(state, action);
    case types.SEARCH_SIGNATUREPACKAGE_SUCCESS:
      return handleSearchSignaturePackageSuccess(state, action);
    case types.SEARCH_SIGNATUREPACKAGE_ERROR:
      return handleSearchSignaturePackageError(state, action);
    case types.LOAD_MORE_SIGNATUREPACKAGES:
      return handleLoadMoreSignaturePackages(state);
    case types.SET_NB_SEARCH_LAUNCHED:
      return handleSetNbSearchLaunched(state, action);
    default:
      return state;
  }
};

const handleSearchSignaturePackage = (state: SignaturePackageState, { searchCriteria }: SearchSignaturePackage) => ({
  ...state,
  searchCriteria,
  searchKeyValueErrors: [],
  signaturePackages: [],
  loading: true,
  searchLoading: true,
  totalResults: 0,
  currentOffset: 0,
});

const handleSearchSignaturePackageSuccess = (state: SignaturePackageState, { paginatedResponse, searchKeyValue }: SearchSignaturePackageSuccess) => {
  const signaturePackages = [...state.signaturePackages];
  let totalResults = 0;
  let { currentOffset } = { ...state };

  for (const signaturePackage of paginatedResponse.data) {
    if (signaturePackages.findIndex((element) => element.id === signaturePackage.id) === -1) {
      signaturePackages.push(signaturePackage);
    }
  }

  totalResults = state.totalResults < paginatedResponse.total ? paginatedResponse.total : state.totalResults;
  if (searchKeyValue.searchType === SearchType.clientName) {
    currentOffset = currentOffset + paginatedResponse.data.length >= paginatedResponse.total ? paginatedResponse.total : paginatedResponse.offset + paginatedResponse.limit;
  }

  const searchLoading: boolean = state.nbSearchLaunched - 1 > 0 && signaturePackages.length === 0;

  return { ...state, signaturePackages, loading: false, searchLoading, currentOffset, totalResults, nbSearchLaunched: state.nbSearchLaunched - 1 };
};

const handleSearchSignaturePackageError = (state: SignaturePackageState, { searchKeyValue }: SearchSignaturePackageError) => ({
  ...state,
  searchKeyValueErrors: [...state.searchKeyValueErrors, searchKeyValue],
  loading: false,
});

const handleLoadMoreSignaturePackages = (state: SignaturePackageState) => {
  let { currentOffset } = { ...state };
  const totalResults = state.signaturePackages.length;
  const limit = LimitSearchSignaturePackage;

  currentOffset = currentOffset + limit >= totalResults ? totalResults : currentOffset + limit;

  return { ...state, currentOffset };
};

const handleSetNbSearchLaunched = (state: SignaturePackageState, { nbSearchLaunched }: SetNbSearchLaunched) => ({
  ...state,
  nbSearchLaunched,
});

export default SignaturePackageReducer;
