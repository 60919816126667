import { TFunction } from "i18next";
import * as React from "react";

export interface PersonalizedAccessCodeProps {
    children: string;
    t: TFunction;
  }

const PersonalizedAccessCode: React.FC<PersonalizedAccessCodeProps> = ({ children, t }: PersonalizedAccessCodeProps) => <>{children || t("App:client.noPersonalizedAccessCode")}</>;

export default PersonalizedAccessCode;
