import { ColumnType } from "antd/lib/table";
import { ExpandableConfig } from "antd/lib/table/interface";
import { TFunction } from "i18next";
import { RenderExpandIconProps } from "rc-table/lib/interface";
import * as React from "react";

import { expandIcon } from "../../../../../../common/table/ExpandIcon";
import StyledTable from "../../../../../../common/table/StyledTable";
import { AccountGroupedByBankAccount } from "../../../../interfaces/AccountGroupedByBankAccount";
import { accountsExpandedRow } from "../AccountsTable/AccountsExpandedRow";
import { AccountNumberColumn, InstitutionNameColumn, InstitutonBranchColumn } from "./Columns";

export interface BankAccountsTableProps {
  t: TFunction;
  dataSource: AccountGroupedByBankAccount[];
  locale: string;
  loadingAccounts: boolean;
}

const tableColumns = (props: BankAccountsTableProps): ColumnType<AccountGroupedByBankAccount>[] => {
  const columns: ColumnType<AccountGroupedByBankAccount>[] = [InstitutionNameColumn(props), InstitutonBranchColumn(props), AccountNumberColumn(props)];
  return columns;
};

const expandable = (props: BankAccountsTableProps): ExpandableConfig<AccountGroupedByBankAccount> => ({
  rowExpandable: (record: AccountGroupedByBankAccount) => record.accounts.length > 0,
  expandedRowRender: (record: AccountGroupedByBankAccount) => accountsExpandedRow(record.accounts, record.accountId, props.t),
  expandIconColumnIndex: 4,
  expandIcon: (expandProps: RenderExpandIconProps<AccountGroupedByBankAccount>) => expandIcon<AccountGroupedByBankAccount>(expandProps),
});

const BankAccountsTable: React.FC<BankAccountsTableProps> = (props: BankAccountsTableProps) => {
  const { dataSource, loadingAccounts, t } = props;
  return <StyledTable t={t} loading={loadingAccounts} columns={tableColumns(props)} dataSource={dataSource} rowKey="id" pagination={false} expandable={expandable(props)} />;
};

export default BankAccountsTable;
