import { ChartData, ChartLegendOptions, ChartOptions, ChartTooltipItem, ChartTooltipOptions } from "chart.js";
import * as React from "react";
import { ChartComponentProps, Doughnut } from "react-chartjs-2";

import { Styled } from "./StyledDoughnut.style";

export interface StyledDoughnutProps extends ChartComponentProps {}

export const defaultTooltips: ChartTooltipOptions = {
  callbacks: {
    label: (tooltipItem: ChartTooltipItem, data: ChartData) => {
      if (tooltipItem.index === undefined || data.labels === undefined) {
        return '';
      }
      const label = data.labels[tooltipItem.index] || '';
      return `${label}`;
    },
  },
};

const StyledDoughnut: React.FC<StyledDoughnutProps> = (props: StyledDoughnutProps) => {
  const { legend, options } = props;

  const resultLegend = React.useMemo<ChartLegendOptions | undefined>(() => {
    if (!legend) {
      return { position: "right", labels: { boxWidth: 15, usePointStyle: true, padding: 10 } };
    }
    return legend;
  }, [legend]);

  const resultOptions = React.useMemo<ChartOptions | undefined>(() => {
    if (!options) {
      return {
        tooltips: defaultTooltips,
      };
    }
    return options;
  }, [options]);

  return (
    <Styled>
      <Doughnut
        {...props}
        legend={resultLegend}
        options={resultOptions}
      />
    </Styled>
  );
};
export default StyledDoughnut;
