export const ClearSession = () => {
    sessionStorage.clear();
};

type SessionName = 'accountStatusUpdated';

export const SetStorageKeyValue = (key: SessionName, value: any) => {
    sessionStorage.setItem(key, String(value));
};

export const GetIsStorageValueByKey = (key: SessionName): boolean => {
    const value = sessionStorage.getItem(key);
    return value !== undefined && value === 'true';
};

export const GetIsStorageValueByKeyAndValue = (key: SessionName, value: any): boolean => {
    const currentValue = sessionStorage.getItem(key);
    if(currentValue === undefined) return false;
    else return currentValue === value;
};
