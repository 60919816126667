import { ChartData, ChartPoint } from "chart.js";
import { TFunction } from "i18next";
import { Moment } from "moment";

import RiskToleranceLevel from "../enum/RiskToleranceLevel";
import { RiskTolerance } from "../interfaces/RiskTolerance";

/* eslint-disable no-nested-ternary */
export class RiskToleranceHelper {
  static getLabel(level: RiskToleranceLevel, t: TFunction): string {
    switch (level) {
      case RiskToleranceLevel.Low:
        return t("App:riskTolerance.low");
      case RiskToleranceLevel.Medium:
        return t("App:riskTolerance.medium");
      case RiskToleranceLevel.MediumHigh:
        return t("App:riskTolerance.mediumHigh");
      case RiskToleranceLevel.High:
        return t("App:riskTolerance.high");
      default:
        return t("App:riskTolerance.unknown");
    }
  }

  static getBackgroundColor(level: RiskToleranceLevel): string {
    switch (level) {
      case RiskToleranceLevel.Low:
        return "#003DA5";
      case RiskToleranceLevel.Medium:
        return "#407BFF";
      case RiskToleranceLevel.MediumHigh:
        return "#7BC2D9";
      case RiskToleranceLevel.High:
        return "#C0CA33";
      default:
        return "";
    }
  }

  static getRiskToleranceDataChart(risksTolerance: RiskTolerance[], t: TFunction): ChartData {
    if (!risksTolerance) {
      return {};
    }

    const values = [...risksTolerance];
    const labels: Array<string | string[] | number | number[] | Date | Date[] | Moment | Moment[]> = [];
    const data: Array<number | null | undefined | number[]> | ChartPoint[] = [];
    const backgroundColor: string[] = [];
    const hoverBackgroundColor: string[] = [];
    values.sort((a: RiskTolerance, b: RiskTolerance) => a.level - b.level);

    values
      .filter((riskTolerance: RiskTolerance) => riskTolerance.percentage > 0)
      .forEach((riskTolerance: RiskTolerance) => {
        const percentage = riskTolerance.percentage * 100;
        labels.push(`${RiskToleranceHelper.getLabel(riskTolerance.level, t)}: ${percentage}%`);
        data.push(percentage);
        backgroundColor.push(RiskToleranceHelper.getBackgroundColor(riskTolerance.level));
      });

    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor,
          hoverBackgroundColor,
        },
      ],
    } as ChartData;
  }
}
