import { Advisor } from "../../../../../../common/advisor/interfaces/Advisor";
import { Address } from "../../../../../../common/interfaces/Address";
import { Website } from "../../../../../../common/interfaces/Website";
import { Address as AddressIcon, Laptop, Phone as PhoneIcon } from "../../../../../../common/svg";
import * as React from 'react';

import BranchPhoneCard from './branchPhoneCard';
import { WebsitesSection } from './styles/advisorCard.style';
import {
  BranchDiv,
  BranchInfo,
  BranchInfos,
  FlexDiv,
  TeamMembers,
  TeamSection,
  BranchSubTitle,
} from './styles/SearchResult.style';
import TeamMemberCard from './teamMemberCard';
import { TFunction } from 'i18next';
import { TeamSubtitle } from '../styles/SearchResult.style';

interface ILocalProps {
  branch: Advisor;
  team: Advisor[];
  t: TFunction;
  locale: string;
}

const formatWebSiteUrl = (url: string): string => {
  const URL_SCHEME_REGEXP = /^((?:f|ht)tps?:)?\/\//;
  const DEFAULT_SCHEME = 'http';
  let formatedUrl = url;

  if (!URL_SCHEME_REGEXP.test(url)) {
    formatedUrl = `${DEFAULT_SCHEME}://${url}`;
  }

  return formatedUrl;
};

const teamMemberCards = (teamMembers: Advisor[], locale: string): JSX.Element[] => teamMembers.slice(0, 3).map((teamMember: Advisor) => <TeamMemberCard key={teamMember.id} teamMember={teamMember} locale={locale} />);

const BranchCard: React.FC<ILocalProps> = ({ branch, team, locale, t }: ILocalProps) => {
  if (branch === undefined || team === undefined) {
    return <></>;
  }
  const branchAddress: Address | null = branch.addresses.length > 0 ? branch.addresses[0] : null;
  const branchWebsite: Website | null = branch.websites.length > 0 ? branch.websites[0] : null;
  return (
    <BranchDiv>
      <BranchInfos>
        {branchAddress !== null && (
          <FlexDiv>
            {AddressIcon(40)}
            <BranchInfo>
            <BranchSubTitle>  {t('App:contactInformations.address').toUpperCase()}</BranchSubTitle>
              <div>{branchAddress.streetName}</div>
              <div>{branchAddress.additionalInfo}</div>
              <div>
                {branchAddress.municipality} {branchAddress.regionCode}
              </div>
              {branchAddress.postalCode}
            </BranchInfo>
          </FlexDiv>
        )}
        {branch.phones.length > 0 && (
          <FlexDiv>
            {PhoneIcon(40)}
            <BranchInfo>
            <BranchSubTitle>{t('App:contactInformations.phone').toUpperCase()}</BranchSubTitle>
              <BranchPhoneCard phones={branch.phones} />
            </BranchInfo>
          </FlexDiv>
        )}
        {branchWebsite !== null && (
          <FlexDiv>
            {Laptop(40)}
            <BranchInfo>
            <BranchSubTitle>{t('App:contactInformations.website').toUpperCase()}</BranchSubTitle>
              <WebsitesSection>
                <a href={formatWebSiteUrl(branchWebsite.url)} target="_blank">
                  {branchWebsite.url}
                </a>
              </WebsitesSection>
            </BranchInfo>
          </FlexDiv>
        )}
      </BranchInfos>
      {team.length !== 0 && (
        <TeamSection>
          <TeamSubtitle>{t('advisorCard.team').toUpperCase()}</TeamSubtitle>
          <TeamMembers> {teamMemberCards(team, locale)}</TeamMembers>
        </TeamSection>
      )}
    </BranchDiv>
  );
};

export default BranchCard;
