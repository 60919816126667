import { LinkedUser } from "../../interfaces/LinkedUser";
import { Document } from "../interfaces/Document";
import { SignaturePackage } from "../interfaces/SignaturePackage";
import { Signer } from "../interfaces/Signer";
import { Status } from "../interfaces/Status";

/* eslint-disable @typescript-eslint/no-explicit-any */
export class SignaturePackageMapper {
  public mapSignaturePackage(data: any): SignaturePackage {
    const transaction: SignaturePackage = {
      createdTime: data.createdTime,
      status: this.mapStatus(data),
      id: data.id,
      transactionId: data.transactionId,
      signers: this.mapSigners(data.signers),
      documents: this.mapDocuments(data.documents),
      repCode: data.metadata.advisorId,
      sharer: this.mapLinkedUser(data.metadata.sharer),
      client: this.mapLinkedUser(data.metadata.client),
      expireOn: data.expireOn,
    };
    return transaction;
  }

  private mapStatus(transac: any): Status {
    const status: Status = {
      statusModifiedDate: transac.statusModifiedDate,
      transactionStatus: transac.status,
      cancelReason: transac.cancelReason,
      cancelBy: transac.metadata.cancelBy,
    };
    return status;
  }

  private mapSigners(value: any[]): Signer[] {
    const signers: Signer[] = [];

    value.forEach((sign) => {
      signers.push({
        status: sign.status,
        firstName: sign.firstName,
        lastName: sign.lastName,
        email: sign.email,
        reason: sign.reason,
        authenticationMode: sign.authenticationMode,
        question: sign.question,
        phoneNumber: sign.phoneNumber,
        answer: sign.answer,
        role: sign.role,
        fullName: `${sign.firstName} ${sign.lastName}`,
      });
    });

    return signers;
  }

  private mapDocuments(value: any[]): Document[] {
    const documents: Document[] = [];

    value.forEach((document: any) => {
      const { type, other, id } = document;
      documents.push({
        name: document.name,
        type: other !== "" ? other : type,
        id,
      });
    });

    return documents;
  }

  private mapLinkedUser(value: any): LinkedUser {
    const linkedUser: LinkedUser = {
      id: value.id,
      name: decodeURIComponent(value.name),
    };

    return linkedUser;
  }
}
