import * as React from "react";

import { AddressInformation } from "../ClientSearchResult.style";

interface LocalProps {
  state: string;
  municipality: string;
  postalCode: string;
}

const ClientSearchResultAddress: React.FC<LocalProps> = ({ municipality, postalCode, state }: LocalProps) => {
  const stateCode = state ? ` (${state.toUpperCase()}) ` : " ";
  return <AddressInformation>{`${municipality}${stateCode}${postalCode}`}</AddressInformation>;
};

export default ClientSearchResultAddress;
