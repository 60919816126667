import { all, Effect } from "@redux-saga/core/effects";

import AdvisorSaga from "../../../common/advisor/redux/advisor.saga";
import JointAccoundHolderSaga from "../../../common/client/jointAccountHolders/redux/jointAccountHolders.saga";
import ClientSaga from "../../../common/client/redux/client.saga";

/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/unbound-method */
export default function* ClientProfileSaga(): IterableIterator<Effect> {
  yield all([ClientSaga(), JointAccoundHolderSaga(), AdvisorSaga()]);
}
