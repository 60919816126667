import { all, apply, call, Effect, fork, put, takeLatest } from "@redux-saga/core/effects";

import { resetError, setError, setSearchCriteriaHistory } from "../../../redux/app.actions";
import Section from "../../enum/Section";
import { PaginatedResponse } from "../../interfaces/PaginatedResponse";
import { RequestQuery } from "../../interfaces/RequestQuery";
import { SearchCriteria } from "../../interfaces/SearchCriteria";
import SearchType from "../enum/SearchType";
import { SearchKeyValue } from "../interfaces/SearchKeyValue";
import { SignaturePackage } from "../interfaces/SignaturePackage";
import SignaturePackageService from "../signaturePackage.service";
import { searchSignaturePackageError, searchSignaturePackageSuccess, setNbSearchLaunched } from "./signaturePackage.actions";
import { SearchSignaturePackage, SignaturePackageActionTypes as types } from "./signaturePackage.actions.types";

/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/unbound-method */

function* successSignaturePackageSearch(paginatedResponse: PaginatedResponse<SignaturePackage>, searchKeyValue: SearchKeyValue) {
  yield put(searchSignaturePackageSuccess(paginatedResponse, searchKeyValue));
}

function* searchByRepCodes(searchCriteria: SearchCriteria, offset: number) {
  try {
    const service = new SignaturePackageService();
    const requestQueries: RequestQuery[] = searchCriteriaStatusQueries(searchCriteria);
    const response: PaginatedResponse<SignaturePackage> = yield apply(service, service.search, [requestQueries, offset, searchCriteria.limit]);
    yield call(successSignaturePackageSearch, response, { searchType: SearchType.repCodes, value: searchCriteria.text });
  } catch (error) {
    yield put(searchSignaturePackageError({ searchType: SearchType.repCodes, value: searchCriteria.text }));
    yield put(setError({ section: Section.signaturePackageSearch, error }));
  }
}

function* searchByClientName(searchCriteria: SearchCriteria, offset: number) {
  try {
    const service = new SignaturePackageService();
    const requestQueries: RequestQuery[] = searchCriteriaStatusQueries(searchCriteria);
    const response: PaginatedResponse<SignaturePackage> = yield apply(service, service.search, [requestQueries, offset, searchCriteria.limit]);

    yield call(successSignaturePackageSearch, response, { searchType: SearchType.clientName, value: searchCriteria.text });
  } catch (error) {
    yield put(searchSignaturePackageError({ searchType: SearchType.clientName, value: searchCriteria.text }));
    yield put(setError({ section: Section.signaturePackageSearch, error }));
  }
}

function* searchByPackageId(searchCriteria: SearchCriteria) {
  try {
    const service = new SignaturePackageService();
    const requestQueries: RequestQuery[] = [];
    const response: PaginatedResponse<SignaturePackage> = yield apply(service, service.search, [requestQueries, 0, searchCriteria.limit]);
    yield call(successSignaturePackageSearch, response, { searchType: SearchType.signaturePackageId, value: searchCriteria.text });
  } catch (error) {
    yield put(searchSignaturePackageError({ searchType: SearchType.signaturePackageId, value: searchCriteria.text }));
    yield put(setError({ section: Section.signaturePackageSearch, error }));
  }
}

function* handleSearchSignaturePackage({ searchCriteria }: SearchSignaturePackage) {
  const nbSearchLaunched = searchCriteria.text !== "" ? 3 : 1;
  yield put(resetError());
  yield put(setNbSearchLaunched(nbSearchLaunched));

  yield fork(searchByRepCodes, searchCriteria, 0);
  if (searchCriteria.text !== "") {
    yield fork(searchByClientName, searchCriteria, 0);
    yield fork(searchByPackageId, searchCriteria);
  }

  yield put(setSearchCriteriaHistory(searchCriteria));
}

function* watchSearchSignaturePackage() {
  yield takeLatest(types.SEARCH_SIGNATUREPACKAGE, handleSearchSignaturePackage);
}

export default function* SignaturePackageSaga(): IterableIterator<Effect> {
  yield all([watchSearchSignaturePackage()]);
}

const statusFiltersQueries = (statusFilters: string[]): RequestQuery[] => statusFilters.map((filter) => ({ queryString: "statusFilter", queryValue: filter }));

const searchCriteriaStatusQueries = (searchCriteria: SearchCriteria): RequestQuery[] => {
  if (searchCriteria.statusFilters) {
    return [...statusFiltersQueries(searchCriteria.statusFilters)];
  }
  return [];
};
