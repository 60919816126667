import styled from "styled-components";

import { StyledSection } from "../../../clientProfile.style";

export const InvestmentKnowledgeContainer = styled(StyledSection)``;
export const InvestmentObjectivesContainer = styled(StyledSection)``;

export const ChartContainer = styled.div`
max-width:400px;

`;

export const KnowledgeLevelItem = styled.div`
    display: inline-block;
    background: ${(props) => props.theme.colors.white};
    width: 25%;
    text-align: center;
    border-right: 1px solid ${(props) => props.theme.colors.gray2};
    padding: 9px 0px;
    font-size: 14px;
`;

export const KnowledgeLevelSelectedItem = styled(KnowledgeLevelItem)`
    font-weight: 600;
    background: ${(props) => props.theme.colors.blue5};
    color: ${(props) => props.theme.colors.white};
`;

export const OwnerLevelItem = styled.div`
    background: ${(props) => props.theme.colors.purple1};
    padding: 8px 16px 8px 16px;
`;

export const AccountTitle = styled.div`
    background: ${(props) => props.theme.colors.blue5};
    font-weight: bold;
    font-size: 14px;
    color: ${(props) => props.theme.colors.white};
    padding: 8px 16px 8px 16px;
`;
export const ChartTitle = styled.h4`
`;

export const Accounts = styled.div`
    border-bottom-color: ${(props) => props.theme.colors.blue6};
    border-bottom-style: solid;
    border-bottom-width: 2px;

    .styled-table.no-border {
        .ant-table {
          .ant-table-tbody {
             border-bottom: none;
          }
        }
      }
`;
