import { all, apply, call, Effect, fork, put, select, takeLatest } from "@redux-saga/core/effects";

import { LimitSearchClientAutoComplete } from "../../../constants/API";
import { resetError, setError, setSearchCriteriaHistory } from "../../../redux/app.actions";
import { getAccountsClientInfoByClientIds } from "../../account/redux/account.actions";
import AccountSaga from "../../account/redux/account.saga";
import { getAdvisorsByRepCodes } from "../../advisor/redux/advisor.actions";
import { selectRepCodes } from "../../advisor/redux/advisor.selector";
import Section from "../../enum/Section";
import { PaginatedResponse } from "../../interfaces/PaginatedResponse";
import { ResponsePayload } from "../../interfaces/ResponsePayload";
import { SearchCriteria } from "../../interfaces/SearchCriteria";
import ClientService from "../client.service";
import SearchType from "../enum/SearchType";
import { Client } from "../interfaces/Client";
import { SearchKeyValue } from "../interfaces/SearchKeyValue";
import { getJointAccountHoldersByAccounts } from "../jointAccountHolders/redux/jointAccountHolders.actions";
import {
  getClientError,
  getClientSuccess,
  searchClientError,
  searchClientSuccess,
  setNbSearchLaunched,
  getClientLocked,
  getClientLockedSuccess,
  getClientLockedError,
} from "./client.actions";
import {
  ClientActionTypes as types,
  GetClient,
  LoadMoreClients,
  SearchClient,
  SearchClientAutoComplete,
} from "./client.actions.types";

/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/unbound-method */

function* successClientSearch(paginatedResponse: PaginatedResponse<Client>, searchKeyValue: SearchKeyValue) {
  yield put(searchClientSuccess(paginatedResponse, searchKeyValue));
}

function* searchByFullName(searchCriteria: SearchCriteria, offset: number) {
  try {
    const service = new ClientService();
    const response: PaginatedResponse<Client> = yield apply(service, service.searchByFullName, [searchCriteria.text, searchCriteria.repCodes, offset, searchCriteria.limit]);

    yield call(successClientSearch, response, { searchType: SearchType.fullName, value: searchCriteria.text });
  } catch (error) {
    yield put(searchClientError({ searchType: SearchType.fullName, value: searchCriteria.text }));
    yield put(setError({ section: Section.clientSearch, error }));
  }
}

function* searchByAccountId(searchCriteria: SearchCriteria) {
  try {
    const service = new ClientService();
    const response: PaginatedResponse<Client> = yield apply(service, service.searchByAccountId, [searchCriteria.text, searchCriteria.repCodes]);
    yield call(successClientSearch, response, { searchType: SearchType.clientId, value: searchCriteria.text });
  } catch (error) {
    yield put(searchClientError({ searchType: SearchType.clientId, value: searchCriteria.text }));
    yield put(setError({ section: Section.clientSearch, error }));
  }
}

function* searchByAccessCode(searchCriteria: SearchCriteria) {
  try {
    const service = new ClientService();
    const response: PaginatedResponse<Client> = yield apply(service, service.searchByAccessCode, [searchCriteria.text, searchCriteria.repCodes]);
    yield call(successClientSearch, response, { searchType: SearchType.accessCode, value: searchCriteria.text });
  } catch (error) {
    yield put(searchClientError({ searchType: SearchType.accessCode, value: searchCriteria.text }));
    yield put(setError({ section: Section.clientSearch, error }));
  }
}

function* handleSearchClientAutoComplete({ criteria }: SearchClientAutoComplete): any {
  if (criteria !== "") {
    const repCodes = yield select(selectRepCodes);
    const searchCriteria: SearchCriteria = {
      text: criteria,
      repCodes,
      limit: LimitSearchClientAutoComplete,
    };

    yield fork(searchByFullName, searchCriteria, 0);

    if (criteria.length === 6) {
      yield fork(searchByAccountId, searchCriteria);
    }
  }
}

function* watchSearchClientAutoComplete() {
  yield takeLatest(types.SEARCH_CLIENT_AUTOCOMPLETE, handleSearchClientAutoComplete);
}

function* handleSearchClient({ searchCriteria }: SearchClient) {
  const nbSearchLaunched = searchCriteria.text !== "" ? 3 : 1;
  yield put(resetError());
  yield put(setNbSearchLaunched(nbSearchLaunched));

  yield fork(searchByFullName, searchCriteria, 0);
  if (searchCriteria.text !== "") {
    yield fork(searchByAccountId, searchCriteria);
    yield fork(searchByAccessCode, searchCriteria);
  }

  yield put(setSearchCriteriaHistory(searchCriteria));
}

function* watchSearchClient() {
  yield takeLatest(types.SEARCH_CLIENT, handleSearchClient);
}

function* handleLoadMoreClients({ searchCriteria, offset }: LoadMoreClients) {
  yield fork(searchByFullName, { ...searchCriteria }, offset);
  yield put(setSearchCriteriaHistory({ ...searchCriteria }));
}

function* watchLoadMoreClients() {
  yield takeLatest(types.LOAD_MORE_CLIENTS, handleLoadMoreClients);
}

function* handleFetchclient({ id, fetchAdditionnalInfos }: GetClient) {
  try {
    yield put(resetError());
    const service = new ClientService();
    const response: ResponsePayload<Client> = yield apply(service, service.getByClientId, [id]);
    yield put(getClientSuccess(response));

    if (fetchAdditionnalInfos) {
      const client = response.data;
      const { accounts } = client;
      const clientIds = accounts.map((account) => account.id).filter((v, i, a) => a.indexOf(v) === i);
      const repCodes = accounts.map((account) => account.repCode).filter((v, i, a) => a.indexOf(v) === i);

      yield put(getAdvisorsByRepCodes(repCodes));
      yield put(getAccountsClientInfoByClientIds(clientIds));
      yield put(getJointAccountHoldersByAccounts(accounts, client.id));
      yield put(getClientLocked(client.id));
    }
  } catch (error) {
    yield put(getClientError(id));
    yield put(setError({ section: Section.clientProfile, error }));
  }
}

function* watchGetClient() {
  yield takeLatest(types.GET_CLIENT, handleFetchclient);
}

function* handleFetchClientLocked({ id }: GetClient) {
  try {
    yield put(resetError());
    const service = new ClientService();
    const response: ResponsePayload<any> = yield apply(service, service.getClientLockedById, [id]);
    yield put(getClientLockedSuccess(response.data.isLocked));

  } catch (error) {
    yield put(getClientLockedError(id));
  }
}

function* watchGetClienLocked() {
  yield takeLatest(types.GET_CLIENT_LOCKED, handleFetchClientLocked);
}

export default function* ClientSaga(): IterableIterator<Effect> {
  yield all([watchSearchClientAutoComplete(), watchSearchClient(), watchLoadMoreClients(), watchGetClient(), watchGetClienLocked(), AccountSaga()]);
}
