import * as faker from "faker";
import moment from "moment";

import { LinkedUser } from "../interfaces/LinkedUser";
import { PaginatedResponse } from "../interfaces/PaginatedResponse";
import { AuthenticationMethod, SignaturePackageStatus, SignerRole, SignerStatus } from "./enum";
import { Document } from "./interfaces/Document";
import { SignaturePackage } from "./interfaces/SignaturePackage";
import { Signer } from "./interfaces/Signer";
import { Status } from "./interfaces/Status";

/* eslint-disable @typescript-eslint/no-explicit-any */
class SignaturePackageMockService {
  public async search(offset: number, limit: number): Promise<PaginatedResponse<SignaturePackage>> {
    const signaturePackagesPromise = await new Promise<PaginatedResponse<SignaturePackage>>((resolve) => {
      setTimeout(() => {
        resolve(this.getSignaturePackages(offset, limit));
      }, 2000);
    });

    return signaturePackagesPromise;
  }

  private getSignaturePackages(offset: number, limit: number): PaginatedResponse<SignaturePackage> {
    const signaturePackages: SignaturePackage[] = [];
    const nbItems = faker.random.number({ min: 1, max: limit * 4 });

    for (let i = 0; i < nbItems; i++) {
      const randomStatus = faker.random.arrayElement(Object.values(SignaturePackageStatus));
      const createdTime = faker.date.between("2019-01-01", new Date());
      const expireOn: Date = new Date(Number(createdTime));
      const days = 28;
      expireOn.setDate(createdTime.getDate() + days);

      const signaturePackage: SignaturePackage = {
        createdTime: moment(createdTime).format(),
        expireOn: moment(expireOn).format(),
        status: this.getStatus(randomStatus),
        id: faker.random.number({ min: 100000, max: 999999 }),
        transactionId: faker.random.uuid(),
        signers: this.getSigners(),
        documents: this.getDocuments(),
        repCode: faker.lorem.word(),
        client: this.getLinkedUser(),
        sharer: this.getLinkedUser(),
      };

      signaturePackages.push(signaturePackage);
    }

    const paginatedResponse: PaginatedResponse<SignaturePackage> = {
      data: signaturePackages.sort((a: SignaturePackage, b: SignaturePackage) => new Date(b.createdTime).getTime() - new Date(a.createdTime).getTime()),
      limit,
      offset,
      total: signaturePackages.length,
    };

    return paginatedResponse;
  }

  private getLinkedUser(): LinkedUser {
    return {
      id: faker.random.alphaNumeric(7),
      name: faker.name.findName(),
    };
  }

  private getDocuments(): Document[] {
    const nbItems = faker.random.number({ min: 1, max: 4 });
    const documents: Document[] = [];

    for (let index = 0; index < nbItems; index++) {
      const document: Document = {
        id: faker.random.uuid(),
        name: `${faker.random.words()}.pdf`,
        type: "",
      };
      documents.push(document);
    }

    return documents;
  }

  private getSigners(): Signer[] {
    const nbItems = faker.random.number({ min: 1, max: 4 });
    const signers: Signer[] = [];

    for (let index = 0; index < nbItems; index++) {
      const firstName = faker.name.firstName();
      const lastName = faker.name.lastName();
      const status = faker.random.arrayElement(Object.values(SignerStatus));
      const role = faker.random.arrayElement(Object.values(SignerRole));
      let authenticationMode = faker.random.arrayElement(Object.values(AuthenticationMethod));

      if (authenticationMode === AuthenticationMethod.unknown) {
        authenticationMode = AuthenticationMethod.question;
      }

      let phoneNumber = "";
      let question = "";
      let answer = "";

      if (authenticationMode === AuthenticationMethod.question) {
        question = faker.random.word();
        answer = faker.random.alphaNumeric();
      } else {
        phoneNumber = faker.phone.phoneNumber();
      }

      let reason = "";
      if (status === SignerStatus.refused) {
        reason = faker.lorem.text();
      }

      const signer: Signer = {
        status,
        firstName,
        lastName,
        email: faker.internet.email(firstName, lastName),
        reason,
        fullName: `${firstName} ${lastName}`,
        role,
        authenticationMode,
        phoneNumber,
        question,
        answer,
      };
      signers.push(signer);
    }

    return signers;
  }

  private getStatus(randomStatus: SignaturePackageStatus): Status {
    let cancelReason = "";
    let cancelBy = "";

    if (randomStatus === SignaturePackageStatus.cancelled) {
      cancelReason = faker.lorem.text();
      cancelBy = faker.name.findName();
    }
    const status: Status = {
      statusModifiedDate: faker.date.past(2020),
      transactionStatus: randomStatus,
      cancelReason,
      cancelBy,
    };

    return status;
  }
}

export default SignaturePackageMockService;
